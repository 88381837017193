import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  paymentsListQuery,
  paymentsListOptionsQuery,
} from "../../Graphql/query/PaymentTracking";
import Privileges from "../../Constants/Privilages";
import { safeJsonParse, showToast } from "../../Helpers/HelperFns";
import { PaymentStats as Stats } from "../../Constants/PaymentTracking";
import { onFormResetAction, updateValueAction } from "../../Store/Actions";
import { serializeFetchPayment } from "../../Helpers/HelperFns/PaymentTracking";
import { changePaymentStatusMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  PreviewInvoice,
  PayInvoiceModal,
  EditInvoiceModal,
  SetInvoiceModal,
  EditPaymentModal,
  PreviewPaymentLogsModal,
  ChangePaymentsStatusModal,
  UpsertPaymentCommentModal,
} from "../../Components/PaymentTrackingModals";
import IconButtonWithTooltip, {
  EditIconButton,
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  CreditCard,
  UploadFile,
  Visibility,
  TextIncrease,
  SendOutlined,
  InsertComment,
  CancelOutlined,
} from "@mui/icons-material";
import {
  PaymentMethodCell,
  PaymentPartialPaymentCell,
} from "../../Components/PaymentTracking";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ReactShowMoreText from "react-show-more-text";
import { BSelect, DateTimePickerForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { ColorButton, ExportButton } from "../../Components/Buttons";

const reducer = "paymentTracking";
const filtersFormName = "paymentsListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };

const tabsTypes = [
  // Keep this order
  Stats.UPCOMING,
  Stats.PENDING,
  Stats.GENERATED,
  Stats.SENT,
  Stats.PAID,
  Stats.OVERDUE,
  Stats.CANCELLED,
];
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "payInvoice", Modal: PayInvoiceModal },
  { name: "previewInvoice", Modal: PreviewInvoice },
  { name: "editInvoice", Modal: EditInvoiceModal },
  { name: "editPayment", Modal: EditPaymentModal },
  { name: "setInvoice", Modal: SetInvoiceModal },
  { name: "previewLogs", Modal: PreviewPaymentLogsModal },
  { name: "addComment", Modal: UpsertPaymentCommentModal },
  { name: "changeState", Modal: ChangePaymentsStatusModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    plan_id: filters?.plan ? +filters?.plan : undefined,
    status: filters?.type,
    due_to: filters?.to || undefined,
    company_id: +filters?.companyID || +filters?.company || undefined,
    due_from: filters?.from || undefined,
  };
};

const PaymentsList = ({
  companyID,
  isCompanyProfile,
  isSubscriptionProfile,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [isClearSelectedRows, setIsClearSelectedRows] = React.useState(false);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [changeStatus, { loading: changeStatusLoading }] = useMutation(
    changePaymentStatusMutation
  );
  const { loading: isOptionsLoading } = useQuery(paymentsListOptionsQuery, {
    skip: isSubscriptionProfile,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ plans, companies }) => {
      dispatch(
        onFormResetAction("paymentTrackingOptions", {
          ...options,
          plans: plans?.data || [],
          companies: companies || [],
        })
      );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const { loading, refetch: refetchList } = useQuery(paymentsListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination.currentPage,
      ...sendFilters({ companyID, ...filters }),
    },
    onCompleted: ({ payments }) => {
      setData(payments?.data ? payments.data?.map(serializeFetchPayment) : []);
      setPagination(payments?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      {
        name: t("Company"),
        omit: isSubscriptionProfile || isCompanyProfile,
        cell: ({ company, company_id }) => (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={company}
          >
            <Link to={`/admin-panel/company-profile/${company_id}`}>
              {company}
            </Link>
          </HasPrivileges>
        ),
      },
      {
        grow: 1.5,
        name: t("Details"),
        cell: ({ details = "" }) => (
          <ReactShowMoreText>{details}</ReactShowMoreText>
        ),
      },
      { name: t("Expected Amount"), selector: "amount" },
      { name: t("Actual Amount"), selector: "receivedAmount" },
      {
        name: t("Method"),
        selector: (row) => (
          <PaymentMethodCell method={safeJsonParse(row?.method)} />
        ),
      },
      { name: t("suspension date"), selector: "dueDate" },
      { name: t("Due Date"), selector: "from" },
      {
        width: "150px",
        name: t("Invoice"),
        cell: (row) =>
          ![Stats.GENERATED, Stats.SENT, Stats.PAID, Stats.CANCELLED].includes(
            row?.status
          ) ? (
            <>
              <HasPrivileges
                reqireMain={[
                  Privileges.GENERATE_INVOICES,
                  Privileges.SUPER_PRIVILEGE,
                ]}
              >
                <ColorButton
                  isIconOnly
                  color="#2764AC"
                  variant="outlined"
                  label="Generate Invoice"
                  icon={<UploadFile fontSize="small" />}
                  sx={{ width: 30, height: 30 }}
                  onClick={() => handleGenerate(row?.id, row?.eInvoice)}
                />
              </HasPrivileges>
              <HasPrivileges
                reqireMain={[
                  Privileges.PREVIEW_PRO_FORMA_INVOICES,
                  Privileges.SUPER_PRIVILEGE,
                ]}
              >
                <ColorButton
                  isIconOnly
                  color="#7b8288"
                  variant="outlined"
                  label="Preview Invoice"
                  icon={<Visibility fontSize="small" />}
                  sx={{ width: 30, height: 30, mx: 1 }}
                  onClick={() => handlePreviewInvoice(row?.id)}
                />
              </HasPrivileges>
            </>
          ) : (
            <>
              {row?.invoice ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.VIEW_GENERATED_INVOICES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <button
                    className="link-btn"
                    onClick={() => handlePreviewInvoice(row?.id)}
                  >
                    {row?.invoice}
                  </button>
                </HasPrivileges>
              ) : null}
              {row.status === Stats.PAID ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.SET_INVOICES_AS_PAID,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <EditIconButton
                    label="Edit actual data"
                    onClick={() => handlePayPayment(row?.id)}
                  />
                </HasPrivileges>
              ) : (
                <HasPrivileges
                  reqireMain={[
                    Privileges.GENERATE_INVOICES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <IconButtonWithTooltip
                    label="Set Invoice serial"
                    icon={<TextIncrease fontSize="small" />}
                    onClick={() => handleSetInvoice(row?.id, row?.eInvoice)}
                  />
                </HasPrivileges>
              )}
            </>
          ),
      },
      {
        name: "E-Invoice",
        cell: (row) =>
          row?.eInvoice ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
      },
      { name: t("Status"), selector: "status" },
      {
        name: "comments",
        cell: (row) => (
          <>
            <IconButtonWithTooltip
              label="comments"
              onClick={() => handleAddComment(row)}
              icon={<InsertComment fontSize="small" />}
            />
          </>
        ),
      },
      {
        name: "partial payment",
        cell: (row) => (
          <PaymentPartialPaymentCell row={row} refetchList={refetchList} />
        ),
      },
      {
        name: "logs",
        cell: (row) => (
          <>
            <PreviewIconButton onClick={() => handlePreviewLogs(row)} />
          </>
        ),
      },

      {
        name: "actions",
        cell: (row) => {
          const overdueWithInvoice =
            row.status === Stats.OVERDUE && row?.invoice;

          return row.status !== Stats.CANCELLED ? (
            <>
              {/* Pay */}
              {[Stats.SENT, Stats.GENERATED].includes(row?.status) ||
              overdueWithInvoice ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.SET_INVOICES_AS_PAID,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <IconButtonWithTooltip
                    label="pay"
                    onClick={() => handlePayPayment(row?.id)}
                    icon={
                      <CreditCard fontSize="small" sx={{ color: "#009EFB" }} />
                    }
                  />
                </HasPrivileges>
              ) : null}

              {/* Send */}
              {[Stats.GENERATED].includes(row?.status) || overdueWithInvoice ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.SEND_INVOICES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <IconButtonWithTooltip
                    label="send"
                    onClick={() => handleSendPayment(row?.id)}
                    icon={
                      <SendOutlined
                        sx={{
                          fontSize: 18,
                          color: "#28a745",
                          transform: "rotate(-45deg) translateX(3px)",
                        }}
                      />
                    }
                  />
                </HasPrivileges>
              ) : null}
              {[Stats.UPCOMING, Stats.PENDING].includes(row?.status) ||
              (row.status === Stats.OVERDUE && !row?.invoice) ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.EDIT_PAYMENTS,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <EditIconButton
                    label="Edit Payment"
                    onClick={() => handleEditPayment(row?.id)}
                  />
                </HasPrivileges>
              ) : null}

              {/* Cancel */}
              {[Stats.SENT, Stats.PAID, Stats.GENERATED].includes(
                row?.status
              ) || overdueWithInvoice ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.CANCEL_GENERATED_INVOICES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <IconButtonWithTooltip
                    label="Cancel"
                    onClick={() => handleCancelPayment(row?.id)}
                    icon={
                      <CancelOutlined sx={{ color: "#DC3545", fontSize: 17 }} />
                    }
                  />
                </HasPrivileges>
              ) : null}
            </>
          ) : null;
        },
      },
    ],
    []
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleSelectRows = ({ selectedRows = [] }) => {
    setSelectedRows(selectedRows);
  };

  const handleOpenModal = (name, data = null) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, data, isOpen: true } : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePreviewInvoice = (id) => {
    handleOpenModal("previewInvoice", { id });
  };

  const handleSetInvoice = (id, eInvoice) => {
    handleOpenModal("setInvoice", { id, eInvoice });
  };

  const handleEditPayment = (id) => {
    handleOpenModal("editPayment", { id });
  };

  const handleEditInvoice = (data) => {
    handleOpenModal("editInvoice", data);
  };

  const handleAddComment = (data) => {
    handleOpenModal("addComment", data);
  };

  const handlePreviewLogs = (data) => {
    handleOpenModal("previewLogs", { logs: data?.logs });
  };

  const handleOpenStateModal = () => {
    const { UPCOMING, PAID, ...stats } = Stats;
    handleOpenModal("changeState", {
      payments: selectedRows,
      options: { stats: Object.values(stats) },
    });
  };

  const handleChangeStatus = (id, status) => {
    changeStatus({
      variables: { id: [+id], status },
      onCompleted: () => {
        refetchList();
        showToast("success");
        setIsClearSelectedRows(true);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleGenerate = (id, eInvoice) => {
    // handleChangeStatus(id, Stats.GENERATED);
    handleSetInvoice(id, eInvoice)
  };

  const handleCancelPayment = (id) => {
    handleChangeStatus(id, Stats.CANCELLED);
  };

  const handleSendPayment = (id) => {
    handleChangeStatus(id, Stats.SENT);
  };

  const handlePayPayment = (id) => {
    handleOpenModal("payInvoice", { id });
  };

  const handleFilterTypeChange = (_, val) => {
    dispatch(updateValueAction(filtersFormName, "type", val));
  };

  const handleExport = () => {};

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  return (
    <>
      {changeStatusLoading ? <Loader fixed /> : null}

      {/* Filters */}
      <div className="d-flex flex-wrap justify-content-between gap-20 mt-2">
        {isSubscriptionProfile ? null : (
          <>
            {!isCompanyProfile ? (
              <BSelect
                {...FiltersFormProps}
                label="company"
                name="company"
                icon="department"
                isClearable
                rootStyle="flex-1"
                options={options.companies}
                isLoading={isOptionsLoading}
              />
            ) : null}
            <BSelect
              {...FiltersFormProps}
              label="plan"
              name="plan"
              icon="document"
              isClearable
              rootStyle="flex-1"
              options={options?.plans || []}
              isLoading={isOptionsLoading}
            />
          </>
        )}
        <DateTimePickerForm
          {...FiltersFormProps}
          label="from"
          name="from"
          hasIcon
          isClearable
          rootStyle="flex-1"
          containerStyle="pb-0"
          labelStyle="mb-2 pb-1"
          datePickerContainer="w-100"
        />
        <DateTimePickerForm
          {...FiltersFormProps}
          label="to"
          name="to"
          hasIcon
          isClearable
          rootStyle="flex-1"
          containerStyle="pb-0"
          labelStyle="mb-2 pb-1"
          datePickerContainer="w-100"
        />
      </div>
      <div className="d-flex flex-wrap-reverse gap-10 justify-content-between align-items-center my-3">
        <Tabs
          value={filters.type}
          onChange={handleFilterTypeChange}
          aria-label="Select desired type"
          sx={{
            minHeight: 30,
            ".MuiTabs-indicator": { display: "none" },
            ".Mui-selected": { border: "1px solid", borderRadius: 1 },
            ".MuiTab-root": {
              height: 30,
              minHeight: 30,
              textTransform: "capitalize",
            },
          }}
        >
          <Tab value="All" label="All" />
          {tabsTypes.map((type) => (
            <Tab key={type} value={type} label={type} />
          ))}
        </Tabs>

        <div className="d-flex gap-10">
          <ColorButton
            color="rgba(48, 130, 105, 1)"
            label="Change Status"
            onClick={handleOpenStateModal}
            disabled={!selectedRows.length}
            disabledLabel="Select records first to change status"
          />
          <ExportButton
            variant="outlined"
            onClick={handleExport}
            disabled={!selectedRows.length}
            disabledLabel="Select records first to be able to export"
          />
        </div>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={props?.refetchSubscriptionProfileLoading || loading}
        progressComponent={<Loader inner />}
        selectableRows
        clearSelectedRows={isClearSelectedRows}
        onSelectedRowsChange={handleSelectRows}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            setIsClearSelectedRows={setIsClearSelectedRows}
            {...(name === "previewInvoice" && {
              actions: {
                handleExport,
                handlePayPayment,
                handleEditInvoice,
                handleSendPayment,
                handleGenerate,
                handleCancelPayment,
              },
            })}
          />
        ) : null
      )}
    </>
  );
};

export default PaymentsList;
