import React from "react";
import { useMutation } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";
import { PaymentStats } from "../../Constants/PaymentTracking";
import { changePaymentStatusMutation } from "../../Graphql/mutation/PaymentTracking";

import Loader from "../Loader";
import { TimesIconButton } from "../IconButtonWithTooltip";

const ChangePlanTierGeneratedInvoices = ({ invoices, refetch }) => {
  const [changeStatus, { loading: changeStatusLoading }] = useMutation(
    changePaymentStatusMutation
  );

  const handleCancel = (id) => {
    changeStatus({
      variables: { id: [+id], status: PaymentStats.CANCELLED },
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return invoices?.length ? (
    <>
      {changeStatusLoading ? <Loader fixed /> : null}

      <strong className="d-block mt-2">Invoices should be cancelled</strong>
      {invoices?.map((invoice) => (
        <div
          key={invoice?.id}
          className="d-flex justify-content-between align-items-center"
        >
          {invoice?.from}
          <TimesIconButton
            label="cancel"
            onClick={() => handleCancel(invoice?.id)}
          />
        </div>
      ))}
    </>
  ) : null;
};

export default ChangePlanTierGeneratedInvoices;
