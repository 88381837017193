import React, { useEffect, useState } from "react";
import MainModal from "../MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissAdminHolidaysModalAction,
  upsertAdminHolidaysAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "adminHolidaysModal";
const formNameValidation = "adminHolidaysModalValidation";
const formServerValidation = "adminHolidaysModalServerValidation";

const AdminHolidaysModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };
  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.upsertAdminHolidaysAction({
      data: props.adminHolidaysModal,
      ref: "adminHolidaysModalActions",
    });
  };

  const toggleModal = () => {
    props.dismissAdminHolidaysModalAction({
      ref: "adminHolidaysModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [props?.modalData?.isVissible]);

  return (
    <MainModal
      isOpen={props?.modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${props?.adminHolidaysModal?.id != null ? "edit" : "add"} holidays`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.modalData?.isLoading}
    >
      <>
        <div className="row">
          <div className="col-12 mb-3">
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="name"
              reducer="admin"
              validationName={`input.name`}
              placeholder={t("name")}
              label="name"
              labelStyle="mb-2"
              containerStyle="mt-0"
              inputContainerStyle=" "
            />
          </div>
          <div className="col-6 mb-2">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="start_date"
              validateBy="textRequired"
              reducer="admin"
              validationName={`input.start_date`}
              label="start date"
              labelStyle="mb-2"
              hasIcon
              minDate={new Date()}
            />
          </div>
          <div className="col-6 mb-2">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="end_date"
              label="end date"
              labelStyle="mb-2"
              reducer="admin"
              validationName={`input.end_date`}
              validateBy="end_date"
              dependTime="start_date"
              hasIcon
              minDate={new Date()}
            />
          </div>
          <div className="col-12 mb-2">
            <BSelect
              {...FormProps}
              name="countries_ids"
              optionLabel="name"
              optionValue="id"
              validateBy="textRequired"
              reducer="admin"
              label="country"
              labelStyle="mb-2"
              validationName={`input.countries_ids`}
              options={props?.data?.holidaysCountriesFilterOptions}
              keepDefaultStyle
              containerStyle=""
              isClearable
              isMulti
              placeholder={t("select country")}
            />
          </div>
        </div>

        {/* (Start) Error Message */}
        {props?.modalData?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">
            {props?.modalData?.modalMessage}
          </div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.admin.adminHolidaysModalActions,
  modalValidation: state.admin[formNameValidation],
  adminHolidaysModal: state.admin[formName],
  serverVaildation: state.admin[formServerValidation],
  data: state.admin,
});

export default connect(mapStateToProps, {
  dismissAdminHolidaysModalAction,
  upsertAdminHolidaysAction,
})(AdminHolidaysModal);
