import { EgyptId } from "../../Constants";

export const initState = {
  paymentTrackingOptions: {
    plans: [],
    packages: [],
    countries: [],
    companies: [],
    currencies: [],
  },
  paymentTrackingClientValidation: [],
  paymentTrackingServerValidation: {},

  // Packages
  packagesListFilters: {
    name: "",
    type: 'active',
  },

  // Plans
  plansListFilters: {
    currency: "",
    package: "",
    billingPeriod: "",
    contractDuration: "",
    search: "",
    type: "All",
    suspend_after: "",
  },
  planUpsertModal: {
    name: "",
    currency: "",
    package: "",
    companyIds: [],
    billingPeriod: "",
    contractDuration: "",
    paymentType: "Fixed",
    pricing: "Stairstep",
  },
  updatePlanCompaniesModal: {
    companyIds: [],
  },
  attachTierModal: {
    name: "",
    to: "",
    from: "",
    price: "",
  },

  // Subscriptions
  subscriptionsListFilters: {
    billingPeriod: "",
    company: "",
    package: "",
    type: "All",
  },
  subscriptionForm: {
    company: "",
    country: EgyptId,
    applyTax: 1,
    taxation: "Custom",
    taxAmount: null,
    eInvoice: 0,

    plan: "",
    billingPeriod: "",
    contractDuration: "",
    employees: "",
    ratePerPeriod: "",
    startDate: "",
    endDate: "",
    prorateAmount: 1,

    applyPromotion: 0,
    applyPromotionAll: 1,
    promotions: [],
  },

  // Subscription Profile
  changePlanModal: {
    sameBillingCycle: 0,
    billingPeriod: "",
    contractDuration: "",
    startFrom: "",
    plan: "",
    tier: null,
    ratePerPeriod: "",
    applyPromotion: 0,
    applyPromotionAll: 1,
  },
  changeTiersModal: {
    type: "Increase",
    tier: null,
    ratePerPeriod: "",
    startFrom: "",
  },
  endSubscriptionModal: {
    endDate: "",
    amount: "",
    addToCredit: false,
    invoice: false,
  },

  // Payments
  paymentsListFilters: {
    to: "",
    form: "",
    plan: "",
    company: "",
    type: "All",
  },
  editPaymentModal: {
    paymentDetails: "",
    amount: "",
    dueDate: "",
    eInvoice: 0,
    paymentMethod: "",
    country: EgyptId,
    applyTax: 1,
    taxation: "Custom",
    taxAmount: null,
  },
  payInvoiceModal: {
    paymentDetails: "",
    amount: "",
    collector: "",
    received_at: "",
    paymentMethod: "",
  },
  editInvoiceModal: {
    dueDate: "",
    paymentTerms: "",
    paymentMethod: "",
  },
  setInvoiceModal: {
    eInvoice: 0,
    serial: "",
  },
};

export default function PaymentTrackingReducer(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
