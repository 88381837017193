import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { clsx } from "clsx";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { attachTierMutation } from "../../Graphql/mutation/PaymentTracking";

import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { Divider } from "@mui/material";
import { InputForm } from "form-builder";
import { RemoveIconButton } from "../IconButtonWithTooltip";

const reducer = "paymentTracking";
const formName = "attachTierModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const tierInitState = { name: "", from: "", to: "", price: "" };
const handleSingleDigit = (d) => (String(d).length > 1 ? d : "0" + String(d));

const AttachTiersModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [tiers, setTiers] = React.useState([
    { ...tierInitState, from: data?.tiers.at(-1).to + 1 },
  ]);
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  // Reducer State
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [attachTier, { loading: attachTierLoading }] =
    useMutation(attachTierMutation);

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleAddTier = () => {
    setTiers((prev) => [...prev, { ...tierInitState, from: "" }]);
  };

  const handleRemoveTier = (idx) => {
    let arr = [...tiers];

    // Remove tier
    arr = arr.filter((_, i) => i !== idx);

    // Set last tier to value
    let prevTo;
    arr = arr.map((a, i) => {
      if (arr.length - 2 === i) prevTo = a?.to;
      return arr.length - 1 === i
        ? { ...a, from: prevTo ? prevTo + 1 : data?.tiers.at(-1).to + 1 }
        : a;
    });

    setTiers(arr);
  };

  const handleTiersChange = (e, idx) => {
    const val = +e.target.value;

    if (e.target.name === "to") {
      setTiers((prev) => {
        if (isNaN(val)) {
          return prev;
        } else {
          let to;
          return prev?.map((p, i) => {
            if (i === idx - 1) to = +p?.to;
            if (i === idx)
              return {
                ...p,
                to: val,
                from: to ? to + 1 : data?.tiers.at(-1).to + 1,
              };
            if (i === idx + 1) return { ...p, from: val ? val + 1 : "-" };
            else return p;
          });
        }
      });
    } else if (e.target.name === "name") {
      setTiers((prev) =>
        prev?.map((p, i) => (i === idx ? { ...p, name: e.target.value } : p))
      );
    } else {
      setTiers((prev) =>
        isNaN(val)
          ? prev
          : prev.map((p, i) => (i === idx ? { ...p, [e.target.name]: val } : p))
      );
    }
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation.length) return true;

    // tiers validation
    return [...data.tiers, ...tiers].reduce((acc, curr) => {
      if (!curr.from || !curr.to || !curr.price) {
        setErrMsg("You should enter all tiers section inputs");
        return true;
      } else {
        switch (true) {
          case +curr.from === +curr.to:
          case curr.from > curr.to:
            setErrMsg("Set proper to/from values");
            return true;

          default:
            setErrMsg("");
            return acc;
        }
      }
    }, false);
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    attachTier({
      variables: {
        id: +data?.id,
        configuration: JSON.stringify({
          tiers: [...data.tiers, ...tiers].map((t) => ({
            limit: t?.to,
            name: t?.name,
            amount: t?.price,
          })),
        }),
      },

      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      modalTitle="Attach Tier"
      btnSubmitLoading={attachTierLoading}
    >
      <div className="d-flex gap-20 mb-2">
        <span className="flex-1">{t("Employees")}</span>
        <span className="w-25">{t("Price")}</span>
        <span className="w-25">{t("Name")}</span>
      </div>
      <div className="border p-2" style={{ borderRadius: 4 }}>
        {data?.tiers.map(({ from, to, price, name }, i) => (
          <React.Fragment key={i}>
            <div className="d-flex gap-20">
              <div className="d-flex gap-20 flex-1">
                <span>{handleSingleDigit(from)}</span>
                <span>-</span>
                <span>{handleSingleDigit(to)}</span>
              </div>
              <span className="w-25">{price}</span>
              <span className="w-25">{name}</span>
            </div>

            {data?.tiers.length - 1 !== i ? (
              <Divider
                light
                className="my-1"
                sx={{
                  left: -8,
                  position: "relative",
                  width: "calc(100% + 16px)",
                }}
              />
            ) : null}
          </React.Fragment>
        ))}
      </div>

      <div className="d-flex align-items-center gap-10 my-2">
        <b className="blue-color">{t("Add Tiers")}</b>
        <AddButton isIconOnly onClick={handleAddTier} />
      </div>
      {tiers.map(({ from, to, price, name }, i) => (
        <div
          key={i}
          style={{ borderRadius: 4 }}
          className={clsx("d-flex gap-20 border p-2", {
            "mb-2": tiers.length - 1 !== i,
          })}
        >
          <div className="d-flex gap-5 align-items-center flex-1">
            <span style={{ width: 80, display: "flex", paddingInlineStart: 4 }}>
              {from || "--"} <span className="d-block mx-2" /> to
            </span>
            <InputForm
              name="to"
              value={to}
              placeholder="to"
              onChange={(e) => handleTiersChange(e, i)}
              rootStyle="w-100"
              containerStyle="w-100"
            />
          </div>
          <div className="d-flex gap-10" style={{ width: "40%" }}>
            <InputForm
              name="price"
              value={price}
              placeholder="price"
              onChange={(e) => handleTiersChange(e, i)}
              rootStyle="flex-1"
              containerStyle="w-100"
            />
            <InputForm
              name="name"
              value={name}
              placeholder="name"
              onChange={(e) => handleTiersChange(e, i)}
              rootStyle="flex-1"
              containerStyle="w-100"
            />
            <RemoveIconButton
              disabled={!i}
              sx={{ opacity: i ? undefined : "0 !important" }}
              onClick={() => handleRemoveTier(i)}
            />
          </div>
        </div>
      ))}

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default AttachTiersModal;
