import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  showAddNewDeductionsModalAction,
  showEditDeductionsModalAction,
  fetchUsersDeductionsAction,
  onInputResetAction,
  deleteDeductionAction,
  showErrorToast,
} from "../../Store/Actions";
import {
  BSelect,
  DateTimePickerForm,
  RadioboxForm,
  InputForm,
} from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import AddNewDeductionsModal from "./AddNewDeductionsModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import PaymentFactors from "../../Constants/PaymentFactors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { AddButton, ColorButton } from "../../Components/Buttons";
import ImportDeductionsModal from "./ImportDeductionsModal";
import { useLazyQuery } from "@apollo/client";
import { Spinner } from "reactstrap";
import { exportDeductionQuery } from "../../Graphql/query";
import HelperFns from "../../Helpers/HelperFns";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const dataTableRef = "DeductionsList";

const Deductions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isImportModalOpen, setIsOpenModalOpen] = React.useState(false);

  const fetchData = () => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "from");
      props.onInputResetAction(dataTableRef, "to");
      props.onInputResetAction(dataTableRef, "user_id");
      props.onInputResetAction(dataTableRef, "employee_search");
    };
  }, []);

  const handleFilter = () => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data.status,
    props.data.from,
    props.data.to,
    props.data.user_id,
    props.data.employee_search,
  ]);

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: page,
        },
      },
    });
  };

  // for add new deductions modal
  const handleAddDeductions = () => {
    props.showAddNewDeductionsModalAction({
      ref: "AddNewDeductionsModalActions",
      formName: "AddNewDeductionsModal",
    });
  };

  // for edit deductions modal
  const handleEditDeductionsModal = (data) => {
    let editRecord = {
      name: data?.name,
      user_id: data?.user?.id,
      payment_factor_id: data?.payment_factor_id,
      value: data?.value,
      payroll_at: data?.payroll_at,
      description: data?.description,
      id: data?.id,
      policy_id: null,
      deduction_type: "CUSTOM",
      cost_center_id: data?.cost_center?.id,
    };
    props.showEditDeductionsModalAction(editRecord);
  };

  // to delete deduction record
  const handleDeleteDeduction = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteDeductionAction(row?.id);
      }
    });
  };

  // to export deductions
  const handleExportDeductionList = () => {
    const deductionListFilters = props.data;

    exportDeductionsList({
      variables: {
        input: {
          filter_data: {
            filter_flag: "deductions_filter",
            user_id: deductionListFilters?.user_id,
            status: deductionListFilters?.status,
            from: deductionListFilters?.from,
            to: deductionListFilters?.to,
          },
        },
      },
    });
  };

  const [exportDeductionsList, { loading }] = useLazyQuery(
    exportDeductionQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (
          response?.exportDeductions &&
          response?.exportDeductions?.status === "success"
        ) {
          HelperFns.downloadFile(response?.exportDeductions?.file);
        } else {
          dispatch(showErrorToast(response?.exportDeductions?.message));
        }
      },
      onError: (error) => {
        dispatch(showErrorToast(error?.message));
      },
    }
  );

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const { allowCostCenter } = useAllowCostCenter();

  const columns = [
    {
      name: t("employee name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 2.2,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.user?.manager?.id}
          copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.user?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.user?.id}`}
            >
              {row?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div className="d-flex">
          {row?.amount > 0 ? (
            <div>
              <span className="pr-1">{t(row?.currency?.name)}</span>
              {roundToTwo(row?.amount)}
            </div>
          ) : (
            <div>
              {roundToTwo(row?.value)}{" "}
              <span className="pl-1">
                {row?.payment_factor_id === PaymentFactors.FIXED_AMOUNT ? (
                  row?.currency ? (
                    t(row?.currency?.name)
                  ) : (
                    <FontAwesomeIcon icon={faCoins} />
                  )
                ) : (
                  t("days")
                )}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      name: t("deductible name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("created by"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.createdBy?.name}</>,
    },
    {
      name: t("payable at"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        !!row?.payroll_at
          ? moment(row?.payroll_at).format("DD-MM-YYYY")
          : "",
    },
    ...(allowCostCenter
      ? [
          {
            name: t("cost center"),
            wrap: true,
            sortable: false,
            grow: 1.2,
            cell: (row) => row?.cost_center?.name,
          },
        ]
      : []),
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        row?.payment_status?.toLowerCase() == "unpaid" && (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                <EditIconButton />
                <RemoveIconButton />
              </div>
            }
          >
            <div className="cards_table_actions">
              <EditIconButton onClick={() => handleEditDeductionsModal(row)} />
              <RemoveIconButton onClick={() => handleDeleteDeduction(row)} />
            </div>
          </HasPrivileges>
        ),
    },
  ];

  const isExportBtnDisabled =
    loading || !!!props?.data?.from || !!!props?.data?.to;

  return (
    <div className="extra_deductions_wrapper_style">
      <RadioboxForm
        formName={dataTableRef}
        options={Constants.DeductionsStatusFilter?.map(({ label, value }) => ({
          label: t(label),
          value,
        }))}
        name="status"
        type="radio"
        containerStyle="my-2 my-lg-0"
        labelStyle="label-style"
        optionsContainerStyle="optionsContainerStyle"
        optionItemStyle="optionItemStyle"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
      />

      <div className="d-flex justify-content-between align-items-baseline mb-4 mt-2">
        <div className="d-flex gap-10 justify-content-between align-items-center">
          <DateTimePickerForm
            name="from"
            placeholder="from"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <DateTimePickerForm
            name="to"
            placeholder="to"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />

          <InputForm
            reducer="super"
            formName={dataTableRef}
            name="employee_search"
            icon="search"
            type="search"
            placeholder={t("employee search")}
            value={props?.data?.employee_search?.trimStart()}
            inputContainerStyle="w-100"
            rootStyle="min-width-200"
          />

          <BSelect
            reducer="super"
            name="user_id"
            formName={dataTableRef}
            options={props?.extraEmployeesFilter}
            keepDefaultStyle
            optionLabel="name"
            optionValue="id"
            rootStyle="min-width-200"
            isClearable
            placeholder={t("employee")}
            icon="person"
          />
        </div>
        <div className="d-flex gap-10">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<AddButton />}
          >
            <AddButton onClick={handleAddDeductions} />
          </HasPrivileges>
          <ColorButton
            onClick={() => setIsOpenModalOpen(true)}
            label="import deductions"
          />
          <button
            className={`btn py-0 export-btn text-nowrap
                       ${isExportBtnDisabled ? "disabled" : ""}`}
            type="button"
            onClick={handleExportDeductionList}
            disabled={isExportBtnDisabled}
          >
            {loading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#fff" }}
              />
            ) : (
              <>
                <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                {t("export")}
              </>
            )}
          </button>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4">{t("no_records")}</div>}
        className="cards_table"
        columns={columns}
        data={props.data.data}
        noHeader
        persistTableHead
        sortServer
        defaultSortAsc={props.data.sorting.dir == "asc"}
        defaultSortField={props.data.sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of  Add new deductions modal */}
      {props?.AddNewDeductionsModalActions?.isVissible ? (
        <AddNewDeductionsModal />
      ) : null}
      {/* End of  Add new deductions modal */}
      {isImportModalOpen ? (
        <ImportDeductionsModal
          refetchList={fetchData}
          onClose={() => setIsOpenModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super[dataTableRef],
    extraEmployeesFilter: state.super.extraEmployeesFilter,
    AddNewDeductionsModalActions: state.super.AddNewDeductionsModalActions,
  };
};

export default connect(mapStateToProps, {
  showAddNewDeductionsModalAction,
  showEditDeductionsModalAction,
  fetchUsersDeductionsAction,
  onInputResetAction,
  deleteDeductionAction,
})(Deductions);
