import React from "react";
import { useMutation } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";
import { payPartiallyMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  EditIconButton,
  TimesIconButton,
  AcceptIconButton,
} from "../IconButtonWithTooltip";
import { Box, CircularProgress, TextField } from "@mui/material";

const PaymentPartialPaymentCell = ({ row, refetchList }) => {
  // Local State
  const [editPartially, setEditPartially] = React.useState(false);
  const [partiallyAmount, setPartiallyAmount] = React.useState("");

  // Server State
  const [payPartially, { loading: payPartiallyLoading }] =
    useMutation(payPartiallyMutation);

  /* ↓ Helpers ↓ */

  const handlePayPartially = (row) => {
    setEditPartially(false);
    payPartially({
      variables: {
        id: +row?.id,
        amount: partiallyAmount,
      },
      onCompleted: () => {
        refetchList();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  if (payPartiallyLoading) return <CircularProgress size={20} />;

  return (
    <Box
      className="d-flex gap-5 align-items-center"
      sx={{ button: { padding: 0 }, svg: { fontSize: 13 } }}
    >
      {editPartially ? (
        <>
          <TextField
            size="small"
            type="number"
            disabled={payPartiallyLoading}
            value={partiallyAmount || row?.paid || 0}
            onChange={(e) => setPartiallyAmount(e.target.value)}
          />

          <AcceptIconButton onClick={() => handlePayPartially(row)} />
          <TimesIconButton
            label="Cancel"
            onClick={() => setEditPartially(false)}
          />
        </>
      ) : (
        <>
          {row?.paid || "N/A"}
          <EditIconButton onClick={() => setEditPartially(true)} />
        </>
      )}
    </Box>
  );
};

export default PaymentPartialPaymentCell;
