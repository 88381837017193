import Types from "../Actions/shiftsActions/types";
import { RequestLimitInitState, workRemotelySettings } from "../InitialState/sharedFormSections";
import {
  getRequestSettings,
  serializeFetchedDayOffException,
  serializeFetchedHolidayException,
  serializeFetchWorkRemotely,
} from "../../Helpers/HelperFns";

export const initState = {
  upsertWorkGroupForm: {
    id: null,
    name: "",
    managersIds: [],
    employeesIds: [],
    workTimingsIds: [],
    spacesIds: [],
  },
  upsertWorkGroupFormValidation: [],
  upsertWorkGroupFormServerValidation: {},

  upsertWorkTeamForm: {
    id: null,
    name: "",
    workGroup: null,
    employeesIds: [],
    start: null,
  },
  upsertWorkTeamFormValidation: [],
  upsertWorkTeamFormServerValidation: {},

  addWorkTeamMembersForm: {
    start: "",
    employeesIds: [],
  },
  addWorkTeamMembersFormValidation: [],
  addWorkTeamMembersFormServerValidation: {},

  editWorkTeamMembersForm: {
    name: "",
  },
  editWorkTeamMembersFormValidation: [],
  editWorkTeamMembersFormServerValidation: {},

  moveWorkTeamMembersForm: {
    toId: [],
    start: "",
  },
  moveWorkTeamMembersFormValidation: [],
  moveWorkTeamMembersFormServerValidation: {},

  workScheduleForm: {},
  workScheduleFormValidation: [],
  workScheduleFormServerValidation: {},

  employeeWorkScheduleForm: {
    id: null,
    attendanceType: "office based",
    start: "",
    workGroupId: null,
    workTeamId: null,
    joiningFrom: null,
    allowMultipleCheckIns: 0,
    allow_customize_check_ins: 0,
    check_in_form_id: null,
    check_out_form_id: null,
    attendanceProfileId: null,

    // work remotely
    ...workRemotelySettings,
    first_day_of_the_week: null, // work remotely (in case of shifts based only)

    allow_work_on_day_off: false,
    day_off_exception_id: null,
    apply_compensation: 0,
    weight: 1,
    compensation_type: null,
    compensation_quantity: null,
    treat_as_normal: false,
    allow_permission: false,
    allow_overtime: false,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting_identical: 1,

    check_in_setting_id: null,
    check_in_settings_as_work_timing_sign_in_settings: 1,
    reuiqre_facial_recognition: 0,
    employee_can_check_in_from: "ANY_PLACE",
    check_in_specific_work_laces: [],
    apply_same_setting_when_checking_out: 1,
    employee_can_check_out_from: "ANY_PLACE",
    check_out_specific_work_laces: [],
    metres: "",

    // holiday config inputs
    allow_work_on_holiday: 0,
    same_config: 1,
    holiday_exception_id: null,
    holiday_apply_compensation: 0,
    holiday_weight: 1,
    holiday_compensation_type: null,
    holiday_compensation_quantity: null,
    holiday_treat_as_normal: false,
    holiday_allow_permission: false,
    holiday_allow_overtime: false,
    holiday_allow_half_days: 0,
    holiday_normal_work_timing_id: null,
    holiday_first_half_work_timing_id: null,
    holiday_second_half_work_timing_id: null,
    holiday_workplace_setting: "DEFAULT",
    holiday_workplace_setting_signout: "DEFAULT",
    holiday_additional_work_places: [],
    holiday_additional_work_places_signout: [],
    holiday_workplace_setting_identical: 1,

    dayoff_cost_center_id: null,
    holiday_cost_center_id: null,
    ...RequestLimitInitState
  },
  prevTemployeeWorkScheduleForm: {
    start: "",
    attendanceProfileId: "",
  },
  employeeWorkScheduleFormValidation: [],
  employeeWorkScheduleFormServerValidation: {},

  workCalendarForm: {
    filter: "workGroups",
    work_group_ids: [],
    work_places_ids: [],
    employees_ids: [],
    teams_ids: [],
  },
  workCalendarFormValidation: [],
  workCalendarFormServerValidation: {},
  workTeamFilters: {
    search: "",
    workGroupId: null,
  },
};

export const shiftsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.EDIT_WORK_GROUP:
      return {
        ...state,
        upsertWorkGroupForm: {
          ...state.upsertWorkGroupForm,
          ...action.payload,
        },
      };

    case Types.EDIT_WORK_TEAM:
      return {
        ...state,
        editWorkTeamMembersForm: {
          name: action.payload,
        },
      };

    case Types.SET_SCHEDULE_SERVER_VALIDATION:
      return {
        ...state,
        workScheduleFormServerValidation: action.payload,
      };

    case Types.EDIT_EMPLOYEE_WORK_SCHEDULE:
      const { checkInForm = {}, checkOutForm = {} } =
        action?.payload?.attendanceProfile;

      const holidayExceptionConfig = !!action.payload.same_exception_config
        ? action.payload?.attendanceProfile?.dayOffExceptionConfig
        : action.payload?.attendanceProfile?.holidayExceptionConfig;

      return {
        ...state,
        prevTemployeeWorkScheduleForm: {
          start: action?.payload?.start,
          attendanceProfileId: action?.payload?.attendanceProfile?.id,
        },
        employeeWorkScheduleForm: {
          ...state.employeeWorkScheduleForm,

          // day off exception
          ...serializeFetchedDayOffException({
            ...action?.payload?.attendanceProfile?.dayOffExceptionConfig,
            allow_work_on_day_off:
              action?.payload?.attendanceProfile?.allow_work_on_day_off,
          }),

          id: action?.payload?.id,
          start: action?.payload?.start,
          attendanceType: action?.payload?.type,
          workGroupId: action?.payload?.workGroup?.id,
          workTeamId: action?.payload?.workTeam?.id,
          joiningFrom: action?.payload?.join_from,
          check_in_form_id: checkInForm?.id,
          check_out_form_id: checkOutForm?.id,
          allow_customize_check_ins: +Boolean(
            checkInForm?.id || checkOutForm?.id
          ),
          allowMultipleCheckIns:
            action?.payload?.attendanceProfile?.allow_multiple_check_ins,
          attendanceProfileId: action?.payload?.attendanceProfile?.id,

          // work remotely
          ...serializeFetchWorkRemotely(action.payload?.workRemotelySettings),

          first_day_of_the_week:
            action.payload?.attendanceProfile?.week_start_day,
          canEdit: action?.payload?.canEdit,

          // check in settings
          metres:
            action.payload?.attendanceProfile?.check_in_distance_setting?.toString(),

          check_in_setting_id:
            action.payload?.attendanceProfile?.checkInSetting?.id,
          check_in_settings_as_work_timing_sign_in_settings: !!!action.payload
            ?.attendanceProfile?.checkInSetting
            ? 1
            : 0,
          reuiqre_facial_recognition:
            action.payload?.attendanceProfile?.checkInSetting
              ?.face_recognition_in ||
              action.payload?.attendanceProfile?.checkInSetting
                ?.face_recognition_out
              ? 1
              : 0,
          employee_can_check_in_from:
            action.payload?.attendanceProfile?.checkInSetting
              ?.workplace_setting,
          check_in_specific_work_laces:
            action.payload?.attendanceProfile?.checkInSetting?.workPlacesIn ??
            [],
          apply_same_setting_when_checking_out: action.payload
            ?.attendanceProfile?.checkInSetting?.workplace_setting_identical
            ? 1
            : 0,
          employee_can_check_out_from:
            action.payload?.attendanceProfile?.checkInSetting
              ?.workplace_setting_signout,
          check_out_specific_work_laces:
            action.payload?.attendanceProfile?.checkInSetting?.workPlacesOut ??
            [],

          // holiday config inputs
          ...serializeFetchedHolidayException({
            ...action.payload.attendanceProfile,
            ...holidayExceptionConfig,
          }),

          ...getRequestSettings(action.payload.requestSettings)


        },
      };

    case Types.SET_ATT_TYPE_SERVER_VALIDATION:
      return {
        ...state,
        employeeWorkScheduleFormServerValidation: action.payload,
      };

    case Types.SET_WORK_SCHEDULE_SERVER_VALIDATION:
      return {
        ...state,
        workScheduleFormServerValidation: action.payload,
      };

    default:
      return state;
  }
};
