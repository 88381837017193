import React from "react";
import { useSelector } from "react-redux";

import { paymentOptions } from "../../Constants/PaymentTracking";

import { BSelect } from "form-builder";

const PaymentInfo = ({ FormProps, ...props }) => {
  // Reducer State
  const data = useSelector(
    (state) => state?.[FormProps?.reducer || "super"]?.[FormProps?.formName]
  );

  // Constants
  const validateBy =
    data?.paymentMethod && data?.paymentMethod !== "Cash"
      ? "textRequired"
      : undefined;

  return (
    <>
      <BSelect
        {...FormProps}
        name="paymentMethod"
        label="Payment Method"
        icon="money"
        // rootStyle=" pr-2"
        options={paymentOptions.paymentMethods}
        // validateBy="textRequired"
        // validationName="method"
        {...props}
      />

      <div className="d-flex gap-10">
        {/* Bank Transfer */}
        <BSelect
          {...FormProps}
          name="bankName"
          label="bank name"
          icon="landmark"
          rootStyle="flex-1"
          options={paymentOptions.bankNames}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
          {...props}
        />
        <BSelect
          {...FormProps}
          name="accountNumber"
          label="account number"
          icon="type"
          rootStyle="flex-1"
          options={paymentOptions.accountNumbers?.[data?.bankName]}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
          {...props}
        />
        <BSelect
          {...FormProps}
          name="IBAN"
          label="IBAN"
          icon="type"
          rootStyle="flex-1"
          options={paymentOptions.IBANs?.[data?.bankName]}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
          {...props}
        />

        {/* Mobile Wallet */}
        <BSelect
          {...FormProps}
          name="wallet"
          label="wallet"
          icon="wallet"
          rootStyle="flex-1"
          options={paymentOptions.wallets}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Wallet"]}
          {...props}
        />
        <BSelect
          {...FormProps}
          name="phoneNumber"
          label="phone Number"
          icon="type"
          rootStyle="flex-1"
          options={paymentOptions.phoneNumbers?.[data?.wallet]}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Wallet"]}
          {...props}
        />

        {/* Prepaid Card */}
        <BSelect
          {...FormProps}
          name="card"
          label="card"
          icon="card"
          rootStyle="flex-1"
          options={paymentOptions.cards}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Prepaid"]}
          {...props}
        />
        <BSelect
          {...FormProps}
          name="cardNumber"
          label="card number"
          icon="type"
          rootStyle="flex-1"
          options={paymentOptions.cardNumbers?.[data?.card]}
          validateBy={validateBy}
          // validationName="method"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Prepaid"]}
          {...props}
        />
      </div>
    </>
  );
};

export default PaymentInfo;
