import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import { connect, useDispatch } from "react-redux";
import { showToast } from "../../Helpers/HelperFns";
import { packageFormQuery } from "../../Graphql/query/PaymentTracking";
import { onFormResetAction, resetSystemPlanForm } from "../../Store/Actions";
import { upsertPackageMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  SystemPrivilegesTree,
  PackageFeatureSection,
} from "../../Components/PaymentTracking";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Loader from "../../Components/Loader";
import { KeyboardBackspace } from "@mui/icons-material";
import { ColorButton } from "../../Components/Buttons";
import { InputForm, CheckboxBooleanForm } from "form-builder";

const formName = "systemPlan";
const formNameValidation = "systemPlanValidation";
const formServerValidation = "systemPlanServerValidation";

const EditPackagePage = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(id);

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Server State
  const [upsert, { loading: upsertLoading }] = useMutation(
    upsertPackageMutation
  );
  const { loading, data } = useQuery(packageFormQuery, {
    variables: { id, includePackage: isEdit },
    onCompleted: (res) => {
      isEdit &&
        dispatch(
          onFormResetAction("systemPlan", {
            id: res?.package?.id,
            name: res?.package?.name,
            features: res?.package?.features?.map((feat) => ({
              id: feat.id,
              limit: feat?.pivot?.limits,
            })),
            trial_value: res?.package?.trial_value,
            is_free: res?.package?.trial_value == null ? 1 : 0,
            selectedPrivileges: res?.package?.privileges?.map(
              (privilege) => privilege?.id
            ),
          })
        );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const formProps = {
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  useEffect(() => {
    return () => {
      setFormSubmitting(false);
      dispatch(resetSystemPlanForm());
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.systemPlanValidation?.length) return;

    const { is_free, ...systemPlan } = props.systemPlan;
    let input = {
      ...systemPlan,
      trial_value: is_free ? null : +props.systemPlan?.trial_value,
    };

    upsert({
      variables: { input },
      onCompleted: (res) => {
        isEdit
          ? history.push(`/payment-tracking?tab=packages`)
          : history.push(`/payment-tracking/package/${res?.package?.id}/roles`);
      },
      onError: (err) => {
        if (err?.graphQLErrors?.[0]?.extensions?.validation) {
          dispatch(
            onFormResetAction(
              "systemPlanServerValidation",
              err?.graphQLErrors?.[0]?.extensions?.validation
            )
          );
        } else {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        }
      },
    });
  };

  return (
    <div className="content">
      <header>
        <Link to="/payment-tracking?tab=packages">
          <div className="back-btn d-flex align-items-center position-sticky ">
            <KeyboardBackspace className="back-icon" />
            <span>{t("back")}</span>
          </div>
        </Link>

        <h4 className="page-title mt-3">
          {isEdit ? t("edit package") : t("new package")}
        </h4>
      </header>

      <div className="d-flex align-items-center">
        <div className="flex-1">
          <InputForm
            {...formProps}
            validateBy="textRequired"
            name="name"
            type="text"
            placeholder={t("name")}
            reducer="admin"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
            inputContainerStyle=" "
          />
          <PackageFeatureSection label="number of offices" featureId="2" />
          <PackageFeatureSection label="number of profiles" featureId="6" />
        </div>
        <Divider className="mx-4" orientation="vertical" flexItem light />
        <div className="flex-1">
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <CheckboxBooleanForm
              {...formProps}
              options={["free package"]}
              name="is_free"
              validationName="input.absent_early_sign_out"
              type="checkbox"
              reducer="admin"
            />
            {props.systemPlan.is_free !== 1 ? (
              <InputForm
                labelStyle="mr-5"
                {...formProps}
                containerStyle="container-style-default ml-auto"
                validateBy="textRequired"
                label="free trial period"
                name="trial_value"
                reducer="admin"
                validationName="input.sign_out_cuttoff_time"
                type="text"
                hasSuffix
                suffixTitle="Days"
              />
            ) : null}
          </div>

          <PackageFeatureSection
            label="number of normal work timings"
            featureId="5"
          />
          <PackageFeatureSection label="number of employees" featureId="1" />
        </div>
      </div>
      <div className="invalid-container-style">
        <p role="alert" className="validity-msg-style mb-0">
          {props?.systemPlanServerValidation?.["input.features"]?.toString() ??
            ""}
        </p>
      </div>

      <Divider light sx={{ mt: 2, mb: 3 }} />

      {loading || !data?.system_privileges ? (
        <Loader />
      ) : (
        <div className="view-system-privileges">
          <SystemPrivilegesTree parents={JSON.parse(data?.system_privileges)} />
          <div className="invalid-container-style">
            <p role="alert" className="validity-msg-style mb-0">
              {props?.systemPlanServerValidation?.[
                "input.selectedPrivileges"
              ]?.toString() ?? ""}
            </p>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end mt-4 pt-2">
        <ColorButton
          loadingButton
          color="#23aaeb"
          loading={upsertLoading}
          onClick={handleSubmitBtn}
          label={isEdit ? "submit" : "next"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    systemPlan: state.admin?.systemPlan,
    systemPlanValidation: state.admin?.systemPlanValidation,
    systemPlanServerValidation: state.admin?.systemPlanServerValidation,
  };
};

export default connect(mapStateToProps)(EditPackagePage);
