import gql from "graphql-tag";

import { PaymentCommentFragment } from "../fragments";

/*
  Packages
*/

export const upsertPackageMutation = gql`
  mutation upsertPackage($input: PlanInput) {
    package: upsert_plan(input: $input) {
      id
    }
  }
`;

export const deletePackageMutation = gql`
  mutation deletePackage($id: ID) {
    delete_package: delete_plan(input: { id: $id }) {
      id
    }
  }
`;

export const setPackageStatusMutation = gql`
  mutation setPackageStatus($id: ID, $status: String) {
    package: change_plan_status(input: { plan_id: $id, status: $status }) {
      id
    }
  }
`;

export const upsertRoleMutation = gql`
  mutation upsertRole($input: RoleInput!) {
    role: upsert_role_to_plan(input: $input) {
      id
    }
  }
`;

export const deletePackageRoleMutation = gql`
  mutation deletePackageRole($roleId: ID!) {
    delete_role(id: $roleId) {
      status
      message
    }
  }
`;

export const upsertRoleTemplateToPackageMutation = gql`
  mutation upsertRoleTemplateToPackage($input: RoleInput!) {
    role: upsert_role_template_to_plan(input: $input) {
      id
    }
  }
`;

/*
  Plans
*/

export const storePlanMutation = gql`
  mutation storePlan(
    $name: String
    $package_id: Int
    $currency_id: Int
    $companyIds: [Int]
    $period: String
    $duration: Int
    $type: String
    $pricing: String
    $configuration: JSON
    $suspend_after: Int
  ) {
    payment_tracking_store_plan(
      name: $name
      package_id: $package_id
      companyIds: $companyIds
      currency_id: $currency_id
      period: $period
      duration: $duration
      type: $type
      pricing: $pricing
      suspend_after: $suspend_after
      configuration: $configuration
    ) {
      id
    }
  }
`;

export const removePlanMutation = gql`
  mutation removePlan($id: Int) {
    payment_tracking_remove_plan(id: $id)
  }
`;

export const setPlanStatusMutation = gql`
  mutation setPlanStatus($id: Int, $status: String) {
    payment_tracking_set_plan_status(id: $id, status: $status)
  }
`;

export const updatePlanCompaniesMutation = gql`
  mutation updatePlanCompanies($id: Int, $companyIds: [Int]!) {
    payment_tracking_synchronize_companies(id: $id, companyIds: $companyIds)
  }
`;

export const attachTierMutation = gql`
  mutation attachTier($id: Int, $configuration: JSON) {
    payment_tracking_change_plan(id: $id, configuration: $configuration) {
      id
    }
  }
`;

/*
  Subscriptions
*/

export const storeSubscriptionMutation = gql`
  mutation storeSubscription(
    $users: Int
    $from: String
    $prorate: Int
    $plan_id: Int
    $duration: Int
    $applyTax: Int
    $eInvoice: Int
    $company_id: Int
    $country_id: Int
    $taxation: String
    $promotions: JSON
    $taxAmount: String
    $suspend_after: Int
  ) {
    payment_tracking_store_subscription(
      from: $from
      users: $users
      prorate: $prorate
      plan_id: $plan_id
      duration: $duration
      applyTax: $applyTax
      taxation: $taxation
      eInvoice: $eInvoice
      taxAmount: $taxAmount
      promotions: $promotions
      company_id: $company_id
      country_id: $country_id
      suspend_after: $suspend_after
    ) {
      id
    }
  }
`;

export const changeSubscriptionMutation = gql`
  mutation changeSubscription(
    $id: Int
    $users: Int
    $from: String
    $prorate: Int
    $plan_id: Int
    $duration: Int
    $applyTax: Int
    $eInvoice: Int
    $company_id: Int
    $country_id: Int
    $taxation: String
    $promotions: JSON
    $taxAmount: String
  ) {
    payment_tracking_change_subscription(
      id: $id
      from: $from
      users: $users
      prorate: $prorate
      plan_id: $plan_id
      duration: $duration
      applyTax: $applyTax
      eInvoice: $eInvoice
      taxation: $taxation
      taxAmount: $taxAmount
      promotions: $promotions
      company_id: $company_id
      country_id: $country_id
    ) {
      id
    }
  }
`;

/*
  Subscription Profile
*/

export const changeSubscriptionPlanMutation = gql`
  mutation changeSubscriptionPlan(
    $company_id: Int
    $from: String
    $json: JSON
  ) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      company_id: $company_id
      actionType: "CHANGE_PLAN"
    )
  }
`;

export const changeSubscriptionTiersMutation = gql`
  mutation changeSubscriptionTiers(
    $company_id: Int
    $from: String
    $json: JSON
  ) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      company_id: $company_id
      actionType: "CHANGE_TIER"
    )
  }
`;

export const endSubscriptionMutation = gql`
  mutation endSubscription($company_id: Int, $from: String, $json: JSON) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      actionType: "END"
      company_id: $company_id
    )
  }
`;

export const endCreateSubscriptionMutation = gql`
  mutation endCreateSubscription($company_id: Int, $from: String, $json: JSON) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      actionType: "CREATE"
      company_id: $company_id
    )
  }
`;

/*
  Payments
*/

export const changePaymentStatusMutation = gql`
  mutation changePaymentStatus($id: [Int], $status: String) {
    payment_tracking_set_payment_status(id: $id, type: $status)
  }
`;

export const editPaymentMutation = gql`
  mutation editPayment(
    $id: Int
    $method: JSON
    $applyTax: Int
    $eInvoice: Int
    $amount: String
    $due_date: String
    $taxAmount: String
  ) {
    payment_tracking_change_payment(
      id: $id
      amount: $amount
      method: $method
      applyTax: $applyTax
      eInvoice: $eInvoice
      due_date: $due_date
      taxAmount: $taxAmount
    ) {
      id
    }
  }
`;

export const payInvoiceMutation = gql`
  mutation payInvoice(
    $id: Int
    $file: Upload
    $method: JSON
    $amount: String
    $received_at: String
    $collector_id: String
  ) {
    payment_tracking_pay(
      id: $id
      file: $file
      method: $method
      receivedAmount: $amount
      received_at: $received_at
      collector_id: $collector_id
    )
  }
`;

export const editInvoiceMutation = gql`
  mutation editInvoice(
    $id: Int
    $items: [JSON]
    $terms: String
    $method: JSON
    $due_date: String
  ) {
    payment_tracking_change_invoice(
      id: $id
      terms: $terms
      items: $items
      method: $method
      due_date: $due_date
    ) {
      id
    }
  }
`;

export const payPartiallyMutation = gql`
  mutation payPartially($id: Int, $amount: String) {
    payment_tracking_pay_partially(id: $id, amount: $amount)
  }
`;

export const upsertPaymentCommentMutation = gql`
  mutation upsertPaymentComment($id: Int, $comment: String) {
    payment_tracking_comment(paymentId: $id, body: $comment) {
      ...comment
    }
  }
  ${PaymentCommentFragment}
`;
