import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import HelperFns from "../../../Helpers/HelperFns";
import Privileges from "../../../Constants/Privilages";
import { fetchLeadQuery } from "../../../Graphql/query";

import {
  Phone,
  Language,
  LocationOn,
  LocationCity,
  PersonOutline,
} from "@mui/icons-material";
import {
  TrialsList,
  MeetingsList,
  RemindersList,
  ActivitiesList,
  LeadProgressList,
} from "../../../Containers/CRMLists";
import BlockUi from "react-block-ui";
import { CRMTabs } from "../CRMPage";
import { Box, Tab } from "@mui/material";
import ContactsPanel from "./ContactsPanel";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { LeadModal } from "../../../Components/CRMFormModals";
import { ColorButton } from "../../../Components/Buttons";

const modalInitState = { isOpen: false, data: null };
const modals = [{ name: "lead", Modal: LeadModal }];

const tabs = [
  {
    label: "contacts",
    Panel: ContactsPanel,
    privileges: [Privileges.VIEW_LEAD_CONTACT, Privileges.SUPER_PRIVILEGE],
  },
  {
    label: "progress",
    Panel: LeadProgressList,
    privileges: [Privileges.VIEW_ACTIVITIES, Privileges.SUPER_PRIVILEGE],
  },
  {
    label: "activities",
    Panel: ActivitiesList,
    privileges: [Privileges.VIEW_ACTIVITIES, Privileges.SUPER_PRIVILEGE],
  },
  {
    label: "reminders",
    Panel: RemindersList,
    privileges: [Privileges.VIEW_REMINDERS, Privileges.SUPER_PRIVILEGE],
  },
  {
    label: "trials",
    Panel: TrialsList,
    privileges: [Privileges.VIEW_TRIALS, Privileges.SUPER_PRIVILEGE],
  },
  {
    label: "meetings",
    Panel: MeetingsList,
    privileges: [Privileges.VIEW_MEETINGS, Privileges.SUPER_PRIVILEGE],
  },
];

const getFirstTab = () => {
  let tab;
  const privileges = tabs.map((tab) => tab.privileges);

  for (const [idx, privilege] of privileges.entries()) {
    if (
      HelperFns.checkPrivileges({
        allowBP: false,
        privileges: privilege,
      })
    ) {
      tab = idx;
      break;
    }
  }

  return String(tab);
};

const LeadProfilePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );
  // Local State
  const [value, setValue] = React.useState(null);
  const [leadProfileData, setLeadProfileData] = React.useState(null);

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  // Server State
  const {
    data,
    refetch: refetchData,
    loading: isDataLoading,
  } = useQuery(fetchLeadQuery, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  // Constants
  const addOn = data?.lead?.date;
  const addBy = data?.lead?.user?.name;
  const assignTo = data?.lead?.assignedTo?.name || "";
  const info = [
    {
      icon: <Phone fontSize="small" />,
      txt: data?.lead?.phones?.[0]?.[1],
    },
    { icon: <LocationOn fontSize="small" />, txt: data?.lead?.country },
    { icon: <LocationCity fontSize="small" />, txt: data?.lead?.industry },
    { icon: <PersonOutline fontSize="small" />, txt: data?.lead?.eps_no },
    { icon: <Language fontSize="small" />, txt: data?.lead?.website },
  ];

  /* ↓ Helpers ↓ */

  React.useEffect(() => {
    setValue(getFirstTab());
  }, []);

  React.useEffect(() => {
    !isDataLoading &&
      setLeadProfileData({
        id: data?.lead?.id,
        name: data?.lead?.name,
        contacts: data?.lead?.contacts,
      });
  }, [isDataLoading]);

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <BlockUi tag="div" className="content pt-3" blocking={isDataLoading}>
        <CRMTabs />

        <div className="d-flex align-items-center gap-10 mt-4 page-title">
          <Link to="/crm?tab=leads" aria-label="Go back to leads list">
            <ArrowBackIosNewIcon fontSize="small" sx={{ color: "#707070" }} />
          </Link>
          <h2 className="h3 mb-0">{data?.lead?.name}</h2>
        </div>

        {isDataLoading ? null : (
          <div className="boxContainer py-3">
            <ul className="list-unstyled d-flex flex-wrap gap-20">
              {info.map(({ icon, txt }, i) => (
                <li key={i} className="d-flex align-items-center gap-10">
                  {icon}
                  {txt || "----"}
                </li>
              ))}
            </ul>

            <ul className="list-unstyled d-flex flex-wrap gap-20 mb-0">
              <li className="d-flex align-items-center gap-10">
                <strong>{t("Added By")}</strong>
                <span>{addBy || "----"}</span>
              </li>
              <li className="d-flex align-items-center gap-10">
                <strong>{t("added on")}</strong>
                <span>{addOn || "----"}</span>
              </li>
              <li className="d-flex align-items-center gap-10">
                <strong>{t("assigned to")}</strong>
                <span>{assignTo || "----"}</span>
              </li>

              <HasPrivileges
                reqireMain={[
                  Privileges.ADD_EDIT_LEADS,
                  Privileges.SUPER_PRIVILEGE,
                ]}
              >
                <li style={{ marginLeft: "auto" }}>
                  <ColorButton
                    color="#27b40c"
                    label="Edit"
                    onClick={() =>
                      handleOpenModal("lead", { id: data?.lead?.id })
                    }
                  />
                </li>
              </HasPrivileges>
            </ul>
          </div>
        )}

        {value ? (
          <TabContext value={value}>
            {/* Tabs */}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                {tabs.map(({ label, privileges }, i) =>
                  HelperFns.checkPrivileges({
                    allowBP: false,
                    privileges,
                  }) ? (
                    <Tab key={label} label={t(label)} value={String(i)} />
                  ) : null
                )}
              </TabList>
            </Box>

            {/* Panels */}
            {tabs.map(({ Panel, privileges }, i) => (
              <TabPanel key={i} value={String(i)}>
                {HelperFns.checkPrivileges({
                  allowBP: false,
                  privileges,
                }) ? (
                  <Panel
                    isLeadProfile
                    data={data?.lead}
                    refetchData={refetchData}
                    isDataLoading={isDataLoading}
                    leadProfileData={leadProfileData}
                  />
                ) : null}
              </TabPanel>
            ))}
          </TabContext>
        ) : null}
      </BlockUi>

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchData}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default LeadProfilePage;
