import React from "react";
import { useTranslation } from "react-i18next";

import {
  FaceIconRow,
  TimeIconRow,
  LocationIconRow,
} from "../../Components/FacialRecognitionCard/shared";

import { Grid, Tooltip } from "@mui/material";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import FacialRecognitionCard from "../../Components/FacialRecognitionCard";

export default function List({
  isListView,
  tableData,
  isLoading,
  tablePaginator,
  handlePaginate,
  onViewLocation,
}) {
  return isListView ? (
    <ListView
      tableData={tableData}
      isLoading={isLoading}
      tablePaginator={tablePaginator}
      handlePaginate={handlePaginate}
      onViewLocation={onViewLocation}
    />
  ) : (
    <GridView
      tableData={tableData}
      isLoading={isLoading}
      tablePaginator={tablePaginator}
      handlePaginate={handlePaginate}
      onViewLocation={onViewLocation}
    />
  );
}

const ListView = ({
  isLoading,
  tableData,
  tablePaginator,
  handlePaginate,
  onViewLocation,
}) => {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        width: "300px",
        name: t("name"),
        cell: ({ emp_name }) => (
          <Tooltip title={emp_name?.length > 30 ? emp_name : ""}>
            <span className="text-truncate">{emp_name}</span>
          </Tooltip>
        ),
      },
      {
        grow: 0.5,
        name: t("type"),
        cell: ({ type }) => type?.replace("_", " "),
        //, width: "103px"
      },
      {
        name: t("date"),
        selector: "date",
        //, width: "103px"
      },

      {
        // width: "116px",
        name: t("in time"),
        cell: ({ in_time }) => <TimeIconRow isIn time={in_time} />,
      },
      {
        // minWidth: "230px",
        name: t("in location"),
        cell: (row) => (
          <div className="w-100 d-flex flex-column gap-10 py-2">
            <LocationIconRow
              isListView
              locationName={row?.in_location_name}
              isMockedLocation={row?.mocked_location_in}
              handleViewLocation={() => handleViewLocation(row)}
            />
          </div>
        ),
      },
      {
        // minWidth: "250px",
        name: t("in face"),
        cell: ({ in_face_path, liveness_ratio_in }) => (
          <FaceIconRow
            isListView
            face={in_face_path}
            faceSuccessRatio={liveness_ratio_in}
          />
        ),
      },

      {
        // width: "116px",
        name: t("out time"),
        cell: ({ out_time }) =>
          out_time ? <TimeIconRow time={out_time} /> : "-----",
      },
      {
        // minWidth: "230px",
        name: t("out location"),
        cell: (row) =>
          row?.out_location_name ? (
            <div className="w-100 d-flex flex-column gap-10 py-2">
              <LocationIconRow
                isListView
                locationName={row?.out_location_name}
                isMockedLocation={row?.mocked_location_out}
                handleViewLocation={() => handleViewLocation(row, false)}
              />
            </div>
          ) : (
            "-----"
          ),
      },
      {
        // minWidth: "250px",
        name: t("out face"),
        cell: ({ out_face_path, liveness_ratio_out }) =>
          out_face_path ? (
            <FaceIconRow
              isListView
              face={out_face_path}
              faceSuccessRatio={liveness_ratio_out}
            />
          ) : (
            "-----"
          ),
      },
    ],
    []
  );

  const handleViewLocation = (data, isIn = true) => {
    onViewLocation({
      name: data?.emp_name,
      signinOutLocationsModal: true,
      type: data?.type?.toLowerCase(),
      lng: isIn ? data?.in_lng : data?.out_lng,
      lat: isIn ? data?.in_lat : data?.out_lat,
      date: isIn ? data?.in_time : data?.out_time,
      face: isIn ? data?.in_face_path : data?.out_face_path,
      location_name:
        (isIn ? data?.in_location_name : data?.out_location_name) ||
        t("unspecified location"),
    });
  };

  return (
    <DataTable
      data={tableData}
      columns={columns}
      noHeader
      pagination
      paginationServer
      paginationComponent={() => (
        <Pagination
          styleWraper=""
          onPaginate={handlePaginate}
          customPaginator={tablePaginator}
        />
      )}
      className="cards_table"
      progressPending={isLoading}
      progressComponent={<Loader inner />}
      // customStyles={{ tableWrapper: { style: { minWidth: 1500 } } }}
    />
  );
};

const GridView = ({
  isLoading,
  tableData,
  tablePaginator,
  handlePaginate,
  onViewLocation,
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <Loader inner />
  ) : tableData?.length ? (
    <>
      <Grid container spacing={2} alignItems="stretch" className="mt-4">
        {tableData?.map((log, index) => (
          <Grid key={`facial-card-${index}`} item xs={12} sm={6} lg={4}>
            <FacialRecognitionCard onViewLocation={onViewLocation} {...log} />
          </Grid>
        ))}
      </Grid>
      <Pagination
        styleWraper="mt-3"
        onPaginate={handlePaginate}
        customPaginator={tablePaginator}
      />
    </>
  ) : (
    <p role="alert" className="border shadow-sm rounded text-center p-4 mt-4">
      {t("no data available")}
    </p>
  );
};
