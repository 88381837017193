import React from "react";

import adminTabs from "./tabs";

import { DemoButton } from "../../../Components/AdminPanel";
import NavigationTabs from "../../../Components/NavigationTabs";

const PATH = "/admin-panel";

export const AdminPanelTabs = ({ isRouteSidebar = false }) => {
  return (
    <NavigationTabs.TabsList
      path={PATH}
      tabs={adminTabs}
      isRouteSidebar={isRouteSidebar}
    />
  );
};

const AdminPanelPage = () => {
  return (
    <div className="content pt-3">
      <AdminPanelTabs />
      <DemoButton />
      <NavigationTabs.ActivePanel path={PATH} tabs={adminTabs} />
    </div>
  );
};

export default AdminPanelPage;
