import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import { showToast } from "../Helpers/HelperFns";

import { Alert } from "@mui/material";
import Loader from "../Components/Loader";

const GET_URL = gql`
  query getMsgByToken($token: String!) {
    url: getMsgByToken(token: $token)
  }
`;

const VerifyPage = () => {
  const { token } = useParams();

  // Local State
  const [isExpired, setIsExpired] = React.useState(false);

  // Server State
  useQuery(GET_URL, {
    variables: { token },
    onCompleted: (data) => {
      if (data?.url?.includes("Expired")) {
        setIsExpired(true);
        return showToast("error", data?.url);
      }
      window.location.href = data?.url;
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  return (
    <main
      className="min-vh-100"
      style={{ display: "grid", placeItems: "center" }}
    >
      {isExpired ? (
        <Alert severity="error">Token is Expired</Alert>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default VerifyPage;
