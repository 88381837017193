import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import moment from "moment";
import gql from "graphql-tag";
import swal from "sweetalert";
import { connect } from "react-redux";
import Privileges from "../../../../Constants/Privilages";
import { showToast } from "../../../../Helpers/HelperFns";
import { showAddCompanySubscriptionModalAction } from "../../../../Store/Actions";
import { deleteCompanyTrialSubscriptionMutation } from "../../../../Graphql/mutation";

import Loader from "../../../Loader";
import { AddButton } from "../../../Buttons";
import Pagination from "../../../Pagination";
import DataTable from "react-data-table-component";
import HasPrivileges from "../../../../Helpers/HOC/HasPrivileges";
import UpsertTrialSubscriptionModal from "./UpsertTrialSubscriptionModal";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../../IconButtonWithTooltip";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const GET_TRIALS_SUBSCRIPTIONS = gql`
  query getTrialsSubscriptions($first: Int!, $page: Int, $companyId: ID) {
    trialsSubscriptions: subscriptions(
      first: $first
      page: $page
      company_id: $companyId
    ) {
      data {
        id
        client_at
        plan {
          id
          name
          features {
            id
            pivot {
              limits
            }
          }
        }
        start_date
        end_date
        employees_limit
        payment_amount
        createdByAgent {
          id
          name
        }
        future_subscription_check
        last_subscription_check
        status
        currency {
          id
          name
          symbol
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

const TrialSubscriptionsList = (props) => {
  const { t } = useTranslation();
  const { companyId } = useParams();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Server State
  const [deleteTrialSubscription, { loading: deleteLoading }] = useMutation(
    deleteCompanyTrialSubscriptionMutation
  );
  const { loading, refetch } = useQuery(GET_TRIALS_SUBSCRIPTIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      companyId,
      first: pagination.perPage,
      page: pagination.currentPage,
    },
    onCompleted: ({ trialsSubscriptions }) => {
      setData(trialsSubscriptions?.data);
      setPagination(trialsSubscriptions?.paginatorInfo);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = [
    {
      name: t("from"),
      grow: 1.2,
      cell: (row) => (
        <>
          {row?.start_date != null
            ? moment(row?.start_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("to"),
      grow: 1.2,
      cell: (row) => (
        <>
          {row?.end_date != null
            ? moment(row?.end_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("package"),
      grow: 1.2,
      cell: (row) => <>{row?.plan?.name}</>,
    },
    {
      name: t("Amount Paid"),
      grow: 1.2,
      cell: (row) => (
        <>
          <b className="currency_symbol_color pr-1">{row?.currency?.symbol}</b>
          {row?.payment_amount}
        </>
      ),
    },
    {
      name: t("employees limit"),
      selector: "employees_limit",
    },
    {
      name: t("actions"),
      grow: 1.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.EDIT_DELETE_TRIAL_SUBSCRIPTION,
          ]}
        >
          <div className="data-table-action">
            {row?.status?.toLowerCase() == "active" ||
            row?.future_subscription_check ? (
              <EditIconButton
                onClick={() => handleEditPlanSubscriptionModal(row)}
              />
            ) : (
              ""
            )}

            {row?.last_subscription_check ? (
              <RemoveIconButton
                onClick={() => handleDeletePlanSubscription(row)}
              />
            ) : (
              ""
            )}
          </div>
        </HasPrivileges>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  // handleing add subscription modal
  const handleAddCompanySubscription = () => {
    let data = {
      company_id: companyId,
      id: null,
    };
    props.showAddCompanySubscriptionModalAction({
      data,
      ref: "addSubscriptionModalActions",
      formName: "addSubscriptionModal",
    });
  };

  // for edit plan subscription
  const handleEditPlanSubscriptionModal = (row) => {
    let data = {
      company_id: companyId,
      contract_amount: props?.contract_amount,
      contract_duration: props?.contract_duration,
      id: row?.id,
      plan_id: row?.plan?.id,
      employees_limit: row?.employees_limit ?? "",
      currency_id: row?.currency?.id,
      payment_amount: row?.payment_amount,
      start_date: row?.start_date,
      end_date: row?.end_date,
      created_by_agent_id: row?.createdByAgent?.id,
      client_at: row?.client_at,
      isActive: row?.status?.toLowerCase() === "active",
    };
    props.showAddCompanySubscriptionModalAction({
      data,
      ref: "addSubscriptionModalActions",
      formName: "addSubscriptionModal",
    });
  };

  // to delete plan subscription record
  const handleDeletePlanSubscription = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteTrialSubscription({
          variables: { id: row?.id },
          onCompleted: ({ delete_trial_subscription }) => {
            if (delete_trial_subscription?.status === "error") {
              showToast("error", delete_trial_subscription?.message);
            } else {
              refetch();
              showToast("success");
            }
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handlePaginate = (currentPage = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage }));
  };

  return (
    <>
      {deleteLoading ? <Loader fixed /> : null}

      {/* Actions */}
      <div className="ml-auto mb-4 fit-width">
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ADD_NEW_TRIAL_SUBSCRIPTION,
          ]}
        >
          <AddButton
            label={t("Trial Subscription")}
            onClick={handleAddCompanySubscription}
          />
        </HasPrivileges>
      </div>

      {/* Table */}
      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="requests-profile-data-list datatable_shadow_style"
        columns={columns}
        data={data}
        noHeader
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {props?.isModalVisible ? <UpsertTrialSubscriptionModal /> : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isModalVisible: state.super.addSubscriptionModalActions.isVissible,
  };
};

export default connect(mapStateToProps, {
  showAddCompanySubscriptionModalAction,
})(TrialSubscriptionsList);
