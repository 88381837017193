import React from "react";

import paymentTabs from "./tabs";

import NavigationTabs from "../../../Components/NavigationTabs";

const PATH = "/payment-tracking";

export const PaymentTrackingTabs = ({ isRouteSidebar = false }) => {
  return (
    <NavigationTabs.TabsList
      path={PATH}
      tabs={paymentTabs}
      isRouteSidebar={isRouteSidebar}
    />
  );
};

const PaymentTrackingPage = () => {
  return (
    <div className="content pt-3">
      <PaymentTrackingTabs />
      <NavigationTabs.ActivePanel path={PATH} tabs={paymentTabs} />
    </div>
  );
};

export default PaymentTrackingPage;
