import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import moment from "moment";
import { weekdays } from "moment/moment";
import Constants from "../../Helpers/Constants";
import { calendarQuery } from "../../Graphql/query/CRM";
import { crmYearsOptions, dateFormat } from "../../Constants/CRM";
import { buildOption, safeJsonParse, showToast } from "../../Helpers/HelperFns";

import Box from "@mui/material/Box";
import { BSelect } from "form-builder";
import Loader from "../../Components/Loader";
import { LeadAgentSelect } from "../../Components/CRM";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CalendarEventModal } from "../../Components/CRMFormModals";
import { Eventcalendar, localeAr, localeEn } from "@mobiscroll/react";

const modalInitState = { isOpen: false, data: null };
const minMaxInitState = {
  min: moment().startOf("month"),
  max: moment().endOf("month"),
};
const statsColors = {
  Meetings: "#6f42c1",
  "My Activities": "#2764ac",
  Activities: "#23aaeb",
  "My Reminders": "#ff6600",
  Reminders: "#ffbf00",
  "Trial Start": "#27b40c",
  "Trial Ends": "#c80000",
};

const formatData = (arr) => {
  return arr.map(({ date, type, info }) => {
    const data = info ? safeJsonParse(info) : null;

    return {
      kind: type,
      start: moment(date),
      kindID: data?.id,
      title: data?.name,
      color: statsColors[type],
      type: safeJsonParse(data?.type || "{}")?.name,
      period: data?.period
        ? data?.period === "1W"
          ? "1 week"
          : "2 weeks"
        : null,
      ...data,
    };
  });
};
const getState = (type, dur) => {
  if (type) return type;
  if (dur) return dur + " trial";
  return "appoint";
};

const Calendar = () => {
  const { t } = useTranslation();
  const isMediaMatch = useMediaQuery("(max-width: 991px)");

  // Local State
  const [data, setData] = useState(null);
  const [clientFiltersStats, setClientFiltersStats] = useState([]);
  const [filteredClientData, setFilteredClientData] = useState(null);
  const [minMax, setMinMax] = useState(minMaxInitState);
  const [modalState, setModalState] = useState(modalInitState);
  const [filters, setFilters] = useState({
    assigned_to: null,
    month: {
      key: String(moment().month()),
      value: moment().format("MMMM"),
    },
    year: buildOption(String(moment().year())),
  });

  // Reducer State
  const lng = useSelector((state) => state.auth.userProfile.lng);

  // Server State
  const { loading } = useQuery(calendarQuery, {
    variables: {
      assigned_to: filters.assigned_to?.id,
      from: minMax.min.format(dateFormat),
      to: minMax.max.format(dateFormat),
    },
    onCompleted: ({ crm_calendar }) => {
      setData(crm_calendar ? formatData(crm_calendar) : null);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    setMinMax({
      min: moment([filters.year.id, filters.month.key - 1]).startOf("month"),
      max: moment([filters.year.id, filters.month.key - 1]).endOf("month"),
    });
  }, [filters.month.key, filters.year.id]);

  /* ↓ Helpers ↓ */

  const handleCLientFilters = (_, newVal) => {
    setClientFiltersStats(newVal);
    setFilteredClientData(
      newVal.length ? data.filter((d) => newVal.includes(d?.kind)) : null
    );
  };

  const handleResetCLientFilters = () => {
    setClientFiltersStats([]);
    setFilteredClientData(null);
  };

  const handleSelectFilters = (val, { name }) => {
    handleResetCLientFilters();
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleOpenModal = (data) => {
    setModalState({ isOpen: true, data });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-20 my-4">
        <LeadAgentSelect
          isClearable
          page="CALENDAR"
          name="assigned_to"
          label="assigned to"
          icon="employee"
          rootStyle="w-100"
          value={filters.assigned_to}
          placeholder={t("assigned to")}
          onChange={handleSelectFilters}
        />
        <BSelect
          name="month"
          label="month"
          icon="calendar"
          rootStyle="w-100"
          optionValue="key"
          optionLabel="value"
          value={filters.month}
          placeholder={t("select month")}
          options={Constants.MonthData}
          onChange={handleSelectFilters}
        />
        <BSelect
          name="year"
          label="year"
          icon="calendar"
          rootStyle="w-100"
          value={filters.year}
          options={crmYearsOptions}
          placeholder={t("select year")}
          onChange={handleSelectFilters}
        />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Client Filters Stats */}
          <ToggleButtonGroup
            size="small"
            value={clientFiltersStats}
            onChange={handleCLientFilters}
            aria-label="choose desired stats"
          >
            {Object.keys(statsColors).map((s) => (
              <ToggleButton value={s} key={s} sx={{ gap: 1, mb: 2 }}>
                <Box
                  aria-hidden="true"
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: statsColors[s],
                  }}
                />
                <strong>{s}</strong>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {/* List */}
          <Box
            sx={{
              ".mbsc-calendar": {
                minHeight: 500,
                height: "calc(100vh - 300px)",
              },
              ".mbsc-calendar-header, .mbsc-calendar-week-days, .mbsc-calendar-labels":
                {
                  display: "none",
                },
              ".mbsc-calendar-body-inner": { overflowY: "auto" },
              ".mbsc-calendar-scroll-wrapper": { overflow: "initial" },
              ".day-event": {
                width: 1,
                height: 60,
                bgcolor: "#fff",
                borderRadius: 2,
                paddingBottom: 2,
                marginBottom: 1.25,
                overflow: "hidden",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
                },
              },
            }}
          >
            <div
              className="d-flex align-items-center text-center"
              style={{ backgroundColor: "#f7f7f7", height: 40 }}
            >
              {[t("saturday"), ...weekdays()].map((day, i) =>
                i !== 6 ? (
                  <span key={day} className="flex-fill">
                    {day}
                  </span>
                ) : null
              )}
            </div>
            <Eventcalendar
              data={filteredClientData || data}
              firstDay={6}
              max={minMax.max}
              min={minMax.min}
              locale={lng?.includes("ar") ? localeAr : localeEn}
              view={{
                calendar: "month",
                agenda: isMediaMatch ? { type: "day" } : undefined,
              }}
              renderHeader={() => undefined}
              renderDayContent={({ events = [], i }) =>
                isMediaMatch
                  ? undefined
                  : events?.map((data) => (
                      <div
                        key={i}
                        className="day-event text-left"
                        onClick={() => handleOpenModal(data)}
                      >
                        <Box
                          aria-hidden="true"
                          sx={{ height: 6, bgcolor: data?.color }}
                        />
                        <div className="px-2">
                          {data?.title ? (
                            <strong className="font-13 pt-1 text-ellipsis">
                              {data.title}
                            </strong>
                          ) : (
                            <br />
                          )}
                          {data?.kind === "Meetings" ? null : (
                            <div className="d-flex text-12 gap-20">
                              {getState(data?.type, data?.period)}
                              <time dateTime={data?.start}>
                                {moment(data?.start).format("hh:mm A")}
                              </time>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
              }
              renderEventContent={({ title, original, startDate, ...rest }) => (
                <div>
                  {title}
                  <div className="d-flex gap-20">
                    {getState(original?.type, original?.period)}
                    <time>{moment(startDate).format("hh:mm A")}</time>
                  </div>
                </div>
              )}
              onEventClick={(_, eventData) => handleOpenModal(eventData)}
            />
          </Box>
        </>
      )}

      {modalState.isOpen ? (
        <CalendarEventModal onClose={handleCloseModal} data={modalState.data} />
      ) : null}
    </>
  );
};

export default Calendar;
