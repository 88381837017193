import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  updateValueAction,
  onFormResetAction,
  fetchChangeAssignments,
  fetchAssignmentsAttempt,
  setFormServerValidationAction,
  showToast,
} from "../../Store/Actions";
import {
  serializeFetchChangeAssignment,
  serializeUpsertChangeAssignment,
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import {
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  changeAssignmentFormQuery,
} from "../../Graphql/query";
import { upsertAssignmentMutation } from "../../Graphql/mutation";

import Loader from "../../Components/Loader";
import MainModal from "../../Components/MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { BSelect, DateTimePickerForm, CheckboxBooleanForm } from "form-builder";
import { WorkPlaces } from "../../Components/SharedFormSections/DayOffException";
import _ from "lodash";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ChangeAssignmentModal = ({ onClose, data, date, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allowCostCenter } = useAllowCostCenter();

  // Local State
  const [modalMessage, setModalMessage] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Constants
  const formProps = {
    formSubmitting,
    reducer: "assignments",
    formName: "changeAssignment",
    formNameValidation: "changeAssignmentFormValidation",
    formServerValidation: "changeAssignmentFormServerValidation",
  };

  // Reducer State
  const formData = useSelector(
    (state) => state?.[formProps.reducer]?.[formProps.formName]
  );

  const isChangeShift = data?.isEdit
    ? !formData?.retain_default_work_timing
    : data?.isChangeShift;

  const formClientValidation = useSelector(
    (state) => state?.[formProps.reducer]?.[formProps.formNameValidation]
  );

  const { from, employees, locations, work_timings } = useSelector(
    (state) => state.assignments.assignmentFilters
  );

  // Server State
  const [upsert, { loading: upsertLoading }] = useCustomMutation(
    upsertAssignmentMutation
  );

  const [normalWorkTimingList, setNormalWorkTimingList] = useState([]);
  const [halfWorkTimingList, setHalfWorkTimingList] = useState([]);

  const { loading: workTimmingLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(formData?.date).format("YYYY-MM-DD"),
      },
      onCompleted: (res) => {
        setNormalWorkTimingList(res?.work_timings_menu);
        setHalfWorkTimingList(res?.half_work_timings_menu);
        console.log(formData);

        if (
          !res?.work_timings_menu?.find(
            (wt) => wt.id == formData?.normal_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction("changeAssignment", "normal_work_timing_id", null)
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formData?.first_half_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "changeAssignment",
              "first_half_work_timing_id",
              null
            )
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formData?.second_half_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "changeAssignment",
              "second_half_work_timing_id",
              null
            )
          );
        }
      },
      skip: !formData?.date,
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const getWorkTimings = (workTimingList, actualWorkTiming = false) => {
    let returnOptions = [...workTimingList];
    if (actualWorkTiming) {
      returnOptions = [...workTimingList, actualWorkTiming];
    }
    return _.uniqBy(returnOptions, "id");
  };

  const { loading, data: changeAssignmentQueryData } = useQuery(
    changeAssignmentFormQuery,
    {
      variables: {
        isIncludeAssignment: data?.isEdit,
        assignmentId: data?.id,
        workTimingsMenuDate: date,
      },
      onCompleted: ({ assignment, offices, employees, work_timings_menu }) => {
        dispatch(
          fetchChangeAssignments({
            ...(data?.isEdit && {
              ...serializeFetchChangeAssignment(assignment),
            }),

            // Options
            offices,
            employees,
            work_timings: work_timings_menu || [],
          })
        );
      },
    }
  );

  /* State Effects */

  React.useEffect(() => {
    data.isEmpProfile &&
      dispatch(
        updateValueAction(formProps.formName, "employee_applicable_on_ids", [
          data.userId,
        ])
      );
  }, [data.isEmpProfile, loading]);

  /* Helpers */

  const handleUpdateWeight = (_, val) => {
    dispatch(updateValueAction(formProps.formName, "weight", val?.weight));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (!formClientValidation?.length) {
      upsert({
        isShowSuccessModal: false,
        variables: {
          input: serializeUpsertChangeAssignment({
            isChangeShift,
            ...formData,
          }),
        },
        onCompleted: ({ updateOrCreateAssignment }) => {
          if (updateOrCreateAssignment?.message)
            return setModalMessage(updateOrCreateAssignment?.message);

          data?.isEmpProfile
            ? props?.actions?.refetch()
            : dispatch(
                fetchAssignmentsAttempt({
                  from: moment(from, "DD-MM-YYYY").format("YYYY-MM-DD"),
                  to: moment(from, "DD-MM-YYYY")
                    .add(6, "days")
                    .format("YYYY-MM-DD"),
                  employeeIds: employees.map((employee) => +employee),
                  locationIds: locations.map((location) => +location),
                  workTimingsIds: work_timings.map((workTiming) => +workTiming),
                })
              );
          handleCloseModal();
        },
        onError: (err) => {
          if (err?.[0]?.extensions?.validation)
            dispatch(
              setFormServerValidationAction({
                serverValidationName: formProps.formServerValidation,
                validations: err?.[0]?.extensions?.validation,
              })
            );
        },
      });
    }
  };

  const handleCloseModal = () => {
    dispatch(onFormResetAction(formProps.formName));
    dispatch(
      setFormServerValidationAction({
        serverValidationName: formProps.formServerValidation,
        validations: {},
      })
    );
    onClose();
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      modalTitle={isChangeShift ? "Change Shifts" : "additional Shifts"}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertLoading}
    >
      {(data?.isEdit || data?.isEmpProfile) && loading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <DateTimePickerForm
        {...formProps}
        name="date"
        validateBy="textRequired"
        validationName="input.from"
        label="date"
        placeholder={t("choose day")}
        datePickerContainer="w-100"
        hasIcon
        isClearable
        inputStyle="assignment-form-date-picker date-picker-input-default"
        iconBackground={false}
        rootStyle="w-100 mr-custom-5"
        disabled={formData?.canEditFrom === false}
      />
      <HasPrivileges
        allowBP
        reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_ASSIGNMENTS]}
      >
        <BSelect
          {...formProps}
          label="employees"
          isLoading={loading}
          name="employee_applicable_on_ids"
          validateBy="arrayRequired"
          validationName="input.applicable_on_ids"
          placeholder={t("select employees")}
          keepDefaultStyle
          options={formData?.employees}
          filterOption={handleFilterOptionByEmpCodeOrName}
          getOptionLabel={(opt) => `${opt.name} - ${opt.office.name}`}
          optionValue="id"
          rootStyle="mt-2"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          isMulti
          icon="person"
          isDisabled={data.isEmpProfile}
        />
      </HasPrivileges>

      {/* Work timing */}
      <BSelect
        {...formProps}
        isLoading={loading || workTimmingLoading}
        isDisabled={loading || workTimmingLoading}
        name="normal_work_timing_id"
        validateBy="textRequired"
        validationName="input.work_timing"
        placeholder={t("select work timing")}
        onInterceptInputOnChange={handleUpdateWeight}
        options={getWorkTimings(
          normalWorkTimingList,
          formData?.normalWorkTiming
        )}
        optionLabel="name"
        label="Work timing"
        optionValue="id"
        labelStyle="mt-3 mb-2"
        containerStyle="d-flex align-items-start flex-column"
        inputContainerStyle="w-100"
        icon="calendar"
        customComponents={{ Option }}
        skipLocalization
      />
      <CheckboxBooleanForm
        {...formProps}
        containerStyle="mt-3"
        name="allow_employees_to_request_half_days"
        options={["allow employees to request half-days"]}
      />
      <div className="d-flex align-items-center justify-content-between my-2">
        <BSelect
          {...formProps}
          isLoading={loading}
          validation="textRequired"
          label="first half work timing"
          name="first_half_work_timing_id"
          placeholder={t("select work timing")}
          validateBy="textRequired"
          validationName="input.work_timing"
          keepDefaultStyle
          options={getWorkTimings(
            halfWorkTimingList,
            formData?.firstHalfWorkTiming
          )}
          optionLabel="name"
          optionValue="id"
          inputContainerStyle="w-100"
          dependOn="allow_employees_to_request_half_days"
          dependancyType="equal"
          dependancyValue={[1]}
          icon="user"
          rootStyle="w-100 mr-5"
          customNewStyles
          customComponents={{ Option }}
        />
        <BSelect
          {...formProps}
          isLoading={loading}
          label="second half work timing"
          name="second_half_work_timing_id"
          placeholder={t("select work timing")}
          validateBy="textRequired"
          validationName="input.work_timing"
          keepDefaultStyle
          options={getWorkTimings(
            halfWorkTimingList,
            formData?.secondHalfWorkTiming
          )}
          optionLabel="name"
          optionValue="id"
          inputContainerStyle="w-100"
          dependOn="allow_employees_to_request_half_days"
          dependancyType="equal"
          dependancyValue={[1]}
          icon="user"
          rootStyle="w-100"
          customNewStyles
          customComponents={{ Option }}
        />
      </div>

      {allowCostCenter ? (
        <>
          <BSelect
            {...formProps}
            name="cost_center_id"
            validationName="input.cost_center_id"
            placeholder={t("select cost center")}
            options={changeAssignmentQueryData?.cost_centers?.data ?? []}
            label="Cost center"
            labelStyle="mt-3 mb-2"
            containerStyle="d-flex align-items-start flex-column"
            inputContainerStyle="w-100"
            icon="money"
            rootStyle="flex-1"
            skipLocalization
          />

          {!isChangeShift ? ( // if additioanl shift (retains)
            <div className="d-flex align-items-center gap-5 my-2">
              <WarningAmberIcon fontSize="small" color="warning" />
              <p className="m-0 font-weight-bold" style={{ fontSize: 12 }}>
                {t(
                  "Some of the employees selected may not be able to sign in to this assignment in case they have signed in to an alternative shift with a different cost center"
                )}
              </p>
            </div>
          ) : null}
        </>
      ) : null}

      {/* locations */}

      <h4 className="assignment-form-header">{t("locations")}</h4>
      <CheckboxBooleanForm
        {...formProps}
        name="apply_work_timing_location"
        options={["use work timing location settings"]}
      />
      {!formData?.apply_work_timing_location ? (
        <WorkPlaces
          formProps={formProps}
          locationsOptions={formData?.offices}
        />
      ) : null}

      {/* Modal Message */}
      {modalMessage && formSubmitting && (
        <p role="alert" aria-live="assertive" className="warnig-msg-style mt-3">
          {modalMessage}
        </p>
      )}
    </MainModal>
  );
};

export default ChangeAssignmentModal;
