import React, { useEffect, useState } from "react";
import MainModal from "../../../MainModal";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissUpdateBussPartnerModalAction,
  editBussPartnerAction,
  onInputChangeAction,
} from "../../../../Store/Actions";
import { useTranslation } from "react-i18next";
import IntlTelInput from "react-intl-tel-input";
// import "../../../Assets/sass/phone-input.scss";

const formName = "updateBussPartnerModal";
const formNameValidation = "updateBussPartnerModalValidation";
const formServerValidation = "updateBussPartnerModalServerValidation";

const EditBussPartnersModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForceSave, setIsForceSave] = useState(false);
  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // Start of country code extention function action
  const handleUpdateCountryCode = (
    currentNumber,
    seletedCountryData,
    fullNumber,
    isValid
  ) => {
    let e = {
      target: {
        value: seletedCountryData.iso2,
        name: "phone_country_code",
      },
    };
    props.onInputChangeAction(formName, e);
  };

  const handlePhoneChange = (
    isValid,
    value,
    seletedCountryData,
    fullNumber,
    extension,
    event
  ) => {
    let e = {
      target: {
        value,
        name: "phone",
      },
    };
    props.onInputChangeAction(formName, e);
    let eCode = {
      target: {
        value: seletedCountryData.iso2,
        name: "phone_country_code",
      },
    };
    props.onInputChangeAction(formName, eCode);
  };
  // End of country code extention function action

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }
    
    const modalData = { ...props.updateBussPartnerModal, force_save: isForceSave};
    props.editBussPartnerAction({
      data: modalData,
      ref: "updateBussPartnerModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissUpdateBussPartnerModalAction({
      ref: "updateBussPartnerModalActions",
      formName,
    });
  };

  useEffect(() => {
    Object.keys(props.serverVaildation).forEach((key, index) => {
      if(props.serverVaildation[key] == "phone already exists"){
        setIsForceSave(true);
      }
    })
  }, [JSON.stringify(props.serverVaildation)]);

  useEffect(() => {
    setIsSubmitting(false);
    setIsForceSave(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Company Business Partner")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
      btnLabel={isForceSave? "Force Save": "save"}
      btnColor={isForceSave? "danger": "primary"}
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <div className="d-flex align-items-baseline">
              <div className="mb-2">
                <h4 className="sub-title-style mb-1">{t("business partner")}</h4>
                <hr />
              </div>
            </div>

            <div className="row mt-2 mb-2">
              <div className="col mb-2">
                <InputForm
                  {...FormProps}
                  name="name"
                  validateBy="textRequired"
                  validationName={`input.name`}
                  label="name"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  icon="person"
                />
              </div>

              <div className="col mb-2">
                <div
                  className={
                    isSubmitting &&
                    props?.serverVaildation &&
                    `input.phone` in props?.serverVaildation
                      ? "invalid-container-style"
                      : " "
                  }
                >
                  <label className="mb-2 validity-label-style">
                    {t("Phone Number")}
                  </label>
                  <div dir="ltr">
                    <IntlTelInput
                      inputClassName="input-style-default"
                      style={{ display: "flex" }}
                      fieldName={"phone"}
                      telInputProps={{
                        dir: "ltr",
                      }}
                      preferredCountries={["eg", "ae", "sa"]}
                      onPhoneNumberChange={(
                        isValid,
                        value,
                        seletedCountryData,
                        fullNumber,
                        extension,
                        event
                      ) => {
                        handlePhoneChange(
                          isValid,
                          value,
                          seletedCountryData,
                          fullNumber,
                          extension,
                          event
                        );
                      }}
                      onSelectFlag={(
                        currentNumber,
                        seletedCountryData,
                        fullNumber,
                        isValid
                      ) => {
                        handleUpdateCountryCode(
                          currentNumber,
                          seletedCountryData,
                          fullNumber,
                          isValid
                        );
                      }}
                      defaultCountry={props?.updateBussPartnerModal?.phone_country_code?.toLowerCase()}
                      separateDialCode
                      format={false}
                      formatOnInit={false}
                      value={props.updateBussPartnerModal?.phone}
                    />
                  </div>
                  <div className="validity-msg-style">
                    {props?.serverVaildation &&
                    `input.phone` in props?.serverVaildation
                      ? props?.serverVaildation[`input.phone`]
                      : null}
                  </div>
                </div>
              </div>

              <div className="col mb-2">
                <InputForm
                  {...FormProps}
                  name="email"
                  validationName={`input.email`}
                  label="email"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  icon="mail"
                />
              </div>
            </div>
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.updateBussPartnerModalActions,
  modalValidation: state.super[formNameValidation],
  updateBussPartnerModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissUpdateBussPartnerModalAction,
  editBussPartnerAction,
  onInputChangeAction,
})(EditBussPartnersModal);
