import React from "react";

import crmTabs from "./tabs";

import NavigationTabs from "../../../Components/NavigationTabs";

const PATH = "/crm";

export const CRMTabs = ({ isRouteSidebar = false }) => {
  return (
    <NavigationTabs.TabsList
      path={PATH}
      tabs={crmTabs}
      isRouteSidebar={isRouteSidebar}
    />
  );
};

const CRMPage = () => {
  return (
    <div className="content pt-3">
      <CRMTabs />
      <NavigationTabs.ActivePanel path={PATH} tabs={crmTabs} />
    </div>
  );
};

export default CRMPage;
