import { makeStyles } from "tss-react/mui";

/**
 *
 * Start of vertical styles
 *
 */

const verticalNavbarStyles = {
  root: {
    flexGrow: 1,
    color: "#2764ac",
    boxShadow: "none",
  },
  tabRoot: {
    opacity: 0.7,
    minHeight: 48,
    color: "#2764ac",
    paddingInlineStart: 25,

    justifyContent: "flex-start",
    "&:focus": { outline: 0 },
    "&:hover": {
      outline: 0,
      opacity: 1,
      backgroundColor: "#2764ac",
      color: "white !important",
    },
  },
  indicator: { display: "none" },
  selected: {
    opacity: 1,
    borderRadius: "2px",
    color: "white !important",
    borderLeft: "4px solid  #59c203",
    backgroundColor: "#2764ac",
  },
  flexContainer: { overflow: "auto" },
};

export const getVerticalNavbarStyles = makeStyles()(() => verticalNavbarStyles);

/**
 *
 * End of vertical styles
 *
 * Start of horizontal styles
 *
 */

const horizontalNavbarStyles = {
  root: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      backgroundColor: "rgba(39, 100, 172, 0.3)",
      width: "100%",
      height: "3px",
      bottom: 0,
      zIndex: -1,
    },
  },
  tabRoot: {
    opacity: 0.7,
    minHeight: 48,
    color: "#2764ac !important",
    "&:hover": {
      color: "white !important",
      borderRadius: "2px",
      backgroundColor: "#2764ac",

      "& svg": {
        color: "white !important",
      },
    },
  },
  selected: {
    opacity: 1,
    color: "white !important",
    borderRadius: "2px",
    backgroundColor: "#2764ac",

    "& svg": {
      color: "white !important",
    },
  },
  scrollButtons: {
    "&.Mui-disabled": { opacity: 0.3 },
  },
  separator: { margin: "-4px" },
  indicator: { display: "none" },
};

export const getHorizontalNavbarStyles = makeStyles()(
  () => horizontalNavbarStyles
);

/**
 *
 * End of horizontal styles
 *
 */
