import React, { useState, useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  InputForm,
  BSelect,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "../../Builder/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissLoansModalAction,
  onInputChangeAction,
  onInputResetAction,
  addInstallmentsInputAction,
  removeInstallmentsInputAction,
  childInputChange,
  generateInstallmentInputAction,
  setLoanCurrencyAccordingToSalaryConfigAction,
  upsertLoansAction,
  upsertLoansSuccess,
  upsertLoansFailed,
  showLoansModalAction,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import CloseIcon from "@mui/icons-material/Close";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_USER_RELATIVE_SALARY_CONFIGURATION,
  GET_WEEK_OPTIONS,
  loansModalQuery,
  loansUserSalaryConfigCurrencyQuery,
} from "../../Graphql/query";
import {
  upsertLoanMutation,
  deleteInstallmentMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HelperFns, {
  showToast,
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";
import loanStatus from "../../Constants/LoanStatusConstants";
import usePrevious from "../../Helpers/Hooks/usePrevious";
import { Tooltip } from "@mui/material";

const formName = `loansModal`;
const formNameValidation = `loansModalValidation`;
const formServerValidation = "loansModalServerValidation";

const LoanModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInstallmentInputs, setShowInstallmentInputs] = useState(false);
  const [showIncludePayroll, setShowIncludePayroll] = useState(false);
  const [disableCurrencyField, setDisableCurrencyField] = useState(false);
  const [options, setOptions] = useState({ users: [], currencies: [] });

  // modal states
  const loanModalData = useSelector((state) => state.super.loansModal);
  const loansModalActions = useSelector(
    (state) => state.super.loansModalActions
  );
  const modalValidation = useSelector(
    (state) => state.super.loansModalValidation
  );
  const loansModalServerValidation = useSelector(
    (state) => state.super.loansModalServerValidation
  );
  const loansList = useSelector((state) => state.super.loansList);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const [
    attemptFetchWeekOptions,
    { loading: weekOptionsLoading, data: weekOptions },
  ] = useLazyQuery(GET_WEEK_OPTIONS);

  const [
    attemptGetRelevantSalaryConfig,
    { data: relevantSalaryConfigData, loading: relevantSalaryConfigLoading },
  ] = useLazyQuery(GET_USER_RELATIVE_SALARY_CONFIGURATION);

  // handel text area change
  const handleTextArea = (e) => {
    dispatch(onInputChangeAction(formName, e));
  };

  // handle generate btn function
  const handleGenerateInstallment = () => {
    const installmentsStartingFromDate =
      relevantSalaryConfigData?.employee?.activeSalaryConfig?.payment?.toLowerCase() ==
      "monthly"
        ? loanModalData?.starting_from
        : loanModalData?.week_start;

    if (
      loanModalData?.installment_number > "0" &&
      installmentsStartingFromDate != ""
    ) {
      setShowInstallmentInputs(!showInstallmentInputs);
      dispatch(
        generateInstallmentInputAction(
          +loanModalData?.installment_number,
          installmentsStartingFromDate,
          loanModalData?.amount,
          relevantSalaryConfigData?.employee?.activeSalaryConfig?.payment
        )
      );
    }
  };

  // Start of handle add/remove installments inputs
  const handleAddInstallmentInput = () => {
    dispatch(addInstallmentsInputAction());
  };

  const handleRemoveInstallmentsInput = (index) => {
    dispatch(removeInstallmentsInputAction(index));
  };

  const handleChildInputChange = (event, childName, inputName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        inputName,
        index,
        inputName === "payment_date" ? event : event.target.value
      )
    );
  };
  // End of handle add/remove installments inputs

  // handleing delete swal
  const handleDeleteSwal = (installmentID) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteInstallment(installmentID);
      }
    });
  };

  // handle delete installments mutation
  const handleDeleteInstallment = (installmentID) => {
    deleteInstallment({
      variables: {
        installment_id: installmentID,
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response.data?.delete_installment?.__typename === "StatusResponse"
        ) {
          if (response?.data?.delete_installment?.status === "error") {
            dispatch(
              showErrorToast(
                response.data?.delete_installment?.message ||
                  response.errors[0]?.extensions?.reason
              )
            );
          }
        }
      })
      .catch((error) => {
        dispatch(showErrorToast(error?.message));
      });
  };

  const [
    deleteInstallment,
    {
      data: deleteInstallmentResponse,
      loading: isDeleteInstallmentLoading,
      error: deleteInstallmentError,
    },
  ] = useMutation(deleteInstallmentMutation, {
    onCompleted: (data) => {
      if (data?.delete_installment?.__typename === "Loan") {
        dispatch(showSuccessToast());

        // handle fetch loans list
        props?.handleFetchLoansData &&
          props?.handleFetchLoansData({
            variables: {
              input: {
                status: loansList?.status,
                user_id: props?.isInEmployeeProfile
                  ? props?.userId
                  : loansList?.user_id,
              },
              rows: loansList?.pagination?.perPage,
              page: loansList?.pagination?.currentPage,
            },
          });

        // handle reftech modal data
        let refetchedData = {
          id: data?.delete_installment?.id,
          user_id: data?.delete_installment?.user?.id,
          currency_id: data?.delete_installment?.currency?.id,
          name: data?.delete_installment?.name,
          amount: data?.delete_installment?.amount,
          granted_on: data?.delete_installment?.granted_on,
          notes: data?.delete_installment?.notes,
          installment_number: data?.delete_installment?.installment_number,
          include_payroll: data?.delete_installment?.include_payroll,
          status: data?.status?.id,
          installments: data?.delete_installment?.installments.map(
            (install) => ({
              id: install?.id,
              amount: install?.amount,
              payment_date: moment(install?.payment_date),
              status: install?.status?.id,
            })
          ),
        };
        dispatch(
          showLoansModalAction({
            data: refetchedData,
            ref: "loansModalActions",
            formName: "loansModal",
          })
        );
      }
    },
  });

  // handle fetch loans currency options query
  const [
    handleFetchLoansCurrencyOptions,
    { loading: isFetchModalDataLoading },
  ] = useLazyQuery(loansModalQuery, {
    onError(err) {
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message
        )
      );
      // to dismiss modal
      toggleModal();
    },
    onCompleted: (data) => {
      setOptions((prev) => ({
        ...prev,
        currencies: data?.currencies || [],
        users: data?.users_by_role?.data || [],
      }));
    },
  });

  // handle fetch loans user salary config currency options query
  const [
    handleFetchLoansUserSalaryConfigCurrencyOptions,
    {
      data: userSalaryConfigCurrencyResponse,
      loading: isFetchUserSalaryConfigCurrencyLoading,
      error: fetchUserSalaryConfigCurrencyError,
    },
  ] = useLazyQuery(loansUserSalaryConfigCurrencyQuery, {
    onError(err) {
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message
        )
      );
    },
    onCompleted: (data) => {
      if (
        data?.employee?.activeSalaryConfig?.type?.toLowerCase() === "internal"
      ) {
        dispatch(
          setLoanCurrencyAccordingToSalaryConfigAction(
            data?.employee?.activeSalaryConfig?.details?.currency?.id
          )
        );
        setDisableCurrencyField(true);
        setShowIncludePayroll(true);
      } else {
        setShowIncludePayroll(false);
        setDisableCurrencyField(false);
      }
    },
  });

  // handle upsert loan mutation
  const handleUpsertLoan = () => {
    upsertLoan({
      variables: {
        input: {
          id: loanModalData?.id,
          user_id: props?.isInEmployeeProfile
            ? props?.userId
            : loanModalData?.user_id,
          currency_id: loanModalData?.currency_id,
          name: loanModalData?.name,
          amount: parseFloat(loanModalData?.amount),
          granted_on: loanModalData?.granted_on,
          notes: loanModalData?.notes,
          installment_number: parseInt(loanModalData?.installment_number),
          include_payroll: !!loanModalData?.include_payroll,
          installments: loanModalData?.installments.map((install) => ({
            id: install?.id,
            amount: parseFloat(install?.amount),
            payment_date: moment(install?.payment_date).format("yyyy-MM-DD"),
          })),
        },
      },
    });
  };

  const [upsertLoan, { data: upsertLoanResponse, loading: upsertLoanLoading }] =
    useMutation(upsertLoanMutation, {
      onCompleted: (data) => {
        if (
          data?.upsert_loan?.__typename == "StatusResponse" &&
          data?.upsert_loan?.status == "error"
        ) {
          dispatch(
            upsertLoansFailed({
              errors: {},
              modalMessage: data?.upsert_loan?.message,
            })
          );
        }

        if (data?.upsert_loan?.id) {
          dispatch(upsertLoansSuccess());
          dispatch(showSuccessToast());

          // handle fetch loans list
          props?.handleFetchLoansData &&
            props?.handleFetchLoansData({
              variables: {
                input: {
                  status: loansList?.status,
                  user_id: props?.isInEmployeeProfile
                    ? props?.userId
                    : loansList?.user_id,
                },
                rows: loansList?.pagination?.perPage,
                page: loansList?.pagination?.currentPage,
              },
            });
        }
      },
      onError: (error) => {
        if (error?.graphQLErrors?.[0]?.extensions?.category == "validation") {
          dispatch(
            upsertLoansFailed({
              errors: HelperFns.parseValidatorErrors(
                error?.graphQLErrors?.[0]?.extensions?.validation
              ),
            })
          );
        } else {
          showToast(
            "error",
            error?.graphQLErrors?.[0]?.message ?? t("something went wrong")
          );
        }
      },
    });

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (modalValidation?.length) {
      return;
    }

    // trigger upsert loan attempt action
    dispatch(upsertLoansAction());

    // upsert loan function
    handleUpsertLoan();
  };

  const toggleModal = () => {
    dispatch(
      dismissLoansModalAction({
        ref: "loansModalActions",
        formName,
      })
    );
  };

  // to get the previous values using usePrevious hook
  const prevUserId = usePrevious(loanModalData?.user_id);

  // handle cuurency when loan is included in payroll
  useEffect(() => {
    if (
      loanModalData?.granted_on &&
      (props?.userId || loanModalData?.user_id)
    ) {
      if (prevUserId && prevUserId !== loanModalData?.user_id) {
        dispatch(onInputResetAction(formName, "include_payroll"));
        dispatch(onInputResetAction(formName, "currency_id"));
      }

      // to set the currency for loan
      handleFetchLoansUserSalaryConfigCurrencyOptions({
        variables: {
          user_id: props?.isInEmployeeProfile
            ? props?.userId
            : loanModalData?.user_id,
          date: loanModalData?.granted_on,
        },
      });
      setDisableCurrencyField(false);
      setShowIncludePayroll(false);
    }
  }, [loanModalData?.granted_on, loanModalData?.user_id]);

  useEffect(() => {
    setIsSubmitting(false);
    setShowInstallmentInputs(false);

    if (loansModalActions?.isVissible) {
      // to fetch currency options
      handleFetchLoansCurrencyOptions();
    }
  }, [loansModalActions?.isVissible]);

  useEffect(() => {
    // should reset here
    setShowInstallmentInputs(false);

    if (
      (!!!props?.userId && !!!loanModalData?.user_id) ||
      !!!loanModalData?.granted_on
    ) {
      return;
    }

    attemptGetRelevantSalaryConfig({
      variables: {
        userId: props?.userId ? props?.userId : loanModalData?.user_id,
        date: loanModalData?.granted_on,
      },
      onCompleted: (data) => {
        if (
          data?.employee?.activeSalaryConfig?.payment?.toLowerCase() == "weekly"
        ) {
          attemptFetchWeekOptions({
            variables: {
              from: moment().startOf("year").format("YYYY-MM-DD"),
              to: moment().endOf("year").format("YYYY-MM-DD"),
              userId: props?.userId ? +props?.userId : +loanModalData?.user_id,
              type: "Users",
            },
          });
        }
      },
    });
  }, [loanModalData?.user_id, loanModalData?.granted_on, props?.userId]);

  if (isFetchModalDataLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  const isGenerateButtonDisabled =
    !!!loanModalData?.granted_on ||
    (!!!props?.userId && !!!loanModalData?.user_id) ||
    parseInt(loanModalData?.installment_number) < 1;

  const resetInstallments = () => {
    dispatch(onInputResetAction(formName, "installment_number"));
    dispatch(onInputResetAction(formName, "starting_from"));
    dispatch(onInputResetAction(formName, "week_start"));
    dispatch(onInputResetAction(formName, "installments"));
  };

  const handleInterceptChangeUserOrChangeGrantedOnDate = () => {
    resetInstallments();
    return false;
  };

  return (
    <MainModal
      isOpen={loansModalActions?.isVissible}
      toggle={toggleModal}
      modalTitle={loanModalData?.id == null ? t("new loan") : t("edit loan")}
      btnLabel={t("save")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={upsertLoanLoading}
      className="loan_modal_wrapper_style"
      size="lg"
    >
      {isDeleteInstallmentLoading || isFetchUserSalaryConfigCurrencyLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <>
        <div className="row mb-2">
          <div className="col">
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              label="name"
              name="name"
              validationName="input.name"
              type="text"
              placeholder={t("name")}
              labelStyle="mb-2"
              containerStyle=" "
              inputContainerStyle="w-100"
              icon="money"
            />
          </div>

          {props?.isInEmployeeProfile ? null : (
            <div className="col-6">
              <BSelect
                {...FormProps}
                label="employee"
                name="user_id"
                validationName="input.user_id"
                placeholder={t("select employee")}
                optionLabel="name"
                optionValue="id"
                labelStyle="mb-2"
                containerStyle=" "
                options={options?.users}
                filterOption={handleFilterOptionByEmpCodeOrName}
                isClearable
                isDisabled={
                  loanModalData?.installments?.filter(
                    (install) => install?.status == loanStatus.SETTLED_LOAN
                  ).length > 0
                    ? true
                    : false
                }
                validateBy="textRequired"
                icon="person"
                onInterceptInputOnChange={
                  handleInterceptChangeUserOrChangeGrantedOnDate
                }
              />
            </div>
          )}
        </div>

        <div className="row mb-2 align-items-baseline">
          <div className="col-6">
            <div className="row align-items-baseline">
              <div className="col-6">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  label="loan amount"
                  name="amount"
                  validationName="input.amount"
                  type="number"
                  placeholder={t("loan amount")}
                  labelStyle="mb-2"
                  containerStyle=" "
                  disabled={
                    loanModalData?.installments?.filter(
                      (install) => install?.status == loanStatus.SETTLED_LOAN
                    ).length > 0
                      ? true
                      : false
                  }
                  inputContainerStyle="w-100"
                  icon="currency"
                />
              </div>

              <div className="col-6">
                <BSelect
                  {...FormProps}
                  label="Currency"
                  labelStyle="mb-2"
                  name="currency_id"
                  validationName="input.currency_id"
                  options={options?.currencies}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  isDisabled={
                    disableCurrencyField ||
                    loanModalData?.installments?.filter(
                      (install) => install?.status == loanStatus.SETTLED_LOAN
                    ).length > 0
                      ? true
                      : false
                  }
                  placeholder={t("select currency")}
                  icon="currency"
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <DateTimePickerForm
              name="granted_on"
              label="Granted On"
              labelStyle="mb-2"
              requestFormat="YYYY-MM-DD"
              validationName="input.granted_on"
              placeholder={t("select date")}
              datePickerContainer="w-100"
              hasIcon
              validateBy="textRequired"
              disabled={
                loanModalData?.installments?.filter(
                  (install) => install?.status == loanStatus.SETTLED_LOAN
                ).length > 0
                  ? true
                  : false
              }
              {...FormProps}
              // format="MMMM, yyyy"
              // requestFormat="yyyy-MM-DD"
              // picker="month"
              onIntercept={handleInterceptChangeUserOrChangeGrantedOnDate}
            />
          </div>
        </div>

        {showIncludePayroll ? (
          <div className="row mb-2">
            <div className="col-12">
              <CheckboxBooleanForm
                {...FormProps}
                options={["Include in Payroll"]}
                name="include_payroll"
                validationName="input.include_payroll"
                type="checkbox"
              />
            </div>
          </div>
        ) : null}

        <div className="row mb-2">
          <div
            className={`col-12 ${
              loansModalServerValidation?.["input.notes"]
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">{t("Note")}</label>

            <textarea
              rows="4"
              {...FormProps}
              name="notes"
              onChange={handleTextArea}
              value={loanModalData?.notes}
            />
            <span className="validity-msg-style">
              {loansModalServerValidation?.["input.notes"]
                ? loansModalServerValidation?.["input.notes"]
                : null}
            </span>
          </div>
        </div>

        {loanModalData?.id == null ? (
          <>
            {!showInstallmentInputs ? (
              <div className="row mb-2 align-items-baseline">
                <div className="col-6">
                  <InputForm
                    {...FormProps}
                    validateBy="textRequired"
                    label="Installments"
                    name="installment_number"
                    validationName="input.installment_number"
                    type="number"
                    stepInput
                    inputContainerStyle="input-container-style-default"
                    containerStyle="d-inline-flex flex-column"
                    labelStyle="mb-2"
                  />
                </div>

                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-9">
                      {relevantSalaryConfigData?.employee?.activeSalaryConfig?.payment?.toLowerCase() ==
                      "weekly" ? (
                        <BSelect
                          {...FormProps}
                          label="week start"
                          name="week_start"
                          optionLabel="from"
                          optionValue="from"
                          isOptionDisabled={(option) =>
                            option.status !== "Pending"
                          }
                          options={weekOptions?.weeks ?? []}
                          isLoading={weekOptionsLoading}
                          isClearable
                          placeholder={t("select week start")}
                          isDisabled={props?.isFirst || props?.activate}
                        />
                      ) : (
                        <DateTimePickerForm
                          label="Starting From"
                          labelStyle="mb-2"
                          {...FormProps}
                          name="starting_from"
                          placeholder={t("select date")}
                          datePickerContainer="w-100"
                          hasIcon
                          requestFormat="YYYY-MM-DD"
                          // format="MMMM, yyyy"
                          // requestFormat="yyyy-MM-DD"
                          // picker="month"
                        />
                      )}
                    </div>

                    <div className="col-12 col-lg-3 mt-lg-4 pt-1 d-flex justify-content-end">
                      <div>
                        <Tooltip
                          title={
                            isGenerateButtonDisabled
                              ? t(
                                  "please select an employee and number of installments and a granted on date first"
                                )
                              : ""
                          }
                        >
                          <button
                            className="btn add_new_btn_style installments_generate_btn_style"
                            type="button"
                            onClick={handleGenerateInstallment}
                            disabled={isGenerateButtonDisabled}
                          >
                            <span>{t("generate")}</span>
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row installments_in_form_wrapper_style mt-3">
                <div className="col-12">
                  <div className="d-flex align-items-baseline">
                    <div className="mb-2">
                      <label>{t("Installments")}</label>
                    </div>

                    <div className="mx-3">
                      <button
                        className="btn add_new_btn_style"
                        type="button"
                        onClick={() => handleAddInstallmentInput()}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    {loanModalData?.installments?.map((installment, i) => {
                      return (
                        <div className="col-12 col-lg-6">
                          <div className="row mt-2 mb-2">
                            <div className="col mb-2">
                              <InputForm
                                {...FormProps}
                                name={`installment-amount-${i}`}
                                validateBy="textRequired"
                                value={installment.amount}
                                validationName={`input.installments.${i}.amount`}
                                onChange={(e) =>
                                  handleChildInputChange(
                                    e,
                                    "installments",
                                    "amount",
                                    i
                                  )
                                }
                                type="number"
                                placeholder={t("amount")}
                                containerStyle="mt-1"
                                inputContainerStyle=" "
                                icon="currency"
                              />
                            </div>

                            <div className="col mb-2">
                              <DateTimePickerForm
                                {...FormProps}
                                name="payment_date"
                                validationName={`input.installments.${i}.payment_date`}
                                value={installment.payment_date}
                                onChange={(e) =>
                                  handleChildInputChange(
                                    e,
                                    "installments",
                                    "payment_date",
                                    i
                                  )
                                }
                                placeholder={t("select date")}
                                datePickerContainer="w-100"
                                hasIcon
                                validateBy="textRequired"
                                requestFormat={"YYYY-MM-DD"}
                              />
                            </div>

                            {installment.id != null ? (
                              <div
                                className="cancel_object_btn_style"
                                onClick={() =>
                                  handleDeleteSwal(installment?.id)
                                }
                              >
                                <CloseIcon />
                              </div>
                            ) : (
                              <div
                                className="cancel_object_btn_style"
                                onClick={() => handleRemoveInstallmentsInput(i)}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="row installments_in_form_wrapper_style mt-3">
              <div className="col-12">
                <div className="d-flex align-items-baseline">
                  <div className="mb-2">
                    <label>{t("Installments")}</label>
                  </div>

                  <div className="mx-3">
                    <button
                      className="btn add_new_btn_style"
                      type="button"
                      onClick={() => handleAddInstallmentInput()}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <div className="row">
                  {loanModalData?.installments?.map((installment, i) => (
                    <div className="col-12 col-lg-6">
                      <div className="row mt-2 mb-2">
                        <div className="col mb-2">
                          <InputForm
                            {...FormProps}
                            name={`amount-${i}`}
                            validateBy="textRequired"
                            value={installment?.amount}
                            validationName={`input.installments.${i}.amount`}
                            onChange={(e) =>
                              handleChildInputChange(
                                e,
                                "installments",
                                "amount",
                                i
                              )
                            }
                            type="number"
                            placeholder={t("amount")}
                            containerStyle="mt-1"
                            inputContainerStyle=" "
                            disabled={
                              installment?.status == loanStatus.SETTLED_LOAN
                                ? true
                                : false
                            }
                          />
                        </div>

                        <div className="col mb-2">
                          <DateTimePickerForm
                            {...FormProps}
                            name="payment_date"
                            validationName={`input.installments.${i}.payment_date`}
                            value={installment?.payment_date}
                            onChange={(e) =>
                              handleChildInputChange(
                                e,
                                "installments",
                                "payment_date",
                                i
                              )
                            }
                            placeholder={t("select date")}
                            datePickerContainer="w-100"
                            hasIcon
                            validateBy="textRequired"
                            disabled={
                              installment?.status == loanStatus.SETTLED_LOAN
                                ? true
                                : false
                            }
                            requestFormat="YYYY-MM-DD"
                          />
                        </div>

                        {installment?.id != null ? (
                          <div
                            className="cancel_object_btn_style"
                            onClick={() => handleDeleteSwal(installment?.id)}
                          >
                            <CloseIcon />
                          </div>
                        ) : (
                          <div
                            className="cancel_object_btn_style"
                            onClick={() => handleRemoveInstallmentsInput(i)}
                          >
                            <CloseIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="invalid-container-style">
          <span className="validity-msg-style">
            {loansModalServerValidation?.["input.installments"]
              ? loansModalServerValidation?.["input.installments"]
              : null}
          </span>
        </div>

        {/* (Start) Error Message */}
        {loansModalActions?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">
            {loansModalActions?.modalMessage}
          </div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

export default LoanModal;
