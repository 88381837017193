import React from "react";
import { useLazyQuery } from "@apollo/client";

import gql from "graphql-tag";
import { paginatorFragment } from "../../Graphql/fragments";
import { safeJsonParse, showToast } from "../../Helpers/HelperFns";

import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { ColorButton } from "../../Components/Buttons";
import { BSelect, DateTimePickerForm, InputForm } from "form-builder";

const GET_LOGS = gql`
  query getLogs($first: Int!, $page: Int, $input: RecordLogsInput) {
    recordLogs(first: $first, page: $page, input: $input) {
      data {
        reason
        created_at
        employee {
          user {
            id
            name
          }
        }
        actionBy {
          id
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const typeOptions = [
  { id: "Sign_in_out", name: "Sign In/Out" },
  { id: "Suspend", name: "Suspension" },
  { id: "Privilege", name: "Privilege Leave" },
  { id: "AnnualLeave", name: "Annual Leave" },
  { id: "Permission", name: "Permission Request" },
  { id: "HomeExchange", name: "Home Office Exchange" },
  { id: "HomeFlexible", name: "Flexible Home Office" },
  { id: "OverTime", name: "Overtime" },
  { id: "EmergencyLeave", name: "Emergency Leave" },
  { id: "SickLeave", name: "Sick Leave" },
  { id: "UnpaidLeave", name: "Unpaid Leave" },
  { id: "UnpaidPermission", name: "Unpaid Permission" },
  { id: "AnnualHalfDay", name: "Annual Half-Day Leave" },
  { id: "EmergencyHalfDay", name: "Emergency Half-Day Leave" },
  { id: "EditAttendance", name: "Attendance Edit" },
  { id: "CustomRequest", name: "Custom Request" },
  { id: "IgnoreException", name: "Ignore Exception" },
  { id: "UnpaidHalfDay", name: "Unpaid Half-Day Leave" },
  { id: "DayOff", name: "Day Off" },
  { id: "ChangeShift", name: "Shift Change" },
];
const sendFilters = (filters) => {
  return {
    page: 1,
    input: {
      loggable_type: filters.type.id,
      employee_id: filters.employee_id,
      date_to: filters?.to?.format("YYYY-MM-DD"),
      date_from: filters?.from?.format("YYYY-MM-DD"),
    },
  };
};

const DeletedSignInsList = () => {
  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [filters, setFilters] = React.useState({
    employee_id: null,
    from: null,
    to: null,
    type: "",
  });

  // Server State
  const [getLogs, { loading, refetch: refetchList }] = useLazyQuery(GET_LOGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...sendFilters(filters),
      first: pagination.perPage,
    },
    onCompleted: ({ recordLogs }) => {
      setData(recordLogs?.data || []);
      setPagination(recordLogs?.paginatorInfo || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const applyFilters = Object.values(filters).every((val) => val);
  const columns = [
    { name: "employee", grow: 2, selector: (row) => row?.employee?.user?.name },
    { name: "created at", selector: "created_at" },
    { name: "action by", selector: (row) => row?.actionBy?.name },
    {
      name: "reason",
      cell: (row) => {
        const { reason = "" } = safeJsonParse(safeJsonParse(row?.reason), {});
        return reason;
      },
    },
  ];

  /* ↓ Helpers ↓ */

  const handleFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex align-items-end gap-20 mb-3">
        <InputForm
          icon="search"
          name="employee_id"
          label="employee ID"
          placeholder="search employee by id"
          value={filters.employee_id}
          onChange={handleFilters}
          containerStyle="d-flex flex-column gap-10"
          inputContainerStyle="w-100"
        />
        <BSelect
          name="type"
          label="type"
          value={filters.type}
          onChange={handleSelectFilters}
          inputContainerStyle="w-100"
          options={typeOptions}
          rootStyle="flex-1"
          containerStyle="d-flex flex-column gap-10"
        />
        <DateTimePickerForm
          hasIcon
          label="from"
          value={filters.from}
          rootStyle="flex-1"
          datePickerContainer="w-100"
          containerStyle="d-flex flex-column gap-10"
          onChange={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          label="to"
          value={filters.to}
          rootStyle="flex-1"
          datePickerContainer="w-100"
          containerStyle="d-flex flex-column gap-10"
          onChange={(val) => handleDateFiltersChange("to", val)}
        />
        <ColorButton
          onClick={getLogs}
          label="apply filters"
          disabled={!applyFilters}
          disabledLabel="you need to select all filters to be able to get records"
        />
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        pagination
        paginationServer
        progressPending={loading}
        progressComponent={<Loader />}
      />
    </>
  );
};

export default DeletedSignInsList;
