import React, { useState, useEffect } from "react";
import Validation from "../../Helpers/Validations";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";
import Tooltip from "@mui/material/Tooltip";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const CheckboxBooleanForm = ({
  name,
  dependOn,
  label,
  formName,
  _onChange,
  validateBy,
  formSubmitting,
  handleInValidForm,
  FormValid = [],
  formNameValidation,
  rootStyle,
  containerStyle,
  labelStyle,
  inputStyle,
  optionsContainerStyle,
  optionItemStyle,
  optionInputStyle,
  optionLabelStyle,
  optionLabelActiveStyle,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  formServerValidation,
  validationName,
  validateContainerStyle,
  setWithValue = false,
  checkedValue = true,
  unCheckedValue = false,
  onInterceptInputOnChange = () => undefined,
  ...props
}) => {
  const inputval = props.value ?? props.super[formName]?.[name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation]?.[validationName ?? name]
      ? true
      : false;

  const { t } = useTranslation();

  const handleInputChange = (event, val) => {
    if (setWithValue) {
      switch (event.target.checked) {
        case true:
          val = checkedValue;
          break;
        case false:
          val = unCheckedValue;
          break;
      }
    } else {
      switch (event.target.checked) {
        case true:
          val = 1;
          break;
        case false:
          val = 0;
          break;
      }
    }
    event.persist();
    if (onInterceptInputOnChange(event, val)) {
      return;
    }
    props.onInputChangeAction(formName, event, "booleanCheckbox", val);


  };

  const InputTooltip = ({ title = "", tooltipStyle }) => {
    return (
      <span className={`${tooltipStyle ? tooltipStyle : "tooltip-icon-style"}`}>
        <Tooltip placement="top" title={title}>
          <ErrorOutline fontSize="inherit" color="inherit" />
        </Tooltip>
      </span>
    );
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  const isChecked = () => {
    let inputName = props.super[formName]?.[name];
    if (setWithValue) {
      return inputName == checkedValue;
    } else {
      return +inputName == 1;
    }
  };

  return (
    <div className={`${vaildContainer()} ${rootStyle}`}>
      <div className={containerStyle ? containerStyle : "my-1"}>
        {label && (
          <label className={`${labelStyle ? labelStyle : ""}`}>
            {t(label)}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}

        <div
          className={`${optionsContainerStyle ? optionsContainerStyle : ""}`}
        >
          {props.options.map((opt) => (
            <div key={opt} className={optionItemStyle ? optionItemStyle : " "}>
              <label
                className={optionInputStyle ? optionInputStyle : "checkbox"}
              >
                <input
                  type="checkbox"
                  name={name}
                  value={opt}
                  onChange={(e) => handleInputChange(e, opt)}
                  checked={isChecked()}
                  {...props}
                />
                <span></span>

                <div
                  className={`
                  ${optionLabelStyle
                      ? optionLabelStyle
                      : "booleanInputLabelStyle"
                    }
                  ${+props.super[formName]?.[name]
                      ? optionLabelActiveStyle
                        ? optionLabelActiveStyle
                        : "lightActiveColor"
                      : ""
                    }
                  `}
                >
                  {t(opt)}
                  {hasTooltip && (
                    <InputTooltip
                      title={tooltipTitle}
                      tooltipStyle={tooltipStyle}
                    />
                  )}
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      {!validate.validity ||
        (isServerValid && (
          <span className="validity-msg-style">
            {validate.validityMessage}
            {validate.validityMessage && <br />}
            {Boolean(props.super[formServerValidation])
              ? props.super[formServerValidation][validationName ?? name] ?? ""
              : null}
          </span>
        ))}
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};
export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
})(withDependency(CheckboxBooleanForm));
