import gql from "graphql-tag";
import {
  userProfileFragment,
  requestResponseFragment,
  updateSuspensionResponseFragment,
  checkInOutsResponseFragment,
  payInstallmentUnionResponseFragment,
  deleteInstallmentUnionResponseFragment,
  revertPaymentInstallmentUnionResponseFragment,
  checkInOutFragment,
  userPenaltiesFragment,
  authUserFragment,
} from "../fragments";

export * from "./CRM";
export * from "./Settings";
export * from "./Attendance";
export * from "./Finanical";
export * from "./Assets";
export * from "./Employee";

export const authUserMutation = gql`
  mutation authUser(
    $username: String!
    $password: String!
    $device_token: String!
    $device_type: String!
  ) {
    login(
      input: {
        username: $username
        password: $password
        device_token: $device_token
        device_type: $device_type
        api_type: "graphql"
      }
    ) {
      access_token
      user {
        ...authUser
      }
    }
  }
  ${authUserFragment}
`;

export const createAttProfileMutation = gql`
  mutation newAttProfile {
    attPro: init_attendance_profile {
      id
    }
  }
`;

export const initEmployeeMutation = gql`
  mutation createEmployee($id: ID) {
    user: upsert_user {
      ...userProfile
      workday_locations {
        workday
      }
    }
  }
  ${userProfileFragment}
`;

export const updateEmployeeMutation = gql`
  mutation updateEmployee(
    $input: UserInput!
    $includePaymentMethod: Boolean!
    $userPaymentInput: UserPaymentInput
    $userPaymentId: ID
    $deletePayment: Boolean!
  ) {
    update_user(input: { user_input: $input }) {
      id
    }
    updateOrCreateUserPayment(input: $userPaymentInput)
      @include(if: $includePaymentMethod) {
      user {
        id
        name
      }
      payment_method
      data
    }
    deleteUserPayment(id: $userPaymentId) @include(if: $deletePayment) {
      status
    }
  }
`;

export const cancelRequestMutation = gql`
  mutation cancelRequest($id: ID, $end_date: String) {
    cancel_request(id: $id, end_date: $end_date) {
      ...reqUnionResponse
    }
  }
  ${requestResponseFragment}
`;
export const acceptInboundRequestMutation = gql`
  mutation acceptRequest($id: ID) {
    accept_request(id: $id) {
      ...reqUnionResponse
      ... on EditAttendanceAndAcceptRequestException {
        __typename
        messageForEdit
        SignInId
        RequestTypeId
        workTiming {
          id
          name
          work_timing_profile_type
        }
      }
    }
  }
  ${requestResponseFragment}
`;

export const acceptOvertimeRequestMutation = gql`
  mutation acceptOvertime(
    $id: ID
    $interval: String
    $created_at: String
    $force_confirm: Boolean
  ) {
    accept_overtime(
      id: $id
      interval_in_time: $interval
      payable_at: $created_at
      force_confirm: $force_confirm
    ) {
      id
    }
  }
`;

export const rejectInboundRequestMutation = gql`
  mutation rejectRequest($id: ID) {
    reject_request(id: $id) {
      ...reqUnionResponse
      ... on EditAttendanceAndAcceptRequestException {
        __typename
        messageForEdit
        SignInId
        RequestTypeId
        workTiming {
          id
          name
          work_timing_profile_type
        }
      }
    }
  }
  ${requestResponseFragment}
`;

export const NormalRequestMutation = gql`
  mutation NormalRequest(
    $employee_id: ID
    $from: String
    $deducted_from_emergency: Boolean
    $to: String
    $requestable: RequestableInput
    $attachments: [Face]
  ) {
    normal_request: annualFullDayRequest(
      input: {
        employeeId: $employee_id
        timeFrom: $from
        timeTo: $to
        deductedFromEmergency: $deducted_from_emergency
        requestable: $requestable
        files: $attachments
      }
    ) {
      __typename
      ... on AnnualLeave {
        request {
          id
        }
      }
      ... on EmergencyLeave {
        request {
          id
        }
      }
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const UnpaidRequestMutation = gql`
  mutation unpaidRequest(
    $employee_id: ID
    $from: String!
    $to: String!
    $requestable: RequestableInput
    $attachments: [Face]
  ) {
    unpaid_request: unpaidLeaveRequest(
      input: {
        employeeId: $employee_id
        timeFrom: $from
        timeTo: $to
        requestable: $requestable
        files: $attachments
      }
    ) {
      __typename
      ... on GeneralException {
        message
      }
      ... on UnpaidLeave {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const EmergencyRequestMutation = gql`
  mutation EmergencyRequest($employee_id: ID, $from: String!, $to: String!) {
    emergency_request(
      input: {
        employee_id: $employee_id
        emergency_from: $from
        emergency_to: $to
      }
    ) {
      ...reqUnionResponse
    }
  }
  ${requestResponseFragment}
`;

export const PermissionRequestMutation = gql`
  mutation PermissionRequest(
    $employee_id: ID
    $from: String!
    $to: String!
    $requestDay: String!
    $requestableId: ID!
    $requestableType: String
    $attachments: [Face]
    $workTimingId: ID
  ) {
    permission_request: createPermission(
      input: {
        employeeId: $employee_id
        timeFrom: $from
        timeTo: $to
        requestDay: $requestDay
        requestable: {
          requestableId: $requestableId
          requestableType: $requestableType
          workTimingId: $workTimingId
        }
        files: $attachments
      }
    ) {
      __typename
      ... on GeneralException {
        message
      }
      ... on Permission {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;
export const unpaidPermissionRequestMutation = gql`
  mutation unpaidPermissionRequest(
    $employee_id: ID
    $from: String!
    $to: String!
    $requestDay: String!
    $requestableId: ID!
    $requestableType: String
    $attachments: [Face]
    $workTimingId: ID
  ) {
    permission_request: createUnpaidPermission(
      input: {
        employeeId: $employee_id
        timeFrom: $from
        timeTo: $to
        requestDay: $requestDay
        requestable: {
          requestableId: $requestableId
          requestableType: $requestableType
          workTimingId: $workTimingId
        }
        files: $attachments
      }
    ) {
      __typename
      ... on GeneralException {
        message
      }
      ... on UnpaidPermission {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const HalfdayRequestMutation = gql`
  mutation HalfdayRequest(
    $employee_id: ID
    $deducted_from_emergency: Boolean
    $date: String!
    $half: String!
    $requestableId: ID
    $requestableType: String
    $workTimingId: ID
    $attachments: [Face]
  ) {
    halfday_request: annualHalfDayRequest(
      input: {
        employeeId: $employee_id
        deductedFromEmergency: $deducted_from_emergency
        requestDay: $date
        addInfo: $half
        requestable: {
          requestableId: $requestableId
          requestableType: $requestableType
          workTimingId: $workTimingId
        }
        files: $attachments
      }
    ) {
      __typename
      ... on GeneralException {
        message
      }
      ... on EmergencyHalfDay {
        request {
          id
        }
      }
      ... on AnnualHalfDay {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const HomeExchangeRequestMutation = gql`
  mutation homeExchangeRequest(
    $employee_id: ID
    $homeDay: String!
    $instead: String!
    $requestableId: ID
    $requestableType: String
    $attachments: [Face]
  ) {
    home_exchange_request: homeExchangeRequest(
      input: {
        employeeId: $employee_id
        requestDay: $homeDay
        instead: $instead
        requestable: {
          requestableId: $requestableId
          requestableType: $requestableType
        }
        file: $attachments
      }
    ) {
      ... on GeneralException {
        __typename
        message
      }
      ... on HomeExchange {
        __typename
        request {
          id
        }
      }
      ... on PaidPayrollException {
        __typename
        message
      }
    }
  }
`;

export const HomeFlexRequestMutation = gql`
  mutation homeFlexRequest(
    $employee_id: ID
    $homeDay: String!
    $requestableId: ID
    $requestableType: String
    $attachments: [Face]
  ) {
    home_flex_request: homeFlexRequest(
      input: {
        employeeId: $employee_id
        requestDay: $homeDay
        requestable: {
          requestableId: $requestableId
          requestableType: $requestableType
        }
        files: $attachments
      }
    ) {
      __typename
      ... on GeneralException {
        message
      }
      ... on HomeFlexible {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const createSignInMutation = gql`
  mutation signInRequest($from_home: Int) {
    sign_in(
      input: { lat: null, lng: null, face: null, from_home: $from_home }
    ) {
      sign_in_time
      sign_out_time
      from_home
    }
  }
`;

export const dismissHomeSignInMutation = gql`
  mutation dismissHomeSignIn {
    dismiss_work_from_home {
      status {
        hide
        show
      }
      message
      confirm
      sign_in_out {
        sign_in_time
        sign_out_time
        status
        from_home
      }
    }
  }
`;

export const createSignOutMutation = gql`
  mutation createSignOut {
    sign_out(input: { lng: null, lat: null, face: null }) {
      sign_in_time
      sign_out_time
      from_home
    }
  }
`;

export const adjustSignOutMutation = gql`
  mutation signOutEmployee(
    $sign_in_out_ids: [ID]
    $auto_sign_out_all: Int
    $filtered_emp_id: [ID]
    $from: String
    $to: String
    $status: [String]
  ) {
    doSignOut(
      input: {
        sign_in_out_ids: $sign_in_out_ids
        auto_sign_out_all_records: $auto_sign_out_all
        emp_id: $filtered_emp_id
        date_range: { from: $from, to: $to }
        status: $status
      }
    ) {
      message
    }
  }
`;

export const updateSuspensionMutation = gql`
  mutation updateSuspension(
    $suspended_employee: ID
    $suspend_from: String
    $suspend_to: String
    $handleLeaveBalanceAfterSuspensionOption: ID
    $replace_by: ID
    $new_manager_for_replaced_by: ID
    $affected_balance: ID
    $is_blacklisted: Boolean
  ) {
    update_suspension(
      input: {
        suspended_employee: $suspended_employee
        suspend_from: $suspend_from
        suspend_to: $suspend_to
        handleLeaveBalanceAfterSuspensionOption: $handleLeaveBalanceAfterSuspensionOption
        replace_by: $replace_by
        new_manager_for_replaced_by: $new_manager_for_replaced_by
        affected_balance: $affected_balance
        is_blacklisted: $is_blacklisted
      }
    ) {
      ...updateSuspensionResponse
    }
  }
  ${updateSuspensionResponseFragment}
`;

export const upsertDepartmentMutation = gql`
  mutation upsertDepartment($id: ID, $name: String, $manager_id: ID) {
    upsert_departments(
      input: [{ id: $id, name: $name, manager_id: $manager_id }]
    ) {
      id
    }
  }
`;

export const deleteDepartmentMutation = gql`
  mutation deleteDepartment($id: ID!) {
    delete_department(id: $id) {
      status
      message
    }
  }
`;

export const upsertPositionMutation = gql`
  mutation upsertPosition($id: ID, $name: String) {
    upsert_positions(input: [{ id: $id, name: $name }]) {
      id
    }
  }
`;

export const upsertHolidayMutation = gql`
  mutation upsertHoliday(
    $id: ID
    $name: String
    $start_date: String!
    $end_date: String!
    $all_offices: Int!
    $office_ids: [ID]!
    $att_profile_ids: [ID]
    $all_att_profiles: Int!
    $suggested_holiday_id: ID
  ) {
    apply_holiday(
      input: {
        id: $id
        name: $name
        start_date: $start_date
        end_date: $end_date
        all_offices: $all_offices
        office_ids: $office_ids
        att_profile_ids: $att_profile_ids
        all_att_profiles: $all_att_profiles
        suggested_holiday_id: $suggested_holiday_id
      }
    ) {
      id
    }
  }
`;

export const updateOfficeMutation = gql`
  mutation updateOffice($input: OfficeInput) {
    upsert_office(input: $input) {
      id
    }
  }
`;

export const deletePositionMutation = gql`
  mutation deletePosition($id: ID!) {
    delete_position(id: $id) {
      status
      message
    }
  }
`;

export const deleteHolidayMutation = gql`
  mutation deleteHoliday($id: ID!) {
    delete_holiday(id: $id) {
      status
      message
    }
  }
`;

export const deleteOfficeMutation = gql`
  mutation deleteOffice($id: ID!) {
    delete_office(id: $id) {
      status
      message
    }
  }
`;

export const deleteSuspensionMutation = gql`
  mutation deleteSuspension($id: ID!, $password: String!) {
    delete_suspension(id: $id, password: $password) {
      status
      message
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser(
    $id: ID!
    $password: String!
    $replace_by: ID
    $new_manager_for_replaced_by: ID
  ) {
    delete_user(
      id: $id
      password: $password
      replace_by: $replace_by
      new_manager_for_replaced_by: $new_manager_for_replaced_by
    ) {
      status
      message
    }
  }
`;

export const checkPasswordMutation = gql`
  mutation userCheckPassword($password: String!) {
    check_password(password: $password) {
      status
      message
    }
  }
`;

export const deleteAttProfileMutation = gql`
  mutation deleteAttProfile($id: ID!) {
    delete_attendance_profile(id: $id) {
      status
      message
    }
  }
`;

export const deleteWorkTimeMutation = gql`
  mutation deleteWorkTime($id: ID!) {
    delete_work_timing(id: $id) {
      status
      message
    }
  }
`;

export const cloneAttProfileMutation = gql`
  mutation cloneAttProfile($id: ID!) {
    clone_attendance_profile(id: $id) {
      id
    }
  }
`;

export const cloneWorkTimeMutation = gql`
  mutation cloneWorkTime($id: ID!) {
    clone_work_timing(id: $id) {
      id
    }
  }
`;

export const activateUserMutation = gql`
  mutation activateUser(
    $user_id: ID
    $activation_date_from: String
	$activation_date_to: String
    $user_salary_config_input: SalaryConfiguration_Input
    $employee_attendance: String
    $newBalanceData: NewBalanceDataInput!
    $attendanceTypeConfig: UpdateOrCreateAttendanceTypeConfigurationInput
    $scheduleBaseDataForActivation: ScheduleBaseDataForActivation
    $force_save: Boolean
    $force_email: Boolean
  ) {
    activate_user(
      input: {
        user_id: $user_id
		activation_date_from: $activation_date_from
		activation_date_to: $activation_date_to
        salaryConfiguration: $user_salary_config_input
        employee_attendance: $employee_attendance
        newBalanceData: $newBalanceData
        attendanceTypeConfig: $attendanceTypeConfig
        scheduleBaseDataForActivation: $scheduleBaseDataForActivation
        force_save: $force_save
        force_email: $force_email
      }
    ) {
      status
      message
    }
  }
`;

export const editHistoryModalMutation = gql`
  mutation editAttendance(
    $id: ID!
    $home: Boolean
    $user_status: String!
    $sign_in_time: String
    $sign_out_time: String
    $day_type: String!
    $day_sub_type: String
    $work_timing_id: ID
    $custom_leave_payment_type: String
  ) {
    edit_attendance: editAttendance(
      input: {
        signInOutId: $id
        fromHome: $home
        status: $user_status
        signInTime: $sign_in_time
        signOutTime: $sign_out_time
        dayType: $day_type
        daySubType: $day_sub_type
        workTimingId: $work_timing_id
        customLeavePaymentType: $custom_leave_payment_type
      }
    ) {
      id
    }
  }
`;

export const upsertAttendanceProfileMutation = gql`
  mutation upsertAttendanceProfile($input: AttendanceProfileInput) {
    upsert_attendance_profile(input: $input) {
      id
    }
  }
`;

export const upsertLeavesBreaksSettingMutation = gql`
  mutation upsertLeavesBreaksSetting(
    $input: PermissionLeavesBreakSettingInput
  ) {
    update_permission_leaves_break_setting(input: $input) {
      id
      name
    }
  }
`;

export const cloneLeavesBreaksSettingMutation = gql`
  mutation cloneLeavesBreaksSetting($id: ID) {
    clone_permission_leaves_break_setting(id: $id) {
      name
    }
  }
`;

export const deleteLeavesBreaksSettingMutation = gql`
  mutation deleteLeavesBreaksSetting($id: ID) {
    delete_permission_leaves_break_setting(id: $id) {
      id
    }
  }
`;

export const initBalanceModalMutation = gql`
  mutation initBalance($input: AdjustBalanceInput) {
    adjust_balance(input: $input) {
      ... on Balance {
        id
        employee {
          user {
            id
            name
          }
        }
      }

      ... on GeneralException {
        message
        __typename
      }

      ... on EmployeeNotActiveException {
        __typename
        employee {
          user {
            id
            name
          }
        }
        message
      }
    }
  }
`;

export const deleteBalanceModalMutation = gql`
  mutation deleteBalance($id: ID!) {
    delete_balance(id: $id) {
      ... on StatusResponse {
        status
        message
      }
    }
  }
`;

export const forgotPasswordMutation = gql`
  mutation forgot_Password($email: String, $phone: String, $dialCode: String) {
    forgotPassword(
      input: { email: $email, phone: $phone, phone_code: $dialCode }
    ) {
      message
      status
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      message
    }
  }
`;

export const upsertBonusMutation = gql`
  mutation upsertBonus($input: BonusInput) {
    upsert_bonus(input: $input) {
      status
      message
    }
  }
`;

export const deleteBonusMutation = gql`
  mutation deleteBonus($id: ID!) {
    delete_bonus(id: $id) {
      status
      message
    }
  }
`;

export const upsertDeductionMutation = gql`
  mutation upsertDeduction($input: DeductionInput) {
    upsert_deduction(input: $input) {
      status
      message
    }
  }
`;

export const deleteDeductionMutation = gql`
  mutation deleteDeduction($id: ID!) {
    delete_deduction(id: $id) {
      status
      message
    }
  }
`;

export const upsertEmployeePrivilegesMutation = gql`
  mutation upsertEmployeePrivileges(
    $main: [ID]
    $all: [ID]
    $managed: [ID]
    $user_id: ID
    $presetAction: PresetActionInput
  ) {
    upsert_user_privileges(
      input: {
        selected_privileges: $main
        selected_privileges_with_all_employee_scope: $all
        selected_privileges_with_managed_employee_scope: $managed
        user_id: $user_id
        presetAction: $presetAction
      }
    ) {
      id
    }
  }
`;

export const upsertClaimsRequestMutation = gql`
  mutation upsertClaimRequest($input: ClaimInput!) {
    upsert_claim(input: $input) {
      ... on Claim {
        id
        __typename
      }
      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const upsertSickLeavesRequestMutation = gql`
  mutation upsertSickLeavesRequest($input: SickLeaveRequestInput!) {
    sick_request: sickLeaveRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on SickLeave {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const upsertCustomLeavesRequestMutation = gql`
  mutation upsertCustomLeavesRequest($input: CustomRequestInput) {
    custom_request: customRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on CustomRequest {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const deductionsMutation = gql`
  mutation editdeductions(
    $monthlyId: ID!
    $deductible_id: Int!
    $amount: Float!
    $revert: Boolean!
  ) {
    edit_payroll_view_record(
      input: {
        payroll_id: $monthlyId
        deductible_id: $deductible_id
        amount: $amount
        revert: $revert
      }
    ) {
      status
      message
    }
  }
`;

export const upsertEmployeeClaimsRequestMutation = gql`
  mutation upsertEmployeeClaimRequest($input: ClaimInput!) {
    upsert_claim(input: $input) {
      id
    }
  }
`;

export const createNewAllowanceMutation = gql`
  mutation createAllowance($value: String!) {
    newAllowance: upsertAllowance(input: { name: $value }) {
      value: id
      label: name
    }
  }
`;

export const claimRequestActionMutation = gql`
  mutation mutateClaimRqeuestAction($input: ClaimActionsInput!) {
    claim_actions(input: $input) {
      id
    }
  }
`;

export const createNewDeductibleMutation = gql`
  mutation createDeductible($value: String!) {
    newDeductible: upsertDeductible(input: { name: $value }) {
      value: id
      label: name
    }
  }
`;

export const createNewEmployeeSalaryMutation = gql`
  mutation createNewEmployeeSalary(
    $user_salary_config_input: SalaryConfiguration_Input
    $user_input: UserInput!
    $user_payment: UserPaymentInput
  ) {
    newEmployee: store_user_with_user_salary_config(
      input: {
        salaryConfiguration: $user_salary_config_input
        user_input: $user_input
        user_payment: $user_payment
      }
    ) {
      id
    }
  }
`;

export const updatePaymentMutation = gql`
  mutation updatePayment($ids: [ID!], $status_id: Int!, $confirm: Int) {
    update_payroll(
      input: { payroll_ids: $ids, status_id: $status_id, continue: $confirm }
    ) {
      status
      message
    }
  }
`;

export const cancelClaimRqeuestMutation = gql`
  mutation cancelClaimRqeuest($id: ID) {
    cancel_claim(id: $id) {
      status
      message
    }
  }
`;

export const upsertLocationMutation = gql`
  mutation upsertLocation(
    $id: ID
    $name: String
    $lng: String
    $lat: String
    $address: String
    $max_distance: String
    $is_office: Int # $office_id: ID
  ) {
    upsert_location(
      input: {
        id: $id
        name: $name
        lng: $lng
        lat: $lat
        address: $address
        max_distance: $max_distance
        is_office: $is_office
        #   office_id: $office_id
      }
    ) {
      id
      name
      lng
      lat
      max_distance
      address
    }
  }
`;

export const deleteLocationMutation = gql`
  mutation deleteLocation($id: ID!) {
    delete_location(id: $id) {
      status
      message
    }
  }
`;

export const initOfficeMutation = gql`
  mutation {
    init_office {
      id
      canEditMonthStartDay
      canEditStartOfMonthOrWeek: canEditMonthStartDay
    }
  }
`;

export const exportPayrollMutation = gql`
  mutation exportPayrollReport($input: exportPayrollReportInput) {
    exportPayroll: export_payroll_report(input: $input) {
      file
      status
      message
    }
  }
`;

// Export Payslip
export const exportEmployeePayslip = gql`
  mutation ExportEmployeePayslip($input: exportPayslipReportInput) {
    export_payslip_report(input: [$input]) {
      file
      status
      message
    }
  }
`;

// localization
export const upsertUserLocaleMutation = gql`
  mutation upsertUserLocale($user_id: ID, $locale: String) {
    upsert_locale(user_id: $user_id, locale: $locale) {
      id
      name
      lng: locale
      prviliege_ids
      prviliege_ids_with_all_employee_scope
      prviliege_ids_with_managed_employee_scope
    }
  }
`;

// delete companies mutation
export const deleteCompaniesMutation = gql`
  mutation deleteCompanies($id: ID!) {
    delete_company(id: $id) {
      id
    }
  }
`;

// acivate companies mutation
export const activiateCompaniesMutation = gql`
  mutation activateCompanies($input: CompanyStatusInput) {
    activate_company(input: $input) {
      id
    }
  }
`;

// suspend companies mutation
export const suspendCompaniesMutation = gql`
  mutation suspendCompanies($input: CompanyStatusInput) {
    suspend_company(input: $input) {
      id
    }
  }
`;

// upsert company mutation
export const upsertCompanyMutation = gql`
  mutation upsertCompany($input: UpsertCompany) {
    upsert_company(input: $input) {
      id
    }
  }
`;

// update company account managers mutation
export const updateCompanyAccountManagersMutation = gql`
  mutation updateCompanyAccountManagers($input: CompanySaasInput) {
    edit_company_saas(input: $input) {
      id
    }
  }
`;

// update company bussiness partners mutation
export const updateBussinessPartnersCompanyMutation = gql`
  mutation updateCompanyBussPartners($input: [UpsertBusinessPartnerInput]!) {
    upsert_partners(input: $input) {
      user {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`;

// edit company bussiness partner mutation
export const editBussinessPartnerCompanyMutation = gql`
  mutation editCompanyBussPartner($input: UpsertBusinessPartnerInput) {
    update_partner(input: $input) {
      user {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`;

// delete company bussiness partners mutation
export const deleteBussinessPartnersCompanyMutation = gql`
  mutation deleteCompanyBussPartners($input: DeleteBusinessPartnerInput) {
    delete_partner(input: $input) {
      id
    }
  }
`;

// upsert company subscription mutation
export const upsertCompanySubscriptionMutation = gql`
  mutation upsertCompanySubscription($input: SubscriptionInput) {
    upsert_subscription(input: $input) {
      id
    }
  }
`;

// to delete Company subscription
export const deleteCompanyTrialSubscriptionMutation = gql`
  mutation deleteCompanyTrialSubscription($id: ID) {
    delete_trial_subscription: delete_subscription(id: $id) {
      status
      message
    }
  }
`;

// Edit Att Request
export const editAttRequestMutation = gql`
  mutation editAttRequest(
    $id: ID
    $emp_id: ID
    $home: Int
    $sign_in_time: String
    $sign_out_time: String
    $date: String
  ) {
    edit_attendance_request(
      input: {
        id: $id
        emp_id: $emp_id
        home: $home
        sign_in_time: $sign_in_time
        sign_out_time: $sign_out_time
        date: $date
      }
    ) {
      ...reqUnionResponse
    }
  }
  ${requestResponseFragment}
`;

export const verifyUserPhoneMutation = gql`
  mutation verifyUserPhone($phone_token: String) {
    verify_phone_number(phone_token: $phone_token) {
      status
      message
    }
  }
`;

export const verifyUserEmailMutation = gql`
  mutation verifyEmailAddress($email_token: String) {
    verify_email(email_token: $email_token) {
      status
      message
    }
  }
`;

export const deleteAdminUserMutation = gql`
  mutation deleteAdminUser(
    $assign_primary_to: ID = ""
    $assign_secondary_to: ID = ""
    $saas_id: ID
  ) {
    delete_saas_user(
      input: {
        assign_primary_to: $assign_primary_to
        assign_secondary_to: $assign_secondary_to
        saas_id: $saas_id
      }
    ) {
      status
      message
    }
  }
`;

export const SendUserPhoneVerificationMutation = gql`
  mutation SendUserPhoneVerification(
    $user_id: ID
    $phone: String
    $force_send: Boolean
    $send_via: String
  ) {
    send_verification_message: send_verification_message(
      input: {
        user_id: $user_id
        phone: $phone
        force_send: $force_send
        send_via: $send_via
      }
    ) {
      status
      message
    }
  }
`;

export const AuthSendUserPhoneVerificationMutation = gql`
  mutation AuthSendUserPhoneVerificationMutation(
    $user_id: ID
    $phone: String
    $force_send: Boolean
    $send_via: String
  ) {
    send_verification_message: auth_send_verification_message(
      input: {
        user_id: $user_id
        phone: $phone
        force_send: $force_send
        send_via: $send_via
      }
    ) {
      status
      message
    }
  }
`;

export const sendUserEmailVerificationMutation = gql`
  mutation sendUserEmailVerification(
    $user_id: ID
    $email: String
    $force_send: Boolean
  ) {
    send_verification_email: send_verification_email(
      user_id: $user_id
      force_send: $force_send
      email: $email
    ) {
      status
      message
    }
  }
`;

export const AuthSendUserEmailVerificationMutation = gql`
  mutation AuthSendUserEmailVerificationMutation(
    $user_id: ID
    $email: String
    $force_send: Boolean
  ) {
    send_verification_email: auth_send_verification_email(
      user_id: $user_id
      force_send: $force_send
      email: $email
    ) {
      status
      message
    }
  }
`;

export const upsertAdminUserMutation = gql`
  mutation upsertAdminUser($user_id: ID!, $role_id: ID!, $company_ids: [ID]) {
    updateOrCreateSaasUser(
      input: { user_id: $user_id, role_id: $role_id, company_ids: $company_ids }
    ) {
      id
      user_id
      role_id
      user {
        id
        name
        email
        phone
        phone_country_code
      }
      companies {
        id
        name
      }
    }
  }
`;

export const upsertAdminUserPrivilegesMutation = gql`
  mutation upsertAdminUserPrivileges(
    $main: [ID]
    $all: [ID]
    $managed: [ID]
    $user_id: ID
    $presetAction: PresetActionInput
  ) {
    upsert_user_privileges(
      input: {
        selected_privileges: $main
        selected_privileges_with_all_employee_scope: $all
        selected_privileges_with_managed_employee_scope: $managed
        user_id: $user_id
        presetAction: $presetAction,
        admin_privileges: true
      }
    ) {
      id
    }
  }
`;

export const fetchActivationAttendanceMutatuon = gql`
  mutation fetchActivationAttendance(
    $user_id: Int!
    $activation_date: String!
    $activation_date_to: String
    $attendanceProfileId: Int
  ) {
    init_activate(
      user_id: $user_id
      activation_date: $activation_date
      activation_date_to: $activation_date_to
      attendanceProfileId: $attendanceProfileId
    ) {
      status
      message
      require_create_new_salary_configuration
      employee_attendance
    }
  }
`;

// work timing
export const upsertWorkTimingsMutation = gql`
  mutation upsertWorkTimings($workTimingForm: WorkTimingInput) {
    upsert_work_timing(input: $workTimingForm) {
      id
      name
      work_timing_profile_type
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      weight
    }
  }
`;

export const updateEmailNotifcationsSettingsMutation = gql`
  mutation updateEmailNotifcationsSettings($input: UserNotificationInput) {
    upsert_notification_settings(input: $input) {
      status
      message
    }
  }
`;

// upsert petty cash balance
export const upsertPettyCashBalanceMutation = gql`
  mutation upsertPettyCashBalance($input: PettyCashInput) {
    add_or_update_petty_cash(input: $input) {
      id
      emp_id
    }
  }
`;

// upsert company settings
export const upsertCompanySettingsMutation = gql`
  mutation upsertCompanySettings($input: CategoryInput) {
    add_category(input: $input) {
      id
    }
  }
`;

// delete company settings mutation
export const deleteCompanySettingsMutation = gql`
  mutation deleteCompanySettings($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

// export company expenses
export const exportCompanyExpensesMutation = gql`
  mutation exportCompanyExpenses($input: PettyCashFilterInput) {
    export_petty_cash(input: $input) {
      file
      status
      message
    }
  }
`;

export const resendCredentailsMutation = gql`
  mutation resendCredentails($user_id: ID!) {
    resend_verification(user_id: $user_id) {
      status
      message
    }
  }
`;

// delete company expense mutation
export const deleteCompanyExpenseMutation = gql`
  mutation deleteCompanyExpense($id: ID!) {
    delete_expense(id: $id) {
      id
    }
  }
`;

// delete petty cash attachment file mutation
export const deletePettyCashAttachmentFileMutation = gql`
  mutation deletePettyCashAttachmentFile($input: DeletePettyCashFileInput) {
    delete_petty_cash_file(input: $input) {
      id
      employee_name
      emp_id
      type
      date
      details
      amount
      currency {
        id
        name
        symbol
      }
      category {
        id
        name
      }
      sub_category {
        id
        name
      }
      office {
        id
        name
      }
      created_at
      current_balance
      files {
        id
        path
      }
    }
  }
`;

export const upsertEmployeeDocumentMutation = gql`
  mutation upsertEmployeeDocument($documnet: UpsertDocumentInput) {
    upsert_user_document(input: $documnet) {
      id
      document {
        id
      }
      user {
        id
        company {
          id
        }
      }
    }
  }
`;

export const deleteUserDocumentAttachmentMutation = gql`
  mutation deleteUserDocumentAttachment($user_document_id: ID!, $file_id: ID!) {
    delete_user_document_file(
      input: { user_document_id: $user_document_id, file_id: $file_id }
    ) {
      id
    }
  }
`;

export const upsertRequestRulesMutation = gql`
  mutation upsertRequestRules($input: RulesInput) {
    upsert_rule(input: $input) {
      id
    }
  }
`;

export const deleteRequestRulesMutation = gql`
  mutation deleteRequestRules($id: ID!) {
    delete_rule(id: $id) {
      status
      message
    }
  }
`;

export const addDepartments = gql`
  mutation ($DepartmentsInput: [DepartmentInput]) {
    upsert_departments(input: $DepartmentsInput) {
      id
      created_at
    }
  }
`;

export const addPositions = gql`
  mutation AddPositions($PositionsInput: [PositionInput]) {
    upsert_positions(input: $PositionsInput) {
      id
      created_at
    }
  }
`;

// upsert request comment mutation
export const requestCommentMutation = gql`
  mutation upsertRequestComments($input: RequestCommentInput) {
    upsert_comment(input: $input) {
      id
      request_id
      request {
        employee {
          user {
            id
          }
        }
      }
    }
  }
`;

// to change applied flag for penalties
export const applyPenaltiesMutation = gql`
  mutation applyPenalties($id: ID) {
    switch_apply_flag(id: $id) {
      ...userPenalties
    }
  }
  ${userPenaltiesFragment}
`;

// to delete recruitment process
export const deleteRecruitmentProcessMutation = gql`
  mutation deleteRecruitmentProcess($id: ID) {
    delete_process(id: $id) {
      status
      message
    }
  }
`;

// to clone recruitment process
export const cloneRecruitmentProcessMutation = gql`
  mutation cloneRecruitmentProcess($id: ID) {
    clone_process(id: $id) {
      status
      message
    }
  }
`;

// upsert admin holidays
export const upsertAdminHolidayMutation = gql`
  mutation upsertAdminHoliday($input: AdminHolidayInput) {
    upsert_admin_holiday(input: $input) {
      id
    }
  }
`;

// delete admin holiday mutation
export const deleteAdminHolidayMutation = gql`
  mutation deleteAdminHolidays($holiday_id: ID) {
    delete_admin_holiday(holiday_id: $holiday_id) {
      status
      message
    }
  }
`;

// to update recruitment process status
export const updateRecruitmentProcessStatusMutation = gql`
  mutation updateRecruitmentProcessStatus($id: ID) {
    status: change_process_status(id: $id) {
      id
    }
  }
`;

// to auto suggest holidays mutation
export const autoSuggestHolidaysMutation = gql`
  mutation autoSuggestHolidays($id: ID) {
    change_auto_suggestion(id: $id) {
      id
    }
  }
`;

// to delete intake form
export const deleteIntakeFormMutation = gql`
  mutation deleteIntakeForm($id: ID) {
    delete_intake_form(id: $id) {
      status
      message
    }
  }
`;

// to clone intake form
export const cloneIntakeFormMutation = gql`
  mutation cloneIntakeForm($id: ID) {
    clone_intake_form(id: $id) {
      status
      message
    }
  }
`;

// to accept suggested holiday
export const acceptSuggsetedHolidayMutation = gql`
  mutation acceptSuggsetedHoliday($suggested_holiday_id: ID) {
    accept_holiday_suggestion(suggested_holiday_id: $suggested_holiday_id) {
      id
    }
  }
`;

// delete suggeseted holiday mutation
export const deleteSuggestedHolidayMutation = gql`
  mutation deleteSuggesetedHolidays($holiday_id: ID) {
    delete_suggested_holiday(holiday_id: $holiday_id) {
      status
      message
    }
  }
`;

// to update intake form status
export const updateIntakeFormStatusMutation = gql`
  mutation updateIntakeFormStatus($id: ID) {
    status: change_intake_form_status(id: $id) {
      id
    }
  }
`;

// to delete job post
export const deleteJobPostMutation = gql`
  mutation deleteJobPost($id: ID) {
    delete_job_post(id: $id) {
      status
      message
    }
  }
`;

// genarte demo mutation
export const generateDemoMutation = gql`
  mutation generateDemo {
    generate_demo {
      status
      message
    }
  }
`;

// to update job post status
export const updateJobPostStatusMutation = gql`
  mutation updateJobPostStatus($id: ID) {
    status: activate_job_post(id: $id) {
      id
    }
  }
`;

// to delete salary config
export const deleteSalaryConfigMutation = gql`
  mutation deleteSalaryConfig($salary_config_id: ID) {
    delete_user_salary_config(salary_config_id: $salary_config_id) {
      status
      message
    }
  }
`;

// upsert Recruitment Process mutation
export const upsertRecruitmentProcessMutation = gql`
  mutation upsertRecruitmentProcess($input: ProcessInput) {
    upsert_process(input: $input) {
      id
    }
  }
`;

// to update application stage status
export const updateApplicationStageStatusMutation = gql`
  mutation updateApplicationStageStatus(
    $stage_id: ID
    $applicant_job_post_id: ID
  ) {
    change_application_stage(
      stage_id: $stage_id
      applicant_job_post_id: $applicant_job_post_id
    ) {
      status
      message
    }
  }
`;

// to update locations ids in attendnace logs mutation
export const updateLocationsIdsMutation = gql`
  mutation updateLocationsIds(
    $sign_in_id: ID
    $new_available_locations_ids: [ID]
  ) {
    change_available_locations_ids(
      sign_in_id: $sign_in_id
      new_available_locations_ids: $new_available_locations_ids
    ) {
      available_locations {
        id
        name
      }
    }
  }
`;

export const upsertRecruitmentIntakeMutation = gql`
  mutation upsertRecruitmentIntake($input: IntakeFormInput) {
    upsert_intake_form(input: $input) {
      id
    }
  }
`;

// to delete applicant
export const deleteApplicantMutation = gql`
  mutation deleteApplicant($id: ID) {
    delete_applicant(id: $id) {
      status
      message
    }
  }
`;

// upsert job post
export const upsertJobPostMutation = gql`
  mutation upsertJobPost($input: JobPostInput) {
    upsert_job_post(input: $input) {
      id
      link
    }
  }
`;

// update applicant email
export const updateApplicantEmailMutation = gql`
  mutation updateApplicantEmail($input: changeEmailInput) {
    edit_email(input: $input) {
      status
      message
    }
  }
`;

// update applicant phone
export const updateApplicantPhoneMutation = gql`
  mutation updateApplicantPhone($input: PhoneNumberInput) {
    edit_phone_number(input: $input) {
      status
      message
    }
  }
`;

// delete applicant phone
export const deleteApplicantPhoneNumberMutation = gql`
  mutation deleteApplicantPhoneNumber(
    $applicant_id: ID
    $phone_number: String
  ) {
    delete_phone_number(
      applicant_id: $applicant_id
      phone_number: $phone_number
    ) {
      status
      message
    }
  }
`;

// upsert application
export const upsertApplicationMutation = gql`
  mutation upsertApplication($input: SettingIntakeFormAnswersInput) {
    apply_on_job_Post: add_new_applicant_on_job_Post(input: $input) {
      status
      message
    }
  }
`;

// external upsert application
export const externalUpsertApplicationMutation = gql`
  mutation upsertApplication($input: SettingIntakeFormAnswersInput) {
    apply_on_job_Post(input: $input) {
      status
      message
    }
  }
`;

export const fillStageFormMutation = gql`
  mutation fillStageForm($input: SettingStageFormAnswersInput) {
    fill_stage_data(input: $input) {
      status
      message
    }
  }
`;

// to update check in mutation
export const updateCheckInMutation = gql`
  mutation updateCheckIn($input: UpdateCheckInOutInput) {
    update_check_in_out(input: $input) {
      ...checkInUnionResponse
    }
  }
  ${checkInOutsResponseFragment}
`;

export const upsertRoleToCompanyMutation = gql`
  mutation upsertRoleToCompany($input: CompanyRoleInput) {
    upsert_role_to_company(input: $input) {
      id
      name
    }
  }
`;

export const deleteCompanyRoleMutation = gql`
  mutation deleteCompanyRole($role_id: ID!) {
    deleteRole(id: $role_id) {
      status
      message
    }
  }
`;

// upsert loans
export const upsertLoanMutation = gql`
  mutation upsertLoan($input: LoanInput) {
    upsert_loan(input: $input) {
      ... on Loan {
        __typename
        id
      }
      ... on StatusResponse {
        __typename
        status
        message
      }
    }
  }
`;

// delete loan mutation
export const deleteLoanMutation = gql`
  mutation deleteLoan($loan_id: ID) {
    delete_loan(loan_id: $loan_id) {
      status
      message
    }
  }
`;

// pay loan mutation
export const payLoanMutation = gql`
  mutation payLoan($installment_id: ID) {
    pay_installment(installment_id: $installment_id) {
      ...payInstallmentUnionResponse
    }
  }
  ${payInstallmentUnionResponseFragment}
`;

// delete installments mutation
export const deleteInstallmentMutation = gql`
  mutation deleteInstallment($installment_id: ID) {
    delete_installment(installment_id: $installment_id) {
      ...deleteInstallmentUnionResponse
    }
  }
  ${deleteInstallmentUnionResponseFragment}
`;

// revert payment loan mutation
export const revertPaymentLoanMutation = gql`
  mutation payLoan($installment_id: ID) {
    reverse_installment(installment_id: $installment_id) {
      ...revertPaymentInstallmentUnionResponse
    }
  }
  ${revertPaymentInstallmentUnionResponseFragment}
`;

//change all notifications to read
export const checkAllNotificationToReadMutation = gql`
  mutation updatingNotificationsStatus {
    updateNotificationMessagesStatus {
      status
      message
    }
  }
`;

// Shifts
export const upsertWorkGroupMutation = gql`
  mutation upsertWorkGroup($input: UpdateOrCreateWorkGroupInput) {
    updateOrCreateWorkGroup(input: $input) {
      ... on WorkGroup {
        __typename
        id
        name
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const deleteWorkGroupMutation = gql`
  mutation deleteWorkGroup($id: ID!) {
    deleteWorkGroup(id: $id) {
      id
    }
  }
`;

export const upsertWorkTeamMutation = gql`
  mutation upsertWorkTeamMutation($input: UpdateOrCreateWorkTeamInput) {
    updateOrCreateWorkTeam(input: $input) {
      ... on WorkTeam {
        __typename
        id
        name
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const addMembersToWorkTeamMutation = gql`
  mutation addMembersToWorkTeam($input: AttachEmployeesToWorkTeamInput) {
    attachEmployeesToWorkTeam(input: $input) {
      ... on WorkTeam {
        __typename
        id
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const moveWorkTeamMembersMutation = gql`
  mutation moveMembersToWorkTeam($input: MoveEmployeesToWorkTeamInput) {
    moveEmployeesToWorkTeam(input: $input) {
      ... on WorkTeam {
        __typename
        id
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const deleteWorkTeamMembersMutation = gql`
  mutation removeWorkTeamMembers($input: DetachEmployeesFromWorkTeamInput) {
    detachEmployeesFromWorkTeam(input: $input) {
      ... on WorkTeam {
        __typename
        id
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const deleteWorkTeamMutation = gql`
  mutation deleteWorkTeam($id: ID!) {
    deleteWorkTeam(id: $id) {
      id
    }
  }
`;

export const upsertScheduleMutation = gql`
  mutation upsertWorkSchedule($input: ScheduleInput) {
    upsert_schedule(input: $input) {
      id
      work_group_id
      start
      end
      week_start_day
      schedule_days
      is_draft
      break_into
      draft_date
      draft_employees
      workGroup {
        id
        name
        locations: spaces {
          id
          name
        }
      }
      employees {
        id
        name
        user_type
      }
      teams {
        id
        name
      }
    }
  }
`;

export const deleteShiftMutation = gql`
  mutation deleteShift($schedule_id: ID, $container_ids: [ID]) {
    delete_shift(
      input: { schedule_id: $schedule_id, container_ids: $container_ids }
    ) {
      id
      start
      end
      day_off
      schedule_detail_id
      work_timing_id
      location_id: space_id
      user
      team
      resource
      calendar_date
      title
      user_count
      first_two_users
      work_timing_name
    }
  }
`;

export const cloneShiftMutation = gql`
  mutation cloneShift(
    $paste_date: String
    $paste_resource: String
    $schedule_id: ID
    $shifts: [ShiftCollectionIds]
  ) {
    clone_shift(
      input: {
        paste_date: $paste_date
        paste_resource: $paste_resource
        schedule_id: $schedule_id
        shifts: $shifts
      }
    ) {
      id
      start
    }
  }
`;

export const upsertShiftByLocationMutation = gql`
  mutation upsertShiftByLocation($schedule_id: ID, $shift: Shift) {
    upsert_shift_by_location(
      input: { schedule_id: $schedule_id, shift: $shift }
    ) {
      id
    }
  }
`;

export const upsertShiftByEmployeeMutation = gql`
  mutation upsertShiftByEmployee(
    $schedule_id: ID
    $shift_employee: ShiftEmployee
  ) {
    upsert_shift_by_employee(
      input: { schedule_id: $schedule_id, shift_employee: $shift_employee }
    ) {
      start
      end
      day_off
      schedule_detail_id
      title
      work_timing_id
      id
      user
      team
      resource
      location_id: space_id
    }
  }
`;

export const updateShiftMutation = gql`
  mutation updateShift(
    $schedule_id: ID
    $shift_ids: [ID]
    $paste_date: String
    $paste_resource: String
    $container_id: ID
  ) {
    relocate_shift(
      input: {
        schedule_id: $schedule_id
        shift_ids: $shift_ids
        paste_date: $paste_date
        paste_resource: $paste_resource
        container_id: $container_id
      }
    ) {
      status
      message
    }
  }
`;

export const upsertAttendanceTypeMutation = gql`
  mutation upsertAttendanceTypeConfig(
    $input: UpdateOrCreateAttendanceTypeConfigurationInput
  ) {
    updateOrCreateAttendanceTypeConfiguration(input: $input) {
      ... on AttendanceTypeConfiguration {
        __typename
        id
        type
      }

      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const publishScheduleMutation = gql`
  mutation publishWorkSchedule($schedule_id: ID) {
    publish_schedule(schedule_id: $schedule_id) {
      id
      work_group_id
      start
      end
      week_start_day
      schedule_days
      is_draft
      break_into
      draft_date
      draft_employees
      workGroup {
        id
        name
      }
      employees {
        id
        name
      }
      teams {
        id
        name
      }
      blockedDates {
        resource
        start
        end
        allDay
      }
      repeat
    }
  }
`;

export const ignoreExceptionMutation = gql`
  mutation ignoreExceptionMutation($input: IgnoreExceptionInput!) {
    ignoreException(input: $input) {
      __typename
      ... on Request {
        id
        type
      }
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const revertPenaltyMutation = gql`
  mutation revertPenalty($id: ID!) {
    revert_penalty(id: $id) {
      ...userPenalties
    }
  }
  ${userPenaltiesFragment}
`;
export const editPenaltyMutation = gql`
  mutation editPenalty($input: EditPenaltyInput) {
    edit_penalty(input: $input) {
      ...userPenalties
    }
  }
  ${userPenaltiesFragment}
`;
export const unpaidHalfDayMutation = gql`
  mutation unpaidHalfDay($input: UnpaidHalfDayInput) {
    unpaidHalfDay(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on UnpaidHalfDay {
        request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const upsertCheckInOutFormMutation = gql`
  mutation upsertCheckInOutForm($input: CheckInOutFormInput!) {
    upsert_checkInOut_form(input: $input) {
      ...checkInOut
    }
  }
  ${checkInOutFragment}
`;

export const deleteDraftMutation = gql`
  mutation deleteDraft($id: ID) {
    delete_draft(id: $id) {
      status
      message
    }
  }
`;

export const upsertTaxInfo = gql`
  mutation UpsertTaxInfo($input: TaxInfoInput) {
    upsert_tax_info(input: $input) {
      __typename
      id
      name
    }
  }
`;

export const upsertRequestApprovalRule = gql`
  mutation upsertRequestApprovalRule(
    $rule_id: ID
    $request_type_ids: [ID!]!
    $applicable: ApplicableTypeInput!
    $layers: [RequestMLRuleLayerInput!]!
  ) {
    save_request_multi_layer_rule(
      rule_id: $rule_id
      request_type_ids: $request_type_ids
      applicable: $applicable
      layers: $layers
    ) {
      id
      __typename
    }
  }
`;

export const deleteRequestApprovalRule = gql`
  mutation deleteRequestApprovalRule($id: ID!) {
    delete_request_multi_layer_rule(id: $id) {
      id
      __typename
    }
  }
`;

export const conflictModalMutation = gql`
  mutation conflictModal($id: ID) {
    ignoreProcess(process_id: $id) {
      status
      message
    }
  }
`;

export const SUBMIT_PAYROLLS = gql`
  mutation submitPayrolls($PaymentArgs: [SubmitArgs]) {
    payroll_v2_submit(args: $PaymentArgs)
  }
`;

export const EDIT_PAYROLL_DEDUCTIBLES = gql`
  mutation editPayrollDeductibles(
    $paidAt: String
    $userId: ID
    $payrollType: String
    $quantity: Int
    $deductibleType: String
    $isRevert: Int!
    $from: String
  ) {
    payroll_v2(
      paid_at: $paidAt
      user_id: $userId
      type: $payrollType
      quantity: $quantity
      fieldName: $deductibleType
      isRevert: $isRevert
      from: $from
    )
  }
`;

export const positionOrDepartmentMutation = gql`
  mutation positionOrDepartment(
    $isDepartment: Boolean!
    $employees_ids: [ID!]!
    $department_id: ID
    $position_id: ID
  ) {
    addDepartementToEmployees(
      input: { employees_ids: $employees_ids, department_id: $department_id }
    ) @include(if: $isDepartment) {
      status
      message
    }
    addPostionToEmployees(
      input: { position_id: $position_id, employees_ids: $employees_ids }
    ) @skip(if: $isDepartment) {
      status
      message
    }
  }
`;

export const addManagersMutation = gql`
  mutation addManagers(
    $isDirectManager: Boolean!
    $copied_managers_ids: [ID]
    $employees_ids: [ID]
    $replace_managers: Boolean
    $manager_id: ID
  ) {
    addCopiedManagersToEmployees(
      input: {
        employees_ids: $employees_ids
        copied_managers_ids: $copied_managers_ids
        replace_managers: $replace_managers
      }
    ) @skip(if: $isDirectManager) {
      status
      message
    }

    addDirectManagerToEmployees(
      input: { employees_ids: $employees_ids, manager_id: $manager_id }
    ) @include(if: $isDirectManager) {
      status
      message
    }
  }
`;

export const addWorkRemotelySettingsForEmployeesMutation = gql`
  mutation addWorkRemotelySettingsForEmployees(
    $input: WorkRemotelySettingsInput
  ) {
    addWorkRemotelySettingsForEmployees(input: $input) {
      status
      message
    }
  }
`;

export const changeEmployeesDayOffSettingsMutation = gql`
  mutation changeEmployeesDayOffSettings(
    $input: DayOffExceptionConfigMultipleInput
  ) {
    addDayOffExceptionConfigForEmployees(input: $input) {
      status
      message
    }
  }
`;

export const changeEmployeesHolidaySettingsMutation = gql`
  mutation changeEmployeesHolidaySettings(
    $input: HolidayExceptionConfigMultipleInput
  ) {
    addHolidayExceptionConfigForEmployees(input: $input) {
      status
      message
    }
  }
`;

export const addCheckInSettingsForEmployeesMutation = gql`
  mutation addCheckInSettingsForEmployees($input: BulkCheckInSettingInput) {
    addCheckInSettingsForEmployees(input: $input) {
      status
      message
    }
  }
`;

export const verifyMultipleEmployeesMutation = gql`
  mutation verifyMultipleEmployees($input: VerifyMultipleEmployeesInput) {
    verifyMultipleEmployees(input: $input) {
      status
      message
    }
  }
`;

export const bulkAddResetBalanceMutaion = gql`
  mutation bulkAddBalance(
    $addBalanceInput: AdjustBalanceForMultipleEmployeesInput
    $isAddBalance: Boolean!
    $resetBalanceInput: ResetBalanceForMultipleEmployeesInput
  ) {
    addBalanceForMultipleEmployees(input: $addBalanceInput)
      @include(if: $isAddBalance) {
      ... on StatusResponse {
        status
        message
      }
      ... on GeneralException {
        message
      }
      ... on EmployeeNotActiveException {
        employee {
          user {
            id
          }
        }
        message
      }
    }
    resetBalanceForMultipleEmployees(input: $resetBalanceInput)
      @skip(if: $isAddBalance) {
      ... on StatusResponse {
        status
        message
      }
      ... on GeneralException {
        message
      }
      ... on EmployeeNotActiveException {
        employee {
          user {
            id
          }
        }
        message
      }
    }
  }
`;

export const requestChangeShiftMutation = gql`
  mutation requestChangeShift($input: ChangeShiftRequestInput) {
    change_shift_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        employee {
          user {
            id
            name
          }
        }
        Payroll {
          id
          status {
            status
          }
        }
        message
      }
      ... on ChangeShift {
        request {
          id
        }
      }
    }
  }
`;

export const acceptChangeShiftRequestMutation = gql`
  mutation accept_change_shift_request($input: AcceptRequestChangeShiftInput) {
    accept_change_shift_request(input: $input) {
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        employee {
          user {
            id
            name
          }
        }
        Payroll {
          id
          status {
            status
          }
        }
        message
      }
      ... on Request {
        id
      }
    }
  }
`;

export const dayOffRequestMutation = gql`
  mutation dayOffRequest($input: DayOffRequestInput) {
    day_off_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        employee {
          user {
            id
            name
          }
        }
        Payroll {
          id
          status {
            status
          }
        }
        message
      }
      ... on DayOff {
        request {
          id
        }
      }
    }
  }
`;

export const acceptDayOffMutation = gql`
  mutation acceptDayOff($input: AcceptDayOffRequestInput) {
    accept_day_off_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on PaidPayrollException {
        employee {
          user {
            id
            name
          }
        }
        Payroll {
          id
          status {
            status
          }
        }
        message
      }
      ... on Request {
        id
        status
      }
    }
  }
`;

export const acceptLoanRequestMutation = gql`
  mutation acceptLoanRequest($input: AcceptLoanRequestInput) {
    accept_loan_request(input: $input) {
      __typename

      ... on LoanRequest {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const requestLoanMutation = gql`
  mutation requestLoan($input: LoanRequestInput) {
    upsert_loan_request(input: $input) {
      ... on GeneralException {
        message
      }

      ... on LoanRequest {
        id
      }
    }
  }
`;

export const cancleLoanRequestMutation = gql`
  mutation cancleLoan($id: ID!) {
    cancel_loan_request(id: $id) {
      status
      message
    }
  }
`;

export const rejectLoanRequestMutation = gql`
  mutation reject_loan($id: ID!) {
    reject_loan_request(id: $id) {
      ... on GeneralException {
        message
      }
      ... on LoanRequest {
        id
      }
    }
  }
`;

export const archiveWorkTimmingMutation = gql`
  mutation archiveWorkTimming($id: ID) {
    archive_work_timing(id: $id) {
      id
      name
    }
  }
`;

export const archiveAttendanceProfileMutation = gql`
  mutation archiveAttendanceProfile($id: ID!) {
    archive_attendance_profile(id: $id) {
      id
      name
    }
  }
`;

export const ADD_TEMPORARY_PASSWORD = gql`
  mutation addTemporaryPassword($userId: Int, $password: String) {
    addTemporaryPassword(user_id: $userId, password: $password) {
      status
      message
    }
  }
`;

export const ADD_WASSAGE_PASSWORD = gql`
  mutation updatePasswordFromWassage($input: NewPasswordWithCodeInput) {
    updateForgottenPasswordForPlatform(input: $input) {
      status
      message
    }
  }
`;

// upsert company activity mutation
export const upsertCompanyActivityMutation = gql`
  mutation upsert_company_activities($input: CompaniesActivitiesInput) {
    upsert_company_activities(input: $input) {
      id
    }
  }
`;

export const UPSERT_SALARY_CONFIG = gql`
  mutation upsertSalaryConfig($args: SalaryConfiguration_Input) {
    payroll_v2_change_configuration(args: $args) {
      user_id
    }
  }
`;

// delete company activity mutation
export const deleteCompanyActivityMutation = gql`
  mutation delete_companies_activities($id: ID!) {
    delete_companies_activities(id: $id) {
      status
      message
    }
  }
`;

// upsert comment company activity mutation
export const upsertCommentCompanyActivityMutation = gql`
  mutation upsert_comments($input: CommentsInput) {
    upsert_comments(input: $input) {
      id
      comment_id
      comment
      created_at
      user {
        id
        name
      }
    }
  }
`;

// upsert contact company muttation
export const upsertContactCompanyMutation = gql`
  mutation upsert_company_contacts($input: CompanyContactInput) {
    upsert_company_contacts(input: $input) {
      id
      name
      company {
        id
        name
        contacts {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_USER_SALARY_CONFIG = gql`
  mutation deleteUserSalaryConfig($userId: Int, $from: String) {
    payroll_v2_remove_configuration(user_id: $userId, from: $from)
  }
`;

export const DELETE_COMPANY_REMINDERS = gql`
  mutation delete_company_reminder($id: ID!) {
    delete_company_reminder(id: $id) {
      message
      status
    }
  }
`;

export const UPSERT_REMINDER = gql`
  mutation upsert_company_reminder($input: CompanyReminderInput) {
    upsert_company_reminder(input: $input) {
      id
      type
      company {
        name
      }
      contact {
        name
      }
      comments {
        id
      }
      status
      user {
        id
        name
      }
    }
  }
`;

export const DELETE_COMPANY_ACTIVITIES_REMENIDERS_ATTACHMENTS = gql`
  mutation delete_company_activities_reminders_att($id: ID!, $path: String) {
    delete_attachments(id: $id, path: $path) {
      status
      message
    }
  }
`;

// handle edit activation mutation
export const editActivationMutation = gql`
  mutation edit_activation($input: ActivationInput) {
    edit_activation(input: $input) {
      status
      message
    }
  }
`;

// handle open request mutation
export const openRequestMutation = gql`
  mutation createOpenRequest($input: CreateOpenRequestInput) {
    createOpenRequest(input: $input) {
      ... on GeneralException {
        message
      }

      ... on OpenRequest {
        request {
          id
          type
        }
      }
    }
  }
`;

// handle add grace minutes mutation
export const addGraceMinutesMutation = gql`
  mutation createBulkGraceMinute($input: CreateBulkGraceMinuteInput) {
    createBulkGraceMinute(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_COST_CENTER = gql`
  mutation createCostCenter(
    $name: String
    $allowanceConfiguration: [CostCenterConfigurationInput]
  ) {
    store_cost_center(
      name: $name
      allowanceConfiguration: $allowanceConfiguration
    ) {
      id
    }
  }
`;

export const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter(
    $id: Int
    $name: String
    $allowanceConfiguration: [CostCenterConfigurationInput]
  ) {
    change_cost_center(
      id: $id
      name: $name
      allowanceConfiguration: $allowanceConfiguration
    ) {
      id
    }
  }
`;

export const DELETE_COST_CENTER = gql`
  mutation deleteCostCenter($id: Int) {
    remove_cost_center(id: $id)
  }
`;

export const ADD_COST_CENTER_CONFIGURATION = gql`
  mutation addCostCenterConfiguration(
    $costCenterId: Int
    $from: String
    $originalFrom: String
    $allowances: [CostCenterAllowanceInput]
  ) {
    change_cost_center_configuration(
      id: $costCenterId
      from: $from
      originalFrom: $originalFrom
      allowances: $allowances
    )
  }
`;

export const TOGGLE_COST_CENTER_STATUS = gql`
  mutation toggleCostCenterStatus($id: Int) {
    change_cost_center_status(id: $id) {
      id
    }
  }
`;

export const DELETE_COST_CENTER_ALLOWANCE_CONFIG = gql`
  mutation deleteCostCenterAllowanceConfig($id: Int, $from: String) {
    remove_cost_center_configuration(id: $id, from: $from)
  }
`;
