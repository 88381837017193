import React from "react";

import Privileges from "../../../Constants/Privilages";

import {
  PlansList,
  PackagesList,
  PaymentsList,
  SubscriptionsList,
} from "../../../Containers/PaymentTrackingLists";
import {
  Payments,
  Inventory,
  EventRepeat,
  InsertDriveFile,
} from "@mui/icons-material";

const paymentTabs = [
  {
    label: "Packages",
    icon: <Inventory fontSize="small" />,
    value: "packages",
    privileges: [Privileges.VIEW_PACKAGES, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <PackagesList />,
  },
  {
    label: "Plans",
    icon: <InsertDriveFile fontSize="small" />,
    value: "plans",
    privileges: [Privileges.VIEW_PAYMENT_PLANS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <PlansList />,
  },
  {
    label: "Subscriptions",
    icon: <EventRepeat fontSize="small" />,
    value: "subscriptions",
    privileges: [
      Privileges.VIEW_PAYMENT_SUBSCRIPTIONS,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <SubscriptionsList />,
  },
  {
    label: "Payments",
    icon: <Payments fontSize="small" />,
    value: "payments",
    privileges: [Privileges.VIEW_PAYMENTS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <PaymentsList />,
  },
];

export default paymentTabs;
