import React, { useEffect } from "react";
import MainModal from "../MainModal";
import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FETCH_ADMIN_USERS } from "../../Graphql/query";
import { useTranslation } from "react-i18next";
import {
  deleteAdminUserMutation,
} from "../../Graphql/mutation";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import Select from "react-select";
import Loader from "../Loader";

const initState = {
  primary_id: null,
  secondary_id: null,
};

const ChangeAdminUsersModal = (props) => {
  const { t } = useTranslation();
  const [input, setInput] = useState(initState);

  const [fetchAdminUsers, { loading, data, error }] = useLazyQuery(
    FETCH_ADMIN_USERS,
    {
      onError(error) {
        if (error?.graphQLErrors?.[0]?.extensions?.reason) {
          showToast("error", error?.graphQLErrors?.[0]?.extensions?.reason);
          return;
        }
        // do something
      },
    }
  );

  // validate direct manager
  const [valid, setValid] = useState({});

  useEffect(() => {
    fetchAdminUsers();
    return () => {
      setInput(initState);
    };
  }, [props.modalData.isOpen]);

  const handleSave = () => {
    // if(!validate()) return
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm, Edit the employee manager",
      "",
      () => submit()
    );
  };

  const [deleteSaasUser, { loading: submitLoading, error: mutationError }] =
    useMutation(deleteAdminUserMutation, {
      onCompleted(res) {
        // do someting
        handleCancle();
        props?.refetch();
      },
      onError(error) {
        // do something
        console.log({ error });
        if (error?.graphQLErrors?.[0]?.extensions?.reason) {
          showToast("error", error?.graphQLErrors?.[0]?.extensions?.reason);
          return;
        }

        if (error?.graphQLErrors?.[0]?.extensions?.validation) {
          setValid(error?.graphQLErrors?.[0]?.extensions?.validation);
        }
      },
    });

  const submit = () => {
    setValid({});
    deleteSaasUser({
      variables: {
        saas_id: props?.modalData?.oldAdmin,
        assign_primary_to: input?.primary_id,
        assign_secondary_to: input?.secondary_id,
      },
    });
  };

  const handleCancle = () => {
    props.reset();
    setInput(initState);
  };

  const handleSelect = (val, e) => {
    setInput((prevState) => ({
      ...prevState,
      [e.name]: val?.id ?? null,
    }));
  };

  return (
    <MainModal
      isOpen={props.modalData.isOpen}
      size="lg"
      modalTitle={t("replace Admin")}
      btnSubmitLoading={loading || submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      {loading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div
        className={
          "p-2 " +
          (valid?.["input.assign_primary_to"] ? "invalid-container-style" : "")
        }
      >
        <label className={"select-def-label mb-2 validity-label-style"}>
          {t("primary replacer")}
        </label>
        <form action="">
          <Select
            name="primary_id"
            className={
              "select-def-input-containe b-select-style flex-grow-1 px-0"
            }
            classNamePrefix={"b-select-style"}
            value={data?.active_saas_users?.data?.find(
              (el) => el.id == input?.primary_id
            )}
            onChange={handleSelect}
            getOptionLabel={(opt) => opt?.name}
            getOptionValue={(opt) => opt?.id}
            isLoading={loading}
            isClearable={true}
            isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
            isSearchable
            placeholder={t("select Manager")}
            options={data?.active_saas_users?.data || []}
          />
        </form>
        <div className="validity-msg-style text-left">
          {valid?.["input.assign_primary_to"]}
        </div>
      </div>

      <div
        className={
          "p-2 " +
          (valid?.["input.assign_secondary_to"]
            ? "invalid-container-style"
            : "")
        }
      >
        <label className={"select-def-label mb-2 validity-label-style"}>
          {t("secondary replacer")}
        </label>
        <form action="">
          <Select
            name="secondary_id"
            className={
              "select-def-input-containe b-select-style flex-grow-1 px-0"
            }
            classNamePrefix={"b-select-style"}
            value={data?.active_saas_users?.data?.find(
              (el) => el.id == input?.secondary_id
            )}
            onChange={handleSelect}
            getOptionLabel={(opt) => opt?.name}
            getOptionValue={(opt) => opt?.id}
            isLoading={loading}
            isClearable={true}
            isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
            isSearchable
            placeholder={t("select Manager")}
            options={data?.active_saas_users?.data || []}
          />
        </form>
        <div className="validity-msg-style text-left">
          {valid?.["input.assign_secondary_to"]}
        </div>
      </div>
    </MainModal>
  );
};

export default ChangeAdminUsersModal;
