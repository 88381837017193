import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { updatePlanFeatureInput } from "../../Store/Actions";

const PackageFeatureSection = ({ label, featureId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planFeatuesState = useSelector(
    (state) => state.admin.systemPlan?.features
  );

  const index = planFeatuesState?.findIndex(
    (feature) => feature?.id == featureId
  );

  const handleFeatLimitChange = (e) => {
    let event = {
      target: {
        name: "limit",
        value: e.target.value !== "unlimted" ? +e.target.value : "unlimted",
      },
    };
    dispatch(updatePlanFeatureInput(event, index));
  };

  if (index < 0) {
    return null;
  }

  return (
    <div className="row my-3 mx-0 align-items-center">
      <div className="col-md-10 col-sm-12 px-0">
        <div className="row">
          <label className="col-md-5 col-sm-6">{t(label)}</label>
          <div className="col-md-6 col-sm-6 row">
            <div className="col">
              <label className="radio">
                <input
                  type="radio"
                  name={featureId}
                  value={"unlimted"}
                  onChange={handleFeatLimitChange}
                  checked={planFeatuesState[index]?.limit === null}
                />
                <span></span>

                <div className={`"mb-0 mr-3" "optionLabelActiveStyle"`}>
                  {t("unlimited")}
                </div>
              </label>
            </div>
            <div className="col">
              <label className="radio">
                <input
                  type="radio"
                  name={featureId}
                  value={1}
                  onChange={handleFeatLimitChange}
                  checked={planFeatuesState[index]?.limit !== null}
                />
                <span></span>

                <div className={`"mb-0 mr-3" "optionLabelActiveStyle"`}>
                  {t("limited")}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      {planFeatuesState[index]?.limit !== null && (
        <div className="d-flex justify-content-end col-md-2 col-sm-12">
          <input
            className={"input-style-default validity-input-style text-center"}
            name="limit"
            placeholder={t("limited")}
            value={planFeatuesState[index]?.limit}
            onChange={handleFeatLimitChange}
          />
        </div>
      )}
    </div>
  );
};

export default PackageFeatureSection;
