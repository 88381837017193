import gql from "graphql-tag";

/*
  Packages
*/

export const PackageFragment = gql`
  fragment package on Plan {
    id
    name
    trial_value
    features {
      id
      pivot {
        limits
      }
    }
  }
`;

/*
  Plans
*/

export const PlanFragment = gql`
  fragment plan on PaymentPlan {
    id
    name
    package {
      id
      name
    }
    currency {
      id
      name
    }
    period
    duration
    type
    pricing
    configuration
    status
    activeSubscribers
    companyIds
    suspend_after
  }
`;

/*
  Subscriptions
*/

export const SubscriptionFragment = gql`
  fragment subscription on PaymentSubscription {
    id
    to
    from
    status
    prorate
    duration
    taxation
    currentTier
    planName
    paymentType: type
    ratePerPeriod: rpp
    billingPeriod: period
    suspend_after
  }
`;

/*
  Payments
*/

export const PaymentCommentFragment = gql`
  fragment comment on PaymentTrackingComment {
    body
    user {
      name
    }
  }
`;

export const PaymentFragment = gql`
  fragment payment on Payment {
    id
    company
    details
    amount
    eInvoice
    receivedAmount
    method
    due_date
    invoice
    status
    from
    paid
    logs
    comments {
      ...comment
    }
  }
  ${PaymentCommentFragment}
`;
