import React from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import { gql } from "@apollo/client";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";

import MainModal from "../MainModal";
import { CheckboxBooleanForm, InputForm } from "form-builder";

const reducer = "paymentTracking";
const formName = "setInvoiceModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const changeTierMutation = gql`
  mutation changeTier($paymentId: Int, $serial: String, $eInvoice: Int) {
    payment_tracking_change_serial(
      paymentId: $paymentId
      eInvoice: $eInvoice
      serial: $serial
    )
  }
`;

const SetInvoiceModal = ({ data, onClose, refetchList }) => {
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [setSerials, { loading: setSerialsLoading }] =
    useMutation(changeTierMutation);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* State Effects */

  React.useEffect(() => {
    dispatch(
      onFormResetAction(formName, { ...formData, eInvoice: data?.eInvoice })
    );
  }, []);

  /* Helpers */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation.length) return;

    setSerials({
      variables: {
        paymentId: data?.id,
        serial: formData?.serial,
        eInvoice: formData?.eInvoice,
      },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Set Invoice Serial"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={setSerialsLoading}
    >
      <CheckboxBooleanForm
        {...FormProps}
        name="eInvoice"
        options={["E-invoices"]}
      />
      <InputForm
        {...FormProps}
        name="serial"
        label="serial"
        validateBy="textRequired"
        inputContainerStyle="w-100"
        placeholder="Enter invoice serial"
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {errMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default SetInvoiceModal;
