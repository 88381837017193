import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissCompanyExpenseModalAction,
  upsertCompanyExpensesAction,
  fetchSubCatgoriesInExpenseFormAction,
  fetchCurrenciesInExpenseFormAction,
  fetchEmployeesInExpenseFormAction,
  onInputChangeAction,
  onInputResetAction,
  deleteCompanyExpenseAttachedFileAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import Dropzone from "react-dropzone-uploader";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import usePrevious from "../../Helpers/Hooks/usePrevious";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import swal from "sweetalert";
import {
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";

const formName = "companyExpensesModal";
const formNameValidation = "companyExpensesModalValidation";
const formServerValidation = "companyExpensesModalServerValidation";

const CompanyExpensesModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // to get the previous values using usePrevious hook
  const prevCategoryId = usePrevious(props.companyExpensesModal?.category_id);
  const prevOfficeId = usePrevious(props.companyExpensesModal?.office_id);
  const prevCurrencyId = usePrevious(props.companyExpensesModal?.currency_id);

  // initail vlaue for requestFiles set to empty array
  const [requestFiles, setRequestFiles] = useState([]);

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle uploader image change
  const handleImageChange = ({ file, meta }, status) => {
    console.log(status);

    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result.split(",");
      let fileType = file?.type.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  // start of handling sub gatgory option according to what is chosen in category option
  const handleSubCatgoryOption = () => {
    if (
      prevCategoryId &&
      prevCategoryId !== props.companyExpensesModal?.category_id
    ) {
      props.onInputResetAction(formName, "sub_category_id");
    }

    props.fetchSubCatgoriesInExpenseFormAction({
      ref: formName,
      [formName]: {
        ...props.data[formName],
      },
    });
  };

  const useDidSubCatgoryUpdate = useDidUpdateEffect(handleSubCatgoryOption, [
    props.data[formName].category_id,
  ]);
  // End of handling sub gatgory option according to what is chosen in category option

  // start of handling currency option according to what is chosen in office option
  const handleCurrencyOption = () => {
    if (
      prevOfficeId &&
      prevOfficeId !== props.companyExpensesModal?.office_id
    ) {
      props.onInputResetAction(formName, "currency_id");
    }

    props.fetchCurrenciesInExpenseFormAction({
      ref: formName,
      [formName]: {
        ...props.data[formName],
      },
    });
  };

  const useDidCurrencyUpdate = useDidUpdateEffect(handleCurrencyOption, [
    props.data[formName].office_id,
  ]);
  // End of handling currency option according to what is chosen in office option

  // start of handling employees option according to what is chosen in office and currency option
  const handleEmployeesOption = () => {
    if (
      (prevOfficeId &&
        prevOfficeId !== props.companyExpensesModal?.office_id) ||
      (prevCurrencyId &&
        prevCurrencyId !== props.companyExpensesModal?.currency_id)
    ) {
      props.onInputResetAction(formName, "emp_id");
    }

    props.fetchEmployeesInExpenseFormAction({
      ref: formName,
      [formName]: {
        ...props.data[formName],
      },
    });
  };

  const useDidEmployeesUpdate = useDidUpdateEffect(handleEmployeesOption, [
    props.data[formName].currency_id,
    props.data[formName].office_id,
  ]);
  // End of handling  employees option according to what is chosen in office and currency

  // handle delete petty cash attached file
  const handleDeleteAttchment = (attachment_id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompanyExpenseAttachedFileAction(
          attachment_id,
          props?.companyExpensesModal?.id
        );
      }
    });
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    // to send file and extenstion only without id key in the array
    let formAttachments = requestFiles?.map(({ file, extension }) => ({
      file,
      extension,
    }));

    props.upsertCompanyExpensesAction({
      allData: {
        ...props?.companyExpensesModal,
        attachments: formAttachments,
      },
      ref: "companyExpensesModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissCompanyExpenseModalAction({
      ref: "companyExpensesModalActions",
      formName,
    });
  };

  const handleTextArea = (e) => {
    props.onInputChangeAction(formName, e);
  };

  useEffect(() => {
    setIsSubmitting(false);
    setRequestFiles([]);
    return () => {};
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${props.companyExpensesModal?.id != null ? "Edit" : "Add"} Expense`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_expense_form_wrapper_style"
    >
      {props?.companyExpenseModalDataIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div>
        {props.companyExpensesModal?.id != null ||
        props.companyExpensesModal?.isUserProfile ? (
          ""
        ) : (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_THE_EXPENSES_OF_ALL_OFFICES]}
            allowBP
          >
            <div className="row mb-2">
              <div className="col-12">
                <BSelect
                  label="office"
                  labelStyle="mb-2"
                  reducer="finances"
                  {...FormProps}
                  name="office_id"
                  validationName={`input.office_id`}
                  optionLabel="name"
                  optionValue="id"
                  options={props?.user?.offices}
                  keepDefaultStyle
                  isClearable
                  placeholder={t("offices")}
                  icon="office"
                  skipLocalization
                />
              </div>
            </div>
          </HasPrivileges>
        )}

        <div className="row mb-2">
          <div className="col-12">
            <BSelect
              label="category"
              labelStyle="mb-2"
              reducer="finances"
              {...FormProps}
              name="category_id"
              validationName={`input.category_id`}
              optionLabel="name"
              optionValue="id"
              options={props?.data?.companyExpenseCategoryData}
              keepDefaultStyle
              isClearable
              placeholder={t("select category")}
              icon="type"
            />
          </div>
        </div>

        {props?.data?.expenseFormSubCatgeoriesData?.length == 0 ? (
          ""
        ) : (
          <div className="row mt-2">
            <div className="col-12">
              <BSelect
                label="sub-categories"
                labelStyle="mb-2"
                reducer="finances"
                {...FormProps}
                name="sub_category_id"
                validationName={`input.sub_category_id`}
                optionLabel="name"
                optionValue="id"
                options={props?.data?.expenseFormSubCatgeoriesData}
                keepDefaultStyle
                isClearable
                placeholder={t("select sub-category")}
                icon="type"
              />
            </div>
          </div>
        )}

        <div className="row my-2">
          <div className="col-12 col-lg-6 mt-1">
            <InputForm
              label="amount"
              labelStyle="mb-2"
              placeholder={t("amount")}
              reducer="finances"
              {...FormProps}
              name="amount"
              validationName={`input.amount`}
              containerStyle=""
              inputContainerStyle="w-100"
              type="number"
              icon="money"
            />
          </div>

          <div className="col-12 col-lg-6">
            <BSelect
              label="Currency"
              labelStyle="mb-2"
              reducer="finances"
              name="currency_id"
              {...FormProps}
              validationName={`input.currency_id`}
              options={props?.data?.expenseFormCurrencyData}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              isDisabled={
                props?.data?.expenseFormCurrencyData.length == 1 ? true : false
              }
              placeholder={t("select currency")}
              icon="currency"
              preSelectValue={
                props?.data?.expenseFormCurrencyData?.find(
                  (currency) => currency.defaultCurrencyOffice
                )?.id
              }
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-12">
            <DateTimePickerForm
              label="incurred at"
              dateTime
              labelStyle="mb-2"
              reducer="finances"
              {...FormProps}
              name="date"
              validationName={`input.date`}
              datePickerContainer="w-100"
              hasIcon
              disabled={props.companyExpensesModal?.id != null ? true : false}
              requestFormat="yyyy-MM-DD HH:mm:ss"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <label className="mb-2 validity-label-style">
              {t("description")}
            </label>
            <textarea
              rows="4"
              reducer="finances"
              {...FormProps}
              name="details"
              validationName={`input.details`}
              placeholder={t("description")}
              onChange={handleTextArea}
              value={props?.companyExpensesModal?.details}
            />
          </div>
        </div>

        {props?.isEmployeePettyCash ||
        props.companyExpensesModal?.isUserProfile ? (
          ""
        ) : (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EXPENSES_ON_BEHALF_OF_OTHER_EMPLOYEES]}
            allowBP
          >
            <>
              <div className="row mt-3">
                <div className="col-12 col-lg-5">
                  <CheckboxBooleanForm
                    reducer="finances"
                    name="on_behalf_of"
                    {...FormProps}
                    validationName={`input.on_behalf_of`}
                    options={[t("Add Expense On Behalf Of")]}
                    type="checkbox"
                  />
                </div>

                <div className="col-12 col-lg-7">
                  <BSelect
                    reducer="finances"
                    name="emp_id"
                    {...FormProps}
                    validationName={`input.emp_id`}
                    options={props?.data?.expenseFormEmployeesData}
                    filterOption={handleFilterOptionByEmpCodeOrName}
                    keepDefaultStyle
                    optionLabel="name"
                    optionValue="emp_id"
                    containerStyle="my-0 mb-2 mb-lg-0"
                    isClearable
                    placeholder={t("select_employee")}
                    dependOn="on_behalf_of"
                    dependancyType="equal"
                    dependancyValue={[1]}
                    icon="person"
                  />
                </div>
              </div>
              {props?.companyExpensesModal?.on_behalf_of ? (
                <div className="row mb-2">
                  <div className="col-12">
                    <label className="my-2 expense_form_note_style">
                      {t(
                        "Amount will be deducted from the employee's petty cash balance"
                      )}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          </HasPrivileges>
        )}

        <div className="row mt-2">
          <div
            className={`col-12 mt-1 mb-2 ${
              props?.serverVaildation &&
              "input.files" in props?.serverVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">
              {t("Attachments")}
            </label>
            <div className="attachment_wrapper_style">
              {props?.companyExpensesModal?.attachments?.map((file, i) => (
                <div className="d-flex my-2">
                  <a
                    href={file?.path}
                    title={file?.path}
                    download={`${t("Attachment")} - ${i + 1}`}
                    target="_blank"
                    className="d-flex align-items-baseline fit-width attachment_label_style mb-1"
                  >
                    <FontAwesomeIcon icon={faPaperclip} />
                    <div className="mx-2">{`${t("Attachment")} - ${
                      i + 1
                    }`}</div>
                  </a>

                  <div
                    className="mx-3"
                    role="button"
                    onClick={() => handleDeleteAttchment(file?.id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                </div>
              ))}
            </div>

            <div className="documents_dropzone_style">
              <Dropzone
                onChangeStatus={handleImageChange}
                canRemove
                accept="image/*,.pdf,.doc,.docx,.xlsm,.xls,.xlsx"
                name="attachments"
                multiple={true}
                inputContent={t("face_placeholder")}
              />
            </div>

            <span className="validity-msg-style">
              {props?.serverVaildation &&
              "input.files" in props?.serverVaildation
                ? props?.serverVaildation["input.files"]
                : null}
            </span>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.finances.companyExpensesModalActions,
  modalValidation: state.finances[formNameValidation],
  companyExpensesModal: state.finances[formName],
  serverVaildation: state.finances[formServerValidation],
  data: state.finances,
  user: state.auth.userProfile,
  companyExpenseModalDataIsLoading: state?.finances?.companyExpensesDataLoading,
});

export default connect(mapStateToProps, {
  dismissCompanyExpenseModalAction,
  upsertCompanyExpensesAction,
  fetchSubCatgoriesInExpenseFormAction,
  fetchCurrenciesInExpenseFormAction,
  fetchEmployeesInExpenseFormAction,
  onInputChangeAction,
  onInputResetAction,
  deleteCompanyExpenseAttachedFileAction,
})(CompanyExpensesModal);
