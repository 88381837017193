import React, { useCallback, useEffect, useState } from "react";
import { BSelect, RadioboxForm, CheckboxBooleanForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import { useTranslation } from "react-i18next";
import {
  downloadEmployeeTemplateAttempt,
  setIsEmployeeImportModalOpen,
  showSuccessToast,
  showErrorToast,
  uploadBulkEmployeesCsvAttempt,
  uploadBulkEmployeesCsvFailed,
  uploadBulkEmployeesCsvSuccess,
} from "../../../../Store/Actions";
import { connect, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import MainModal from "../../../MainModal";
import { useParams } from "react-router-dom";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import HelperFns from "../../../../Helpers/HelperFns";
import Switch from "@mui/material/Switch";

const dowmloadShiftBaseTempQuery = gql`
  query ExportFile($companyId: ID) {
    export_excel_file_template_shift_based(input: { company_id: $companyId }) {
      status
      file
    }
  }
`;

const uploadShiftBaseTempQuery = gql`
  query shiftBasedExcelImport(
    $file: Upload,
    $companyId: ID,
    $sendVerificationMsg: Boolean
  ) {
    import_excel_shift_file_template(
      company_id: $companyId,
      file: $file,
      send_verification_msg: $sendVerificationMsg
    ) {
      status
      messages {
        row
        name
        error_data {
          column
          value
          message
        }
      }
    }
  }
`;

const DOWNLOAD_OFFICE_WEEKLY_TEMP = gql`
  query downloadOfficeWeeeklyTemp($companyId: ID) {
    export_excel_file_weekly_template(input: { company_id: $companyId }) {
      status
      file
    }
  }
`;

const DOWNLOAD_SHIFT_WEEKLY_TEMP = gql`
  query downloadShiftWeeeklyTemp($companyId: ID) {
    export_excel_file_weekly_shift_template(input: { company_id: $companyId }) {
      status
      file
    }
  }
`;

const UPLOAD_OFFICE_WEEKLY = gql`
  query uploadOfficeWeekly(
    $file: Upload,
    $companyId: ID,
    $sendVerificationMsg: Boolean
  ) {
    import_weekly_excel_file_template(
      company_id: $companyId,
      file: $file,
      send_verification_msg: $sendVerificationMsg
    ) {
      status
      messages {
        row
        name
        error_data {
          column
          value
          message
        }
      }
    }
  }
`;

const UPLOAD_SHIFT_WEEKLY = gql`
  query uploadShiftWeekly(
    $file: Upload, 
    $companyId: ID, 
    $sendVerificationMsg: Boolean
  ) {
    import_weekly_shift_excel_file_template(
      company_id: $companyId
      file: $file
      send_verification_msg: $sendVerificationMsg
    ) {
      status
      messages {
        row
        name
        error_data {
          column
          value
          message
        }
      }
    }
  }
`;

const AddBulkEmployeesModalContent = (props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(undefined);
  const dispatch = useDispatch();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [attendanceType, setAttendanceType] = useState("office");
  const [paymentInterval, setPaymentInterval] = useState("monthly");

  const [sendVerificationMsg, setSendVerificationMsg] = useState(false);

  const handleVerificationMsg = () => {
    setSendVerificationMsg(!sendVerificationMsg);
  }

  const { companyId } = useParams();

  const [downloadShiftTemplate, { loading }] = useLazyQuery(
    dowmloadShiftBaseTempQuery,
    {
      onCompleted: (data) => {
        if (
          data?.export_excel_file_template_shift_based?.status === "success"
        ) {
          HelperFns.downloadFile(
            data?.export_excel_file_template_shift_based?.file
          );
        }
      },
    }
  );

  const [downloadOfficeWeeklyTemplate, { loading: officeWeeklyTempLoading }] =
    useLazyQuery(DOWNLOAD_OFFICE_WEEKLY_TEMP, {
      onCompleted: (data) => {
        if (data?.export_excel_file_weekly_template?.status === "success") {
          HelperFns.downloadFile(data?.export_excel_file_weekly_template?.file);
        }
      },
    });

  const [downloadShiftWeeklyTemplate, { loading: shiftWeeklyTempLoading }] =
    useLazyQuery(DOWNLOAD_SHIFT_WEEKLY_TEMP, {
      onCompleted: (data) => {
        if (
          data?.export_excel_file_weekly_shift_template?.status === "success"
        ) {
          HelperFns.downloadFile(
            data?.export_excel_file_weekly_shift_template?.file
          );
        }
      },
    });

  const [uploadShiftTemplate, { loading: uploadLoading }] = useLazyQuery(
    uploadShiftBaseTempQuery,
    {
      onCompleted: (data) => {
        console.log(data);
        if (data?.import_excel_shift_file_template?.status === "success") {
          dispatch(uploadBulkEmployeesCsvSuccess());
          dispatch(showSuccessToast());
          resetFile();
        } else {
          dispatch(
            uploadBulkEmployeesCsvFailed(
              data?.import_excel_shift_file_template?.messages
            )
          );
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
          )
        );
      },
    }
  );

  const [uploadOfficeWeekly, { loading: uploadOfficeWeeklyLoading }] =
    useLazyQuery(UPLOAD_OFFICE_WEEKLY, {
      onCompleted: (data) => {
        if (data?.import_weekly_excel_file_template?.status === "success") {
          dispatch(uploadBulkEmployeesCsvSuccess());
          dispatch(showSuccessToast());
          resetFile();
        } else {
          dispatch(
            uploadBulkEmployeesCsvFailed(
              data?.import_weekly_excel_file_template?.messages
            )
          );
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
          )
        );
      },
    });

  const [uploadShiftWeekly, { loading: uploadShiftWeeklyLoading }] =
    useLazyQuery(UPLOAD_SHIFT_WEEKLY, {
      onCompleted: (data) => {
        if (
          data?.import_weekly_shift_excel_file_template?.status === "success"
        ) {
          dispatch(uploadBulkEmployeesCsvSuccess());
          dispatch(showSuccessToast());
          resetFile();
        } else {
          dispatch(
            uploadBulkEmployeesCsvFailed(
              data?.import_weekly_shift_excel_file_template?.messages
            )
          );
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
          )
        );
      },
    });

  useEffect(() => {
    setFile(undefined);
    setIsErrorVisible(false);
  }, [props.isBulkImportEmployeesOpen]);

  const handleImageChange = ({ file, meta }, status) => {
    if (status === "done") {
      setFile(file);
      setIsErrorVisible(false);
    }
  };

  const regulations = [
    { id: 5, name: "Mawared Custom" },
    { id: 2, name: "Test regulation" },
  ];

  const handleDownloadTemplete = () => {
    if (attendanceType == "shift") {
      if (paymentInterval == "monthly") {
        downloadShiftTemplate({
          variables: {
            companyId,
          },
        });
      } else {
        downloadShiftWeeklyTemplate({
          variables: {
            companyId,
          },
        });
      }
    } else {
      if (paymentInterval == "monthly") {
        props.downloadEmployeeTemplateAttempt(companyId);
      } else {
        downloadOfficeWeeklyTemplate({
          variables: {
            companyId,
          },
        });
      }
    }
  };

  const resetFile = () => {
    setFile(undefined);
  };

  const handleUploadEmployeesCsv = () => {
    if (file) {
      if (attendanceType == "shift") {
        if (paymentInterval == "monthly") {
          uploadShiftTemplate({
            variables: {
              file,
              companyId,
              sendVerificationMsg
            },
          });
        } else {
          uploadShiftWeekly({
            variables: {
              file,
              companyId,
              sendVerificationMsg
            },
          });
        }
      } else {
        if (paymentInterval == "monthly") {
          props.uploadBulkEmployeesCsvAttempt({
            file,
            company_id: companyId,
            send_verification_msg: sendVerificationMsg,
            resetFile
          });
        } else {
          uploadOfficeWeekly({
            variables: {
              file,
              companyId,
              sendVerificationMsg
            },
          });
        }
      }
    } else {
      setIsErrorVisible(true);
    }
  };

  const dismissModal = () => {
    props.setIsEmployeeImportModalOpen(false);
  };

  const isSubmitLoading =
    props.uploadEmployeesCsvLoading ||
    uploadLoading ||
    uploadOfficeWeeklyLoading ||
    uploadShiftWeeklyLoading;

  const isDownloadButtonLoading =
    props?.uploadEmployeesCsvLoading ||
    loading ||
    uploadOfficeWeeklyLoading ||
    uploadShiftWeeklyLoading;

  return (
    <MainModal
      toggle={dismissModal}
      btnOnClick={handleUploadEmployeesCsv}
      btnOnCancelClick={dismissModal}
      btnLabel="import"
      btnLabelCancel="Cancel"
      isOpen={props.isBulkImportEmployeesOpen}
      modalTitle="import employees to the system"
      className="import_employees-modal-style"
      btnSubmitLoading={isSubmitLoading}
    >
      <div className="d-flex flex-column justify-content-start align-items-start px-1 w-100 documents_dropzone_style">
        {/* <BSelect
        name="regulation_type"
        formName="companyRegulations"
        options={regulations}
        optionLabel="name"
        optionValue="id"
        validateBy="textRequired"
        containerStyle="w-185 mb-2"
        label="Choose Salary Regulation"
        labelStyle="font-13 mb-2 import-employee-select-style"
      /> */}
        <h6>{t("template")}</h6>
        <span className="font-13 discreption">
          {t(
            "for a successful import, please make sure you are using this template when uploading your data"
          )}
        </span>

        <fieldset className="mb-2">
          <label className="mb-2">{t("attendance type")}</label>

          <div className="d-flex align-items-center">
            <RadioboxForm
              name="attType"
              value={attendanceType}
              options={[
                {
                  label: "office based",
                  value: "office",
                },
              ]}
              onChange={() => setAttendanceType("office")}
              optionInputStyle=" "
              optionItemStyle="px-0"
              optionsContainerStyle="d-flex gap-10"
              rootStyle="mr-3"
            />
            <RadioboxForm
              name="attType"
              value={attendanceType}
              options={[
                {
                  label: "shift based",
                  value: "shift",
                },
              ]}
              onChange={() => setAttendanceType("shift")}
              optionInputStyle=" "
              optionItemStyle="px-0"
              optionsContainerStyle="d-flex gap-10"
            />
          </div>
        </fieldset>
        <fieldset className="mb-2">
          <label className="mb-2">{t("payment interval")}</label>

          <div className="d-flex align-items-center">
            <RadioboxForm
              name="payment_interval"
              value={paymentInterval}
              options={[
                {
                  label: "monthly",
                  value: "monthly",
                },
              ]}
              onChange={() => setPaymentInterval("monthly")}
              optionInputStyle=" "
              optionItemStyle="px-0"
              optionsContainerStyle="d-flex gap-10"
              rootStyle="mr-3"
            />
            <RadioboxForm
              name="payment_interval"
              value={paymentInterval}
              options={[
                {
                  label: "weekly",
                  value: "weekly",
                },
              ]}
              onChange={() => setPaymentInterval("weekly")}
              optionInputStyle=" "
              optionItemStyle="px-0"
              optionsContainerStyle="d-flex gap-10"
            />
          </div>

        </fieldset>
        <fieldset className="mb-2">
          <CheckboxBooleanForm
            name="sendVerificationMsg"
            options={["send verification msg"]}
            onClick={handleVerificationMsg}
            checked={sendVerificationMsg}
          />
        </fieldset>

        <buton
          onClick={handleDownloadTemplete}
          className="employee-upload-btn-style d-flex justify-content-center align-items-center mb-4"
        >
          {isDownloadButtonLoading ? (
            <Spinner
              style={{
                width: "1rem",
                height: "1rem",
                color: "#fff",
              }}
            />
          ) : (
            <>
              <FontAwesomeIcon icon={faFileDownload} />
              <span>{t("download template")}</span>
            </>
          )}
        </buton>

        <h6>{t("upload file")}</h6>
        <Dropzone
          onChangeStatus={handleImageChange}
          canRemove
          accept=".csv,.xlsx,.xls"
          name="attachments"
          maxFiles="1"
          inputContent={t("drag files here or browse")}
          addClassNames={{
            dropzone: "upload-file-container ",
            inputLabel: "drop-zone-label",
          }}
        />
        {isErrorVisible ? (
          <span className="text-danger font-11">
            {t("please upload a valid excel file")}
          </span>
        ) : null}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    bulkImportEmployeesLoading: state?.super?.bulkImportEmployeesLoading,
    uploadEmployeesCsvLoading: state?.super?.uploadEmployeesCsvLoading,
    isBulkImportEmployeesOpen: state?.super?.isBulkImportEmployeesOpen,
  };
};

export default connect(mapStateToProps, {
  downloadEmployeeTemplateAttempt,
  setIsEmployeeImportModalOpen,
  uploadBulkEmployeesCsvAttempt,
})(AddBulkEmployeesModalContent);
