import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  onFormResetAction,
  onInputResetAction,
  activateUserAttempt,
  toggleActivationModal,
  fetchActivationAttendanceAction,
} from "../../Store/Actions";
import moment from "moment";
import gql from "graphql-tag";
import Constants from "../../Constants";
import HelperFns, {
  normalizeSalaryConfigInput,
  serializeUpsertAttType,
} from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";

import Loader from "../Loader";
import MainModal from "../MainModal";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SalaryConfigForm from "../SalaryConfigForm";
import {
  DateTimePickerForm,
  BSelect,
  CheckboxBooleanForm,
  InputForm,
} from "form-builder";
import WorkRemotely from "../SharedFormSections/WorkRemotely";
import AttendanceTypeConfig from "../SharedFormSections/AttendanceTypeConfig";
import {
  GET_COST_CENTERS_MENU,
  GET_EMPLOYEE_ACTIVE_SALARY_CONFIG,
} from "../../Graphql/query";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";
import RequestLimitConfig from "../AttendanceTypeModal/RequestLimitConfig";

// const formName = "activationModal";
// const formNameValidation = "activationModalValidation";
const formAttTypeName = "attendanceTypeConfig"; // step 1
const formSalaryConfigName = "salaryConfigForm"; // step 2
const formNameStepOneValidation = `employeeAttendanceFormValidation`;
const formStepOneServerValidationName = "activationFormStepOneServerValidation";
const formStepTwoServerValidationName = "upsertSalaryConfigServerValidation";

const officeBased = Constants.attendanceTypes.OFFICE_BASED;
const shiftBased = Constants.attendanceTypes.SHIFT_BASED;

/**
 *
 * Start of ActivationModal
 *
 */

const ActivationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: costCentersQueryData } = useQuery(GET_COST_CENTERS_MENU);
  const costCenters = costCentersQueryData?.cost_centers?.data ?? [];

  // Local State
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEndDateForActivation, setShowEndDateForActivation] =
    useState(false);

  // Reducer State
  const activationModal = useSelector((state) => state?.super?.activationModal);

  const timeRangeProfileSetting = useSelector(
    (state) => state?.super?.timeRangeProfileSetting
  );

  const modalData = useSelector(
    (state) => state?.super?.activationModalActions
  );
  const formAttTypeData = useSelector(
    (state) => state?.super?.[formAttTypeName]
  );
  const salaryConfigForm = useSelector(
    (state) => state?.super?.[formSalaryConfigName]
  );
  const formStepOneServerValidation = useSelector(
    (state) => state?.super?.[formStepOneServerValidationName]
  );
  // const formStepTwoServerValidation = useSelector(
  //   (state) => state?.super?.[formStepTwoServerValidationName]
  // );
  const formStepOneValidation = useSelector(
    (state) => state?.super?.employeeAttendanceFormValidation
  );
  const formStepTwoValidation = useSelector(
    (state) => state?.super?.salaryConfigFormValidation
  );
  const loadingActivationAttendance = useSelector(
    (state) => state?.super?.loadingActivationAttendance
  );

  // Constants
  const isModalVissible = modalData.isVissible;
  const modalMessage = modalData.modalMessage;
  const hasPayroll = HelperFns.checkCompanyPrivileges({
    privileges: [Privileges.PAYROLL_MANAGEMENT],
  });
  const AttTypeFormProps = {
    formName: formAttTypeName,
    formSubmitting: isSubmitting,
    formNameValidation: formNameStepOneValidation,
    formServerValidation: formStepOneServerValidationName,
  };
  const salaryConfig = React.useMemo(
    () => (
      <SalaryConfigForm
        formName={formSalaryConfigName}
        isFirst={false}
        formSubmitting={isSubmitting}
        formServerValidation={formStepTwoServerValidationName}
        activate
        countryId={activationModal?.countryId}
        userId={activationModal.id}
        office={activationModal?.office}
      />
    ),
    [isSubmitting]
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (isModalVissible) {
      attemptGetEmployeeActiveSalaryConfig();
    }
    dispatch(onFormResetAction(formAttTypeName));
    dispatch(onFormResetAction(formSalaryConfigName));
    dispatch(onFormResetAction(formStepOneServerValidationName));
    dispatch(onFormResetAction(formStepTwoServerValidationName));
    setIsSubmitting(false);
  }, [isModalVissible]);

  React.useEffect(() => {
    if (salaryConfigForm?.start_at) {
      if (
        formAttTypeData?.attendanceType === officeBased &&
        formAttTypeData?.attendanceProfileId
      ) {
        dispatch(
          fetchActivationAttendanceAction(
            activationModal?.id,
            salaryConfigForm?.start_at,
            salaryConfigForm?.end_at,
            +formAttTypeData?.attendanceProfileId
          )
        );
      }

      if (formAttTypeData?.attendanceType === shiftBased) {
        dispatch(
          fetchActivationAttendanceAction(
            activationModal?.id,
            salaryConfigForm?.start_at,
            salaryConfigForm?.end_at,
            null
          )
        );
      }
    }
  }, [
    salaryConfigForm?.start_at,
    salaryConfigForm?.end_at,
    formAttTypeData?.attendanceType,
    formAttTypeData?.attendanceProfileId,
  ]);

  React.useEffect(() => {
    if (isSubmitting && Object.keys(formStepOneServerValidation)?.length)
      setStep(1);
  }, [isSubmitting, Object.keys(formStepOneServerValidation)?.length]);

  /* ↓ Helpers ↓ */

  const toggleModal = () => {
    dispatch(toggleActivationModal());
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    dispatch(onFormResetAction(formStepOneServerValidationName));
    dispatch(onFormResetAction(formStepTwoServerValidationName));
    if (formStepOneValidation?.length) return setStep(1);
    if (formStepTwoValidation?.length) return;

    let salaryConfigDate = moment(formAttTypeData?.start_at).format(
      "YYYY-MM-DD"
    );

    const { min_overtime_cutoff, max_overtime_limit, min_work_hours, ...rest } =
      timeRangeProfileSetting;

    let timeRangeProfileSettingInput = {
      ...rest,
      min_overtime_cutoff: HelperFns.hoursToTime(min_overtime_cutoff),
      max_overtime_limit: HelperFns.hoursToTime(max_overtime_limit),
      min_work_hours: HelperFns.hoursToTime(min_work_hours),
    };

    let user_salary_config_input =
      !modalData?.require_create_new_salary_configuration
        ? null
        : {
            ...normalizeSalaryConfigInput(
              activationModal?.id,
              salaryConfigForm,
              salaryConfigDate,
              timeRangeProfileSettingInput
            ),
          };

    let employee_attendance = formAttTypeData?.employee_attendance;

    let newBalanceData = {
      annual_leaves: formAttTypeData?.annual_leaves,
      emergency_leaves: formAttTypeData?.emergency_leaves,
      sick_leaves: formAttTypeData?.sick_leaves,
    };

    let attendanceTypeConfig = serializeUpsertAttType({
      ...formAttTypeData,
      user_id: activationModal.id,
      start: moment(salaryConfigForm.start_at).format("YYYY-MM-DD"),
    });

    let scheduleBaseDataForActivation = {
      workplaceID: formAttTypeData?.workplaceID,
      workTimingID: formAttTypeData?.workTimingID,
      costCenterId: formAttTypeData?.costCenterId,
    };

    dispatch(
      activateUserAttempt({
        user_id: activationModal.id,
        activation_date_from: moment(salaryConfigForm?.start_at).format(
          "YYYY-MM-DD"
        ),
        activation_date_to: showEndDateForActivation
          ? moment(salaryConfigForm?.end_at).format("YYYY-MM-DD")
          : "",
        user_salary_config_input,
        employee_attendance,
        newBalanceData,
        attendanceTypeConfig,
        scheduleBaseDataForActivation,
      })
    );
  };

  const ModalFooter = () =>
    hasPayroll ? (
      <>
        {step === 1 && (
          <Button color="info" variant="contained" onClick={() => setStep(2)}>
            {t("next")}
          </Button>
        )}
        {step === 2 && (
          <div className="d-flex gap-10">
            <Button color="info" variant="contained" onClick={() => setStep(1)}>
              {t("previous")}
            </Button>
            <LoadingButton
              color="success"
              variant="contained"
              onClick={handleSubmitBtn}
              loading={modalData.isLoading}
            >
              {t("save")}
            </LoadingButton>
          </div>
        )}
      </>
    ) : (
      <LoadingButton
        color="success"
        variant="contained"
        onClick={handleSubmitBtn}
        loading={modalData.isLoading}
      >
        {t("save")}
      </LoadingButton>
    );

  const [
    attemptGetEmployeeActiveSalaryConfig,
    { isLoading: empActiveSalaryConfigLoading, data: empActiveSalaryConfig },
  ] = useLazyQuery(GET_EMPLOYEE_ACTIVE_SALARY_CONFIG, {
    variables: {
      empId: activationModal?.id,
      date: moment().format("YYYY-MM-DD"),
    },
  });

  return modalData?.loadingModal ? (
    <Loader fixed />
  ) : (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={`${
        empActiveSalaryConfig?.employee?.suspendedSalaryConfiguration !== null
          ? `${t("Activation")} ${t("suspension date")}: ${
              empActiveSalaryConfig?.employee?.suspendedSalaryConfiguration
                ?.from
            }`
          : `${t("Activation")}`
      }`}
      modalFooterComponent={ModalFooter}
      size="lg"
      skipModalHeaderLocal
    >
      {loadingActivationAttendance ? <Loader fixed /> : null}

      {/* Step 1 */}
      <div className={step === 1 ? undefined : "d-none"}>
        <div className="d-flex gap-20">
          {/* Start Date */}
          <DateTimePickerForm
            name="start_at"
            label="Starting From"
            requestFormat="YYYY-MM-DD"
            {...AttTypeFormProps}
            formNameValidation={formNameStepOneValidation}
            formName={formSalaryConfigName}
            placeholder={t("Activation Date")}
            containerStyle="mb-2"
            datePickerContainer="w-100"
            rootStyle="w-100"
            validateBy="textRequired"
            hasIcon
          />

          {/* End Date */}
          {showEndDateForActivation && (
            <DateTimePickerForm
              name="end_at"
              label="end date"
              requestFormat="YYYY-MM-DD"
              {...AttTypeFormProps}
              formNameValidation={formNameStepOneValidation}
              formName={formSalaryConfigName}
              placeholder={t("Activation Date")}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              rootStyle="w-100"
              validateBy="textRequired"
              hasIcon
            />
          )}
        </div>

        {/* checkbox to show or hide activation end date */}
        <CheckboxBooleanForm
          options={[t("set end date for activation")]}
          type="checkbox"
          containerStyle="mb-3"
          checked={showEndDateForActivation}
          onChange={() =>
            setShowEndDateForActivation(!showEndDateForActivation)
          }
        />

        {/* Work Schedule */}
        <strong className="text-16 sec-color">
          {t("work schedule")}
          <hr className="title-line" />
        </strong>
        <AttendanceTypeConfig
          FormProps={AttTypeFormProps}
          serverValidationPrefix="attendanceTypeConfig."
          showEndDateForActivation={showEndDateForActivation}
          startDate={salaryConfigForm?.start_at}
        />

        {!showEndDateForActivation && (
          <GrantEmployee
            FormProps={AttTypeFormProps}
            startDate={salaryConfigForm?.start_at}
            leaveBreakProfile={
              empActiveSalaryConfig?.employee?.permission_leaves_break_setting
            }
          />
        )}

        <RequestLimitConfig FormProps={AttTypeFormProps} />
        {/* Work Remotely */}
        <strong className="text-16 sec-color d-block mt-2">
          {t("work from home")}
          <hr className="title-line" />
        </strong>
        <WorkRemotely FormProps={AttTypeFormProps} />

        {/* Employee Attendance in past */}
        <EmployeeAttendance
          FormProps={AttTypeFormProps}
          workTeamId={formAttTypeData?.workTeamId}
          attType={formAttTypeData?.attendanceType}
          employeeAttendance={formAttTypeData?.employee_attendance}
          costCentersOptions={costCenters}
        />
      </div>

      {/* Step 2 */}
      <div className={step === 2 ? undefined : "d-none"}>
        {modalData?.require_create_new_salary_configuration
          ? salaryConfig
          : null}
      </div>

      {/* (Start) Error Message */}
      {modalMessage && isSubmitting && (
        <div className="warnig-msg-style">{modalMessage}</div>
      )}
      {/* (End) Error Message */}
    </MainModal>
  );
};

export default ActivationModal;

/**
 *
 * End of ActivationModal
 *
 * Start of EmployeeAttendance
 *
 */

const GET_PAST_ATT_OPTIONS = gql`
  query getLateAttOptions {
    work_timings_menu(type: "normal", new_versions_only: true) {
      id
      name
    }
    company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;

export const EmployeeAttendance = ({
  FormProps,
  costCentersOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allowCostCenter } = useAllowCostCenter();

  // Local State
  const [options, setOptions] = useState({ workTimings: [], offices: [] });

  // Reducer State
  const fetchedEmployee_attendance = useSelector(
    (state) => state?.super?.fetchedEmployee_attendance
  );

  // Server State
  const [getOptions, { data, loading }] = useLazyQuery(GET_PAST_ATT_OPTIONS);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (fetchedEmployee_attendance && props?.attType === shiftBased) {
      getOptions({
        onCompleted: ({ work_timings_menu, company_offices }) => {
          setOptions({
            offices: company_offices?.data || [],
            workTimings: work_timings_menu || [],
          });
        },
      });
    }
  }, [props?.attType, fetchedEmployee_attendance]);

  React.useEffect(() => {
    !fetchedEmployee_attendance &&
      dispatch(onInputResetAction(FormProps.formName, "employee_attendance"));

    if (
      props?.attType === officeBased ||
      props?.workTeamId ||
      !fetchedEmployee_attendance
    ) {
      dispatch(onInputResetAction(FormProps.formName, "workplaceID"));
      dispatch(onInputResetAction(FormProps.formName, "workTimingID"));
    }
  }, [props?.attType, props?.workTeamId, fetchedEmployee_attendance]);

  return fetchedEmployee_attendance ? (
    <div className="mt-3">
      <h4 className="sub-title-style mb-0">{t("Employee Attendance")}</h4>
      <hr className="title-line" />

      <BSelect
        {...FormProps}
        name="employee_attendance"
        label="Set Employee Attendance As"
        placeholder={t("Select Working Status")}
        containerStyle="d-flex gap-10 align-items-center mt-3"
        inputContainerStyle="flex-1"
        options={[
          { id: "Absent", name: "Absent" },
          { id: "Attended", name: "Attended" },
          { id: "Day Off", name: "Day Off" },
        ]}
        validateBy="textRequired"
        validationName="input.employee_attendance"
      />

      {props?.attType === shiftBased &&
      !props?.workTeamId &&
      props?.employeeAttendance !== "Day Off" ? (
        <div className="d-flex gap-10">
          <BSelect
            {...FormProps}
            name="workTimingID"
            loading={loading}
            options={options.workTimings}
            label={t("work timing")}
            rootStyle="flex-1"
            placeholder={t("select work timing")}
            inputContainerStyle="w-100"
            validateBy="textRequired"
            validationName="input.scheduleBaseDataForActivation.workTimingID"
          />
          <BSelect
            {...FormProps}
            name="workplaceID"
            loading={loading}
            options={options.offices}
            label={t("work place")}
            rootStyle="flex-1"
            placeholder={t("select work places")}
            inputContainerStyle="w-100"
            validateBy="textRequired"
            validationName="input.scheduleBaseDataForActivation.workplaceID"
          />
          {allowCostCenter ? (
            <BSelect
              {...FormProps}
              name="costCenterId"
              loading={loading}
              options={costCentersOptions ?? []}
              label={t("Cost center")}
              rootStyle="flex-1"
              placeholder={t("select cost center")}
              inputContainerStyle="w-100"
              validationName="input.scheduleBaseDataForActivation.workplaceID"
            />
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;
};

/**
 *
 * End of AttendanceTypeConfig
 *
 * Start of GrantEmployee
 *
 */

const GrantEmployee = ({ FormProps, startDate, leaveBreakProfile }) => {
  const { t } = useTranslation();

  return (
    <div className={"mt-2"}>
      <strong>{t("grant employee")}</strong>
      <div className="align-items-center d-flex justify-content-between mt-2 mb-3">
        <div className="d-flex justify-content-between align-items-center flex-fill flex-wrap">
          {Boolean(
            leaveBreakProfile?.annualLeaveSetting?.allow_annual_leaves
          ) ? (
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="annual_leaves"
              label="normal leaves"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.annual_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}

          {Boolean(
            leaveBreakProfile?.annualLeaveSetting?.allow_annual_leaves
          ) &&
          Boolean(
            leaveBreakProfile?.annualLeaveSetting?.allowed_emergency_leaves
          ) ? (
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="emergency_leaves"
              label="emergency credit"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.emergency_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}

          {Boolean(leaveBreakProfile?.sickLeaveSetting?.sick_leaves) ? (
            <InputForm
              {...FormProps}
              name="sick_leaves"
              label="sick leaves"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.sick_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}
        </div>

        {moment(startDate).isValid() ? (
          <label className="d-flex flex-grow-1 justify-content-end pt-3">
            {t("for")} {moment(startDate).format("yyyy")}
          </label>
        ) : (
          <label className="d-flex flex-grow-1" />
        )}
      </div>
    </div>
  );
};
