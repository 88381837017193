import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Constants from "../../Helpers/Constants";
import { BSelect, InputForm } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import ExportModal from "./ExportModal";
import {
  fetchYearlyReportListAction,
  onFormResetAction,
  onInputResetAction,
  showExportYearlyReportModal,
  showToast,
  updateValueAction,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

import ToggleModal from "./ToggleModal";

import { useTranslation } from "react-i18next";
import { AgGridTable } from "../../Components/AgGridTable";
import HelperFns from "../../Helpers/HelperFns";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";
import ClearIcon from "@mui/icons-material/Clear";
import NewMultiSelect from "../../Components/FiltersDrawer/NewMultiSelect";
import FiltersDrawer from "../../Components/FiltersDrawer";
import FilterBadge from "../../Components/FilterBadge";
import { useQuery } from "@apollo/client";
import moment from "moment";
import gql from "graphql-tag";

const dataTableRef = "yearlyReport";
const YEARLY_REPORT_OPTIONS = gql`
  query yearlyReportOptions {
    extraUsersFilter: users_by_role(input: { page_flag: "yearly_report" }) {
      data {
        id
        name
      }
    }
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

const YearlyReport = () => {
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);
  const gridRef = useRef();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [variables, setVariables] = useState({
    employees: null,
    departments: null,
    offices: null,
    employee_search: null,
    year: `${moment().year()}`,
  });

  const { loading: optionsLoading, data: options } = useQuery(
    YEARLY_REPORT_OPTIONS,
    {
      onError: (error) => {
        showToast(
          "error",
          error?.graphQLErrors?.[0]?.message || error?.message
        );
      },
    }
  );

  // Redux Hooks
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userProfile);
  const companyDepartments = options?.company_departments?.data || [];
  const extraEmployeesFilter = options?.extraUsersFilter?.data || [];
  const tableState = useSelector((state) => state.super[dataTableRef]);
  const isColumnActive = useSelector(
    (state) => state.monthlyReport.yearlyReportColumns
  );

  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsFilterDrawerOpen(open);
  };

  const onFilterReset = () => {
    dispatch(
      onFormResetAction(dataTableRef, {
        ...tableState,
        employees: null,
        departments: null,
        offices: null,
        employee_search: "",
        year: "" + new Date().getFullYear(),
      })
    );
  };

  const onFilterSubmit = () => {
    const isDiff =
      variables?.employees !== tableState?.employees ||
      variables?.departments !== tableState?.departments ||
      variables?.offices !== tableState?.offices ||
      variables?.employee_search !== tableState?.employee_search ||
      variables?.year !== tableState?.year;

    if (isDiff) handleFilter();
    setIsFilterDrawerOpen(false);
  };

  const clearFilters = () => {
    onFilterReset();
    setClearOrCancel(true);
  };

  useEffect(() => {
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: tableState,
      })
    );

    return () => {
      dispatch(onInputResetAction(dataTableRef, "year"));
      dispatch(onInputResetAction(dataTableRef, "employees"));
      dispatch(onInputResetAction(dataTableRef, "offices"));
      dispatch(onInputResetAction(dataTableRef, "departments"));
      dispatch(onInputResetAction(dataTableRef, "employee_search"));
    };
  }, []);

  const handleFilter = () => {
    setVariables({
      employees: tableState?.employees,
      offices: tableState?.offices,
      departments: tableState?.departments,
      ...(tableState?.employee_search
        ? {
            employee_search: tableState?.employee_search,
          }
        : {}),
      year: tableState?.year,
    });
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          pagination: {
            ...tableState?.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const handlePaginate = (page = tableState?.pagination?.currentPage) => {
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          pagination: {
            ...tableState?.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  const columnDefs = useMemo(() => {
    return [
      // {
      //   headerValueGetter: () => t("name"),
      //   field: "name",
      //   minWidth: 220,
      //   pinned: user?.lng === "ar-EG" ? "right" : "left",
      //   lockPinned: true,
      //   valueFormatter: (cell) => (cell.value ? cell.value : null),
      // },
      {
        headerValueGetter: () =>
          isColumnActive?.emp_code ? t("employee") : null,
        pinned: user?.lng === "ar-EG" ? "right" : "left",
        hide: true,
        children: [
          {
            headerValueGetter: () => t("name"),
            field: "name",
            minWidth: 220,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
            pinned: "left",
          },
          {
            headerValueGetter: () => t("employee code"),
            field: "emp_code",
            minWidth: 100,
            hide: isColumnActive?.emp_code ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : "---"),
          },
        ],
      },
      {
        headerValueGetter: () => t("office"),
        field: "office",
        minWidth: 200,
        hide: isColumnActive?.office ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("department"),
        field: "department",
        minWidth: 200,
        hide: isColumnActive?.department ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("position"),
        field: "position",
        minWidth: 200,
        hide: isColumnActive?.position ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("holidays"),
        field: "holidays",
        hide: isColumnActive?.holidays ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("Days Off"),
        field: "weekends",
        hide: isColumnActive?.weekends ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("workdays"),
        hide: isColumnActive?.attended_days ? false : true,
        headerClass: "workdays_header_group",
        children: [
          {
            headerValueGetter: () => t("attended"),
            field: "attended_days",
            hide: isColumnActive?.attended_days ? false : true,
          },
          {
            headerValueGetter: () => t("ontime"),
            headerClass: "ontime_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.ontime
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "ontime_total",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "ontime_normal",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "ontime_half",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
          {
            headerValueGetter: () => t("late"),
            headerClass: "late_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.late
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "late_total",
                hide:
                  isColumnActive?.late && isColumnActive?.late_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "late_normal",
                hide:
                  isColumnActive?.late && isColumnActive?.late_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "late_half",
                hide:
                  isColumnActive?.late && isColumnActive?.late_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("duration"),
                field: "late_duration",
                hide:
                  isColumnActive?.late && isColumnActive?.late_duration
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
        ],
      },
      {
        headerValueGetter: () => t("absent"),
        field: "absent_total",
        hide: isColumnActive?.absent_total ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("leaves"),
        headerClass: "leaves_header_group",
        hide:
          isColumnActive?.leaves &&
          (isColumnActive?.normal_leaves ||
            isColumnActive?.emergency_leaves ||
            isColumnActive?.sick_leaves ||
            isColumnActive?.unpaid_leaves ||
            isColumnActive?.half_days)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("normal"),
            field: "normal_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.normal_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            field: "emergency_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.emergency_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("sick"),
            field: "sick_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.sick_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid"),
            field: "unpaid_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.unpaid_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("half-days"),
            field: "half_days",
            hide:
              isColumnActive?.leaves && isColumnActive?.half_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("remaining leaves"),
        headerClass: "remaining_leaves_header_group",
        hide: isColumnActive?.remaningLeaves ? false : true,
        children: [
          {
            headerValueGetter: () => t("annual"),
            minWidth: 120,
            field: "remaining_annual_leaves",
            hide:
              isColumnActive?.remaningLeaves &&
              isColumnActive?.remaining_annual_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            minWidth: 120,
            field: "remaining_emergency_credit",
            hide:
              isColumnActive?.remaningLeaves &&
              isColumnActive?.remaining_emergency_credit
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("Check-in Hours"),
        headerClass: "checkin_header_group",
        hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
        children: [
          {
            headerValueGetter: () => t("duration"),
            minWidth: 120,
            field: "total_checkIn_hours_secs",
            hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("overtime"),
        headerClass: "overtime_header_group",
        hide:
          isColumnActive?.overtime &&
          (isColumnActive?.overtime_days ||
            isColumnActive?.accepted_overtime_hours)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("days"),
            field: "overtime_days",
            hide:
              isColumnActive?.overtime && isColumnActive?.overtime_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "accepted_overtime_hours",
            hide:
              isColumnActive?.overtime &&
              isColumnActive?.accepted_overtime_hours
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("permissions"),
        headerClass: "permissions_header_group",
        hide:
          isColumnActive?.permissionsColumn &&
          (isColumnActive?.permissions || isColumnActive?.permissions_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("permissions"),
            field: "permissions",
            hide:
              isColumnActive?.permissionsColumn && isColumnActive?.permissions
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "permissions_time",
            hide:
              isColumnActive?.permissionsColumn &&
              isColumnActive?.permissions_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("breaks"),
        headerClass: "breaks_header_group",
        hide:
          isColumnActive?.breaksColumn &&
          (isColumnActive?.breaks || isColumnActive?.breaks_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("number"),
            field: "breaks",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "breaks_time",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("penalties"),
        headerClass: "work_hours_header_group",
        hide:
          !isColumnActive?.total_early_sign_out_time &&
          !isColumnActive?.total_penalties_as_days &&
          !isColumnActive?.total_penalties_as_fixed_amount &&
          !isColumnActive?.total_penalties_as_hours,
        children: [
          {
            headerValueGetter: () => t("total early sign out time"),
            field: "total_early_sign_out_sec",
            width: 160,
            hide: isColumnActive?.total_early_sign_out_time ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as days"),
            field: "total_penalties_as_days",
            width: 160,
            hide: isColumnActive?.total_penalties_as_days ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as fixed amount"),
            field: "total_penalties_as_fixed_amount",
            width: 160,
            hide: isColumnActive?.total_penalties_as_fixed_amount
              ? false
              : true,
          },
          {
            headerValueGetter: () => t("total penalties as hours"),
            field: "total_penalties_as_hours",
            width: 160,
            hide: isColumnActive?.total_penalties_as_hours ? false : true,
          },
        ],
      },
      {
        headerValueGetter: () => t("work hours"),
        headerClass: "work_hours_header_group",
        hide:
          isColumnActive?.workHours &&
          (isColumnActive?.total_hours_secs ||
            isColumnActive?.expected_hours_secs)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("total hrs worked"),
            field: "total_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.total_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("expected work hrs"),
            field: "expected_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.expected_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("total overtime hours"),
        field: "total_over_time_hours",
        width: 160,
        hide: isColumnActive?.total_over_time_hours ? false : true,
      },
    ];
  }, [tableState, isColumnActive, user?.lng, t]);

  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit({
      defaultMinWidth: 110,
    });
  }, [tableState, isColumnActive, user?.lng, t]);

  const [clearOrCancel, setClearOrCancel] = useState(false);

  const renderAppliedFilters = () => {
    const selectedEmployees = extraEmployeesFilter?.filter((emp) =>
      variables?.employees?.includes(emp?.id)
    );
    const selectedOffices = user?.offices?.filter((off) =>
      variables?.offices?.includes(off?.id)
    );
    const selectedDepartments = companyDepartments?.filter((dep) =>
      variables?.departments?.includes(dep?.id)
    );
    const selectedYear = Constants?.YearData?.filter(
      (y) => variables?.year === `${y?.value}`
    ).map((y) => ({ name: `${y?.value}` }));

    const employeeSearch = variables?.employee_search
      ? [{ name: variables?.employee_search }]
      : [];

    const onCancelFilter = (name, emptyVal = null) => {
      dispatch(updateValueAction(dataTableRef, name, emptyVal));
      setClearOrCancel(true);
    };

    return (
      <div className="d-flex gap-1 align-items-center flex-wrap">
        <FilterBadge
          label={t("employee search")}
          selectedItems={employeeSearch}
          onCancelFilter={() => onCancelFilter("employee_search")}
        />

        <FilterBadge
          label={t("employees")}
          selectedItems={selectedEmployees}
          onCancelFilter={() => onCancelFilter("employees")}
        />
        <FilterBadge
          label={t("offices")}
          selectedItems={selectedOffices}
          onCancelFilter={() => onCancelFilter("offices")}
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments}
          onCancelFilter={() => onCancelFilter("departments")}
        />
        <FilterBadge
          label={t("year")}
          selectedItems={selectedYear}
          onCancelFilter={() =>
            onCancelFilter("year", "" + new Date().getFullYear())
          }
          hideCancel={selectedYear?.[0]?.name == new Date().getFullYear()}
        />
      </div>
    );
  };

  useEffect(() => {
    if (clearOrCancel) {
      handleFilter();
      setClearOrCancel(false);
    }
  }, [
    tableState?.departments,
    tableState?.employees,
    tableState?.offices,
    tableState?.year,
    tableState?.employee_search,
  ]);

  const appliedFiltersLength =
    (variables?.employees?.length ?? 0) +
    (variables?.offices?.length ?? 0) +
    (variables?.departments?.length ?? 0) +
    (variables?.year !== `${moment().year()}` ? 1 : 0) +
    (variables?.employee_search ? 1 : 0);

  return (
    <div className="content payroll_wrapper_style yearly-report-style no-padding-first-child position-relative">
      <div className="d-lg-flex align-items-center mb-3 mx-0">
        <div className="pl-0 d-flex pr-0 w-100  align-items-center justify-content-between">
          {renderAppliedFilters()}
          <div className="d-flex">
            {appliedFiltersLength ? (
              <div onClick={clearFilters} className="logs-btn clear-btn">
                <ClearIcon color="inherit" fontSize="small" />
                {t("clear")}
              </div>
            ) : null}
            <div
              onClick={toggleDrawer(true)}
              className={`logs-btn ${
                appliedFiltersLength
                  ? "filter-btn-active"
                  : "filter-btn-inactive"
              }`}
            >
              <FontAwesomeIcon icon={faSlidersH} />
              {t("filters")}
              {appliedFiltersLength ? (
                <div className="filter-count-container">
                  {t(appliedFiltersLength)}
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_ATTENDANCE_MONTHLY_REPORT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button className="btn py-0 yearly-export mx-2" type="button">
                    <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                    {t("export")}
                  </button>
                }
              >
                <button
                  className="btn py-0 yearly-export mx-2"
                  type="button"
                  onClick={() =>
                    dispatch(
                      showExportYearlyReportModal({
                        ref: "exportYearlyReportModal",
                      })
                    )
                  }
                >
                  <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                  {t("export")}
                </button>
              </HasPrivileges>

              <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
            </div>
          </div>
        </div>
      </div>

      {tableState?.isLoading ? (
        <div className="loader_wrapper_style position-absolute">
          <Loader />
        </div>
      ) : null}

      <AgGridTable
        key={user?.lng}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={tableState?.data}
        className="yearlyreport-table"
        enableSorting={true}
        tableFetcherAction={fetchYearlyReportListAction}
        dataTableRef={dataTableRef}
        tableState={tableState}
        gridOptions={{
          enableRtl: user?.lng === "ar-EG" ? true : false,
        }}
      />

      <Pagination
        tableRef={dataTableRef}
        styleWraper=""
        onPaginate={handlePaginate}
        reducer="super"
      />

      {/* Start of Toggled Modal */}
      <ToggleModal
        isModalVissible={isToggleModalVissible}
        toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
      />
      {/* End of Toggled Modal  */}

      {/* Start of Export Modal */}
      <ExportModal
        column={tableState?.orderBy?.column}
        order={tableState?.orderBy?.order}
      />
      {/* End of Export Modal  */}

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={dataTableRef}
        isLoading={optionsLoading}
        formData={tableState}
        employeesOptions={extraEmployeesFilter}
        officesOptions={user.offices}
        positionsOptions={[]}
        departmentsOptions={companyDepartments}
        hideFromTo
        hidePositions
      >
        <InputForm
          formName={dataTableRef}
          labelStyle="mb-2"
          label={t("employee search")}
          name="employee_search"
          icon="search"
          type="search"
          placeholder={t("employee search")}
          value={tableState?.employee_search?.trimStart()}
          inputContainerStyle="w-100"
        />

        <NewMultiSelect
          name="year"
          optionLabel="value"
          optionValue="value"
          options={Constants.YearData?.map((y) => ({ value: "" + y.value }))}
          dataTableRef={dataTableRef}
          label={t("Year")}
          icon="calendar"
          placeHolder={t("select year")}
          value={tableState?.year}
          isLoading={tableState?.isLoading}
          allowClear={false}
          isSingle
        />
      </FiltersDrawer>
    </div>
  );
};

export default YearlyReport;
