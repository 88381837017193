import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LeadProgressListQuery } from "../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import moment from "moment";
import { showToast } from "../../Store/Actions";

const LeadProgressList = (props) => {
  const { t } = useTranslation();

  const [progessList, setProgessList] = useState([]);
  const { loading: isListLoading } = useQuery(LeadProgressListQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      setProgessList(res?.leadProgress);
    },
    variables: { lead_id: props?.data?.id },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = [
    { name: t("type"), cell: ({ type }) => type },
    { name: t("agent"), cell: ({ agent }) => agent },
    {
      name: t("description"),
      cell: ({ description }) =>
        description?.includes("http") ? (
          <a href={description} target="_blank" rel="noopener noreferrer">
            {description}
          </a>
        ) : (
          description
        ),
    },
    { name: t("status"), cell: ({ status }) => status },
    { name: t("sub_status"), cell: ({ sub_status }) => sub_status },
    {
      name: t("date"),
      cell: ({ date }) => moment(date).format("DD-MM-YYYY hh:mm a"),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-4 mb-4"></div>
      {/* List */}
      <DataTable
        noHeader
        data={progessList}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
      />
    </>
  );
};

export default LeadProgressList;
