import gql from "graphql-tag";

export * from "./CRM";
export * from "./Settings";
export * from "./Attendance";
export * from "./PaymentTracking";

export const attProfileFragment = gql`
  fragment attProfile on Att_profile {
    id
    name
    profile_type
    week_start_day
    weekends
    allow_multiple_check_ins
    default_office_holiday_profile
    attendance_profile_work_timing_input: attendance_profile_work_timings {
      id
      work_timing {
        id
        name
        work_timing_profile_type
        work_hours
        sign_in_start_time
        sign_in_end_time
        sign_in_req
      }
      first_half_work_timing {
        id
        name
        work_timing_profile_type
        work_hours
        sign_in_start_time
        sign_in_end_time
        sign_in_req
      }
      second_half_work_timing {
        id
        name
        work_timing_profile_type
        work_hours
        sign_in_start_time
        sign_in_end_time
        sign_in_req
      }
      start
      end
      can_edit
      allow_half_day

      cost_center {
        id
        name
      }
    }
    allow_work_on_day_off

    check_in_distance_setting

    checkInSetting {
      id
      face_recognition_in
      face_recognition_out
      max_distance
      workplace_setting
      workplace_setting_signout
      workplace_setting_identical
      workPlaces
      workPlacesIn
      workPlacesOut
    }
  }
`;

export const userProfileFragment = gql`
  fragment userProfile on User {
    id
    name
    isFoodicsUser
    has_temporary_password
    active
    img_path
    isManager
    profile_picture {
      path
      id
    }

    lng: locale
    working_status
    email_verified
    phone_verified
    position {
      name
      id
    }
    has_credentials
    email
    phone
    checkPassword
    offices {
      id
      name
      head_quarter
      company {
        id
        name
      }
      currency {
        id
      }
      currenciesWithDefaultCurrency {
        id
        name
        symbol
        defaultCurrencyOffice
      }
      country {
        id
      }
    }
    company {
      id
      name
      hasActiveSubscription
      allow_multiple_offices_start
      getMonthBoundaries {
        from
        to
      }
      activeSubscription: currentSubscription {
        plan {
          id
          features {
            id
            pivot {
              limits
            }
          }
          privileges {
            id
          }
        }
      }
      allow_cost_center
    }
    starts_at
    joining_date
    department {
      name
      id
    }
    manager {
      name
      id
    }
    copied_managers {
      name
      id
    }
    userPettyCash {
      id
      emp_id
    }
    loans {
      id
    }
    emp_multiple_sign_ins: card_details {
      sign_in_time
      sign_out_time
      sign_in_location_name
      sign_out_location_name
      status
      work_timing_name
    }
    office {
      id
      name
      time_zone
      getRelevantYear
      country {
        id
      }
    }
  }
`;

export const employeeFragment = gql`
  fragment employeeProfile on User {
    __typename
    id
    name
    emp_code
    user_alias
    is_blacklisted
    saas {
      id
      user_id
      role_id
      primaryCompaniesCount
      secondaryCompaniesCount
    }
    active
    img_path
    user_type
    profile_picture {
      path
      id
    }
    face {
      path
      id
    }
    face_path
    working_status
    on_break
    attendance_profile {
      activeWorkTiming {
        sign_in_req
      }
      isHalfDayAvailable
    }
    position {
      name
      id
    }
    email
    phone
    office {
      name
      id
      country {
        id
      }
      payment_intervals
      getRelevantYear
    }
    starts_at
    joining_date
    department {
      name
      id
    }
    manager {
      name
      id
    }
    copied_managers {
      name
      id
    }
    sign_in_time
    sign_out_time
    from_home
    phone_verified
    email_verified
    checkPassword
    has_credentials
    userPettyCash {
      id
      emp_id
    }
    loans {
      id
    }
    date_of_birth
    marital_status
    gender
    national_id
    insurance_number
  }
`;

export const employeeListFragment = gql`
  fragment employeeProfile on User {
    __typename
    id
    name
    emp_code
    user_alias
    is_blacklisted
    active
    img_path
    user_type
    profile_picture {
      path
      id
    }
    face {
      path
      id
    }
    face_path
    working_status
    on_break

    position {
      name
      id
    }
    email
    phone
    office {
      name
      id
      payment_intervals
      country {
        id
      }
    }
    starts_at
    joining_date
    department {
      name
      id
    }
    manager {
      name
      id
    }
    copied_managers {
      name
      id
    }
    sign_in_time
    sign_out_time
    from_home
    phone_verified
    email_verified
    checkPassword
    has_credentials

    date_of_birth
    marital_status
    gender
    national_id
    insurance_number
  }
`;

export const userPenaltiesFragment = gql`
  fragment userPenalties on Penalty {
    __typename
    id
    order
    penalty_reason
    date
    deducted_from
    quantity
    created_at
    quantity_unit
    apply_flag
    canEdit
    full_cause
    edited
    canApply
    hasComplementaryPenalty
  }
`;

export const extraMonthlyPayrollFieldsFragment = gql`
  fragment extraMonthlyPayrollFields on ExtraFieldsInPayroll {
    users_count
    monthly_base_salary: sum_monthly_base_salary
    monthly_allowances: sum_monthly_allowances
    bonuses_amount: sum_bonuses
    overtime_amount: sum_overtime
    claims_amount: sum_claims
    penalties_amount: sum_penalties
    monthly_social: sum_monthly_social
    monthly_medical: sum_monthly_medical
    monthly_taxes: sum_monthly_taxes
    monthly_deductibles: sum_monthly_deductibles
    monthly_payable_amount: sum_monthly_payable_amount
    monthly_total_net_paid
    monthly_total_paid
    monthly_pending_payment
  }
`;
export const extraPayrollFieldsFragment = gql`
  fragment extraPayrollFields on ExtraFieldsInPayroll {
    users_count
    bonuses_amount: sum_bonuses
    overtime_amount: sum_overtime
    claims_amount: sum_claims
    penalties_amount: sum_penalties
    base_salary: sum_base_salary
    allowances_amount: sum_allowances
    social_amount: sum_social
    medical_amount: sum_medical
    taxes_amount: sum_taxes
    all_other_deductibles_amount: sum_deductibles
    payable_amount: sum_payable_amount
    total_net_paid
    total_paid
    pending_payment
  }
`;

export const userRequestsFragment = gql`
  fragment userRequests on Request {
    id
    add_info
    status
    time_from
    time_to
    number_of_comments
    req_day
    payment_flag
    canCancel
    progress_indicator
    include_weekends_and_holidays_in_custom_requests
    requestType {
      id
      name
    }
    payable_at
    payable_interval_in_time
    interval_in_time
    sign_in_out {
      id
    }
    subModel {
      ... on OverTime {
        __typename
        can_edit_overtime_request
        overtime_payable_at
      }
    }
    documents {
      file {
        id
        path
      }
    }
    taken
    sign_in_time
    sign_out_time
    from_home
    day_type
    sign_in_status
    actual_sign_in_out {
      actualSignIn
      actualSignOut
    }
    action_by {
      name
      id
      user_type
      copied_managers {
        id
      }
      manager {
        id
      }
    }
    applicable {
      __typename
      ... on Exception {
        assignment {
          name
        }
      }
    }
    requestables {
      requestable {
        ... on AttendanceProfileWorkTiming {
          __typename
          id
          work_timing {
            name
          }
        }
        ... on Exception {
          __typename
          id
          normalWorkTiming {
            name
          }
        }
        ... on ScheduleDetailEmployee {
          __typename
          id
          ScheduleDetail {
            WorkTiming {
              name
            }
          }
        }
      }
    }
    layer_approvals {
      level
      acceptor {
        id
        name
      }
      isApproved
    }

    pending_acceptors
    workTimingId {
      id
      name
    }
  }
`;

export const officeFragment = gql`
  fragment officeInfo on Office {
    id
    name
    address
    time_zone
    created_at
    head_quarter
    allow_sign_in_using_office_account
    ignore_location
    user_id
    is_workplace
    country {
      id
      name
    }
    tax_settlement_interval
    ips {
      value: ip_address
    }
    locations {
      id
      name
      lng
      lat
      address
      max_distance
      is_office
    }
    holiday_profile {
      holidays {
        id
        name
        start_date
        end_date
      }
    }
    taxation: regulationType {
      name
      id
    }
    getMonthStartDay
    email_notification_locale
    week_start_day
    payment_intervals
    configurations {
      type
      from
      user {
        name
      }
      created_at
    }

    canEditMonthStartDay

    costCenter {
      id
    }
  }
`;

export const departmentFragment = gql`
  fragment departmentInfo on Department {
    id
    name
    created_at
    manager {
      id
      name
    }
  }
`;
export const positionFragment = gql`
  fragment positionInfo on Position {
    id
    name
    created_at
  }
`;

export const payrollFragment = gql`
  fragment payroll on PayrollView {
    id
    name
    office_id
    month_year_payroll
    payroll_id
    base_salary
    allowances_amount
    bonuses_amount
    overtime_amount
    claims_amount
    penalties_amount
    social_amount
    social_fixed
    medical_amount
    medical_fixed
    taxes_amount
    taxes_fixed
    all_other_deductibles_amount
    currency_id
    payable_amount
    payment_status
    config_type
    user {
      id
      name
      manager {
        id
      }
      copied_managers {
        id
      }
    }
    currency {
      id
      name
      symbol
    }
  }
`;

export const payrollMonthlyFragment = gql`
  fragment payrollMonthly on PayrollView {
    id
    name
    office_id
    month_year_payroll
    payroll_id
    monthly_base_salary
    monthly_allowances
    bonuses_amount
    overtime_amount
    claims_amount
    penalties_amount
    monthly_social
    social_fixed
    monthly_medical
    medical_fixed
    monthly_taxes
    taxes_fixed
    monthly_deductibles: all_monthly_other_deductibles
    currency_id
    monthly_payable_amount
    payment_status
    config_type
    user {
      id
      name
      manager {
        id
      }
      copied_managers {
        id
      }
    }
    currency {
      id
      name
      symbol
    }
  }
`;

export const monthlyReportFragment = gql`
  fragment monthlyReport on MothlyReport {
    user_id
    name
    office
    department
    position
    month_year
    accepted_monthly_overtime
    holidays
    weekends
    attended_days
    ontime_total
    ontime_normal
    ontime_half
    late_total
    late_normal
    late_half
    absent_total
    absent_normal
    absent_half
    normal_leaves
    emergency_leaves
    sick_leaves
    unpaid_leaves
    half_days
    late_duration
    permissions
    permissions_time
    overtime_days
    accepted_overtime_hours
    breaks
    breaks_time
    expected_hours_secs
    total_hours_secs
    total_checkIn_hours_secs
    total_early_sign_out_sec: total_early_sign_out_time
    total_penalties_as_days
    total_penalties_as_fixed_amount
    total_penalties_as_hours
    total_hours_with_out_over_time_sec
    emp_code
    total_over_time_hours
    mocked_location_in_count
    mocked_location_out_count
    spoofed_sign_in_image_count
    spoofed_sign_out_image_count
  }
`;

export const yearlyReportFragment = gql`
  fragment yearlyReport on YearlyReport {
    user_id
    name
    office
    department
    position
    month_year
    holidays
    weekends
    attended_days
    ontime_total
    ontime_normal
    ontime_half
    late_total
    late_normal
    late_half
    absent_total
    absent_normal
    absent_half
    normal_leaves
    emergency_leaves
    sick_leaves
    unpaid_leaves
    half_days
    late_duration
    permissions
    permissions_time
    total_checkIn_hours_secs
    overtime_days
    accepted_overtime_hours
    breaks
    breaks_time
    expected_hours_secs
    total_hours_secs
    remaining_annual_leaves
    remaining_emergency_credit
    total_early_sign_out_sec: total_early_sign_out_time
    total_penalties_as_days
    total_penalties_as_fixed_amount
    total_penalties_as_hours
    emp_code
    total_over_time_hours
  }
`;

export const workTimeFragment = gql`
  fragment workTime on WorkTiming {
    overtime_type
    overtime_value
    sign_in_req
    consider_permissibility_hours
    id
    name
    weight
    work_timing_profile_type

    sign_in_start_time
    sign_in_end_time
    work_hours
    min_work_hours
    end_of_day
    time_allowed_before_sign_in

    apply_minor_tardiness
    late_reset_period
    late_tardiness_penalty_type
    max_lateness_permissibility
    minor_tardiness_range
    minor_violations {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness
    major_tardiness_range
    major_violations {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness1
    major_tardiness_range1
    major_violations1 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness2
    major_tardiness_range2
    major_violations2 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness3
    major_tardiness_range3
    major_violations3 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness4
    major_tardiness_range4
    major_violations4 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness5
    major_tardiness_range5
    major_violations5 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness6
    major_tardiness_range6
    major_violations6 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness7
    major_tardiness_range7
    major_violations7 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness8
    major_tardiness_range8
    major_violations8 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness9
    major_tardiness_range9
    major_violations9 {
      id
      value
      penalty_type
      quantity_unit
    }

    apply_major_tardiness10
    major_tardiness_range10
    major_violations10 {
      id
      value
      penalty_type
      quantity_unit
    }

    absent_reset_period
    emergency_penalty_type
    absent_violations {
      id
      value
      penalty_type
      quantity_unit
    }

    early_signout_reset_period
    early_signout_deduction_type

    apply_missing_sign_out
    missing_sign_out_reset_period
    missing_sign_out_deduction_type
    missing_sign_out_violations {
      id
      value
      penalty_type
      quantity_unit
    }

    allow_early_signout
    skip_not_applied_early_sign_out_penalties
    early_signout_range_to
    early_sign_out_violations {
      id
      value
      penalty_type
      quantity_unit
    }

    allow_early_signout1
    skip_not_applied_early_sign_out_penalties1
    early_signout_range_to1
    early_sign_out_violations1 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout2
    skip_not_applied_early_sign_out_penalties2
    early_signout_range_to2
    early_sign_out_violations2 {
      id
      value
      penalty_type
      quantity_unit
      order
    }
    allow_early_signout3
    skip_not_applied_early_sign_out_penalties3
    early_signout_range_to3
    early_sign_out_violations3 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout4
    skip_not_applied_early_sign_out_penalties4
    early_signout_range_to4
    early_sign_out_violations4 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout5
    skip_not_applied_early_sign_out_penalties5
    early_signout_range_to5
    early_sign_out_violations5 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout6
    skip_not_applied_early_sign_out_penalties6
    early_signout_range_to6
    early_sign_out_violations6 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout7
    skip_not_applied_early_sign_out_penalties7
    early_signout_range_to7
    early_sign_out_violations7 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout8
    skip_not_applied_early_sign_out_penalties8
    early_signout_range_to8
    early_sign_out_violations8 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout9
    skip_not_applied_early_sign_out_penalties9
    early_signout_range_to9
    early_sign_out_violations9 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    allow_early_signout10
    skip_not_applied_early_sign_out_penalties10
    early_signout_range_to10
    early_sign_out_violations10 {
      id
      value
      penalty_type
      quantity_unit
      order
    }

    absent_early_sign_out
    sign_out_cuttoff_time

    overtime_permissible
    min_overtime_cuttoff
    # overtime_payment_factor
    overtime_limit
    # max_overtime_per_month
    max_overtime_per_day

    face_rekognition
    # apply_facial_challenge_to_sign_in
    home_face_rekognition
    remote_sign_in
    sign_in_from_office_ips
    allow_sign_in_outside_specified_locations
    allow_sign_out_outside_specified_locations
    sign_in_from_office_geolocation
    allow_locations_custom_override

    same_policies
    remote_sign_out
    sign_out_from_office_ips
    sign_out_from_office_geolocation
    sign_out_locations_custom_override

    auto_sign_out
    auto_sign_out_time
    sign_in_reminder_time
    sign_out_reminder
    sign_out_reminder_time

    skip_not_applied_absent_penalties
    skip_not_applied_minor_penalties
    skip_not_applied_major_penalties
    skip_not_applied_major_penalties1
    skip_not_applied_major_penalties2
    skip_not_applied_major_penalties3
    skip_not_applied_major_penalties4
    skip_not_applied_major_penalties5
    skip_not_applied_major_penalties6
    skip_not_applied_major_penalties7
    skip_not_applied_major_penalties8
    skip_not_applied_major_penalties9
    skip_not_applied_major_penalties10

    skip_not_applied_missing_sign_out_penalties
    getActiveAttendanceProfiles {
      id
      name
    }
    calc_sign_out_time_from_actual_sign_in
    can_not_sign_out_before
    can_not_sign_in_after

    tardiness_type_sign_in
    tardiness_type_sign_out
    annual_leave_hours_sign_in

    override_break_setting
    break_work_timing_setting: breakWorkTimingSetting {
      break_work_timing_setting_id: id
      allow_break
      deduct_from_work_hours
      break_type
      break_interval {
        start
        end
      }
      start_break
      can_end_break
      allow_limit_total_break
      number_of_allowed_break_sessions_per_day
      maximum_break_session_duration
      maximum_break_duration
    }
    limit_access
  }
`;

export const requestResponseFragment = gql`
  fragment reqUnionResponse on RequestUnion {
    ... on Request {
      __typename
      id
    }
    ... on GeneralException {
      __typename
      message
    }
    ... on PaidPayrollException {
      __typename
      message
    }
  }
`;

export const updateSuspensionResponseFragment = gql`
  fragment updateSuspensionResponse on UpdateSuspensionUnion {
    ... on Suspension {
      __typename
      id
    }
    ... on GeneralException {
      __typename
      message
    }
    ... on EmployeeSuspendedException {
      __typename
      message
    }
  }
`;

export const initSuspensionUnionFragment = gql`
  fragment initSuspension on InitSuspensionUnion {
    ... on InitSuspensionResponse {
      __typename
      deserved {
        annual
      }
      used {
        annual
      }
      remaining {
        annual
      }
      onbaording_activation_balance
    }
    ... on GeneralException {
      __typename
      message
    }
    ... on EmployeeSuspendedException {
      __typename
      message
    }
  }
`;

export const checkInOutsResponseFragment = gql`
  fragment checkInUnionResponse on CheckInUnion {
    ... on CheckIn {
      __typename
    }
    ... on GeneralException {
      __typename
      message
    }
    ... on OpenCheckInException {
      __typename
      message
    }
    ... on InvalidLocationException {
      __typename
      message
    }
    ... on CheckInsIntersectionException {
      __typename
      message
    }
  }
`;

export const payInstallmentUnionResponseFragment = gql`
  fragment payInstallmentUnionResponse on LoanAndInstallmentUnion {
    ... on StatusResponse {
      __typename
      status
      message
    }
    ... on Installment {
      __typename
      actual_payment_time
    }
  }
`;

export const revertPaymentInstallmentUnionResponseFragment = gql`
  fragment revertPaymentInstallmentUnionResponse on LoanAndInstallmentUnion {
    ... on StatusResponse {
      __typename
      status
      message
    }
    ... on Installment {
      __typename
    }
  }
`;

export const deleteInstallmentUnionResponseFragment = gql`
  fragment deleteInstallmentUnionResponse on LoanAndInstallmentUnion {
    ... on StatusResponse {
      __typename
      status
      message
    }
    ... on Loan {
      __typename
      id
      name
      amount
      granted_on
      notes
      installment_number
      include_payroll
      installments {
        id
        amount
        payment_date
      }
      user {
        id
      }
      currency {
        id
      }
    }
  }
`;

export const attendanceLogFragment = gql`
  fragment attendanceLog on Sign_in_out {
    id
    __typename
    date
    sign_in_time
    work_day_hours
    signInSpace {
      name
    }
    sign_out_time
    signOutSpace {
      name
    }
    signInOnBehalfBy {
      name
    }
    signOutOnBehalfBy {
      name
    }
    auto_signed_out
    status
    from_home
    sign_in_lng
    sign_in_lat
    sign_out_lng
    sign_out_lat
    isToday
    early_late
    early_late_time
    sign_in_face {
      id
      path
    }
    work_timing {
      __typename
      id
      name
      sign_in_req
    }

    sign_out_face {
      id
      path
    }
    day_type
    day_sub_type
    created_at
    creator {
      name
    }
    signed_in_outside_specified_locations
    signed_out_outside_specified_locations
    employee {
      name
      id
      balance {
        remaining_annual_leaves
        remaining_emergency_leaves
      }
      manager {
        id
      }
      copied_managers {
        id
      }
    }
    hasPendingEditAttendanceRequests
    checkIns {
      __typename
      id
      sign_in_id
      check_in_time
      check_in_lng
      check_in_lat
      check_in_space_id
      check_in_description
      check_out_time
      check_out_lng
      check_out_lat
      check_out_space_id
      check_out_description
      checkInFiles {
        path
        id
      }
      checkOutFiles {
        path
        id
      }
      checkInSpace {
        id
        name
      }
      checkOutSpace {
        id
        name
      }
      checkInFace {
        id
        path
      }
      checkOutFace {
        id
        path
      }
      checkInFormAnswers {
        answer
        checkInOutFormField {
          name
        }
      }
      checkOutFormAnswers {
        answer
        checkInOutFormField {
          name
        }
      }
    }
    canEdit
    penalties {
      ...userPenalties
    }

    getSignInConfigType {
      __typename
      ... on Exception {
        id
        assignment {
          treat_as_normal
        }
      }
      ... on ScheduleDetailEmployee {
        id
      }
      ... on AttendanceProfileWorkTiming {
        id
      }
    }
    penalties {
      id
      penalty_reason
    }

    breakInOuts {
      id
      break_in_time
      break_out_time
      logs {
        id
        event
        description
        created_at: converted_created_at
      }
    }
    break_setting {
      allow_break
    }
    logs {
      id
    }
  }

  ${userPenaltiesFragment}
`;

export const employeeHistoryFragement = gql`
  fragment employeeHistoryFragement on Sign_in_out {
    id
    date
    sign_in_time
    work_day_hours
    signInSpace {
      name
    }
    sign_out_time
    signOutSpace {
      name
    }
    auto_signed_out
    sign_in_lng
    sign_in_lat
    sign_out_lng
    sign_out_lat
    status
    from_home
    created_at
    creator {
      name
    }
    employee {
      id
      name
      attendance_profile {
        attendance_profile_work_timings {
          end
          start
          work_timing {
            sign_in_req
          }
        }
      }
    }
    hasPendingEditAttendanceRequests
    day_type
    checkIns {
      id
      sign_in_id
      check_in_time
      check_in_lng
      check_in_lat
      check_in_space_id
      check_in_description
      check_out_time
      check_out_lng
      check_out_lat
      check_out_space_id
      check_out_description
      checkInSpace {
        id
        name
      }
      checkOutSpace {
        id
        name
      }
    }
    canEdit
    penalties {
      id
      penalty_reason
    }
    work_timing {
      sign_in_req
    }
  }
`;

export const employeeAttendanceLogFragment = gql`
  fragment employeeAttendanceLog on Sign_in_out {
    id
    sign_in_time
    sign_out_time
    work_day_hours
    date
    status
    from_home
    sign_in_lng
    sign_in_lat
    day_type
    employee {
      name
      id
      attendance_profile {
        week_start_day
        attendance_profile_work_timings {
          end
          start
          work_timing {
            sign_in_req
          }
        }
      }
    }
  }
`;

export const leaveBreaksFragment = gql`
  fragment leaveBreaks on PermissionLeavesBreakSetting {
    id
    name
    annualLeaveSetting {
      id
      add_leaves_at
      applicable_type
      add_leaves_every
      holiday_hours_count
      day_off_hours_count
      start_week_day_number
      leave_basis_type
      deserved_against {
        name
      }
      total_weighted_days
      total_weighted_hours
      allow_annual_leaves
      allowed_annual_leaves
      annual_leave_cuttoff
      allowed_emergency_leaves
      include_weekends_and_holidays_in_annual
      leave_basis
      annual_leaves_deserved_against
      deduct_leaves_against_unpaid_leaves
      deduct_leaves_against_paid_leaves
      deduct_leaves_against_absent_days
    }
    probationPeriodConfiguration {
      id
      request_leaves
    }
    settleLeavesConfigurations {
      id
      remaining_balance_option_id
      rollover_balance_automatic
      max_number_of_leaves_to_be_rolled
      next_year
      payable_at
      rollover_expires_after
    }
    sickLeaveSetting {
      id
      allow_sick_leaves: sick_leaves
      allowed_sick_leaves
      include_weekends_and_holidays_in_sick
      allow_sick_leave_cutoff
      sick_leave_cutoff
      is_deductible
      payment_factor_id
      deduction_type
      deduction_amount
    }
    unpaidLeaveSetting {
      id
      allow_unpaid_leaves: unpaid_leaves
      include_weekends_and_holidays_in_unpaid
      allow_unpaid_leave_cutoff
      unpaid_leave_cutoff
    }
    permissionSetting {
      id
      allow_permissions
      min_permission_unit
      max_units_per_day
      max_units_per_month
      allow_permission_cutoff
      permission_cutoff
    }
    unpaidPermissionSetting {
      id
      allow_permissions
      min_permission_unit
      max_units_per_day
      max_units_per_month
      allow_permission_cutoff
      permission_cutoff
    }
    breakSetting {
      id
      allow_break
      number_of_allowed_break_sessions_per_day
      maximum_break_session_duration
      maximum_break_duration
      deduct_from_work_hours
    }
    customLeaveSetting {
      id
      allow_custom_leave
      bereavement_leave
      maternal_paternal_leave
      hajj_leave
      sabbatical_leave
      study_leave
      marriage_leave
      in_lieu_of_work_day_leave
      military_call_leave
    }
    submitRequestAbility {
      id
      annual_emergency_leave
      annual_emergency_half_day
      unpaid_leave
      unpaid_half_day
      sick_leave
      permission
      unpaid_permission
    }
  }
`;
export const checkInOutFragment = gql`
  fragment checkInOut on CheckInOutForm {
    id
    name
    checkInOutFormFields {
      id
      name
      type
      canDelete
    }
  }
`;

export const paginatorFragment = gql`
  fragment paginator on PaginatorInfo {
    total
    count
    perPage
    lastPage
    lastItem
    firstItem
    currentPage
    hasMorePages
  }
`;
export const simplePaginatorFragment = gql`
  fragment simplePaginator on SimplePaginatorInfo {
    count
    perPage
    lastItem
    firstItem
    currentPage
    hasMorePages
  }
`;

export const taxInfoFragment = gql`
  fragment taxInfo on TaxInfo {
    __typename
    id
    name
    from
    min_insurance_salary
    max_insurance_salary
    employee_insurance_percentage
    employer_insurance_percentage
    personal_exemption
    insurance_salary_percentage
    temporary_employment_percentage
    brackets {
      __typename
      id
      start
      end
      percentage
    }
  }
`;

export const authUserFragment = gql`
  fragment authUser on User {
    isFoodicsUser
    profile_picture {
      path
      id
    }
    saas {
      id
    }
    user_type
    id
    name
    email
    phone
    lng: locale
    prviliege_ids
    prviliege_ids_with_all_employee_scope
    prviliege_ids_with_managed_employee_scope
    active
    hasActiveCompany
    office {
      name
      id
      time_zone
      company {
        name
        id
      }
      country {
        id
      }
    }
    offices {
      id
      name
      head_quarter
      company {
        id
        name
      }
      currency {
        id
      }
      currenciesWithDefaultCurrency {
        id
        name
        symbol
        defaultCurrencyOffice
      }
      country {
        id
      }
    }
    company {
      name
      id
      canAddNewEmployee
      hasActiveSubscription
      allow_multiple_offices_start
      getMonthBoundaries {
        from
        to
      }
      activeSubscription: currentSubscription {
        plan {
          id
          features {
            id
            pivot {
              limits
            }
          }
          privileges {
            id
          }
        }
      }
      allow_cost_center
    }
    has_temporary_password
  }
`;

export const dayOffHolidayExceptionConfigFragment = gql`
  fragment exceptionConfig on DayOffExceptionConfig {
    id
    weight
    apply_compensation
    compensation_type
    payment_factor_id
    compensation_quantity
    treat_as_normal
    allow_permission
    allow_overtime
    # allow_home
    normalWorkTiming {
      id
      name
    }
    firstHalfWorkTiming {
      id
      name
    }
    secondHalfWorkTiming {
      id
      name
    }
    cost_center {
      id
      name
    }
  }
`;

export const presetFragment = gql`
  fragment preset on Preset {
    id
    name
    payment
    fixedSettings: employeeTimeRangeProfilePreset {
      allow_overtime
      overtime_limit
      overtime_cutoff
      min_overtime_cutoff
      max_overtime_limit
      min_work_hours
      overtime_factor
      overtime_hour_rate
    }
    details {
      type
      typeConfiguration {
        ... on SalaryConfiguration_TypeConfiguration_Fulltime {
          salary
          overtimeConfiguration {
            paymentFactor
            value
            factor
            periods {
              from
              to
              factor
            }
          }
        }
        ... on SalaryConfiguration_TypeConfiguration_Day {
          rpd
          overtimeConfiguration {
            paymentFactor
            value
            factor
            periods {
              from
              to
              factor
            }
          }
        }
        ... on SalaryConfiguration_TypeConfiguration_Hour {
          allHours
          hppl
          rpph
          periods {
            from
            to
            day
            rph
          }
          overtimeConfiguration {
            paymentFactor
            value
            factor
            periods {
              from
              to
              factor
            }
          }
        }
      }
      penaltyConfiguration {
        paymentFactor
        oneHour
        value
        monthDays
      }
      unpaidConfiguration {
        paymentFactor
        value
        monthDays
      }

      bonusConfiguration {
        paymentFactor
        value
        monthDays
      }

      allowanceConfiguration {
        id
        name
        paymentFactor
        value
        monthDays
        payableOn
      }

      deductionConfiguration {
        id
        name
        paymentFactor
        value
        monthDays
      }

      taxDeduction

      socialDeduction

      medicalDeduction

      currency {
        id
        name
      }

      temp

      taxRegulation {
        typeId
        taxInfo
      }
    }
  }
`;
