import React from "react";
import { useTranslation } from "react-i18next";

import { periodToDuration } from "../../Helpers/HelperFns/PaymentTracking";

import MainModal from "../MainModal";
import Divider from "@mui/material/Divider";

const handleSingleDigit = (d) => (String(d).length > 1 ? d : "0" + String(d));

const PreviewPlanTiersModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle={data?.name}
      hasModalFooter={false}
    >
      <div
        className="d-flex justify-content-between border p-2"
        style={{ borderRadius: 4 }}
      >
        <div>
          <b className="dark-color d-block mb-2">{t("Payment Type")}</b>
          {data?.paymentType}
        </div>
        <div>
          <b className="dark-color d-block mb-2">{t("Billing Period")}</b>
          {data?.contractDuration + " " + periodToDuration(data?.billingPeriod)}
        </div>
        <div>
          <b className="dark-color d-block mb-2">{t("Package")}</b>
          {data?.package}
        </div>
      </div>

      <b className="dark-color d-block mt-3 mb-1">{t("Tiers")}</b>
      <div className="d-flex gap-20 mb-2">
        <span className="flex-1">{t("Employees")}</span>
        <span className="w-25">{t("Price")}</span>
        <span className="w-25">{t("Name")}</span>
      </div>
      <div className="border p-2" style={{ borderRadius: 4 }}>
        {data?.tiers.map(({ from, to, price, name }, i) => (
          <React.Fragment key={i}>
            <div className="d-flex gap-20">
              <div className="d-flex gap-20 flex-1">
                <span>{handleSingleDigit(from)}</span>
                <span>-</span>
                <span>{handleSingleDigit(to)}</span>
              </div>
              <span className="w-25">{price}</span>
              <span className="w-25">{name}</span>
            </div>

            {data?.tiers.length - 1 !== i ? (
              <Divider
                light
                className="my-1"
                sx={{
                  left: -8,
                  position: "relative",
                  width: "calc(100% + 16px)",
                }}
              />
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </MainModal>
  );
};

export default PreviewPlanTiersModal;
