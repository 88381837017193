import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { changePaymentStatusMutation } from "../../Graphql/mutation/PaymentTracking";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const buildOptions = (options = []) => options.map((o) => ({ id: o, name: o }));

const ChangePaymentsStatusModal = ({
  data,
  onClose,
  refetchList,
  setIsClearSelectedRows,
}) => {
  const { t } = useTranslation();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [selectedStatus, setSelectedStatus] = React.useState("");

  // Server State
  const [changeStatus, { loading: changeStatusLoading }] = useMutation(
    changePaymentStatusMutation
  );

  /* ↓ Helpers ↓ */

  const onStatusChange = (opt) => {
    setSelectedStatus(opt);
  };

  const handleSubmit = () => {
    if (selectedStatus?.id) {
      changeStatus({
        variables: {
          status: selectedStatus?.id,
          id: data?.payments?.map((p) => +p?.id),
        },
        onCompleted: () => {
          refetchList();
          setIsClearSelectedRows(true);
          showToast("success");
          onClose();
        },
        onError: (err) => {
          const validation = err?.graphQLErrors?.[0]?.extensions?.validation;

          const msg =
            validation?.json?.[0] ||
            err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message;
          setErrMsg(msg || "");
        },
      });
    } else {
      setErrMsg("This Field Is Required.");
    }
  };

  return (
    <MainModal
      isOpen
      modalTitle="Change Status"
      toggle={onClose}
      btnOnClick={handleSubmit}
      btnSubmitLoading={changeStatusLoading}
    >
      <BSelect
        value={selectedStatus}
        onChange={onStatusChange}
        options={buildOptions(
          data?.options?.stats.filter((opt) => opt.toLowerCase() !== "overdue")
        )}
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default ChangePaymentsStatusModal;
