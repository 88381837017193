import gql from "graphql-tag";
import {
  attProfileFragment,
  userProfileFragment,
  userRequestsFragment,
  officeFragment,
  departmentFragment,
  positionFragment,
  employeeFragment,
  payrollFragment,
  monthlyReportFragment,
  yearlyReportFragment,
  workTimeFragment,
  payrollMonthlyFragment,
  extraPayrollFieldsFragment,
  extraMonthlyPayrollFieldsFragment,
  initSuspensionUnionFragment,
  attendanceLogFragment,
  employeeAttendanceLogFragment,
  employeeHistoryFragement,
  leaveBreaksFragment,
  userPenaltiesFragment,
  checkInOutFragment,
  taxInfoFragment,
  dayOffHolidayExceptionConfigFragment,
  presetFragment,
  employeeListFragment,
} from "../fragments";

export * from "./CRM";
export * from "./Settings";
export * from "./Attendance";
export * from "./SetupWizard";
export * from "./FinanicalQueries";
export * from "./Assets";

export const managersQuery = gql`
  query getUsers {
    company_users(input: { include_auth: 1, status: ["Active"] }) {
      data {
        id
        name
        emp_code
      }
    }
  }
`;

export const attendanceProfileQuery = gql`
  query attProfile($id: ID, $skipAttProfile: Boolean!) {
    attPro: attendance_profile(id: $id) @skip(if: $skipAttProfile) {
      ...attProfile
      checkInForm {
        ...checkInOut
      }
      checkOutForm {
        ...checkInOut
      }
      holiday_profile {
        holidays {
          id
          name
          start_date
          end_date
        }
      }
      dayOffExceptionConfig {
        ...exceptionConfig
      }

      allow_work_on_holiday

      same_exception_config
      holidayExceptionConfig {
        ...exceptionConfig
      }
    }
    holidays(first: 99999) {
      data {
        name
        id
        start_date
        end_date
      }
    }
    work_timings_menu(new_versions_only: true, type: null) {
      id
      name
      work_timing_profile_type
      work_hours
      sign_in_start_time
      sign_in_end_time
      sign_in_req
    }
    workPlaces: locations_menu(first: 999) {
      data {
        id
        name
      }
    }
  }
  ${attProfileFragment}
  ${checkInOutFragment}
  ${dayOffHolidayExceptionConfigFragment}
`;

export const attendanceProfilesQuery = gql`
  query attProfiles(
    $rows: Int!
    $page: Int!
    $filter: String
    $field: String!
    $order: SortOrder!
    $only_archive: Boolean
  ) {
    company_attendance_profiles(
      first: $rows
      page: $page
      input: { name: $filter, orderBy: { column: $field, order: $order } }
      only_archive: $only_archive
    ) {
      data {
        id
        name
        weekends
        created_at
        profile_type
        default_office_holiday_profile
        holiday_profile {
          holidays {
            name
          }
        }
        activeWorkTiming {
          name
        }
        activeConfiguration {
          allow_half_day
          first_half_work_timing {
            name
          }
          second_half_work_timing {
            name
          }
          cost_center {
            name
          }
        }
        week_start_day
        # office {
        #   name
        #   id
        # }
        allow_work_on_day_off
        archive
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    total: company_attendance_profiles(first: 1, page: 1) {
      paginatorInfo {
        total
      }
    }
  }
`;

export const leavesBreaksQuery = gql`
  query breaksLeavesQuery($name: String, $first: Int!, $page: Int) {
    PermissionLeavesBreakSettings(
      input: { name: $name }
      first: $first
      page: $page
    ) {
      data {
        ...leaveBreaks
        children {
          ...leaveBreaks
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${leaveBreaksFragment}
`;

export const allEmployeesQuery = gql`
  query fetchUsers(
    $rows: Int!
    $page: Int!
    $input: UserFilterInput
    $listView: Boolean!
  ) {
    employees: users_by_role(first: $rows, page: $page, input: $input) {
      data {
        ...employeeProfile
        attendance_profile {
          name
          
        }
        employee {
          relevantAttendanceTypeConfiguration {
            type
            workGroup {
              name
              managers {
                id
                name
              }
            }
            workTeam {
              name
            }
          }
        }
        balance @include(if: $listView) {
          remaining_permissions
          remaining_annual_leaves
          remaining_emergency_leaves
          remaining_sick_leaves
          remaining_grace
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    employeesDetails: usersWorkingDetails(
      perPage: $rows
      page: $page
      input: $input
    ) {
      emp_id
      details {
        applicable_type_config_id
        applicable_type_configuration
        date
        exception_id
        from_home
        request_status_name
        request_type_name
        sign_in_id
        sign_in_location_name
        sign_in_req
        sign_in_time
        sign_out_location_name
        sign_out_time
        status
        work_timing_name
        work_timing_id
      }
    }

    total: profile {
      company {
        canAddNewEmployee
      }
    }
    companyDepartmentsMenu {
      id
      name
    }
    managersMenu {
      id
      name
    }
    companyOfficesMenu {
      id
      name
    }

    companyPositionsMenu {
      id
      name
    }

    work_timings_menu(new_versions_only: true) {
      id
      name
      work_timing_profile_type
    }
  }

  ${employeeListFragment}
`;

export const employeeProfileQuery = gql`
  query fetchUser($id: ID, $isExpired: Boolean!) {
    user: userWithWorkingStatus(id: $id) {
      ...employeeProfile
      emp_multiple_sign_ins: card_details {
        sign_in_time
        sign_out_time
        sign_in_location_name
        sign_out_location_name
        status
        work_timing_name
      }
      employee {
        relevantAttendanceTypeConfiguration {
          type
          workGroup {
            name
            managers {
              id
              name
            }
          }
          workTeam {
            name
          }
          workRemotelySettings {
            can_work_home
            can_ex_days
            home_days
            flexible_home
          }
        }
        profileDocuments {
          required_documents {
            document {
              id
            }
          }
          optional_documents {
            document {
              id
            }
          }
        }
      }
      emp_multiple_sign_ins: card_details {
        sign_in_time
        sign_out_time
        sign_in_location_name
        sign_out_location_name
        status
        work_timing_name
      }
      balance {
        remaining_permissions
        remaining_annual_leaves
        remaining_emergency_leaves
        remaining_sick_leaves
        remaining_grace
      }
      starts_at
      joining_date
      employee {
        probationPeriod {
          end_of_probation
        }
        on_probation
        can_request_leave @skip(if: $isExpired)
        can_request_change_shift
        can_request_day_off
        can_request_ignore_assignment
        dateOfSuspensionInFuture
        permission_leaves_break_setting {
          annualLeaveSetting {
            allow_annual_leaves
            allowed_emergency_leaves
          }
          unpaidLeaveSetting {
            unpaid_leaves
          }
          sickLeaveSetting {
            sick_leaves
          }
          probationPeriodConfiguration {
            request_leaves
          }
          permissionSetting {
            allow_permissions
            min_permission_unit
          }
          probationPeriodConfiguration {
            request_leaves
          }
          submitRequestAbility {
            sick_leave
            annual_emergency_leave
          }
        }
      }
      attendance_profile {
        name
        id
        activeWorkTiming {
          overtime_permissible
          name
        }
      }
      company {
        id
        canAddNewEmployee
      }
    }
  }
  ${employeeFragment}
`;

export const GET_USER_SALARY_CONFIGS = gql`
  query getUserSalaryConfigs($id: ID) {
    user(id: $id) {
      id
      active
      attendance_profile {
        activeWorkTiming {
          overtime_permissible
        }
      }
      office {
        id
      }
      salaryConfigurationsAndSuspension {
        ... on Suspension {
          suspensionId: id
          from: start_date
          to: end_date
          created_at
        }
        
        ... on SalaryConfiguration {
          id
          user_id
          from
          to
          type
          payment
          canEdit
          canDelete
          canEdit_from
          fixedSettings {
            allow_overtime
            overtime_limit
            overtime_cutoff
            min_overtime_cutoff
            max_overtime_limit
            min_work_hours
            overtime_factor
            overtime_hour_rate
          }

          details {
            type
            typeConfiguration {
              ... on SalaryConfiguration_TypeConfiguration_Fulltime {
                salary
                overtimeConfiguration {
                  paymentFactor
                  value
                  factor
                  periods {
                    from
                    to
                    factor
                  }
                }
              }
              ... on SalaryConfiguration_TypeConfiguration_Day {
                rpd
                overtimeConfiguration {
                  paymentFactor
                  value
                  factor
                  periods {
                    from
                    to
                    factor
                  }
                }
              }
              ... on SalaryConfiguration_TypeConfiguration_Hour {
                allHours
                hppl
                rpph
                periods {
                  from
                  to
                  day
                  rph
                }
                overtimeConfiguration {
                  paymentFactor
                  value
                  factor
                  periods {
                    from
                    to
                    factor
                  }
                }
              }
            }
            penaltyConfiguration {
              paymentFactor
              oneHour
              value
              monthDays
            }
            unpaidConfiguration {
              paymentFactor
              value
              monthDays
            }
            bonusConfiguration {
              paymentFactor
              value
              monthDays
            }
            allowanceConfiguration {
              id
              name
              paymentFactor
              value
              monthDays
              payableOn
            }
            deductionConfiguration {
              id
              name
              paymentFactor
              value
              monthDays
            }
            taxDeduction
            socialDeduction
            medicalDeduction
            currency {
              id
              name
            }
            temp
            taxRegulation {
              typeId
              taxInfo
            }
          }
        }
      }
    }
  }
`;

export const employeeRequestsQuery = gql`
  query employeeRequests(
    $id: ID
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $status: [Int]
    $from: String
    $to: String
    $route: String
  ) {
    users_requests: users_requests_by_role(
      first: $rows
      page: $page
      input: {
        emp_id: [$id]
        orderBy: { column: $field, order: $order }
        status: $status
        dateRange: { from: $from, to: $to }
        page: $route
      }
    ) {
      data {
        ...userRequests
        __typename
        employee {
          user {
            name
            id
            manager {
              id
            }
            copied_managers {
              id
            }
          }
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }

  ${userRequestsFragment}
`;

export const employeeClaimsQuery = gql`
  query fetchEmployeeClaims(
    $id: ID
    $claimStatus: [ID]
    $from: String
    $to: String
    $claimRows: Int!
    $claimPage: Int
  ) {
    employeeClaims: claims(
      first: $claimRows
      page: $claimPage
      input: { user_id: $id, status: $claimStatus, from: $from, to: $to }
    ) {
      data {
        id
        payroll_at
        amount
        reason
        incurred_at
        actionBy {
          id
          name
        }
        user {
          id
          name
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        currency {
          id
          name
        }
        status
        files {
          id
          path
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const myProfileQuery = gql`
  {
    profile {
      ...userProfile
      employee {
        probationPeriod {
          end_of_probation
        }
        on_probation
        can_request_change_shift
        can_request_day_off
        can_request_ignore_assignment
        relevantWorkTeam {
          id
          name
          workGroup {
            id
            name
          }
        }
        relevantAttendanceTypeConfiguration {
          type
          workGroup {
            name
            managers {
              id
              name
            }
          }
          workTeam {
            name
          }
          workRemotelySettings {
            can_work_home
            can_ex_days
            home_days
            flexible_home
          }
        }
        permission_leaves_break_setting {
          annualLeaveSetting {
            allowed_emergency_leaves
            allowed_annual_leaves
            allow_annual_leaves
          }
          unpaidLeaveSetting {
            unpaid_leaves
          }
          sickLeaveSetting {
            sick_leaves
          }
          permissionSetting {
            allow_permissions
            min_permission_unit
          }
          probationPeriodConfiguration {
            request_leaves
          }
          submitRequestAbility {
            sick_leave
            annual_emergency_leave
          }
        }
      }
      balance {
        remaining_permissions
        remaining_annual_leaves
        remaining_emergency_leaves
        remaining_sick_leaves
        remaining_grace
      }
      attendance_profile {
        name
        id
        activeWorkTiming {
          name
        }
      }
      sign_in_time
      sign_out_time
      from_home
    }
  }

  ${userProfileFragment}
`;

export const myClaimsQuery = gql`
  query fetchMyClaims(
    $claimStatus: [ID]
    $from: String
    $to: String
    $claimRows: Int!
    $claimPage: Int
  ) {
    myClaims: my_claims(
      first: $claimRows
      page: $claimPage
      input: { status: $claimStatus, from: $from, to: $to }
    ) {
      data {
        id
        payroll_at
        amount
        reason
        incurred_at
        actionBy {
          id
          name
        }
        user {
          id
          name
        }
        currency {
          id
          name
        }
        status
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const initEmployeeFormQuery = gql`
  query employeeForm($attendanceProfileDate: Date) {
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
    company_attendance_profiles(first: 999, archive: $attendanceProfileDate) {
      data {
        id
        name
        activeWorkTiming {
          allow_locations_custom_override
          sign_out_locations_custom_override
          same_policies
          overtime_permissible
        }
        workdays
      }
    }
    company_positions(first: 999) {
      data {
        id
        name
      }
    }
    company_offices(first: 999, input: { is_workplace: 0 }) {
      data {
        id
        name
        defaultRegulation: regulationType {
          id
        }
        currencies: currenciesWithDefaultCurrency {
          id
          name
          defaultCurrencyOffice
        }
        country {
          id
        }
        payment_intervals
      }
    }
    company_leaves_breaks: PermissionLeavesBreakSettingsForMenu(first: 999) {
      data {
        id
        name
        annualLeaveSetting {
          id
          allow_annual_leaves
          allowed_annual_leaves
          allowed_emergency_leaves
        }
        sickLeaveSetting {
          id
          allowed_sick_leaves
        }
        children {
          id
          name
          annualLeaveSetting {
            id
            allow_annual_leaves
            allowed_annual_leaves
            allowed_emergency_leaves
          }
          sickLeaveSetting {
            id
            allowed_sick_leaves
          }
        }
      }
    }
    managers: company_users(first: 9999, input: { status: ["Active"] }) {
      data {
        id
        name
      }
    }
    work_groups_menu {
      id
      name
    }
    workTeams(first: 999, input: {}, menu: true) {
      data {
        id
        name
        workGroup {
          id
        }
      }
    }
    user: profile {
      availableAccessLevels {
          id
          name
        }
      company {
        allowancesOptions: allowances {
          value: id
          label: name
        }
        deductiblesOptions: deductibles {
          value: id
          label: name
        }
      }
    }
    monthOptions {
      value: id
      label: option
      type
    }

    regulation_types {
      id
      name
      taxInfos {
        id
        from
        to
        type
        country {
          currency_id
        }
        employee_insurance_percentage
        employer_insurance_percentage
        insurance_salary_percentage
        min_insurance_salary
        max_insurance_salary
      }
    }

    paymentFactors: payment_factors {
      value: id
      label: factor
      calculation
      deductible
      allowance
    }
    company_offices_workplaces: company_offices(first: 999) {
      data {
        value: id
        label: name
      }
    }

    workPlaces: locations_menu(first: 999) {
      data {
        id
        name
      }
    }

    presets {
      ...preset
    }

    cost_centers: cost_centers_menu(first: 9999) {
    data {
      id
      name
      }
    }
  }
  ${presetFragment}
`;

export const editEmployeeFormQuery = gql`
  query editEmployeeForm($user_id: ID,$starts_at:String) {
    user(id: $user_id) {
      id
      name
      emp_code
      has_credentials
      email
      phone
      active
      display_roster
      phone_country_code: country_short_name
      employee {
        probationPeriod {
          type
          value
        }
        firstRelevantAttendanceTypeConfiguration: relevantAttendanceTypeConfiguration(date:$starts_at) {
            attendanceProfile {
            id
          }    
        }
        relevantAttendanceTypeConfiguration {
          attendanceProfile {
            week_start_day
            allow_multiple_check_ins
          }
          type
          start
          workGroup {
            id
          }
          workTeam {
            id
          }
          join_from
          workRemotelySettings {
            can_work_home
            max_homeDays_per_week
            flexible_home
            can_ex_days
            home_days
          }
        }
      }
      face_path
      face {
        id
        path
      }
      office {
        id
      }
      company {
        id
      }
      department {
        id
      }
      manager {
        id
      }
      attendance_profile {
        id
      }
      position {
        id
      }
      starts_at
      joining_date
      attendance_profile {
        id
      }
      position {
        id
      }
      copied_managers {
        id
      }
      workday_locations: workdayLocations {
        workday
        locations {
          value: id
          label: name
        }
        type
      }

      date_of_birth
      gender
      marital_status
      national_id
      insurance_number
      payments {
        id
        payment_method
        data
      }
    }
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
    company_offices(first: 999, input: { is_workplace: 0 }) {
      data {
        id
        name
        country {
          id
        }
      }
    }
    company_offices_workplaces: company_offices(first: 999) {
      data {
        value: id
        label: name
      }
    }
    company_attendance_profiles(first: 999) {
      data {
        id
        name
        activeWorkTiming {
          allow_locations_custom_override
          sign_out_locations_custom_override
          same_policies
        }
        workdays
      }
    }
    company_positions(first: 999) {
      data {
        id
        name
      }
    }
    managers: company_users(first: 9999, input: { status: ["Active"] }) {
      data {
        id
        name
      }
    }
  }
`;

export const myRequestsQuery = gql`
  query myRequests(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $status: [Int]
    $from: String
    $to: String
  ) {
    requests(
      first: $rows
      page: $page
      input: {
        orderBy: { column: $field, order: $order }
        status: $status
        dateRange: { from: $from, to: $to }
      }
    ) {
      data {
        __typename
        ...userRequests
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }

  ${userRequestsFragment}
`;

export const inboundRequestsQuery = gql`
  query inboundRequests(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $status: [Int]
    $from: String
    $payable_month: String
    $to: String
    $emp_id: [ID]
    $request_type_id: [ID]
    $office_id: [ID]
    $position_id: [ID]
    $department_id: [ID]
    $route: String
    $directManaged: Boolean
    $skipFilterOptions: Boolean!
  ) {
    users_requests: users_requests_by_role(
      first: $rows
      page: $page
      input: {
        directManaged: $directManaged
        orderBy: { column: $field, order: $order }
        status: $status
        dateRange: { from: $from, to: $to }
        emp_id: $emp_id
        request_type_id: $request_type_id
        office_id: $office_id
        position_id: $position_id
        department_id: $department_id
        page: $route
        payable_month: $payable_month
      }
    ) {
      data {
        ...userRequests
        __typename
        employee {
          user {
            name
            id
            manager {
              id
            }
            copied_managers {
              id
            }
            attendance_profile {
              week_start_day
            }
          }
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    totalPendingRequests: users_requests_by_role(
      first: 1
      page: 1
      input: { status: [2], page: $route }
    ) {
      paginatorInfo {
        total
      }
    }
    employeesOptions: users_by_role(first: 9999, input: { page_flag: $route })
      @skip(if: $skipFilterOptions) {
      data {
        id
        name
      }
    }
    officesOptions: company_offices(first: 9999) @skip(if: $skipFilterOptions) {
      data {
        id
        name
      }
    }
    positionsOptions: company_positions(first: 9999)
      @skip(if: $skipFilterOptions) {
      data {
        id
        name
      }
    }
    departmentsOptions: company_departments(first: 9999)
      @skip(if: $skipFilterOptions) {
      data {
        id
        name
      }
    }
  }

  ${userRequestsFragment}
`;

export const attendanceChartEmployeesNameQuery = gql`
  query fetchAttendanceChartEmployeesName($input: AttendanceHistoryUsersInput) {
    att_history_employees(input: $input) {
      user {
        id
        name
        from_home
      }
    }
  }
`;

export const officesQuery = gql`
  query allOffices(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $filter: String
    $isWorkplace: Int
  ) {
    offices(
      first: $rows
      page: $page
      input: {
        name: $filter
        orderBy: { column: $field, order: $order }
        is_workplace: $isWorkplace
      }
    ) {
      data {
        id
        name
        email_notification_locale
        address
        time_zone
        created_at
        is_workplace
        country {
          id
          name
        }
        currency {
          id
          name
        }
        regulationType {
          id
          name
        }
        week_start_day
        getYearStartMonth
        getMonthStartDay
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    total: company_offices(first: 1, page: 1) {
      paginatorInfo {
        total
      }
    }
  }
`;
export const fetchOfficeQuery = gql`
  query fetchOffice($id: ID, $includeOffice: Boolean!) {
    office(id: $id) @include(if: $includeOffice) {
      ...officeInfo
      getYearStartMonth
    }
    holidays(first: 99999) {
      data {
        id
        name
        start_date
        end_date
      }
    }
    profile {
      company {
        holidays_applied_on_all_offices {
          id
          name
          start_date
          end_date
        }
      }
    }
    countries {
      id
      name
      currency_id
    }
    currencies(office_id: $id) {
      id
      name
      defaultCurrencyOffice
    }
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }
  }

  ${officeFragment}
`;

export const departmentsQuery = gql`
  query allDepartments(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $filter: String
    $office_id: ID = null
  ) {
    departments(
      first: $rows
      page: $page
      input: {
        name: $filter
        orderBy: { column: $field, order: $order }
        office_id: $office_id
      }
    ) {
      data {
        ...departmentInfo
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }

  ${departmentFragment}
`;

export const positionsQuery = gql`
  query allPositions(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $filter: String
  ) {
    positions(
      first: $rows
      page: $page
      input: { name: $filter, orderBy: { column: $field, order: $order } }
    ) {
      data {
        ...positionInfo
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }

  ${positionFragment}
`;

export const holidaysQuery = gql`
  query allHolidays($rows: Int!, $page: Int!, $filter: String, $year: String) {
    company_holidays(
      first: $rows
      page: $page
      input: { name: $filter, year: $year }
    ) {
      data {
        id
        name
        start_date
        end_date
        apply_on_all_offices
        apply_on_all_attendance_profiles
        suggested_offices {
          id
          name
        }
        company {
          id
          name
        }
        holidayProfiles {
          id
          name
          office {
            id
            name
          }
        }
        attendanceProfiles {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    company_offices(first: 999) {
      data {
        id
        name
      }
    }
    company_attendance_profiles(first: 999) {
      data {
        id
        name
        default_office_holiday_profile
      }
    }
    profile {
      company {
        auto_suggest_holidays
      }
    }
  }
`;

export const fetchHistoryListQuery = gql`
  query fetchHistoryList(
    $rows: Int!
    $page: Int!
    $status: [String]
    $from: String
    $to: String
  ) {
    sign_in_outs(
      first: $rows
      page: $page
      input: { date_range: { from: $from, to: $to }, status: $status }
    ) {
      data {
        ...employeeHistoryFragement
        multiple_sign_ins {
          ...employeeHistoryFragement
        }
      }

      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${employeeHistoryFragement}
`;
export const refreshSignInOutQuery = gql`
  query refreshSignInOut(
    $BussinessPartner: Boolean!
    $viewRequests: Boolean!
    $viewClaims: Boolean!
  ) {
    profile {
      ...userProfile
      employee {
        probationPeriod {
          end_of_probation
        }
        on_probation
        can_request_change_shift
        can_request_day_off
        can_request_ignore_assignment
        relevantAttendanceTypeConfiguration {
          type
          workGroup {
            name
            managers {
              id
              name
            }
          }
          workTeam {
            name
          }
          workRemotelySettings {
            can_work_home
            employee_can_request
            can_ex_days
            home_days
            flexible_home
          }
        }
        permission_leaves_break_setting {
          annualLeaveSetting {
            allow_annual_leaves
            allowed_emergency_leaves
          }
          unpaidLeaveSetting {
            unpaid_leaves
          }
          sickLeaveSetting {
            sick_leaves
          }
          probationPeriodConfiguration {
            request_leaves
          }
          permissionSetting {
            allow_permissions
            min_permission_unit
          }
          probationPeriodConfiguration {
            request_leaves
          }
          submitRequestAbility {
            sick_leave
            annual_emergency_leave
          }
        }
      }
      nonSeenNotificationsCount
      prviliege_ids
      prviliege_ids_with_all_employee_scope
      prviliege_ids_with_managed_employee_scope
      saas {
        id
      }
      user_type
      balance @skip(if: $BussinessPartner) {
        remaining_permissions
        remaining_annual_leaves
        remaining_emergency_leaves
        remaining_sick_leaves
        remaining_grace
      }
      attendance_profile @skip(if: $BussinessPartner) {
        name
        id
        activeWorkTiming {
          sign_in_req
          name
        }
        isHalfDayAvailable
      }
      sign_in_time @skip(if: $BussinessPartner)
      sign_out_time @skip(if: $BussinessPartner)
      from_home @skip(if: $BussinessPartner)
    }
    users_requests_by_role(
      first: 1
      page: 1
      input: { status: [2], page: "inbound_requests_list" }
    ) @include(if: $viewRequests) {
      paginatorInfo {
        total
      }
    }
    claims(first: 1, page: 1, input: { status: [2] })
      @include(if: $viewClaims) {
      paginatorInfo {
        total
      }
    }
  }
  ${userProfileFragment}
`;

export const myBalanceQuery = gql`
  query myBalance(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $type: String
    $year: Int
  ) {
    users_transactions(
      first: $rows
      page: $page
      input: {
        orderBy: { column: $field, order: $order }
        type: $type
        year: $year
      }
    ) {
      data {
        date_time
        quantity
        quantity_unit
        reason
        point_of_time_balance
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const employeeBalanceQuery = gql`
  query employeeBalance(
    $id: ID
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $type: String
    $year: Int
  ) {
    users_transactions(
      first: $rows
      page: $page
      input: {
        employee_id: $id
        orderBy: { column: $field, order: $order }
        type: $type
        year: $year
      }
    ) {
      data {
        date_time
        quantity
        quantity_unit
        reason
        point_of_time_balance
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const attendanceLogsQuery = gql`
  query attendanceLogs(
    $emp_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $position_id: [ID]
    $work_timing_id: [ID]
    $rows: Int!
    $page: Int!
    $status: [String]
    $from: String
    $to: String
    $isEmpOnly: Boolean! = false
    $attendance_configuration_conflict: Boolean
    $direct_managed: Boolean
    $skipMultible: Boolean! = false
    $includeFilterOptions: Boolean! = true
  ) {
    attendanceLogs: users_sign_in_outs_by_role(
      first: $rows
      page: $page
      input: {
        emp_id: $emp_id
        office_id: $office_id
        department_id: $department_id
        position_id: $position_id
        work_timing_id: $work_timing_id
        status: $status
        direct_managed: $direct_managed
        date_range: { from: $from, to: $to }
        attendance_configuration_conflict: $attendance_configuration_conflict
        page: "history_logs"
      }
    ) @skip(if: $isEmpOnly) {
      data {
        ...attendanceLog
        edited_by_action
        conflict_info
        attendance_configuration_conflict
        __typename
        multiple_sign_ins @skip(if: $skipMultible) {
          ...attendanceLog
          __typename
        }
      }
      paginatorInfo {
        lastItem
        perPage
        currentPage
        hasMorePages
        firstItem
      }
    }
    employees: users_by_role(
      first: 9999
      input: { page_flag: "attendance_history_list" }
    ) @include(if: $includeFilterOptions) {
      data {
        id
        name
        active
      }
    }

    company_departments(first: 999)
      @include(if: $includeFilterOptions)
      @skip(if: $isEmpOnly) {
      data {
        id
        name
      }
    }

    company_offices(first: 999)
      @include(if: $includeFilterOptions)
      @skip(if: $isEmpOnly) {
      data {
        id
        name
      }
    }

    work_timings_menu(new_versions_only: true)
      @include(if: $includeFilterOptions)
      @skip(if: $isEmpOnly) {
      id
      name
    }

    company_positions(first: 999)
      @include(if: $includeFilterOptions)
      @skip(if: $isEmpOnly) {
      data {
        id
        name
      }
    }
  }
  ${attendanceLogFragment}
`;
export const suspensionLogsQuery = gql`
  query suspensionLogs(
    $rows: Int!
    $page: Int!
    $name: String
    $month: Int
    $year: Int
  ) {
    users_suspensions: users_suspensions_by_role(
      first: $rows
      page: $page
      input: { name: $name, date: { month: $month, year: $year } }
    ) {
      data {
        id
        start_date
        end_date
        employee {
          name
          id
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const balanceLogsQuery = gql`
  query balanceLogs(
    $rows: Int!
    $page: Int!
    $name: String
    $type: ID
    $month: String
    $year: Int
    $operation: String
    $requestsField: String!
    $requestsOrder: SortOrder!
  ) {
    users_balances: users_balances_by_role(
      first: $rows
      page: $page
      input: {
        name: $name
        balance_type_id: $type
        duration: { month: $month, year: $year }
        operation: $operation
        orderBy: { column: $requestsField, order: $requestsOrder }
      }
    ) {
      data {
        id
        quantity
        month
        year
        month_year_payroll
        balanceType {
          id
          name
        }
        emergency_quantity
        reason
        balanceCause {
          name
          id
        }
        added_by {
          id
          name
        }
        employee {
          user {
            name
            id
            attendance_profile {
              week_start_day
            }
          }
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    employees: users_by_role(
      first: 9999
      input: { status: ["Active"], page_flag: "adjustments_leaves_list" }
    ) {
      data {
        id
        name
        emp_code
        employee {
          can_request_leave
          permission_leaves_break_setting {
            id
            annualLeaveSetting {
              allow_annual_leaves
              allowed_emergency_leaves
              leave_basis
            }
            sickLeaveSetting {
              sick_leaves
            }
            permissionSetting {
              allow_permissions
            }
          }
        }
      }
    }
  }
`;

export const overtimeLogsQuery = gql`
  query overtimeLogs(
    $rows: Int!
    $page: Int
    $name: String
    $month: Int!
    $year: Int!
  ) {
    users_overtime: users_overtime_by_role(
      first: $rows
      page: $page
      month: $month
      year: $year
      input: { name: $name }
    ) {
      data {
        request {
          id
          overtime_interval
          employee {
            name
            id
          }
        }
        actual_overtime
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const attendanceWorkDaysQuery = gql`
  query attendanceWorkDays($id: ID) {
    attendance_profile(id: $id) {
      workdays
    }
  }
`;

export const officeHolidaysQuery = gql`
  query officeHolidays {
    holidays(first: 99999) {
      data {
        id
        name
        start_date
        end_date
      }
    }
    work_timings(first: 99999) {
      data {
        id
        name
        work_timing_profile_type
        work_hours
        sign_in_start_time
        sign_in_end_time
        sign_in_req
      }
    }
  }
`;

export const usersClaimsQuery = gql`
  query fetchUsersClaims($input: ClaimsFilterInput, $rows: Int!, $page: Int) {
    usersClaims: claims(first: $rows, page: $page, input: $input) {
      data {
        id
        payroll_at
        amount
        reason
        incurred_at
        actionBy {
          id
          name
        }
        user {
          id
          name
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        currency {
          id
          name
        }
        status
        files {
          id
          path
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    totalPendingClaims: claims(first: 1, page: 1, input: { status: [2] }) {
      paginatorInfo {
        total
      }
    }
    extraUsersFilter: users_by_role(
      input: { status: ["Active"], page_flag: "adjustments_claims_list" }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const usersBonusQuery = gql`
  query fetchUsersBonus($input: BonusesFilterInput, $rows: Int!, $page: Int) {
    usersBonus: bonuses(first: $rows, page: $page, input: $input) {
      data {
        id
        payroll_at
        createdBy {
          id
          name
        }
        amount
        name
        value
        description
        payment_factor_id
        currency {
          id
          name
        }
        user {
          id
          name
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        payment_status
        created_at
        updated_at
        sameMonth
        commission_at
        cost_center {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    extraUsersFilter: users_by_role(
      input: { status: ["Active"], page_flag: "adjustments_bonuses_deductions" }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const usersDeductionsQuery = gql`
  query fetchUsersDeductions(
    $input: DeductionsFilterInput
    $rows: Int!
    $page: Int
  ) {
    usersDeductions: deductions(first: $rows, page: $page, input: $input) {
      data {
        id
        payroll_at
        createdBy {
          id
          name
        }
        amount
        name
        value
        description
        payment_factor_id
        currency {
          id
          name
        }
        user {
          id
          name
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        payment_status
        created_at
        updated_at
        cost_center {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    extraUsersFilter: users_by_role(
      input: { status: ["Active"], page_flag: "adjustments_bonuses_deductions" }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const editEmployeePrivilegesQuery = gql`
  query editPrivileges($user_id: ID, $planId: ID!) {
    system_privileges: planPrivileges(id: $planId)
    userPrivileges: userSystemPrivileges(user_id: $user_id) {
      main
      all
      managed
    }
    profile {
      company {
        roles {
          label: name
          value: id
          roleable {
            type: __typename
          }
        }
        currentSubscription {
          plan {
            roles {
              label: name
              value: id
              roleable {
                type: __typename
              }
            }
          }
        }
      }
    }
  }
`;

export const payrollReportQuery = gql`
  query fetchPayrollReport(
    $office_id: ID
    $input: PayrollFilterInput
    $rows: Int!
    $page: Int
    $fetchExtra: Boolean!
    $year: String
    $monthly_amount: Boolean!
  ) {
    payrollReport: Payroll_view(first: $rows, page: $page, input: $input) {
      data {
        ...payroll @skip(if: $monthly_amount)
        ...payrollMonthly @include(if: $monthly_amount)
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    payrollReportEmployeesFilter: users_by_role(
      input: { page_flag: "payroll_list", office_id: $office_id }
    ) {
      data {
        id
        name
      }
    }
    payrollReportCurrenciesFilter: currencies(office_id: $office_id) {
      id
      name
      symbol
    }
    payrollReportOfficesFilter: company_offices(first: 999, page: 1) {
      data {
        id
        name
        head_quarter
      }
    }
    payrollReportRegulationFilter: regulation_types {
      id
      name
    }
    Extra_fields_in_payroll(input: $input) @include(if: $fetchExtra) {
      ...extraPayrollFields @skip(if: $monthly_amount)
      ...extraMonthlyPayrollFields @include(if: $monthly_amount)
    }
    closed_months(year: $year, office_id: $office_id) {
      closed_months
    }
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
  }
  ${payrollFragment}
  ${payrollMonthlyFragment}
  ${extraPayrollFieldsFragment}
  ${extraMonthlyPayrollFieldsFragment}
`;

export const monthlyReportQuery = gql`
  query monthly_report(
    $rows: Int!
    $page: Int
    $column: String!
    $order: SortOrder!
    $user_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $position_id: [ID]
    $from_month_year: String
    $to_month_year: String
    $from_date: String
    $to_date: String
    $employee_search: String
    $custom_report: Boolean
  ) {
    monthlyReport: monthly_report(
      first: $rows
      page: $page
      input: {
        orderBy: { column: $column, order: $order }
        user_id: $user_id
        office_id: $office_id
        department_id: $department_id
        position_id: $position_id
        from_month_year: $from_month_year
        to_month_year: $to_month_year
        employee_search: $employee_search
        from_date: $from_date
        to_date: $to_date
        custom_report: $custom_report
      }
    ) {
      data {
        ...monthlyReport
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${monthlyReportFragment}
`;

export const GET_MONTHLY_REPORT_OPTIONS = gql`
  query getMonthlyReportyOptions {
    extraUsersFilter: users_by_role(input: { page_flag: "monthly_report" }) {
      data {
        id
        name
      }
    }
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
    company_positions(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const exportMonthlyReportQuery = gql`
  query exportMonthlyReport($input: ExportReportColumns) {
    export_monthly_report(input: $input) {
      file
      status
      message
    }
  }
`;

export const yearlyReportQuery = gql`
  query yearly_report(
    $rows: Int!
    $page: Int
    $column: String!
    $order: SortOrder!
    $user_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $employee_search: String
    $year: String
  ) {
    yearlyReport: yearly_report(
      first: $rows
      page: $page
      input: {
        orderBy: { column: $column, order: $order }
        user_id: $user_id
        office_id: $office_id
        department_id: $department_id
        year: $year
        employee_search: $employee_search
      }
    ) {
      data {
        ...yearlyReport
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${yearlyReportFragment}
`;

export const exportYearlyReportQuery = gql`
  query exportYearlyReport($input: ExportYearlyReportColumns) {
    export_yearly_report(input: $input) {
      file
      status
      message
    }
  }
`;

export const myPayrollQuery = gql`
  query fetchMyPayroll {
    payrollReport: my_Payroll_view {
      ...payroll
    }
  }
  ${payrollFragment}
`;

export const userPayslipQuery = gql`
  query fetchUserPayslip($input: PaySlipInput) {
    usersPaySlip: my_pay_slip(input: $input) {
      user {
        id
        name
        department {
          id
          name
        }
        position {
          id
          name
        }
        office {
          id
          name
          address
          company {
            id
            name
          }
        }
      }
      base_salary
      allowances {
        name
        amount
      }
      bonuses {
        name
        amount
      }
      overtime
      claims {
        amount
        reason
      }
      gross_salary
      penalties {
        amount
        reason
      }
      social
      medical
      taxes
      deductions {
        name
        amount
      }
      deductibles {
        name
        amount
      }
      currency {
        id
        name
        symbol
      }
      net_salary
      date
      loans {
        name
        amount
        currencySymbol
      }
      installments {
        id
        currencySymbol
        loanName
        amount
      }
      taxSettlement {
        start: from
        end: to
        year
        value: amount
      }
      unpaid
      totalBonus
      totalDeduction
    }
  }
`;

export const getSignInInfoQuery = gql`
  query getSignInInfo {
    signInInfo: get_sign_in_info {
      from_home
      face_rekognition
      home_face_rekognition
    }
  }
`;

export const getSignOutInfoQuery = gql`
  query getSignOutInfo {
    signOutInfo: get_sign_out_info {
      from_home
      work_hours_exceeded
      face_rekognition
      home_face_rekognition
    }
  }
`;

export const fetchDeductCalculationQuery = gql`
  query fetchDeductCalculation($id: ID!) {
    payroll(id: $id) {
      userTaxesHistory {
        original_amount
      }
      userMedicalHistory {
        original_amount
      }
      userSocialHistory {
        original_amount
      }
    }
  }
`;

export const initSalaryConfigDataQuery = gql`
  query getNewSalaryConfigData($office_id: ID, $countryId: ID!) {
    currencies(office_id: $office_id) {
      id
      name
      defaultCurrencyOffice
    }

    # office(id: $office_id) {
    #   defaultRegulation: regulationType {
    #     id
    #   }
    # }

    office: officeById(id: $office_id) {
      defaultRegulation: regulationType {
        id
      }
    }

    relevantRegulationTypes(country_id: $countryId) {
      id
      name
      hasTaxation
      hasCustomizationOnStates
    }

    relevantStates(country_id: $countryId) {
      id
      name
    }

    user: profile {
      company {
        allowancesOptions: allowances {
          value: id
          label: name
        }
        deductiblesOptions: deductibles {
          value: id
          label: name
        }
      }
    }
    monthOptions {
      value: id
      label: option
      type
    }
    paymentFactors: payment_factors {
      value: id
      label: factor
      calculation
      deductible
      allowance
    }

    presets {
      ...preset
    }
  }
  ${presetFragment}
`;

export const fetchExportPayrollOptsQuery = gql`
  query fetchExportPayrollOpts {
    allowancesOptions: Allowances {
      value: id
      label: name
    }

    deductiblesOptions: Deductibles {
      value: id
      label: name
      company_id
    }
  }
`;

export const adminUsersQuery = gql`
  query fetchAdminUsers($rows: Int!, $page: Int, $filter: String) {
    active_saas_users(first: $rows, page: $page, name: $filter) {
      data {
        id
        name
        email
        phone
        phoneCode: phone_country_code
        phone_country_code: country_short_name
        saas {
          id
          user_id
          role_id
          primaryCompaniesCount
          secondaryCompaniesCount
          companies {
            id
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const adminUsersCompaniesQuery = gql`
  query fetchAdminUsersCompanies {
    companies: saasCompaniesMenu  {
      id
      name
    }
  }
`;

export const adminUsersEmployeesQuery = gql`
  query fetchAdminUsersEmployees($selectedCompanyFilter: ID) {
    company(id: $selectedCompanyFilter) {
      id
      activeUsers {
        id
        name
        emp_code
        saas {
          id
          companies {
            name
          }
        }
      }
    }
  }
`;

export const editAdminUserPrivilegesQuery = gql`
  query editAdminUserPrivileges($user_id: ID) {
    admin_privileges
    userPrivileges: userAdminPrivileges(user_id: $user_id) {
      main
      all
      managed
    }
    saas_roles {
      label: name
      value: id
    }
  }
`;

export const allCompaniesQuery = gql`
  query fetchAllCompanies(
    $rows: Int!
    $page: Int
    $isSuper: Boolean!
    $name: String
    $company_status: String
    $includePlans: Boolean! = false
    $orderByRelation: orderby
  ) {
    companies(
      first: $rows
      page: $page
      input: {
        name: $name
        company_status: $company_status
        orderByRelation: $orderByRelation
      }
    ) @include(if: $isSuper) {
      data {
        id
        name
        usersCount
        primarySAAS {
          user {
            id
            name
          }
        }
        secondarySAAS {
          user {
            id
            name
          }
        }
        canEditMonthStartDay
        allow_am_access
        report_status
        ratio
        getCurrentSubcriptionStatus
        getLastSubcriptionStatus
        week_day
        month_start_day
        year_start_month
        allow_multiple_offices_start
        canAddNewEmployee
        last_sign_in_created_at
        businessPartners {
          user {
            id
            company {
              id
            }
            name
            email
            phone
            phone_country_code: country_short_name
          }
        }
        activeSubscription: currentSubscription {
          employees_limit
          plan {
            id
            name
          }
          start_date
          end_date
          payment_amount
        }
        status
        numberOfEmployees
        activeUsers {
          name
        }
        week_start_day
        logs {
          type
          from
          created_at
          old_value
          new_value
          user {
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    saas_companies(
      first: $rows
      page: $page
      input: {
        name: $name
        company_status: $company_status
        orderByRelation: $orderByRelation
      }
    ) @skip(if: $isSuper) {
      data {
        id
        name
        usersCount
        primarySAAS {
          user {
            id
            name
          }
        }
        secondarySAAS {
          user {
            id
            name
          }
        }
        canEditMonthStartDay
        allow_am_access
        report_status
        ratio
        getCurrentSubcriptionStatus
        getLastSubcriptionStatus
        week_day
        month_start_day
        year_start_month
        allow_multiple_offices_start
        last_sign_in_created_at
        businessPartners {
          user {
            id
            company {
              id
            }
            name
            email
            phone
            phone_country_code: country_short_name
          }
        }
        activeSubscription: currentSubscription {
          plan {
            id
            name
          }
          employees_limit
          start_date
          end_date
          payment_amount
        }
        status
        numberOfEmployees
        activeUsers {
          name
        }
        week_start_day
        logs {
          type
          from
          created_at
          old_value
          new_value
          user {
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    active_saas_users(first: 999) {
      data {
        id
        name
      }
    }
    currencies {
      id
      name
      symbol
    }
    plans(first: 999, page: 1, input: { page_flag: "menu", type: "active" })
      @include(if: $includePlans) {
      data {
        id
        name
        trial_value
        features {
          id
          pivot {
            limits
          }
        }
      }
    }
  }
`;

export const companyProfileQuery = gql`
  query fetchCompanyProfile($id: ID!) {
    company(id: $id) {
      id
      name
      allow_am_access
      getCurrentSubcriptionStatus
      getLastSubcriptionStatus
      canEditSubcriptionStatus
      week_day
      month_start_day
      year_start_month
      allow_multiple_offices_start
      canAddNewEmployee
      status
      hasActiveSubscription
      numberOfEmployees
      contract_duration
      report_status
      ratio
      contract_amount
      currentSubscription {
        employees_limit
        client_at
        company_status
        createdByAgent {
          name
        }
        plan {
          features {
            id
            pivot {
              limits
            }
          }
        }
      }
      primarySAAS {
        user {
          id
          name
        }
      }
      secondarySAAS {
        user {
          id
          name
        }
      }
      businessPartners {
        user {
          id
          company {
            id
          }
          name
          email
          checkPassword
          phone
          phone_verified
          email_verified
          phoneCode: phone_country_code
          phone_country_code: country_short_name
        }
      }
    }
    active_saas_users(first: 999) {
      data {
        id
        name
      }
    }
    currencies {
      id
      name
      symbol
    }
    plans: get_available_plans_for_company(company_id: $id) {
      id
      name
      trial_value
      features {
        id
        pivot {
          limits
        }
      }
    }
  }
`;

export const workTimingsListQuery = gql`
  query workTimings(
    $rows: Int!
    $page: Int!
    $filter: String
    $work_timing_profile_type: String
    $only_archive: Boolean
  ) {
    work_timings(
      first: $rows
      page: $page
      flag: list
      only_archive: $only_archive
      input: {
        name: $filter
        work_timing_profile_type: $work_timing_profile_type
      }
    ) {
      data {
        oldVersions {
          id
          name
          sign_in_start_time
          sign_in_end_time
          absent_violations {
            value
            quantity_unit
          }
          allow_early_signout
          early_sign_out_violations {
            value
            quantity_unit
          }
          apply_missing_sign_out
          missing_sign_out_violations {
            value
            quantity_unit
          }
          archived
          updated_at
          created_at
          overtime_permissible
          face_rekognition
          work_hours
          time_allowed_before_sign_in
        }
        id
        name
        archived
        updated_at
        created_at
        sign_in_start_time
        sign_in_end_time
        absent_violations {
          value
          quantity_unit
        }
        allow_early_signout
        early_sign_out_violations {
          value
          quantity_unit
        }
        apply_missing_sign_out
        missing_sign_out_violations {
          value
          quantity_unit
        }
        overtime_permissible
        face_rekognition
        work_hours
        time_allowed_before_sign_in
        hidden
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    total: work_timings(
      first: 1
      page: 1
      input: { work_timing_profile_type: "normal" }
    ) {
      paginatorInfo {
        total
      }
    }
  }
`;

export const editFetchWorkTimeQuery = gql`
  query workTime($id: ID) {
    work_time: work_timing(id: $id) {
      ...workTime
    }
  }
  ${workTimeFragment}
`;

export const locationsListQuery = gql`
  query fetchLocations($first: Int!, $page: Int!, $name: String) {
    ##
    locations: locations_menu(
      first: $first
      page: $page
      input: { name: $name }
    ) {
      data {
        id
        name
        lng
        lat
        address
        max_distance
        office {
          id
          name
          address
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const emailNotificationsSettingsQuery = gql`
  query fetchEmailNotificationsSettings {
    my_notifications_settings {
      id
      name
      type
      manager_flag
      email_status
      phone_status
    }
  }
`;

export const fetchUpsertDocModalQuery = gql`
  query fetchUpsertDocModal {
    countries {
      id
      name
    }
  }
`;

export const viewAttRequestQuery = gql`
  query viewAttRequest($emp_id: ID, $date: String, $type: String) {
    view_request(emp_id: $emp_id, date: $date, type: $type) {
      id
      status
      req_day
      canCancel
      sign_in_time
      sign_out_time
      from_home
      day_type
      sign_in_status
      actual_sign_in_out {
        actualSignIn
        actualSignOut
      }
      employee {
        user {
          name
        }
      }
    }
  }
`;

export const pettyCashesQuery = gql`
  query fetchPettyCashes(
    $avaliablePettyCashFilterInputType: String
    $rows: Int!
    $page: Int
    $input: PettyCashFilterInput
    $isUserProfile: Boolean!
    $isPettyCashListInFinancesTab: Boolean!
    $usersByRoleInput: UserFilterInput
  ) {
    petty_cashes(first: $rows, page: $page, input: $input) {
      data {
        id
        employee_name
        emp_id
        user {
          manager {
            id
            name
          }
          copied_managers {
            id
            name
          }
        }
        type
        added_by {
          id
          name
        }
        date
        details
        amount
        currency {
          id
          name
          symbol
        }
        category @skip(if: $isPettyCashListInFinancesTab) {
          id
          name
        }
        sub_category @skip(if: $isPettyCashListInFinancesTab) {
          id
          name
        }
        office {
          id
          name
        }
        created_at
        current_balance
        balance
        files {
          id
          path
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    users_by_role(input: $usersByRoleInput)
      @include(if: $isPettyCashListInFinancesTab) {
      data {
        id
        name
        emp_code
        office {
          id
        }
      }
    }
    categories(first: 9999) @skip(if: $isPettyCashListInFinancesTab) {
      data {
        id
        name
      }
    }
    currencies {
      id
      name
      symbol
    }
    get_available_petty_cashes(
      input: {
        currency_id: ""
        office_id: ""
        type: $avaliablePettyCashFilterInputType
      }
    ) @skip(if: $isUserProfile) {
      emp_id
      name
    }
  }
`;

export const subCategoryQuery = gql`
  query fetchSubCategory($input: SubCategoryFilterInput) {
    sub_categories(first: 9999, input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const expenseTotalBalanceQuery = gql`
  query fetchExpenseTotalBalance($input: PettyCashFilterInput) {
    get_total_expenses_balance(input: $input) {
      total
      currency {
        id
        symbol
      }
    }
  }
`;

export const employeeCurrentBalanceQuery = gql`
  query fetchEmployeeCurrentBalance($emp_id: ID) {
    employee(id: $emp_id) {
      get_total_employee_balance {
        balance
        symbol
        currency_name
      }
    }
  }
`;

export const companySettingsQuery = gql`
  query fetchCompanySettings($rows: Int!, $page: Int) {
    categories(first: $rows, page: $page) {
      data {
        id
        name
        sub_categories {
          value: id
          label: name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const currenciesOfficeQuery = gql`
  query fetchCurrenicesOffices($office_id: ID) {
    currencies(office_id: $office_id) {
      id
      name
      symbol
      defaultCurrencyOffice
    }
  }
`;

export const avaliablePettyCashesEmployeesQuery = gql`
  query fetchExpenseFormEmployees($input: EmployeesPettyCashFilterInput) {
    get_available_petty_cashes(input: $input) {
      emp_id
      name
      emp_code
    }
  }
`;

export const pettyCashCurenciesQuery = gql`
  query fetchUserProfilePettyCashCurrencies($emp_id: ID, $type: String) {
    get_petty_cashes_currencies(emp_id: $emp_id, type: $type) {
      id
      name
      symbol
    }
  }
`;

export const fetchEmployeeDocumentsQuery = gql`
  query employeeDocs($id: ID) {
    employeeDocuments: employee(id: $id) {
      profileDocuments {
        required_documents {
          document {
            id
            name
            company_id
            scope
            fields {
              id
              name
            }
            company {
              name
            }
            country {
              id
              name
              short_name
            }
            pivot {
              data
            }
          }
          user_document {
            id
            files {
              id
              path
            }
            data
            isExpired
            created_at
          }
        }
        optional_documents {
          document {
            id
            name
            company_id
            scope
            fields {
              id
              name
            }
            company {
              name
            }
            country {
              name
              id
              short_name
            }
            pivot {
              data
            }
          }
          user_document {
            id
            files {
              id
              path
            }
            data
            isExpired
            created_at
          }
        }
      }
    }
  }
`;

export const exportEmployeeListQuery = gql`
  query exportEmployeeList($input: ExportFileColumns) {
    export_employees_list(input: $input) {
      file
      status
      message
    }
  }
`;

export const initSuspensionQuery = gql`
  query initSuspension($suspended_employee: ID, $suspend_from: String) {
    init_suspension(
      input: {
        suspended_employee: $suspended_employee
        suspend_from: $suspend_from
      }
    ) {
      ...initSuspension
    }
  }
  ${initSuspensionUnionFragment}
`;

export const fetchRequestRulesListQuery = gql`
  query fetchRequestRules($rows: Int!, $page: Int!, $input: RuleFilterInput) {
    rules(first: $rows, page: $page, input: $input) {
      data {
        id
        status {
          id
          status
        }
        all_department
        active
        start_date
        department {
          id
          name
        }
        request_types {
          id
          name
        }
        employees {
          user {
            id
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const fetchRequestRulesOptsQuery = gql`
  query fetchRequestRulesOpts {
    usersOpts: company_users(
      first: 9999
      page: 1
      input: { status: ["Active"] }
    ) {
      data {
        id
        name
        emp_code
        department {
          id
        }
      }
    }
    departmentOpts: company_departments(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;
export const fetchOpenPayrollMonths = gql`
  query fetchPayrollMonths(
    $year: String!
    $office_id: ID
    $viewManagedOnly: Boolean!
  ) {
    open_payroll_months(input: { year: $year, office_id: $office_id }) {
      date
      totalWithCurrencies {
        currency_id
        total
        office_id
      }
    }

    currencies {
      id
      symbol
    }
    profile {
      offices @skip(if: $viewManagedOnly) {
        id
        name
      }
      managedOffices @include(if: $viewManagedOnly) {
        name
        id
      }
    }
  }
`;
export const exportExcelEmployeeTemplate = gql`
  query ExportFile($company_id: ID) {
    export_excel_file_template(input: { company_id: $company_id }) {
      status
      file
    }
  }
`;
export const UploadEmployeeCsv = gql`
  query ImportEmployees(
    $file: Upload
    $company_id: ID
    $send_verification_msg: Boolean
  ) {
    import_excel_file_template(
      file: $file
      company_id: $company_id
      send_verification_msg: $send_verification_msg
    ) {
      status
      messages {
        row
        name
        error_data {
          column
          value
          message
        }
      }
    }
  }
`;

export const initDeleteManagerQuery = gql`
  query initDeleteManager($id: ID) {
    user(id: $id) {
      id
      name
      direct_manager_on {
        id
        name
        active
      }
    }
  }
`;

export const fetchEditAcceptedOvertimeRequestQuery = gql`
  query fetchEditAcceptedOvertimeRequest($request_id: ID) {
    edit_accepted_overtime_request(request_id: $request_id) {
      payable_at
      interval_in_time
    }
  }
`;

export const initSuspendManagerQuery = gql`
  query initSuspendManager($id: ID) {
    user(id: $id) {
      id
      name
      direct_manager_on {
        id
        name
        active
      }
    }
  }
`;

export const fetchReplaceByManagersOptsQuery = gql`
  query fetchReplaceByManagersOpts {
    company_users(first: 9999, page: 1, input: { status: ["Active"] }) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchPlanPrivilegesQuery = gql`
  query fetchPlanPrivilegesQuery($planId: ID!) {
    plan_privileges: planPrivileges(id: $planId)
  }
`;

export const fetchEmployeesTurnover = gql`
  query FetchEmployeesTurnover($office_id: ID, $department_id: ID) {
    employees_turnover_ratio(
      input: { office_id: $office_id, department_id: $department_id }
    ) {
      turnoverPercentage
      year
      totalNoOfEmployees
      currentlyHiredEmployees
      suspendedEmployees
    }
    departments: company_departments(first: 999) {
      data {
        name
        id
      }
    }
    company_offices(first: 999) {
      data {
        name
        id
      }
    }
  }
`;

export const rolePrivilegesQuery = gql`
  query fetchRolePrivileges($roleId: ID) {
    role(id: $roleId) {
      main: prviliege_ids
      all: prviliege_ids_with_all_employee_scope
      managed: prviliege_ids_with_managed_employee_scope
      limit_access
    }
  }
`;

export const fetchPreviewRolePrivilegesDataQuery = gql`
  query fetchPreviewRolePrivilegesData($roleId: ID, $planId: ID!) {
    system_privileges: planPrivileges(id: $planId)
    role(id: $roleId) {
      main: prviliege_ids
      all: prviliege_ids_with_all_employee_scope
      managed: prviliege_ids_with_managed_employee_scope
    }
  }
`;

export const fetchTotalExpensesData = gql`
  query FetchTotalExpenses(
    $currency_id: ID
    $office_id: ID
    $from: String
    $to: String
  ) {
    expenses_chart_for_categories(
      input: {
        currency_id: $currency_id
        office_id: $office_id
        from: $from
        to: $to
      }
    ) {
      id
      name
      total
      currencyId
    }
    currencies(office_id: $office_id) {
      id
      name
      defaultCurrencyOffice
    }
  }
`;

export const fetchSubCategoryCurrenciesData = gql`
  query FetchSubExpenses(
    $currency_id: ID
    $office_id: ID
    $from: String
    $to: String
    $category_id: ID
  ) {
    expenses_chart_for_sub_categories(
      input: {
        currency_id: $currency_id
        office_id: $office_id
        from: $from
        to: $to
        category_id: $category_id
      }
    ) {
      id
      name
      total
      currencyId
    }
  }
`;

export const requestCommentsQuery = gql`
  query fetchRequestCommentsModalData($requestId: ID) {
    get_request(id: $requestId) {
      id
      requestType {
        id
        name
      }
      add_info
      req_day
      time_from
      time_to
      employee {
        user {
          name
        }
      }
      comments {
        id
        request_id
        user_id
        user {
          name
          profile_picture {
            path
          }
        }
        comment
        created_at
      }
    }
  }
`;

export const fetchemployeeAttendanceLogs = gql`
  query fetchEmployeeProfileAttendanceLogs($id: ID) {
    employeeAttendanceLogs: users_sign_in_outs_by_role(
      first: 7
      page: 1
      input: { emp_id: [$id], orderBy: { column: "date", order: DESC } }
    ) {
      data {
        ...employeeAttendanceLog
        multiple_sign_ins {
          ...employeeAttendanceLog
        }
      }
    }
  }
  ${employeeAttendanceLogFragment}
`;

export const penaltiesQuery = gql`
  query fetchPenaltiesList(
    $rows: Int!
    $page: Int
    $route: String
    $input: PenaltyFilterInput
  ) {
    users_penalties_by_role(first: $rows, page: $page, input: $input) {
      data {
        ...userPenalties
        employee {
          user {
            name
            id
            manager {
              id
            }
            copied_managers {
              id
            }
            balance {
              remaining_annual_leaves
              remaining_emergency_leaves
            }
          }
        }
        sign_in_out {
          sign_in_time
          sign_out_time
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    employees: users_by_role(first: 9999, input: { page_flag: $route }) {
      data {
        id
        name
      }
    }
  }
  ${userPenaltiesFragment}
`;

export const recruitmentProcessesQuery = gql`
  query fetchRecruitmentProcessesList(
    $rows: Int!
    $page: Int
    $input: ProcessFilterInput
  ) {
    processes(first: $rows, page: $page, input: $input) {
      data {
        id
        name
        is_active
        jobPosts {
          is_active
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const intakeFormsQuery = gql`
  query fetchIntakeFormsList(
    $rows: Int!
    $page: Int
    $input: IntakeFormFilterInput
  ) {
    intake_forms(first: $rows, page: $page, input: $input) {
      data {
        id
        name
        is_active
        jobPosts {
          is_active
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const jobPostsQuery = gql`
  query fetchJobPostsList($rows: Int!, $page: Int, $input: JobPostFilterInput) {
    job_posts(first: $rows, page: $page, input: $input) {
      data {
        id
        title
        description
        is_active
        link
        no_active_applicants
        allow_multiple_apply
        custom_intakeForm_and_process
        limit_of_recurring_applications
        intake_form_id
        process_id
        process {
          name
          stages {
            id
            name
          }
        }
        intakeForm {
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const applicationsQuery = gql`
  query fetchApplicationsList(
    $job_post_id: ID
    $rows: Int!
    $page: Int
    $input: ApplicantJobPostListFilterInput
  ) {
    applicants_list(
      job_post_id: $job_post_id
      first: $rows
      page: $page
      input: $input
    ) {
      data {
        applicant_job_post_id
        applicant_id
        applicant_name
        email
        phone_numbers
        date_applied
        status
        stage_name
        stage_id
        count_of_applications_per_applicant
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    get_job_post_stages(job_post_id: $job_post_id) {
      id
      name
    }
    job_post(id: $job_post_id) {
      id
      title
    }
  }
`;

export const initRecruitmentProcessFormQuery = gql`
  query initRecruitmentProcessForm($id: ID, $newRecruitmentProcess: Boolean!) {
    validation_types {
      value: id
      label: name
    }
    recruitmentProcessesForm: process(id: $id)
      @skip(if: $newRecruitmentProcess) {
      id
      name
      is_active
      stages {
        id
        name
        process_id
        inactivate_application
        initial_flag
        form {
          id
          fields {
            suggested_field_type
            id
            grid
            parent_field_id
            name
            description
            order
            type
            selection_type
            dependent_option_names: dependable_options {
              value: id
              label: name
            }
            validation_type_ids: validation_type {
              value: id
              label: name
            }
            options {
              value: id
              label: name
            }
            mainOptions: options {
              value: id
              label: name
            }
          }
        }
      }
    }
  }
`;
export const adminHolidaysQuery = gql`
  query fetchAdminHolidaysList(
    $rows: Int!
    $page: Int
    $input: HolidayFilterInput
  ) {
    admin_holidays(first: $rows, page: $page, input: $input) {
      data {
        id
        name
        start_date
        end_date
        countries {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    countries {
      id
      name
    }
  }
`;

export const initRecruitmentIntakeFormQuery = gql`
  query initRecruitmentIntakeForm($id: ID, $newRecruitmentIntake: Boolean!) {
    validation_types {
      value: id
      label: name
    }
    recruitmentIntakeForm: intake_form(id: $id)
      @skip(if: $newRecruitmentIntake) {
      id
      name
      fields {
        id
        suggested_field_type
        grid
        parent_field_id
        name
        description
        order
        type
        selection_type
        dependent_option_names: dependable_options {
          value: id
          label: name
        }
        validation_type_ids: validation_type {
          value: id
          label: name
        }
        options {
          value: id
          label: name
        }
        mainOptions: options {
          value: id
          label: name
        }
      }
    }
  }
`;
// fetch half day work timing template data
export const fetchSuggestedHalfWorkTimingTemplateQuery = gql`
  query fetchSuggestedHalfWorkTimingTemplate($id: ID!, $which_half: String) {
    break_work_timing(id: $id, which_half: $which_half) {
      id
      name
      sign_in_req
      absent_early_sign_out
      absent_reset_period
      absent_violations: broken_absent_violations {
        id
        order
        penalty_type
        value
      }
      allow_early_signout
      allow_locations_custom_override
      apply_major_tardiness
      apply_major_tardiness1
      apply_major_tardiness2
      apply_major_tardiness3
      apply_major_tardiness4
      apply_major_tardiness5
      apply_major_tardiness6
      apply_major_tardiness7
      apply_major_tardiness8
      apply_major_tardiness9
      apply_major_tardiness10
      apply_minor_tardiness
      apply_missing_sign_out
      auto_sign_out
      auto_sign_out_time
      early_sign_out_violations: broken_early_sign_out_violations {
        id
        order
        penalty_type
        value
      }
      early_signout_deduction_type
      early_signout_reset_period
      emergency_penalty_type
      end_of_day
      face_rekognition
      home_face_rekognition
      late_reset_period
      late_tardiness_penalty_type
      major_tardiness_range
      major_violations: broken_major_violations {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range1
      major_violations1: broken_major_violations1 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range2
      major_violations2: broken_major_violations2 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range3
      major_violations3: broken_major_violations3 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range4
      major_violations4: broken_major_violations4 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range5
      major_violations5: broken_major_violations5 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range6
      major_violations6: broken_major_violations6 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range7
      major_violations7: broken_major_violations7 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range8
      major_violations8: broken_major_violations8 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range9
      major_violations9: broken_major_violations9 {
        id
        order
        penalty_type
        value
      }
      major_tardiness_range10
      major_violations10: broken_major_violations10 {
        id
        order
        penalty_type
        value
      }
      max_lateness_permissibility
      max_overtime_per_day
      min_overtime_cuttoff
      minor_tardiness_range
      minor_violations: broken_minor_violations {
        id
        order
        penalty_type
        value
      }
      missing_sign_out_deduction_type
      missing_sign_out_reset_period
      missing_sign_out_violations: broken_missing_sign_out_violations {
        id
        order
        penalty_type
        value
      }
      overtime_limit
      overtime_permissible
      remote_sign_in
      remote_sign_out
      same_policies
      sign_in_end_time
      sign_in_from_office_geolocation
      sign_in_from_office_ips
      allow_sign_in_outside_specified_locations
      allow_sign_out_outside_specified_locations
      sign_in_req
      sign_in_start_time
      sign_out_cuttoff_time
      sign_out_from_office_geolocation
      sign_out_from_office_ips
      sign_out_locations_custom_override
      sign_in_reminder_time
      sign_out_reminder
      sign_out_reminder_time
      skip_not_applied_absent_penalties
      skip_not_applied_early_sign_out_penalties
      skip_not_applied_major_penalties
      skip_not_applied_major_penalties1
      skip_not_applied_major_penalties2
      skip_not_applied_major_penalties3
      skip_not_applied_major_penalties4
      skip_not_applied_major_penalties5
      skip_not_applied_major_penalties6
      skip_not_applied_major_penalties7
      skip_not_applied_major_penalties8
      skip_not_applied_major_penalties9
      skip_not_applied_major_penalties10
      skip_not_applied_minor_penalties
      skip_not_applied_missing_sign_out_penalties
      time_allowed_before_sign_in
      work_hours
      work_timing_profile_type
    }
  }
`;

export const applicantsQuery = gql`
  query fetchApplicantsList(
    $job_post_id: ID
    $rows: Int!
    $page: Int
    $input: ApplicantJobPostListFilterInput
  ) {
    applicants_list(
      job_post_id: $job_post_id
      first: $rows
      page: $page
      input: $input
    ) {
      data {
        applicant_job_post_id
        applicant_name
        applicant_id
        email
        phone_numbers
        job_posts_names
        count_of_applications_per_applicant
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    get_job_post_stages(job_post_id: $job_post_id) {
      id
      name
    }
    job_posts(first: 999, input: { status: "all" }) {
      data {
        id
        title
      }
    }
  }
`;

export const JobPostFormIntakeFormsAndProcessesQuery = gql`
  query fetchJobPostFormIntakeFormsAndProcesses($jobPostId: ID) {
    processes_for_menu(job_post_id: $jobPostId) {
      id
      name
      is_default
      stages {
        id
        name
      }
    }
    intake_forms_for_menu(job_post_id: $jobPostId) {
      id
      name
      is_default
    }
  }
`;
export const editAttendnaceLogsWorkTimingsQuery = gql`
  query fetchEditAttendnaceLogsWorkTimings($work_timing_profile_type: String) {
    work_timings: work_timings_menu(
      new_versions_only: true
      type: $work_timing_profile_type
    ) {
      oldVersions {
        id
        name
        sign_in_start_time
        sign_in_end_time
        work_hours
        sign_in_req
        created_at
        updated_at
        archived
      }
      id
      name
      sign_in_start_time
      sign_in_end_time
      work_hours
      sign_in_req
      created_at
      updated_at
      archived
    }
  }
`;

export const applicantProfileQuery = gql`
  query fetchApplicantProfile($id: ID) {
    applicant(id: $id) {
      id
      name
      email
      phones
      phone_records {
        id
        applicant_id
        phone_number
      }
      applicant_job_posts {
        id
        date_applied
        last_stage {
          id
          name
        }
        applicant {
          id
          attachment {
            id
            path
          }
        }
        job_post {
          id
          title
          process {
            stages {
              id
              name
              initial_flag
            }
          }
        }
      }
    }
  }
`;

// updated

export const jobPostIntakeFormQuery = gql`
  query fetchJobPostIntakeForm($jobPostId: ID) {
    job_post(id: $jobPostId) {
      title
      description
      intake_form_id
      company {
        name
      }

      intakeForm {
        id
        fields {
          id
          suggested_field_type
          grid
          parent_field_id
          name
          description
          order
          type
          selection_type
          dependent_option_names: dependable_options {
            value: id
            label: name
          }
          options {
            value: id
            label: name
          }
        }
      }
    }
  }
`;
export const externalJobPostIntakeFormQuery = gql`
  query fetchJobPostIntakeForm($jobPostId: ID) {
    job_post: get_job_post_details(id: $jobPostId) {
      title
      description
      intake_form_id
      company {
        name
      }

      intakeForm {
        id
        fields {
          id
          suggested_field_type
          grid
          parent_field_id
          name
          description
          order
          type
          selection_type
          dependent_option_names: dependable_options {
            value: id
            label: name
          }
          options {
            value: id
            label: name
          }
        }
      }
    }
  }
`;

export const checkInOutLocationsQuery = gql`
  query fetchCheckInOutLocations {
    profile {
      company {
        locations: offices {
          id
          name
          lng
          lat
        }
      }
    }
  }
`;

export const editAttendnaceLogsLocationsQuery = gql`
  query fetchEditAttendnaceLogsLocations($user_id: ID) {
    locations_options(user_id: $user_id) {
      name
      value
    }
  }
`;

export const fetchAccessLevelsQuery = gql`
  query fetchCompanyAccessLevels {
    profile {
      company {
        roles {
          label: name
          value: id
          roleable {
            type: __typename
          }
        }
        currentSubscription {
          plan {
            roles {
              label: name
              value: id
              roleable {
                type: __typename
              }
            }
          }
        }
      }
    }
  }
`;

export const editRolePrivilegesQuery = gql`
  query editRolePrivileges($planId: ID!, $roleId: ID!) {
    plan_privileges: planPrivileges(id: $planId)
    role_privileges: role(id: $roleId) {
      id
      name
      main: prviliege_ids
      all: prviliege_ids_with_all_employee_scope
      managed: prviliege_ids_with_managed_employee_scope
      limit_access
    }
  }
`;

// fetch loans query
export const loansQuery = gql`
  query fetchloansList(
    $rows: Int!
    $page: Int
    $input: LoanFilter
    $isInEmployeeProfile: Boolean!
  ) {
    getLoansPerUsersWithConditions(first: $rows, page: $page, input: $input) {
      data {
        id
        user {
          name
          id
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        createdBy {
          id
          name
        }
        name
        amount
        granted_on
        include_payroll
        notes
        installment_number
        status {
          id
          status
        }
        remainingToLoan
        currency {
          id
          name
        }
        installments {
          id
          actual_payment_time
          amount
          payment_date
          status {
            id
            status
          }
          loan {
            currency {
              id
              name
            }
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    users_by_role(input: { status: ["Active"], page_flag: "loans_page" })
      @skip(if: $isInEmployeeProfile) {
      data {
        id
        name
      }
    }
  }
`;

// fetch loans currency query
export const loansModalQuery = gql`
  query fetchLoansModalData {
    currencies {
      id
      name
    }
    users_by_role(input: { status: ["Active"], page_flag: "loans_page" }) {
      data {
        id
        name
        emp_code
      }
    }
  }
`;

// fetch loans user salary config currency query
export const loansUserSalaryConfigCurrencyQuery = gql`
  query fetchLoansUserSalaryConfigCurrency($user_id: ID, $date: String) {
    employee(id: $user_id) {
      activeSalaryConfig(date: $date) {
        type
        details {
          currency {
            id
            name
          }
        }
      }
    }
  }
`;

// fetch my loans query
export const myLoansQuery = gql`
  query fetchMyloansList(
    $page: Int
    $input: LoanFilterPerMyProfile
    $requestsInput: MyLoanRequestFilter
  ) {
    getMyLoans(first: 10, page: $page, input: $input) {
      data {
        id
        createdBy {
          id
          name
        }
        name
        amount
        granted_on
        include_payroll
        notes
        installment_number
        status {
          id
          status
        }
        remainingToLoan
        currency {
          id
          name
        }
        installments {
          id
          actual_payment_time
          amount
          payment_date
          status {
            id
            status
          }
          loan {
            currency {
              id
              name
            }
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    myLoanRequests(first: 10, page: $page, input: $requestsInput) {
      data {
        id
        name
        amount
        granted_on
        include_payroll
        notes
        installment_number: installments_number
        status
        currency_id
        installments {
          id
          amount
          payment_date
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const fetchNotificationsHistoryQuery = gql`
  query userNotifications(
    $notify_for_id: ID
    $page: Int
    $from: String
    $to: String
    $fetchEmployeesFilterOptions: Boolean!
  ) {
    managedAndWatchedEmployees @include(if: $fetchEmployeesFilterOptions) {
      name
      id
    }
    user_notifications_history(
      input: {
        notify_for_id: $notify_for_id
        date_range: { from: $from, to: $to }
      }
      first: 10
      page: $page
    ) {
      paginatorInfo {
        hasMorePages
        currentPage
        lastPage
      }
      data {
        id
        message_title
        message_body
        utc_date_time
        seen
        notifiable {
          ... on Request {
            id
            __typename
            status
            time_from
            time_to
            req_day
            payable_interval_in_time
            interval_in_time
            add_info
            sign_in_status
            from_home
            actual_sign_in_out {
              actualSignIn
              actualSignOut
            }
            sign_in_time
            sign_out_time
            day_type
            requestType {
              id

              name
            }
            action_by {
              name
              id
            }
            employee {
              user {
                id
                name
              }
            }
            canCancel
            workTimingId {
              id
            }
          }

          ... on Sign_in_out {
            id
            __typename
            sign_in_time
            sign_out_time
            signed_in_outside_specified_locations
            signed_out_outside_specified_locations
            employee {
              name
              id
            }
          }
          ... on Penalty {
            id
            __typename
            date
            employee {
              user {
                id
                name
              }
            }
            penalty_reason
            quantity
            deducted_from
          }
          ... on BreakInOut {
            id
            __typename
            date
            break_in_time
            break_out_time
            user {
              name
              id
            }
          }
          ... on RequestComments {
            id
            __typename
            updated_at
            user_id
            request_id
            comment
          }
          ... on CheckIn {
            __typename
            check_in_time
            check_out_time
          }
        }
      }
    }
  }
`;

export const exportLogsQuery = gql`
  query exportAttendanceLogs($input: exportSignInsInput) {
    exportAttendanceHistory(input: $input) {
      status
      message
      file
    }
  }
`;

export const exportBonusQuery = gql`
  query exportBonusesList($input: exportBonusesInput) {
    exportBonuses(input: $input) {
      status
      message
      file
    }
  }
`;

export const exportDeductionQuery = gql`
  query exportDeductionsList($input: exportDeductionsInput) {
    exportDeductions(input: $input) {
      status
      message
      file
    }
  }
`;

export const exportLeadsQuery = gql`
  query exportLeadsList($input: exportLeadsInput) {
    exportLeads(input: $input) {
      status
      message
      file
    }
  }
`;

export const fetchWorkGroupsQuery = gql`
  query fetchWorkGroups($first: Int, $page: Int, $name: String) {
    workGroups(first: $first, page: $page, input: { name: $name }) {
      data {
        canEdit
        canDelete
        id
        name
        managers {
          id
          name
        }
        employees {
          user {
            id
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const fetchWorkGroupModalQuery = gql`
  query fetchWorkGroupModal($id: ID!, $isEditing: Boolean!) {
    workGroup(id: $id) @include(if: $isEditing) {
      id
      name
      managers {
        id
        name
      }
      employees {
        user {
          id
          name
        }
      }
      workTimings {
        id
        name
      }
      locations: spaces {
        id
        name
      }
    }

    managers: users_by_role(
      first: 9999
      input: { status: ["Active"], page_flag: null }
    ) {
      data {
        id
        name
        emp_code
      }
    }

    profile {
      company {
        businessPartners {
          user {
            id
            name
          }
        }
      }
    }

    employees: shiftBasedNotAttachedToAnyWorkGroupYet {
      user {
        id
        name
      }
    }

    offices: company_offices(first: 999) {
      data {
        id
        name
      }
    }

    work_timings_menu(new_versions_only: true, type: null) {
      id
      name
    }
  }
`;

export const fetchWorkTeamsQuery = gql`
  query fetchWorkTeams(
    $first: Int
    $page: Int
    $name: String
    $workGroupId: ID
  ) {
    workTeams(
      first: $first
      page: $page
      input: { name: $name, workGroupId: $workGroupId }
    ) {
      data {
        id
        name
        workGroup {
          id
          name
        }
        created_at
        employees: relatedEmployees {
          user {
            id
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    workGroupsOptions: workGroups(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchWorkTeamQuery = gql`
  query fetchWorkTeam($id: ID!) {
    workTeam(id: $id) {
      id
      name
      canEdit
      canDelete
      workGroup {
        id
        name
      }
    }
  }
`;

export const fetchWorkTeamEmployeesQuery = gql`
  query fetchWorkTeamEmployees(
    $id: ID!
    $name: String
    $first: Int
    $page: Int
  ) {
    teamEmployees(id: $id, name: $name, first: $first, page: $page) {
      data {
        user {
          id
          name
          profile_picture {
            path
          }
          user_type
          position {
            name
          }
          department {
            name
          }
        }
        workTeamEmployee(workTeamId: $id) {
          start
          end
          work_team_id
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const fetchWorkTeamModalQuery = gql`
  query fetchWorkTeamModal {
    work_groups_menu {
      id
      name
      employees {
        relevantWorkTeam {
          id
          name
        }
        user {
          id
          name
        }
        relevantAttendanceTypeConfiguration {
          join_from
          workTeam {
            name
          }
        }
      }
    }
  }
`;

export const fetchAddWorkTeamMembersModalQuery = gql`
  query fetchAddWorkTeamMembersModal(
    $workGroupId: ID!
    $workTeamId: ID!
    $name: String = null
  ) {
    workGroup(id: $workGroupId) {
      employees {
        user {
          id
          name
        }
      }
    }

    teamEmployees(id: $workTeamId, name: $name) {
      data {
        user {
          id
          name
        }
      }
    }
  }
`;

export const fetchMoveWorkTeamMembersModalQuery = gql`
  query fetchMoveWorkTeamMembersModal($workGroupId: ID) {
    workTeams(first: 999, input: { workGroupId: $workGroupId }, menu: true) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchWorkTeamActionModalQuery = gql`
  query fetchWorkTeamActionModal($skipEmployees: Boolean!) {
    employees: users_by_role(first: 9999, input: { page_flag: null })
      @skip(if: $skipEmployees) {
      data {
        id
        name
      }
    }

    workTeams(first: 999, menu: true) @include(if: $skipEmployees) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchScheduleWorkGroupsQuery = gql`
  query fetchScheduleWorkGroups($start_date: Date!) {
    work_groups_menu(start_date: $start_date) {
      id
      name
      WorkTeams {
        id
        name
        work_group_id
        employees {
          user {
            id
            name
            emp_code
          }
        }
      }
      employeesNotInTeams {
        user {
          id
          name
          user_type
          emp_code
        }
      }
    }
  }
`;

export const fetchWorkScheduleQuery = gql`
  query fetchWorkSchedule($id: ID!, $authId: ID) {
    schedule(id: $id) {
      id
      employees {
        id
        name
        user_type
        emp_code
      }
      teams {
        id
        name
        work_group_id
      }
      workGroup {
        id
        name
        employees {
          user {
            id
            name
            user_type
            emp_code
          }
        }
        employeesNotInTeams {
          user {
            id
            name
            user_type
            emp_code
          }
        }
        WorkTeams {
          id
          name
          work_group_id
          employees {
            user {
              id
              name
              emp_code
            }
          }
        }
        locations: spaces {
          id
          name
        }
        workTimings: limitedWorkTimings(user_id: $authId) {
          id
          name
        }
      }
      repeat
      draft_date
      draft_employees
      break_into
      schedule_days
      blockedDates {
        resource
        start
        end
        allDay
      }
      scheduledEmployeesAndTeams {
        user_id
        work_team_id
      }
      start
    }
    schedule_shifts(id: $id) {
      id
      start
      end
      day_off
      schedule_detail_id
      work_timing_id
      location_id: space_id
      user
      team
      resource
      calendar_date
      title
      user_count
      first_two_users
      work_timing_name
      location_name
      shift_users
      shift_teams
    }
  }
`;

export const fetchScheduleShiftsQuery = gql`
  query fetchScheduleShifts($id: ID!) {
    schedule_shifts(id: $id) {
      id
      start
      end
      day_off
      schedule_detail_id
      work_timing_id
      location_id: space_id
      user
      team
      resource
      calendar_date
      title
      user_count
      first_two_users
      work_timing_name
    }
  }
`;

export const fetchScheduleResourcesQuery = gql`
  query fetchScheduleResources($schedule_id: ID!) {
    resources(schedule_id: $schedule_id) {
      id
      name
      type
      orderby
      countryCode
      team_members
    }
  }
`;

export const fetchShiftQuery = gql`
  query fetchShift($id: ID!) {
    shift(id: $id) {
      id
      schedule_id
      scheduleDetails {
        id
        weight
        day_off
        day_order
        start
        end
        draft_date
        workPlaces {
          id
          name
        }
        workPlacesOut {
          id
          name
        }
        workplace_setting
        workplace_setting_signout
        workplace_setting_identical
        employees {
          user {
            id
            name
            user_type
          }
        }
        WorkTiming {
          id
          name
        }
        FirstHalfWorkTiming {
          id
          name
        }
        SecondHalfWorkTiming {
          id
          name
        }
        allow_half_day
        shift_work_teams {
          id
          name
          work_group_id
          user_type
          schedule_detail_employee_id
        }
        location: space {
          id
          name
        }
        alternativeTiming {
          work_timing {
            id
            name
          }
          work_timing_id
          work_timing_first_half {
            id
            name
          }
          first_half_work_timing_id
          work_timing_second_half {
            id
            name
          }
          second_half_work_timing_id
          weight
          cost_center {
            id
          }
        }
        cost_center {
          id
        }
      }
    }
  }
`;

export const fetchShiftEmployeesQuery = gql`
  query fetchShiftEmployee($id: String!) {
    ScheduleDetail(id: $id) {
      id
      weight
      day_off
      day_order
      start
      end
      draft_date
      workPlacesIn {
        id
        name
      }
      workPlacesOut {
        id
        name
      }
      workplace_setting
      workplace_setting_signout
      workplace_setting_identical
      employees {
        user {
          id
          name
          user_type
        }
      }
      WorkTiming {
        id
        name
      }
      FirstHalfWorkTiming {
        id
        name
      }
      SecondHalfWorkTiming {
        id
        name
      }
      allow_half_day
      shift_work_teams {
        id
        name
        work_group_id
        user_type
        schedule_detail_employee_id
      }
      location: space {
        id
        name
      }
      alternativeTiming {
        work_timing {
          id
          name
        }
        work_timing_id
        work_timing_first_half {
          id
          name
        }
        first_half_work_timing_id
        work_timing_second_half {
          id
          name
        }
        second_half_work_timing_id
        weight

        cost_center {
          id
        }
      }
      cost_center {
        id
      }
    }
  }
`;

export const fetchWorkCalendarQuery = gql`
  query fetchWorkCalendar(
    $input: calendarInput
    $page_flag: String = "calendar"
  ) {
    calendar(input: $input, page_flag: $page_flag) {
      id
      start
      end
      day_off
    }

    work_timings_menu {
      id
      name
    }

    workGroups: work_groups_menu {
      id
      name
    }

    employees: users_by_role(first: 9999, input: { page_flag: null }) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchWorkCalendarLocations = gql`
  query fetchCalendarLocations {
    calendarLocations {
      id
      name
      lng
      lat
    }
  }
`;

export const fetchEmployeeWorkSchedulesQuery = gql`
  query fetchEmployeeWorkSchedules($id: ID!) {
    employee(id: $id) {
      user {
        active
      }
      attendanceTypeConfigurations {
        id
        __typename
        type
        start
        end
        canEdit
        canDelete
        canEditStartDate
        join_from
        is_suspension
        logs {
          id
        }
        attendanceProfile {
          id
          __typename
          name
          default_office_holiday_profile
          allow_work_on_day_off
          allow_multiple_check_ins
          checkInForm {
            id
            name
          }
          checkOutForm {
            id
            name
          }
          week_start_day
          activeConfiguration {
            allow_half_day
            first_half_work_timing {
              name
            }
            second_half_work_timing {
              name
            }
          }
          dayOffExceptionConfig {
            ...exceptionConfig
            workplace_setting
            workplace_setting_signout
            workplace_setting_identical
            additional_work_places: workPlacesIn
            additional_work_places_signout: workPlacesOut
            offices {
              id
              name
            }
          }

          check_in_distance_setting

          checkInSetting {
            id
            face_recognition_in
            face_recognition_out
            max_distance
            workplace_setting
            workplace_setting_signout
            workplace_setting_identical
            workPlaces
            workPlacesIn
            workPlacesOut
          }

          same_exception_config

          allow_work_on_holiday

          holidayExceptionConfig {
            ...exceptionConfig
            workplace_setting
            workplace_setting_signout
            workplace_setting_identical
            additional_work_places: workPlacesIn
            additional_work_places_signout: workPlacesOut
          }
        }
        workGroup {
          id
          __typename
          name
          WorkTeams {
            id
            __typename
            name
          }
        }
        workTeam {
          id
          __typename
          name
        }
        workTeams {
          id
          __typename
          workTeam {
            id
            __typename
            name
          }
          start
          end
          canDelete
          canEdit
        }
        workRemotelySettings {
          can_work_home
          employee_can_request
          max_homeDays_per_week
          flexible_home
          can_ex_days
          home_days
          allow_work_remotely_cutoff
          work_remotely_cutoff
        }

        requestSettings{
          period_type
          request_limits
          request_type_id
        }
      }
    }
  }
  ${dayOffHolidayExceptionConfigFragment}
`;

// handle fetch employee logs query
export const fetchEmployeeLogsQuery = gql`
  query fetchEmployeeLogs($id: ID!, $employeeAttTypeId: Int) {
    employee(id: $id) {
      user {
        id
        name
      }
      relevantAttendanceTypeConfigurationById(id: $employeeAttTypeId) {
        logs {
          id
          event
          description
          created_at
          converted_created_at
        }
      }
    }
  }
`;

export const fetchAttendanceTypeModalOptions = gql`
  query fetchAttendanceTypeOptions($isTypeShift: Boolean!) {
    work_groups_menu @include(if: $isTypeShift) {
      id
      name
      WorkTeams {
        id
        name
      }
    }
    locations_menu(first: 999) @include(if: $isTypeShift) {
      data {
        id
        name
      }
    }
    cost_centers: cost_centers_menu(first: 999) @include(if: $isTypeShift) {
      data {
        id
        name
      }
    }
  }
`;

export const getAttendanceProfileQuery = gql`
  query getAttendanceProfile($archive: Date) {
    attendance_profiles: company_attendance_profiles(
      first: 999
      archive: $archive
    ) {
      data {
        id
        name
        workdays
      }
    }
  }
`;

export const getCalculatedTaxesQuery = gql`
  query getCalculatedTaxes($input: CalculateTaxRealtimeInput) {
    calculateTaxRealtime(input: $input) {
      tax_value
      tax_info_id
      tax_details
    }
  }
`;

export const bonusDetailsQuery = gql`
  query fetchBonusDetails($user_id: ID, $month_year: String) {
    detailsData: user_bonuses_by_month_year(
      user_id: $user_id
      month_year: $month_year
    ) {
      id
      value
      amount
      name
      description
      currency {
        name
        symbol
      }
      payroll_at
      payment_factor_id
      createdBy {
        name
      }
    }
  }
`;

export const overtimeDetailsQuery = gql`
  query fetchovertimeDetails($user_id: ID, $month_year: String) {
    detailsData: user_overtimes_by_payable_month_year(
      user_id: $user_id
      month_year: $month_year
    ) {
      req_day
      interval_in_time
      payable_interval_in_time
      amount
      accepted_by
      payable_at
      month_year_payroll
    }
  }
`;

export const penaltyDetailsQuery = gql`
  query fetchPenaltyDetails($user_id: ID, $month_year_record_id: ID) {
    detailsData: user_penalties_by_month_year(
      user_id: $user_id
      month_year_record_id: $month_year_record_id
    ) {
      date
      quantity
      deducted_from
      quantity_unit
      apply_flag
      PenaltyAmount {
        amount
      }
      full_cause
    }
  }
`;
export const allowancesDetailsQuery = gql`
  query fetchAllowancesDetails($month_year_record_id: ID) {
    detailsData: payroll(id: $month_year_record_id) {
      userAllowancesHistory {
        id
        date
        allowance {
          name
        }
        amount
        target_amount
      }
    }
  }
`;

export const otherDeductablesDetailsQuery = gql`
  query fetchOtherDeductablesDetailsQuery($payroll_id: ID) {
    detailsData: all_other_details_payroll_deducted_amount_by_month_year(
      payroll_id: $payroll_id
    ) {
      deductions {
        id
        value
        amount
        name
        description
        currency {
          name
          symbol
        }
        payroll_at
        payment_factor_id
        createdBy {
          name
        }
      }
      deductibles: other_deductibles {
        id
        date
        deductible {
          name
          id
        }
        month_year_payroll
        amount
        target_amount
      }
      installments {
        amount
        loan {
          name
          amount
        }
      }
      taxSettlements {
        id
        start
        end
        year
        value
      }
    }
  }
`;

export const otherAddedDetailsQuery = gql`
  query fetchOtherAddedDetailsQuery($payroll_id: ID) {
    detailsData: all_other_details_payroll_added_amount_by_month_year(
      payroll_id: $payroll_id
    ) {
      claims {
        amount
        actionBy {
          name
        }
        reason
        incurred_at
      }
      loans {
        name
        amount
        createdBy {
          name
        }
      }

      taxSettlements {
        id
        start
        end
        year
        value
      }
    }
  }
`;

export const fetchEmployeesFacialRecognitionReport = gql`
  query fetchEmployeeProfileAttendanceLogs(
    $page: Int
    $perPage: Int!
    $to: String
    $from: String
    $emp_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $isIncludeFilters: Boolean = true
  ) {
    offices: company_offices(first: 9999) @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 9999)
      @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    employees: users_by_role(
      first: 9999
      input: { page_flag: "attendance_history_list" }
    ) @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    employeeAttendanceLogs: users_sign_in_outs_by_role(
      first: $perPage
      page: $page
      input: {
        emp_id: $emp_id
        office_id: $office_id
        department_id: $department_id
        date_range: { to: $to, from: $from }
        has_face_recognation: true
        page: "history_logs"
      }
    ) {
      data {
        id
        date
        sign_in_time
        sign_in_lng
        sign_in_lat
        sign_out_time
        sign_out_lng
        sign_out_lat
        checkIns {
          check_in_lng
          check_in_lat
          check_out_lng
          check_out_lat
          check_in_time
          check_out_time
          liveness_ratio_in
          liveness_ratio_out
          mocked_location_in
          mocked_location_out
          checkInSpace {
            name
          }
          checkOutSpace {
            name
          }
          checkInFace {
            path
          }
          checkOutFace {
            path
          }
        }
        sign_in_face {
          path
        }
        sign_out_face {
          path
        }
        signInSpace {
          name
        }
        signOutSpace {
          name
        }
        liveness_ratio_in
        liveness_ratio_out
        mocked_location_in
        mocked_location_out
        employee {
          name
          id
          manager {
            id
          }
          copied_managers {
            id
          }
          face {
            path
          }
        }
      }
      paginatorInfo {
        count
        perPage
        lastItem
        firstItem
        currentPage
        hasMorePages
      }
    }
  }
`;

export const fetchEditAttendanceEmpProfile = gql`
  query fetchEditAttendanceEmpProfile($id: ID!, $date: String) {
    employee(id: $id) {
      on_probation
      permission_leaves_break_setting(date: $date) {
        annualLeaveSetting {
          allow_annual_leaves
          allowed_annual_leaves
          allowed_emergency_leaves
        }
        unpaidLeaveSetting {
          unpaid_leaves
        }
        sickLeaveSetting {
          allowed_sick_leaves
        }
        probationPeriodConfiguration {
          request_leaves
        }
      }
    }
    getEmployeeProfile(employeeID: $id, date: $date) {
      id
      can_request_half_day
    }
  }
`;

export const workCalendarFiltersQuery = gql`
  query workCalendarFiltersQuery {
    employees: employees_i_manage(input: { status: ["Active"] }) {
      id
      name
      emp_code
    }
    work_places: company_offices(first: 999) {
      data {
        id
        name
      }
    }
    work_groups_i_manage {
      id
      name
    }
    work_teams_i_manage {
      id
      name
    }
  }
`;

export const calendarQuery = gql`
  query calendarQuery(
    $input: calendarInput
    $page_flag: String = "calendar"
    $includeDrafts: Boolean! = false
    $skipCalendar: Boolean! = false
  ) {
    calendar(input: $input, page_flag: $page_flag) @skip(if: $skipCalendar) {
      results {
        id
        name
        schedule_type
        resource
        user_id
        user_name
        user_position
        sign_in_req
        weekend
        schedule_detail_employee_id
        start
        resource
        user_count
        first_two_users
        work_timing_names
        location_name
        shift_users
        shift_teams
        sign_in_data
        request_data
        alternative_normal_work_timing_names
        cost_center_name
      }
      resources {
        id
        name
      }
    }
    calendarResources(input: $input) @skip(if: $skipCalendar) {
      id
      name
    }
    draftSchedules(first: 999) @include(if: $includeDrafts) {
      data {
        id
      }
    }
  }
`;

export const draftSchedulesQuery = gql`
  query draftSchedulesQuery(
    $page: Int
    $workGroupId: ID
    $includeWorkGroups: Boolean!
  ) {
    workGroups @include(if: $includeWorkGroups) {
      data {
        id
        name
      }
    }
    draftSchedules(first: 8, page: $page, workGroupId: $workGroupId) {
      data {
        id
        work_group_id
        updated_at
        schedule_days
        start
        repeat
        workGroup {
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const relevantAssignmentsQuery = gql`
  query relevantAssignmentsQuery($input: RelevantAssignmentsInput!) {
    relevantAssignments(first: 999, input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const fetchEmployeesQuery = gql`
  query fetchEmployees(
    $pageFlag: String = "attendance_history_list"
    $status: [String]
  ) {
    employees: users_by_role(
      first: 9999
      input: { page_flag: $pageFlag, status: $status }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const applicableWorktimingsQuery = gql`
  query applicableWorktimings(
    $employee_id: ID
    $date: String!
    $for: String!
    $includeHalfdayWorktimings: Boolean!
  ) {
    employee(id: $employee_id) {
      applicableWorkTimings(date: $date, for: $for) {
        default {
          ... on AttendanceProfileWorkTiming {
            __typename
            id
            work_timing {
              name
            }
          }
          ... on ScheduleDetailEmployee {
            __typename
            id
            employeeHasShift(date: $date, emp_id: $employee_id)
            ScheduleDetail {
              WorkTiming {
                id
                name
              }
              alternativeTiming {
                work_timing {
                  id
                  name
                }
              }
              alternativeTimingHavingHalf
                @include(if: $includeHalfdayWorktimings) {
                work_timing {
                  id
                  name
                }
              }
            }
          }
        }
        exceptions {
          __typename
          id
          normalWorkTiming {
            name
          }
        }
      }
    }
  }
`;

export const fetchCheckInFormsQuery = gql`
  query fetchCheckOutForms {
    checkInOutForms {
      ...checkInOut
    }
  }
  ${checkInOutFragment}
`;

export const getEmployeeProfile = gql`
  query getEmployeeProfile($employeeID: ID, $date: String) {
    getEmployeeProfile(employeeID: $employeeID, date: $date) {
      id
      isHalfDayAvailable
      can_request_half_day
    }
    employee(id: $employeeID) {
      probationPeriod {
        end_of_probation
      }
      relevantAttendanceTypeConfiguration(date: $date) {
        workRemotelySettings {
          can_work_home
          employee_can_request
          can_ex_days
          home_days
          flexible_home
        }
      }
      permission_leaves_break_setting(date: $date) {
        submitRequestAbility {
          id
          annual_emergency_leave
          annual_emergency_half_day
          unpaid_leave
          unpaid_half_day
          sick_leave
          permission
        }
        annualLeaveSetting {
          allow_annual_leaves
          allowed_emergency_leaves
          leave_basis
        }
        sickLeaveSetting {
          sick_leaves
        }
        unpaidLeaveSetting {
          unpaid_leaves
        }
        probationPeriodConfiguration {
          request_leaves
        }
        permissionSetting {
          allow_permissions
          min_permission_unit
        }
        customLeaveSetting {
          allow_custom_leave
          bereavement_leave
          maternal_paternal_leave
          hajj_leave
          sabbatical_leave
          study_leave
          marriage_leave
          in_lieu_of_work_day_leave
          military_call_leave
        }
      }
    }
  }
`;
export const attendanceTypeConfigOptionsQuery = gql`
  query attendanceTypeConfigOptions($isOffice: Boolean!) {
    # Office Base

    # Shift Base
    workGroups: work_groups_menu @skip(if: $isOffice) {
      id
      name
    }
    workTeams(first: 999, menu: true, input: {}) @skip(if: $isOffice) {
      data {
        id
        name
        workGroup {
          id
        }
      }
    }
    offices: company_offices(first: 999) @skip(if: $isOffice) {
      data {
        id
        name
      }
    }
    normal_work_timings: work_timings_menu(
      new_versions_only: true
      type: "normal"
    ) @skip(if: $isOffice) {
      id
      name
    }
    half_work_timings: work_timings_menu(new_versions_only: true, type: "half")
      @skip(if: $isOffice) {
      id
      name
    }
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;
export const companyWeekStartDay = gql`
  query companyWeekStartDay {
    profile {
      company {
        id
        week_start_day
      }
    }
  }
`;

export const GET_TAX_INFOS = gql`
  query getTaxInfos {
    taxInfos {
      __typename
      id
      name
      from
      min_insurance_salary
      max_insurance_salary
      employee_insurance_percentage
      employer_insurance_percentage
      personal_exemption
      canEdit
      brackets {
        __typename
        id
        start
        end
        percentage
      }
    }
  }
`;

export const GET_TAX_INFO = gql`
  query getTaxInfo($id: ID!) {
    taxInfo(id: $id) {
      ...taxInfo
    }
  }
  ${taxInfoFragment}
`;

export const GET_LATEST_TAX_INFO = gql`
  query getLatestTaxInfo {
    latestTaxInfo(regulation_type_id: 2) {
      ...taxInfo
    }
  }
  ${taxInfoFragment}
`;

export const requestsApprovalsLayersListFilters = gql`
  query requestsApprovalsLayersListFilters {
    company_users(
      first: 999
      input: { page_flag: null, status: ["Active"], userTypes: ["Employee"] }
    ) {
      data {
        id
        name
        emp_code
        department {
          id
        }
        office {
          id
        }
        manager {
          id
        }
        copied_managers {
          id
        }
      }
    }

    company_departments(first: 999) {
      data {
        id
        name
      }
    }

    company_offices(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const requestApprovalRuleManagers = gql`
  query requestApprovalRuleManagers {
    company_users(input: { include_auth: 1, status: ["Active"] }) {
      data {
        id
        name
        emp_code
        department {
          id
        }
        office {
          id
        }
      }
    }
  }
`;

export const requestsApprovalLayersListQuery = gql`
  query requestsApprovalLayersListQuery(
    $page: Int
    $offices: [ID]
    $departments: [ID]
    $employees: [ID]
    $employee_search: String
  ) {
    request_multi_layer_rules(
      first: 10
      page: $page
      filters: {
        offices: $offices
        departments: $departments
        employees: $employees
        employee_search: $employee_search
      }
    ) {
      data {
        __typename
        id
        request_types {
          name
        }
        applicable {
          type {
            __typename
            ... on Office {
              id
              name
            }
            ... on Department {
              id
              name
            }
            ... on User {
              id
              name
            }
          }
        }
        layers {
          id
        }
        employees {
          id
          name
          manager {
            id
          }
          copied_managers {
            id
          }
        }
        created_at
        created_by {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const requestApprovalRule = gql`
  query requestApprovalRule($id: ID!) {
    company_users(input: { include_auth: 1, status: ["Active"] }) {
      data {
        id
        name
        department {
          id
        }
        office {
          id
        }
      }
    }

    request_multi_layer_rule(id: $id) {
      id
      __typename
      request_types {
        id
      }
      applicable {
        id
        type {
          __typename
          ... on Office {
            id
            name
          }
          ... on Department {
            id
            name
          }
          ... on User {
            id
            name
          }
        }
        applies_on_all
      }
      layers {
        level
        acceptors {
          id
          name
        }
      }
      employees {
        id
        name
      }
    }
  }
`;

export const GET_RELEVANT_REGULATION_TYPES = gql`
  query getRelevantRegulationTypes($countryId: ID!) {
    relevantRegulationTypes(country_id: $countryId) {
      id
      name
      hasTaxation
    }
  }
`;

export const GET_RELEVANT_STATES = gql`
  query getRelevantStates($countryId: ID!) {
    relevantStates(country_id: $countryId) {
      id
      name
    }
  }
`;

export const GET_RELEVANT_TAX_INFO = gql`
  query getRelevantTaxInfo($input: RequireTaxInfo) {
    relevantTaxInfo(input: $input) {
      ... on TaxInfo {
        id
        name
        from
        to
        type
        country {
          currency_id
        }
        employee_insurance_percentage
        employer_insurance_percentage
        insurance_salary_percentage
        min_insurance_salary
        max_insurance_salary
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const GET_RELEVANT_REGULATIONS_AND_STATES = gql`
  query getRelevantRegulationsAndStates($countryId: ID!) {
    relevantRegulationTypes(country_id: $countryId) {
      id
      name
      hasTaxation
      hasCustomizationOnStates
    }

    relevantStates(country_id: $countryId) {
      id
      name
    }
  }
`;

export const conflictModalQuery = gql`
  query conflictModal {
    failed_process: falied_process {
      id
      date
      attendanceProfile {
        name
      }
      oldWorkTiming {
        name
      }
      newWorkTiming {
        name
      }
    }
  }
`;

export const GET_SIGN_IN_LOGS = gql`
  query getSignInLogs($signInId: ID!) {
    signInOut(id: $signInId) {
      id
      logs {
        id
        event
        description
        created_at
      }
    }
  }
`;

export const getpositionsOrDepatmentsQuery = gql`
  query getpositionsOrDepatments($isDepartment: Boolean!) {
    company_departments(first: 999) @include(if: $isDepartment) {
      data {
        id
        name
      }
    }

    company_positions(first: 999) @skip(if: $isDepartment) {
      data {
        id
        name
      }
    }
  }
`;

export const dayOffSettingModalQuery = gql`
  query dayOffSettingModal {
    locations_menu(first: 999) {
      data {
        id
        name
      }
    }
    normal_work_timings: work_timings_menu(
      type: "normal"
      new_versions_only: true
    ) {
      id
      name
    }
    half_work_timings: work_timings_menu(
      type: "half"
      new_versions_only: true
    ) {
      id
      name
    }
  }
`;

export const changeCheckInsModalQuery = gql`
  query changeCheckInsModal {
    locations_menu(first: 999) {
      data {
        id
        name
      }
    }
    checkInOutForms {
      ...checkInOut
    }
  }
  ${checkInOutFragment}
`;

export const weeklyPayrollQuery = gql`
  query weeklyPayroll(
    $first: Int
    $page: Int
    $user_id: ID
    $office_ids: [ID]
    $type: String
    $paid_at: String
    $employee_search: String
    $currency_id: ID
    $status: String
    $department_id: ID # $management_type: String
    $isExpected: Int
    $isExternal: Int
    $isAuthPayroll: Boolean!
    $orderBy: [OrderByClause!]
  ) {
    payrollReport: payroll_v2(
      first: $first
      page: $page
      user_id: $user_id
      office_ids: $office_ids
      type: $type
      employee_search: $employee_search
      paid_at: $paid_at
      currency_id: $currency_id
      status: $status
      department_id: $department_id # management_type: $management_type
      isExpected: $isExpected
      isExternal: $isExternal
      orderBy: $orderBy
    ) {
      data {
        user {
          id
          name
          user_type
          payments {
            payment_method
          }
        }
        base_salary
        gross_salary
        paid
        pending
        allowances_amount: allowances
        bonuses_amount: bonuses
        overtime_amount: overtime
        extra_others: e_others
        penalties_amount: penalties
        social_amount: social
        medical_amount: medical
        taxes_amount: taxes
        deductible_others: d_others
        paid_at
        type
        status
        payable_amount: total
        details
        config_type: management_type
        currency {
          id
          symbol
        }
      }

      aggregator @skip(if: $isAuthPayroll)

      paginatorInfo @skip(if: $isAuthPayroll) {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_WEEK_OPTIONS = gql`
  query getAvaliableWeeks(
    $from: String
    $to: String
    $userId: Int = null
    $type: String = "Office"
    $filterKey: String = "paid_at"
  ) {
    weeks(
      from: $from
      to: $to
      id: $userId
      type: $type
      filterKey: $filterKey
    ) {
      from
      name
      paidAt
      status
    }
  }
`;

export const GET_PAYROLL_DETAILS = gql`
  query getPayrollDetails(
    $user_id: ID
    $paid_at: String
    $detailsType: String
    $payrollType: PayrollV2Type
    $from: String
    $isExpected: String
  ) {
    payroll_v2_details(
      user_id: $user_id
      paid_at: $paid_at
      type: $detailsType
      pType: $payrollType
      from: $from
      isExpected: $isExpected
    )
  }
`;

export const GET_PAYROLL_FILTERS_DATA = gql`
  query getPayrollFiltersData($office_id: ID) {
    payrollReportEmployeesFilter: users_by_role(
      input: { page_flag: "payroll_list", office_id: $office_id }
    ) {
      data {
        id
        name
      }
    }
    payrollReportCurrenciesFilter: currencies(office_id: $office_id) {
      id
      name
      symbol
    }
    payrollReportOfficesFilter: company_offices(first: 999, page: 1) {
      data {
        id
        name
        head_quarter
        currency {
          id
        }
        month_start_day
      }
    }

    company_departments(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_USER_RELATIVE_SALARY_CONFIGURATION = gql`
  query getUserRelativeSalaryConfiguration($userId: ID!, $date: String) {
    employee(id: $userId) {
      activeSalaryConfig(date: $date) {
        payment
      }
    }
  }
`;

export const GET_EMPLOYEE_ACTIVE_SALARY_CONFIG = gql`
  query getEmployeeActiveSalaryConfig($empId: ID!, $date: String) {
    employee(id: $empId) {
      suspendedSalaryConfiguration(date: $date) {
        from
      }
      permission_leaves_break_setting {
        id
        sickLeaveSetting {
          sick_leaves
        }
        annualLeaveSetting {
          allow_annual_leaves
          allowed_emergency_leaves
        }
      }
    }
  }
`;

export const GET_PENALTY_DETAILS = gql`
  query getPenaltyDetails($id: ID!) {
    penalty(id: $id) {
      id
      quantity
      deducted_from
      hasComplementaryPenalty
      penalty_reason
      apply_flag
      canEdit
      canApply
      edited
      quantity_unit
      sign_in_out {
        sign_in_time
        sign_out_time
      }
    }
  }
`;

export const requestChangeShiftsModalQuery = gql`
  query requestChangeShiftsModal($workTimmingDate: Date) {
    work_timings_menu(
      new_versions_only: true
      archive: $workTimmingDate
      type: "normal"
    ) {
      id
      name
      work_timing_profile_type
    }
    half_work_timings: work_timings_menu(
      new_versions_only: true
      archive: $workTimmingDate
      type: "half"
    ) {
      id
      name
    }
    locations_menu(first: 999) {
      data {
        id
        name
      }
    }
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;

export const workTimingsQuery = gql`
  query workTimings($date: Date) {
    work_timings_menu(new_versions_only: true, archive: $date, type: null) {
      id
      name
      work_timing_profile_type
    }
  }
`;

export const countryRelatedFieldsQuery = gql`
  query countryRelatedFields {
    profile {
      company {
        offices {
          country {
            name
            id
            customFields {
              id
              country_id
              field_name
              field_title
            }
          }
        }
      }
    }
  }
`;

export const GET_WORKING_TIMMING_OPTIONS_QUERY = gql`
  query getWorkingTimmingOptions($work_timming_date: Date) {
    work_timings_menu(
      type: "normal"
      new_versions_only: true
      archive: $work_timming_date
    ) {
      id
      name
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      work_timing_profile_type
      weight
    }
    half_work_timings_menu: work_timings_menu(
      type: "half"
      new_versions_only: true
      archive: $work_timming_date
    ) {
      id
      name
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      work_timing_profile_type
      weight
    }
  }
`;

export const myLoanRequestsQuery = gql`
  query myLoanRequests {
    myLoanRequests(first: 999, input: { status: null }) {
      data {
        id
        name
        amount
      }
    }
  }
`;

export const globalSearchQuery = gql`
  query fetchUsers($input: UserFilterInput) {
    employees: users_by_role(first: 25, page: 1, input: $input) {
      data {
        active
        id
        name
        emp_code
        department {
          name
        }
        position {
          name
        }
        profile_picture {
          path
        }
        face {
          path
        }
        office {
          id
          name
        }
      }
    }
  }
`;

export const fetchLoanRequestsQuery = gql`
  query fetchLoanRequests(
    $filters: LoanRequestFilter
    $perPage: Int!
    $page: Int!
  ) {
    loanRequests(first: $perPage, page: $page, input: $filters) {
      data {
        id
        employee {
          id
          name
          copied_managers {
            id
          }
          manager {
            id
          }
        }
        status
        name
        amount
        notes
        canCancel
        canAccept
        canReject
        pendingAcceptors
        layerApproval {
          isApproved
          level
          acceptor {
            id
            name
          }
        }
        installments_number
        installments {
          id
          amount
          payment_date
        }
        starting_from
        granted_on
        include_payroll
        currency_id
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    employees: company_users(first: 9999, input: {}) {
      data {
        id
        name
      }
    }
  }
`;

// fetch loans currency query
export const loansCurrencyQuery = gql`
  query fetchLoansCurrency {
    currencies {
      id
      name
    }
  }
`;

// fetch company activites query
export const fetchCompanyActivitesQuery = gql`
  query fetchCompanyActivites(
    $input: companyActivitiesFilter
    $first: Int!
    $page: Int!
  ) {
    company_activities_list(first: $first, page: $page, input: $input) {
      data {
        id
        type
        contact {
          id
          name
          email
          position
          phone
          phone_country_code
          description
        }
        attachments {
          id
          file {
            id
            path
          }
          name
        }
        account_manager {
          id
          name
        }
        comments {
          id
          comment_id
          comment
          created_at
          user {
            id
            name
          }
        }
        date
        notes
        company {
          id
          name
          contacts {
            id
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

// fetch company reminders query
export const fetchCompanyRemindersQuery = gql`
  query fetchCompanyReminders(
    $input: CompanyReminderFilterInput
    $first: Int!
    $page: Int!
  ) {
    company_reminders(first: $first, page: $page, input: $input) {
      data {
        id
        type
        contact {
          id
          name
          email
          position
          phone
          phone_country_code
          description
        }
        attachments {
          id
          file {
            id
            path
          }
          name
        }
        # account_manager {
        #   id
        #   name
        # }
        comments {
          id
          comment_id
          comment
          created_at
          user {
            id
            name
          }
        }
        status
        date
        notes
        company {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query getCompanyContacts($id: ID) {
    company(id: $id) {
      id
      contacts {
        id
        name
        email
        position
        phone
        phone_country_code
        description
      }
    }
  }
`;

export const FETCH_ADMIN_USERS = gql`
  query fetchAdminUsers {
    active_saas_users(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

// fetch allowed work timing options
export const fetchAllowedWorkTimingOptionsQuery = gql`
  query fetchAllowedWorkTimingOptions {
    employees: company_users(
      first: 9999
      input: { status: ["Active"], include_auth: 1 }
    ) {
      data {
        id
        name
      }
    }
    company_departments(first: 999) {
      data {
        id
        name
      }
    }
    company_offices(first: 999) {
      data {
        id
        name
      }
    }
    company_positions(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;

// handle fetch suspension and activation records for employee query
export const fetchSuspensionActivationRecordsQuery = gql`
  query fetchActivationSuspensionList($employeeID: ID!) {
    getActiveAndSuspensionIntervals(employeeID: $employeeID) {
      status
      from
      to
    }
    work_timings_menu(new_versions_only: true) {
      id
      name
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      work_timing_profile_type
      weight
    }
    company_offices(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_COST_CENTER_FORM_OPTIONS = gql`
  query getCostCenterFormOptions {
    profile {
      company {
        allowances {
          value: id
          label: name
        }
      }
    }
  }
`;

export const GET_COST_CENTERS = gql`
  query getCostCenter($name: String, $page: Int, $isActive: Boolean) {
    cost_centers(first: 20, name: $name, page: $page, is_active: $isActive) {
      data {
        __typename
        id
        name
        allowanceConfiguration {
          from
          allowances {
            id
            name
            paymentFactor
            value
            monthDays
            payableOn
          }
        }
        is_active
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_COST_CENTER = gql`
  query getCostCenter($id: ID!) {
    cost_center(id: $id) {
      id
      name
    }
  }
`;

export const COST_CENTERS_REPORT_FILTERS_OPTIONS = gql`
  query costCentersReportFiltersOptions {
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }

    users_by_role(first: 9999, input: { status: ["Active"] }) {
      data {
        id
        name
      }
    }

    currencies {
      id
      name
      defaultCurrencyOffice
    }
  }
`;

export const COST_CENTERS_REPORT = gql`
  query costCentersReport(
    $id: String
    $userId: [Int]
    $currencyId: Int
    $month: Int
    $year: Int
    $paid_at: String
  ) {
    payroll_v2_cost_center(
      id: $id
      userId: $userId
      currencyId: $currencyId
      month: $month
      year: $year
      paid_at: $paid_at
    ) {
      id
      name
      salary
      allowance
      bonus
      overtime
      penalty
      claim
      medical
      social
      tax
      otherDeduction
      total
      json
    }
  }
`;

export const GET_COST_CENTERS_MENU = gql`
  query getCostCenterMenu {
    cost_centers: cost_centers_menu(first: 9999) {
      data {
        id
        name
      }
    }
  }
`; // EMployee Qeury
export const FETCH_MEPLOYEE_LEAVE_BREAK_PROFILES = gql`
  query fetchUserLeaveBreakProfiles($id: ID) {
    user(id: $id) {
      id
      employee {
        permissionLeavesBreakSettingUser {
          id
          user_id
          start
          end
          apply_from
          canEdit
          canDelete
          permissionLeavesBreakSettings {
            id
            name
            created_at
            updated_at
            currentVersion {
              id
              name
              created_at
              updated_at
            }
          }
          logs {
            start
            end
            permissionLeavesBreakSettings {
              id
              name
              created_at
              updated_at
              currentVersion {
                id
                name
                created_at
                updated_at
              }
              permissionSetting {
                allow_permissions
              }
              annualLeaveSetting {
                allow_annual_leaves
              }
              sickLeaveSetting {
                sick_leaves
              }
              unpaidLeaveSetting {
                unpaid_leaves
              }
              customLeaveSetting {
                allow_custom_leave
                bereavement_leave
                maternal_paternal_leave
                bereavement_leave
                hajj_leave
                sabbatical_leave
                study_leave
                marriage_leave
                in_lieu_of_work_day_leave
                military_call_leave
              }
            }
          }
        }
      }
    }
  }
`;

// menu Query for options leave and break settings

export const FETCH_MENU_LEAVE_AND_BREAK_SETTINGS = gql`
  query fetchMenuLeaveAndBreakSettings {
    company_leaves_breaks: PermissionLeavesBreakSettingsForMenu(first: 999) {
      data {
        id
        name
        currentVersion {
          id
          name
          created_at
          updated_at
        }
        children {
          id
          name
          currentVersion {
            id
            name
            created_at
            updated_at
          }
        }
      }
    }
  }
`;
