import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";

import {
  showToast,
  serializeAnnouncementFetching,
  serializeAnnouncementUpsert,
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";
import {
  onInputResetAction,
  setFormServerValidationAction,
} from "../../Store/Actions";
import {
  editAnnouncementMutation,
  storeAnnouncementMutation,
} from "../../Graphql/mutation";
import { announcementFormQuery } from "../../Graphql/query";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import MainModal from "../MainModal";
import Dropzone from "react-dropzone-uploader";
import LoadingButton from "@mui/lab/LoadingButton";

const bodyLimit = 280;
const formName = "announcementForm";
const formClientValidation = "announcementClientValidations";
const formServerValidation = "announcementServerValidations";
const sendToOptions = [
  {
    label: "Send to employees belonging to specific offices",
    value: "offices",
  },
  {
    label: "Send to employees belonging to specific departments",
    value: "departments",
  },
  {
    label: "Send to employees in specific positions",
    value: "positions",
  },
  {
    label: "Send to specific employees",
    value: "employees",
  },
];
const sendToAdminOptions = [
  {
    label: "Send to all company employees",
    value: "ALL",
  },
  {
    label: "Send to business partners only",
    value: "BUSINESS_PARTNERS_ONLY",
  },
  {
    label: "Send to Managers and Business Partners",
    value: "MANAGERS_AND_BUSINESS_PARTNERS",
  },
];

const AnnouncementsModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [modalMsg, setModalMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [options, setOptions] = React.useState({
    offices: [],
    managers: [],
    positions: [],
    employees: [],
    companies: [],
    departments: [],
  });

  // images uploader init state
  const [requestFiles, setRequestFiles] = React.useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  // Reducer State
  const formData = useSelector((state) => state.super[formName]);
  const clientValidation = useSelector(
    (state) => state.super[formClientValidation]
  );

  // Server State
  const [storeAnnouncement, { loading: storeLoading }] = useMutation(
    storeAnnouncementMutation
  );
  const [editAnnouncement, { loading: editLoading }] = useMutation(
    editAnnouncementMutation
  );
  const { loading } = useQuery(announcementFormQuery, {
    variables: {
      id: data?.id,
      isAdminPanel: data?.isAdminPanel,
      isIncludeAnnouncement: data?.isEdit,
    },
    onCompleted: ({
      announcement = {},
      offices,
      managers,
      positions,
      companies,
      employees,
      departments,
    }) => {
      // Options
      setOptions({
        managers: managers || [],
        offices: offices?.data || [],
        companies: companies?.data || [],
        positions: positions?.data || [],
        employees: employees?.data || [],
        departments: departments?.data || [],
      });

      // data
      if (data?.isEdit) {
        dispatch(
          setFormServerValidationAction({
            serverValidationName: formName,
            validations: { ...serializeAnnouncementFetching(announcement) },
          })
        );
      }
    },
  });

  // Constants
  const formProps = {
    formName,
    formSubmitting,
    formServerValidation,
    formNameValidation: formClientValidation,
  };

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const isInvalidClient = () => {
    if (clientValidation.length || formData.body.length > bodyLimit)
      return true;

    if (!formData?.is_all) {
      if (
        !formData?.offices?.length &&
        !formData?.employees?.length &&
        !formData?.managers?.length &&
        !formData?.positions?.length &&
        !formData?.departments?.length
      ) {
        setModalMsg(t("you should select at least one destination"));
        return true;
      }
    }

    return false;
  };

  const onUpsert = (isPublish) => {
    setFormSubmitting(true);
    if (isInvalidClient()) return;

    const upsert = data?.isEdit ? editAnnouncement : storeAnnouncement;
    upsert({
      variables: {
        ...serializeAnnouncementUpsert({
          isAdminPanel: data?.isAdminPanel,
          publish: isPublish,
          ...formData,
          ...(!!serializedAttachemnts[0]
            ? {
                file: { ...serializedAttachemnts[0] },
              }
            : {}),
        }),
      },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });
  };

  // initail vlaue for requestFiles set to empty array

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // Start of handle image uploader on change function
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };
  // End of handle image uploader on change function

  const handlePublish = () => {
    onUpsert(1);
  };

  const handleSaveDraft = () => {
    onUpsert(0);
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onInputResetAction(formName, "title"));
    dispatch(onInputResetAction(formName, "body"));
    data?.isAdminPanel && dispatch(onInputResetAction(formName, "companies"));
    dispatch(
      onInputResetAction(
        formName,
        data?.isAdminPanel ? "sendToState" : "is_all"
      )
    );
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      modalTitle={`${data?.isEdit ? "Edit" : "New"} Announcement`}
      modalFooterComponent={() => (
        <>
          <LoadingButton
            disableElevation
            disableRipple
            size="small"
            variant="outlined"
            onClick={handleSaveDraft}
            sx={{ marginInlineEnd: 1 }}
            loading={data?.isEdit ? editLoading : storeLoading}
          >
            {t("save as draft")}
          </LoadingButton>
          <LoadingButton
            disableElevation
            disableRipple
            size="small"
            variant="contained"
            onClick={handlePublish}
            loading={data?.isEdit ? editLoading : storeLoading}
          >
            {t("save and publish")}
          </LoadingButton>
        </>
      )}
    >
      {data?.isEdit && loading ? <Loader fixed /> : null}

      <InputForm
        {...formProps}
        name="title"
        label="title"
        containerStyle=" "
        validateBy="textRequired"
        validationName="input.title"
      />
      <div
        className={`my-3${
          formData.body.length > bodyLimit ? " red-color" : ""
        }`}
      >
        <InputForm
          isMultiLines
          {...formProps}
          name="body"
          label="body"
          containerStyle=" "
          inputStyle="text-inherit"
          labelStyle="text-inherit"
          validateBy="textRequired"
          validationName="input.body"
        />
        <p className="ml-auto mt-1 mb-0 fit-width opacity-7">
          {formData.body.length} from {bodyLimit}
        </p>
      </div>

      {/* Send To */}
      <span>{t("send to")}</span>
      {data?.isAdminPanel ? (
        <>
          <BSelect
            isMulti
            selectAll
            {...formProps}
            name="companies"
            isLoading={loading}
            placeholder="select companies"
            options={options?.companies}
            validateBy="textRequired"
          />
          <RadioboxForm
            {...formProps}
            name="sendToState"
            labelStyle="mb-2"
            optionInputStyle=" "
            optionItemStyle="px-0"
            optionsContainerStyle=" "
            options={sendToAdminOptions}
          />
        </>
      ) : (
        <>
          <CheckboxBooleanForm
            name="is_all"
            {...formProps}
            containerStyle="mb-3"
            options={["Everyone in the company"]}
          />
          {sendToOptions.map(({ label, value }) => (
            <React.Fragment key={value}>
              <CheckboxBooleanForm
                {...formProps}
                label={value}
                labelStyle="mb-2"
                containerStyle="mb-3"
                name={`is_${value}`}
                options={[label]}
                dependOn="is_all"
                dependancyType="equal"
                dependancyValue={[0]}
              />
              {value === "employees" ? (
                <BSelect
                  isMulti
                  {...formProps}
                  name="managers"
                  isLoading={loading}
                  placeholder="select managers"
                  options={options?.managers}
                  filterOption={handleFilterOptionByEmpCodeOrName}
                  dependOn="is_employees"
                  dependancyType="equal"
                  dependancyValue={[1]}
                />
              ) : null}
              <BSelect
                isMulti
                name={value}
                {...formProps}
                isLoading={loading}
                placeholder={`select ${value}`}
                options={options?.[value]}
                filterOption={handleFilterOptionByEmpCodeOrName}
                dependOn={`is_${value}`}
                dependancyType="equal"
                dependancyValue={[1]}
              />
              {value === "departments" ? (
                <CheckboxBooleanForm
                  {...formProps}
                  name="justManagers"
                  options={["Only send to department managers"]}
                  dependOn="is_departments"
                  dependancyType="equal"
                  dependancyValue={[1]}
                />
              ) : null}
            </React.Fragment>
          ))}
        </>
      )}

      {!data?.isEdit && (
        <div className="row">
          <div className="col-12 mt-1 documents_dropzone_style">
            <label className="mb-2 validity-label-style">{t("files")}</label>
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="attachments"
              maxFiles="1"
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
            />
          </div>
        </div>
      )}

      {modalMsg ? (
        <p role="alert" className="red-color mb-0">
          {modalMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default AnnouncementsModal;
