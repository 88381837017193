import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  hideBalanceModal,
  balanceModalAttempt,
  // setBalanceWeekRange,
  onInputChangeAction,
} from "../../Store/Actions";
import Constants from "../../Constants";
import * as REQUESTS from "../../Constants/Requests";
import HelpersConstants from "../../Helpers/Constants";
import { handleFilterOptionByEmpCodeOrName } from "../../Helpers/HelperFns";
import {
  BSelect,
  InputForm,
  RadioboxForm,
  CheckboxBooleanForm,
} from "form-builder";
import MainModal from "../../Components/MainModal";

const formName = "balanceModal";
const formNameValidation = "balanceModalValidation";
const formServerValidation = "balanceModalServerValidation";
const getYears = (outOfRangeYear) => {
  const minYear = new Date().getFullYear() - 1;
  let data = [],
    i;
  for (i = minYear; i <= minYear + 2; i++) {
    data.push({ value: i });
  }
  outOfRangeYear &&
    !data.map((obj) => obj.value).includes(+outOfRangeYear) &&
    data.push({ value: outOfRangeYear });
  return data;
};

const BalanceModal = ({ refetchQueries }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reducer State
  const {
    isLoading,
    modalMessage,
    isVissible: isModalVissible,
  } = useSelector((state) => state.super.balanceModalActions);
  const modalData = useSelector((state) => state.super.balanceModal);
  const profile = useSelector((state) => state.super.selectedEmployeeProfile);
  const employeesFilter = useSelector(
    (state) => state.super.logsEmployeesFilter
  );
  const serverVaildation = useSelector(
    (state) => state.super[formServerValidation]
  );
  const modalValidation = useSelector(
    (state) => state.super.balanceModalValidation
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  /* ↓ Helpers ↓ */

  const handleEmployeeChange = () => {
    let event = {
      target: {
        name: "apply_on_emergency",
        value: 0,
      },
    };
    dispatch(onInputChangeAction(formName, event));
  };

  const onEmployeeChange = useDidUpdateEffect(handleEmployeeChange, [
    modalData.emp_id,
  ]);

  const canHaveSickLeaves = () => {
    if (userId) {
      return profile?.employee?.permission_leaves_break_setting
        ?.sickLeaveSetting?.sick_leaves;
    } else {
      const currentEmployee = employeesFilter?.find(
        (emp) => emp.id === modalData?.emp_id
      );

      return currentEmployee?.employee
        ?.permission_leaves_break_setting?.sickLeaveSetting?.sick_leaves;
    }
  };

  const canHaveAnnualLeave = userId
    ? profile?.employee?.permission_leaves_break_setting
        ?.annualLeaveSetting?.allow_annual_leaves
    : employeesFilter?.filter((emp) => emp?.id == modalData.emp_id)[0]
        ?.employee?.permission_leaves_break_setting
        ?.annualLeaveSetting?.allow_annual_leaves;

  const canHavePermissions = userId
    ? profile?.employee?.permission_leaves_break_setting
        ?.permissionSetting?.allow_permissions
    : employeesFilter?.filter((emp) => emp?.id == modalData.emp_id)[0]
        ?.employee?.permission_leaves_break_setting?.permissionSetting
        ?.allow_permissions;

  const canHaveEmergencyLeaves = userId
    ? profile?.employee?.permission_leaves_break_setting
        ?.annualLeaveSetting?.allowed_emergency_leaves
    : employeesFilter?.filter((emp) => emp?.id == modalData.emp_id)[0]
        ?.employee?.permission_leaves_break_setting
        ?.annualLeaveSetting?.allowed_emergency_leaves;


  // Start of handling component for each selected option from balance type options
  const handleRenderSelectedOptionComponent = () => {
    switch (modalData?.balance_type_id) {
      case REQUESTS.PERMISSIONS:
        return (
          <>
            <div className="col-6 mt-2">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                validationName={`input.month`}
                formServerValidation={formServerValidation}
                formSubmitting={isSubmitting}
                name="month"
                label="month"
                optionLabel="value"
                optionValue="key"
                options={Constants.MonthsData}
                placeholder={t("month")}
                keepDefaultStyle
                containerStyle=" "
                icon="calendar"
              />
            </div>

            <div className="col-6 mt-2">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                formServerValidation={formServerValidation}
                name="year"
                validationName={`input.year`}
                label="year"
                optionLabel="value"
                optionValue="value"
                options={getYears(modalData.year)}
                placeholder={t("year")}
                keepDefaultStyle
                containerStyle=" "
                icon="calendar"
              />
            </div>
          </>
        );
      case REQUESTS.NORMAL_LEAVES:
        return (
          <>
            {canHaveEmergencyLeaves ? (
              <>
                <div className="col-md-8 mt-2 col-sm-12">
                  <CheckboxBooleanForm
                    formName={formName}
                    formNameValidation={formNameValidation}
                    formSubmitting={isSubmitting}
                    formServerValidation={formServerValidation}
                    options={[
                      `${
                        modalData?.operation === "1"
                          ? "Add Leaves to"
                          : "Deduct Leaves from"
                      } Emergency Credit`,
                    ]}
                    name="apply_on_emergency"
                    validationName="input.apply_on_emergency"
                    type="checkbox"
                  />
                </div>
                <div className="col-md-4 mt-2 col-sm-12">
                  <InputForm
                    formName={formName}
                    formNameValidation={formNameValidation}
                    formSubmitting={isSubmitting}
                    // formServerValidation={formServerValidation}
                    validateBy="textRequired"
                    type="number"
                    name="emergency_quantity"
                    dependOn="apply_on_emergency"
                    dependancyValue={[1]}
                    dependancyType="equal"
                    // validationName="input.emergency_quantity"
                    containerStyle=""
                    inputContainerStyle="input-container-style-default w-100 ml-0"
                    hasSuffix
                    suffixTitle={t("Days")}
                  />
                </div>
              </>
            ) : null}

            {serverVaildation["input.emergency_quantity"] ? (
              <div className="warnig-msg-style mb-1 mt-0 col-sm-12 ">
                {serverVaildation["input.emergency_quantity"]}
              </div>
            ) : null}

            {/* <div className="col-md-6 my-2 col-sm-12">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                validationName={`input.month`}
                formServerValidation={formServerValidation}
                formSubmitting={isSubmitting}
                name="month"
                label="month"
                optionLabel="value"
                optionValue="key"
                options={Constants.MonthsData}
                placeholder={t("month")}
                keepDefaultStyle
                containerStyle=" "
              />
            </div> */}

            <div className="mt-2 col-sm-12">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                formServerValidation={formServerValidation}
                name="year"
                validationName={`input.year`}
                label="year"
                optionLabel="value"
                optionValue="value"
                options={getYears(modalData.year)}
                placeholder={t("year")}
                keepDefaultStyle
                containerStyle=" "
                icon="calendar"
              />
            </div>
          </>
        );
      case REQUESTS.SICK_LEAVES:
        return (
          <>
            <div className="col">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                formServerValidation={formServerValidation}
                name="year"
                validationName={`input.year`}
                optionLabel="value"
                optionValue="value"
                options={getYears(modalData.year)}
                placeholder={t("year")}
                label="year"
                keepDefaultStyle
                containerStyle=" "
                icon="calendar"
              />
            </div>
          </>
        );
      // case "home":
      //   return (
      //     <div className="col-12">
      //       <DateTimePickerForm
      //         dateOnly
      //         formName={formName}
      //         formNameValidation={formNameValidation}
      //         validationName={`input.week`}
      //         formServerValidation={formServerValidation}
      //         formSubmitting={isSubmitting}
      //         name="weekPicker"
      //         datePickerContainer="w-100"
      //         label="Week"
      //         labelStyle="mb-2"
      //         containerStyle=" "
      //         selectsRange
      //         shouldCloseOnSelect={false}
      //         weekSelect
      //         diffDaysNumber={diffDaysNumber}
      //         startDate={
      //           modalData.weekPicker
      //             ? diffDaysNumber > 0
      //               ? new Date(
      //                   moment(
      //                     modalData.weekPicker,
      //                     "yyyy/MM/DD HH:mm"
      //                   ).subtract(diffDaysNumber, "days")
      //                 )
      //               : new Date(
      //                   moment(modalData.weekPicker, "yyyy/MM/DD HH:mm")
      //                 )
      //             : ""
      //         }
      //         endDate={
      //           modalData.weekPicker
      //             ? new Date(
      //                 moment(
      //                   modalData.weekPicker,
      //                   "yyyy/MM/DD HH:mm"
      //                 ).add(6 - diffDaysNumber, "days")
      //               )
      //             : ""
      //         }
      //       />
      //     </div>
      //   );

      default:
        return;
    }
  };
  // End of handling component for each selected option from balance balance_type_id options

  // Start of Balance balance_type_id options
  const balanceTypeOptions = [
    ...(canHaveAnnualLeave
      ? [{ label: "annual leaves", value: REQUESTS.NORMAL_LEAVES }]
      : []),
    ...(canHavePermissions
      ? [{ label: "permissions", value: REQUESTS.PERMISSIONS }]
      : []),
    ...(canHaveSickLeaves()
      ? [{ label: "sick leaves", value: REQUESTS.SICK_LEAVES }]
      : []),
  ];
  // End of Balance balance_type_id options

  // handel text area change
  const handleTextArea = (e) => {
    dispatch(onInputChangeAction(formName, e));
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (modalValidation.length) {
      return;
    }

    dispatch(
      balanceModalAttempt({
        ref: "balanceModalActions",
        formName,
        data: modalData,
        userId,
        refetchQueries,
      })
    );
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={() => dispatch(hideBalanceModal("balanceModalActions", formName))}
      modalTitle={t(`${modalData.type} balance`)}
      btnLabel="save"
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <div>
        {!!!userId && (
          <BSelect
            formName={formName}
            formNameValidation={formNameValidation}
            validationName={`input.emp_id`}
            formServerValidation={formServerValidation}
            formSubmitting={isSubmitting}
            validateBy="textRequired"
            name="emp_id"
            label="employee"
            optionLabel="name"
            optionValue="id"
            options={employeesFilter}
            filterOption={handleFilterOptionByEmpCodeOrName}
            placeholder={t("employee")}
            keepDefaultStyle
            containerStyle="mb-3"
            icon="person"
          />
        )}

        <RadioboxForm
          formName={formName}
          formNameValidation={formNameValidation}
          validationName={`input.operation`}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          validateBy="textRequired"
          name="operation"
          options={Constants.balanceOptions}
          optionsContainerStyle="row"
          containerStyle="my-2"
          optionItemStyle="col-3 text-nowrap"
          optionInputStyle=" "
        />

        <div className="row">
          <div className="col-md-8 mt-2 col-sm-12">
            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.balance_type_id`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              validateBy="textRequired"
              name="balance_type_id"
              label="from"
              optionLabel="label"
              optionValue="value"
              options={balanceTypeOptions}
              placeholder={t("select option")}
              keepDefaultStyle
              containerStyle=" "
              icon="type"
            />
          </div>

          <div className="col-md-4 mt-2 col-sm-12">
            <InputForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              formServerValidation={formServerValidation}
              validateBy="textRequired"
              type="number"
              name="quantity"
              validationName="input.quantity"
              label="quantity"
              labelStyle="mb-2"
              containerStyle=" "
              inputContainerStyle="input-container-style-default w-100 ml-0"
              hasSuffix
              suffixTitle={
                modalData?.balance_type_id === REQUESTS.PERMISSIONS
                  ? t("Minutes")
                  : t("Days")
              }
            />
          </div>

          {handleRenderSelectedOptionComponent()}
        </div>

        <div className="row mt-2 mb-2">
          <div
            className={`col-12 ${
              "input.reason" in serverVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="w-100">
              <span className="validity-label-style mb-1 d-block">
                {t("reason")}
              </span>
              <textarea
                rows="4"
                name="reason"
                placeholder={t("state reason")}
                onChange={handleTextArea}
                value={modalData?.reason}
              />
              <span className="validity-msg-style mt-2">
                {"input.reason" in serverVaildation
                  ? serverVaildation["input.reason"]
                  : null}
              </span>
            </label>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style mt-3">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default BalanceModal;
