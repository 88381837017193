import { put, call, takeLatest, select } from "redux-saga/effects";
import Types from "../Actions/types";

import * as Action from "../Actions";
import * as Client from "../../Services/Client";

import HelperFns from "../../Helpers/HelperFns";

export const getAdminReducer = (state) => state.admin;

// handle generta demo

export function* handleGenertaDemoSaga({ payload }) {
  try {
    const response = yield call(Client.generateDemo, payload);
    if (
      !response.errors &&
      response?.data?.generate_demo?.status === "success"
    ) {
      yield put(Action.generateDemoSuccess());
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.generateDemoFailed());
      yield put(Action.showErrorToast(response?.data?.generate_demo?.message));
    }
  } catch (error) {
    yield put(Action.generateDemoFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onGenertaDemoSaga() {
  yield takeLatest(Types.GENERATE_DEMO_ATTEMPT, handleGenertaDemoSaga);
}

export function* handleFetchAdminHolidaysListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchAdminHolidaysList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchAdminHolidaysSuccess(
          payload?.ref,
          response?.data?.admin_holidays
        )
      );
      yield put(
        Action.fetchAdminHoildaysCountriesOptionsAction(
          response?.data?.countries
        )
      );
    } else {
      yield put(Action.fetcAdminHolidaysFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetcAdminHolidaysFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchAdminHolidays() {
  yield takeLatest(
    Types.FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT,
    handleFetchAdminHolidaysListSaga
  );
}

export function* handleUpsertAdminHolidaySaga({ payload }) {
  try {
    const response = yield call(Client.upsertAdminHoliday, payload?.data);
    if (!response?.errors) {
      yield put(Action.upsertAdminHolidaysSuccess(payload.ref));
      yield put(
        Action.dismissAdminHolidaysModalAction({
          ref: payload.ref,
          formName: "adminHolidaysModal",
        })
      );
      yield put(Action.showSuccessToast());
      let adminReducer = yield select(getAdminReducer);
      yield put(
        Action.fetchAdminHolidaysAction({
          ref: "adminHolidaysList",
          adminHolidaysList: adminReducer["adminHolidaysList"],
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertAdminHolidaysFailed({
            ref: payload.ref,
            serverRef: "adminHolidaysModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertAdminHolidaysFailed({
            ref: payload.ref,
            serverRef: "adminHolidaysModalServerValidation",
            errors: {},
            modalMessage:
              response?.errors[0]?.extensions?.reason ??
              response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertAdminHolidaysFailed({
        ref: payload.ref,
        serverRef: "adminHolidaysModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertAdminHoliday() {
  yield takeLatest(
    Types.UPSERT_ADMIN_HOLIDAYS_ATTEMPT,
    handleUpsertAdminHolidaySaga
  );
}

export function* handleDeleteAdminHolidaysSaga({ payload }) {
  try {
    const response = yield call(Client.deleteAdminHolidays, payload);
    if (
      !response.errors &&
      response.data?.delete_admin_holiday?.status == "success"
    ) {
      yield put(Action.deleteAdminHolidaySuccess());
      yield put(
        Action.showSuccessToast(response.data?.delete_admin_holiday?.message)
      );
      let adminReducer = yield select(getAdminReducer);
      yield put(
        Action.fetchAdminHolidaysAction({
          ref: "adminHolidaysList",
          adminHolidaysList: adminReducer["adminHolidaysList"],
        })
      );
    } else {
      yield put(Action.deleteAdminHolidayFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_admin_holiday?.message ||
            response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteAdminHolidayFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteAdminHolidays() {
  yield takeLatest(
    Types.DELETE_ADMIN_HOLIDAYS_ATTEMPT,
    handleDeleteAdminHolidaysSaga
  );
}

export default {
  onFetchAdminHolidays,
  onUpsertAdminHoliday,
  onDeleteAdminHolidays,
  onGenertaDemoSaga,
};
