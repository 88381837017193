const optionsBuilder = ({ arr, key = "id", val = "value" }) =>
  arr.map((a) => ({ [key]: a, [val]: a }));

const paymentType = ["Fixed", "Ramp"];
const pricing = ["Tier based", "Stairstep", "Volume pricing"];
const billingPeriod = ["Monthly", "Quarterly", "Semi-annually", "Annually"];
export const PaymentStats = {
  SENT: "Sent",
  PAID: "Paid",
  OVERDUE: "Overdue",
  PENDING: "Pending",
  UPCOMING: "Upcoming",
  GENERATED: "Generated",
  CANCELLED: "Cancelled",
};

export const pricingOptions = optionsBuilder({ arr: pricing, key: "label" });
export const billingPeriodOptions = optionsBuilder({
  arr: billingPeriod,
  val: "name",
});
export const paymentTypeOptions = optionsBuilder({
  arr: paymentType,
  key: "label",
});
export const promotionTypeOptions = [
  { id: "Fixed", name: "Discount a Fixed Amount" },
  { id: "Percentage", name: "Discount a percentage" },
  // { id: "2+1", name: "2 + 1 Months Free" },
];

export const paymentOptions = {
  paymentMethods: [
    { id: "Cash", name: "Cash" },
    { id: "Bank", name: "Bank Transfer" },
    { id: "Wallet", name: "Wallet" },
    { id: "Prepaid", name: "Prepaid Card" },
  ],

  // Banks
  bankNames: optionsBuilder({ arr: ["CIB Bank"], val: "name" }),
  accountNumbers: {
    "CIB Bank": optionsBuilder({ arr: ["100061558677"], val: "name" }),
  },
  IBANs: {
    "CIB Bank": optionsBuilder({
      arr: ["EG330010013000000100061558677"],
      val: "name",
    }),
  },

  // Mobile Wallets
  wallets: optionsBuilder({ arr: ["Vodafone Cash"], val: "name" }),
  phoneNumbers: {
    "Vodafone Cash": optionsBuilder({ arr: ["01096120789"], val: "name" }),
  },

  // Prepaid Card
  cards: optionsBuilder({ arr: [""], val: "name" }),
  cardNumbers: { "": optionsBuilder({ arr: [""], val: "name" }) },
};
