import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";

import {
  serializePayInvoice,
  serializeFetchPayments,
} from "../../Helpers/HelperFns/PaymentTracking";
import moment from "moment";
import { onFormResetAction } from "../../Store/Actions";
import { payInvoiceFormQuery } from "../../Graphql/query/PaymentTracking";
import { payInvoiceMutation } from "../../Graphql/mutation/PaymentTracking";
import HelperFns, { safeJsonParse, showToast } from "../../Helpers/HelperFns";

import Loader from "../Loader";
import MainModal from "../MainModal";
import Dropzone from "react-dropzone-uploader";
import { PaymentInfo } from "../PaymentTracking";
import Typography from "@mui/material/Typography";
import { InputForm, DateTimePickerForm, BSelect } from "form-builder";
import { PreviewIconButton, TimesIconButton } from "../IconButtonWithTooltip";

const reducer = "paymentTracking";
const formName = "payInvoiceModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const PayInvoiceModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [file, setFile] = React.useState(null);
  const [errMsg, setErrMsg] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [payInvoice, { loading: editLoading }] =
    useMutation(payInvoiceMutation);
  const { loading } = useQuery(payInvoiceFormQuery, {
    variables: { id: data?.id },
    onCompleted: ({ payment = {}, users }) => {
      setOptions((prev) => ({ ...prev, users }));
      payment?.file && setFile(payment?.file);
      dispatch(
        onFormResetAction(formName, {
          id: data?.id,
          collector: payment?.collector?.id,
          amount: payment?.receivedAmount || payment?.amount,
          received_at: payment?.received_at || moment().format("YYYY-MM-DD"),
          ...serializeFetchPayments(safeJsonParse(payment?.method)),
        })
      );
    },
  });

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleUploadChange = ({ file }, status) => {
    if (status === "done") setFile(file);
    if (status === "removed") setFile(null);
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation?.length) return;

    payInvoice({
      variables: serializePayInvoice({ file, ...formData }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Pay Invoice"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={editLoading}
      className="documents_dropzone_style"
    >
      {loading ? <Loader fixed /> : null}

      <InputForm
        {...FormProps}
        name="amount"
        label="actual Amount"
        icon="money"
        rootStyle="w-50 d-inline-block pr-2 mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
        isLoading={loading}
        validationName="amount"
      />
      <DateTimePickerForm
        {...FormProps}
        name="received_at"
        label="receiving Date"
        hasIcon
        containerStyle="w-100"
        rootStyle="d-inline-block w-50 mb-2"
        isLoading={loading}
        validationName="due_date"
      />
      <BSelect
        {...FormProps}
        label="collected by"
        name="collector"
        icon="employees"
        validateBy="textRequired"
        options={options?.users || []}
      />

      <PaymentInfo FormProps={FormProps} />

      <span className="d-block my-2">{t("proof of payment")}</span>
      {file?.id ? (
        <>
          <div className="d-flex gap-20 align-items-center justify-content-between">
            <Typography noWrap fontSize={14}>
              {file?.path}
            </Typography>
            <PreviewIconButton
              onClick={() => HelperFns.previewFile(file?.path)}
            />
            <TimesIconButton onClick={() => setFile(null)} />
          </div>
        </>
      ) : (
        <Dropzone
          canRemove
          maxFiles="1"
          name="attachments"
          onChangeStatus={handleUploadChange}
        />
      )}

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default PayInvoiceModal;
