import { combineReducers } from "redux";

import { authReducer, initState as authInitState } from "./AuthReducer";
import { userReducer, initState as userInitState } from "./UserReducer";
import { superReducer, initState as superInitState } from "./SuperReducer";
import { MonthlyReportReducer } from "./MonthlyReportReducer";
import { adminReducer, initState as adminInitState } from "./AdminReducer";
import {
  financesReducer,
  initState as financesInitState,
} from "./FinancesReducer";
import {
  documentsReducer,
  initState as documentsInitState,
} from "./DocumentsReducer";
import {
  dashboardUiReducer,
  initState as dashboardUiInitState,
} from "./DashboardUiReducer";
import {
  AssignemntsReducer,
  initState as assignmentsInitState,
} from "./AssignmentsReducer";
import { companyWizardReducer } from "./CompanyWizardReducer";
import {
  recruitmentReducer,
  initState as recruitmentInitState,
} from "./RecruitmentReducer";
import {
  checkInsReducer,
  initState as checkInsInitState,
} from "./CheckInsReducer";
import { shiftsReducer, initState as shiftsInitState } from "./ShiftsReducer";
import CRMReducer, { initState as crmInitState } from "./CRMReducer";
import feedbackReducer from "./FeedbackReducer";
import PaymentTrackingReducer, {
  initState as paymentTrackingInitState,
} from "./PaymentTrackingReducer";

import withBuilder from "../../Helpers/HOC/withBuilder";
import {
  LoginUserList,
  initState as LoginUserListInitState,
} from "./LoginUserList";

import settingsReducer, {
  initState as settingsInitState,
} from "./SettingsReducer";
import { assetsReducer, initState as assetsInitState } from "./AssetsReducer";

export default combineReducers({
  feedback: feedbackReducer,
  auth: withBuilder(authReducer, authInitState),
  user: withBuilder(userReducer, userInitState),
  super: withBuilder(superReducer, superInitState),
  monthlyReport: MonthlyReportReducer,
  finances: withBuilder(financesReducer, financesInitState),
  paymentTracking: withBuilder(PaymentTrackingReducer, paymentTrackingInitState),
  documents: withBuilder(documentsReducer, documentsInitState),
  admin: withBuilder(adminReducer, adminInitState),
  dashboardUi: withBuilder(dashboardUiReducer, dashboardUiInitState),
  wizard: companyWizardReducer,
  recruitment: withBuilder(recruitmentReducer, recruitmentInitState),
  checkIns: withBuilder(checkInsReducer, checkInsInitState),
  assignments: withBuilder(AssignemntsReducer, assignmentsInitState),
  shifts: withBuilder(shiftsReducer, shiftsInitState),
  settings: withBuilder(settingsReducer, settingsInitState),
  crm: withBuilder(CRMReducer, crmInitState),
  LoginUserList: withBuilder(LoginUserList, LoginUserListInitState),
  assets: withBuilder(assetsReducer, assetsInitState),
});
