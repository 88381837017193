import React, { useEffect, useState } from "react";
import MainModal from "../MainModal";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { BSelect } from "form-builder";
import {
  faCheck,
  faPen,
  faTimes,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  dismissAdminUserProfileModalAction,
  updateAdminUserProfileAction,
} from "../../Store/Actions";
import Loader from "../Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import SASS from "../../Constants/SassConstants";

const formName = "adminUsersProfileModal";
const formNameValidation = "adminUsersProfileModalValidation";
const formServerValidation = "adminUsersProfileModalServerValidation";

const AdminUserProfile = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Start of handle the show and hide of assigned companies select component
  const [isShowEdit, setIsShowEdit] = useState(false);

  const handleShowAssignedCompanies = () => {
    setIsShowEdit(true);
  };

  const handleHideAssignedCompanies = () => {
    setIsShowEdit(false);
  };
  // End of handle the show and hide of assigned companies select component

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const isModalVissible = props?.modalData?.isVissible;
  const modalMessage = props?.modalData?.modalMessage;
  const isLoading = props?.modalData?.isLoading;

  useEffect(() => {
    setIsSubmitting(false);
    setIsShowEdit(false);
  }, [isModalVissible]);

  const toggleModal = () => {
    props.dismissAdminUserProfileModalAction({
      ref: "adminUsersProfileModalActions",
      formName,
    });
  };

  if (props?.isCompaniesLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.updateAdminUserProfileAction({
      data: props?.adminUsersProfileModal,
    });

    setIsShowEdit(false);
  };

  return (
    <div>
      <MainModal
        isOpen={isModalVissible}
        toggle={toggleModal}
        modalTitle={t("Admin User Profile")}
        className="admin_user_profile_modal_wrapper_style"
      >
        {props?.isUpdateUserProfileLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div>
          <div>
            <h2>{props?.adminUsersProfileModal?.name}</h2>
            <div className="d-flex">
              <p className="border-right pr-2">
                {props?.adminUsersProfileModal?.phone_country_code}
                {props?.adminUsersProfileModal?.phone}
              </p>
              <p className="pl-2">{props?.adminUsersProfileModal?.email}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex align-items-baseline mt-2">
            <div className="mr-3 mb-2">
              <h2>{t("Assigned Companies")}</h2>
            </div>

            <HasPrivileges
              reqireMain={[
                Privilages.SUPER_PRIVILEGE,
                Privilages.ASSIGN_REMOVE_ADMIN_USER_TO_COMPANIES,
              ]}
            >
              {props?.adminUsersProfileModal?.role_id == SASS.ADMIN ? (
                ""
              ) : (
                <div>
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    onClick={handleShowAssignedCompanies}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                </div>
              )}
            </HasPrivileges>
          </div>

          {!isShowEdit ? (
            props?.adminUsersProfileModal?.role_id == SASS.ADMIN ? (
              <div className="row assigned_company_serction_wrapper_style flex-wrap px-3">
                <div className="d-flex fit-width mr-3 my-1">
                  <p>
                    <b className="pr-2">
                      <FontAwesomeIcon icon={faBuilding} />
                    </b>
                    {t("All Companies")}
                  </p>
                </div>
              </div>
            ) : (
              <div className="row assigned_company_serction_wrapper_style flex-wrap px-3">
                {props?.adminUsersProfileModal?.company_names.map(
                  (companyName) => (
                    <div className="d-flex fit-width mr-3 my-1">
                      <p>
                        <b className="pr-2">
                          <FontAwesomeIcon icon={faBuilding} />
                        </b>
                        {companyName}
                      </p>
                    </div>
                  )
                )}
              </div>
            )
          ) : (
            ""
          )}

          {isShowEdit ? (
            <div className="row add_assigned_company_wrapper_style align-items-baseline">
              <div className="col-10 my-0 pr-0">
                <BSelect
                  {...FormProps}
                  reducer="super"
                  name="company_ids"
                  validationName={`input.company_ids`}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.adminUserCompaniesData}
                  isMulti={true}
                  inputContainerStyle="mt-0"
                  placeholder={t("select company")}
                  validateBy="textRequired"
                />
              </div>

              <div className="d-flex col-2">
                <div className="px-1">
                  {isLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#009efb",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      icon={faCheck}
                      onClick={handleSubmitBtn}
                    />
                  )}
                </div>

                <div className="px-2">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faTimes}
                    onClick={handleHideAssignedCompanies}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </MainModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalData: state.super.adminUsersProfileModalActions,
    modalValidation: state.super[formNameValidation],
    adminUsersProfileModal: state.super[formName],
    serverVaildation: state.super[formServerValidation],
    adminUserCompaniesData: state.super.adminUserCompaniesData,
    isCompaniesLoading: state?.super?.adminUserCompaniesLoading,
    isUpdateUserProfileLoading: state?.super?.updateAdminUserProfileLoading,
  };
};

export default connect(mapStateToProps, {
  dismissAdminUserProfileModalAction,
  updateAdminUserProfileAction,
})(AdminUserProfile);
